// @ts-nocheck
/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * beam-indexer API
 * You are viewing the reference docs for the beam-indexer API.
 * OpenAPI spec version: 0.0.0
 */
import {
  useInfiniteQuery,
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import { client } from './reservoir-axios-client';
import type { ErrorType, BodyType } from './reservoir-axios-client';
export type PostExecuteSolveV1Params = {
/**
 * Signature for the solve request
 */
signature?: string;
};

export type PostExecutePresignatureV1Params = {
/**
 * Signature to attach to the pre-signature
 */
signature: string;
};

export type PostExecutePermitsignatureV1Params = {
/**
 * Signature to attach to the permit
 */
signature: string;
};

export type PostExecuteCancelsignatureV1Params = {
/**
 * Cancellation signature
 */
signature?: string;
/**
 * Optional auth token used instead of the signature
 */
auth?: string;
};

export type PostExecuteCancelimtblV1Params = {
/**
 * Cancellation signature
 */
signature?: string;
};

export type PostExecuteAuthsignatureV1Params = {
/**
 * Signature to attach to the auth challenge
 */
signature: string;
};

/**
 * 1 - All calls per hour/day/month<br>2 - All calls per key per hour/day/month<br>3 - All calls per key per route per hour/day/month<br>4 - All calls per key per route per status code per hour/day/month<br>
 */
export type PostAdminApikeysMetricsBodyGroupBy = typeof PostAdminApikeysMetricsBodyGroupBy[keyof typeof PostAdminApikeysMetricsBodyGroupBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostAdminApikeysMetricsBodyGroupBy = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
} as const;

/**
 * Return results by either hourly/daily/monthly granularity.<br>Hourly will return time in format YYYY-MM-DDTHH:00:000Z<br>Daily will return time in format YYYY-MM-DDT00:00:000Z<br>Monthly will return time in format YYYY-MM-01T00:00:000Z<br>
 */
export type PostAdminApikeysMetricsBodyGranularity = typeof PostAdminApikeysMetricsBodyGranularity[keyof typeof PostAdminApikeysMetricsBodyGranularity];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostAdminApikeysMetricsBodyGranularity = {
  hourly: 'hourly',
  daily: 'daily',
  monthly: 'monthly',
} as const;

export type PostAdminApikeysMetricsBody = {
  /** Get metrics before a particular time (allowed format YYYY-MM-DD HH:00) */
  endTime?: string;
  /** Return results by either hourly/daily/monthly granularity.<br>Hourly will return time in format YYYY-MM-DDTHH:00:000Z<br>Daily will return time in format YYYY-MM-DDT00:00:000Z<br>Monthly will return time in format YYYY-MM-01T00:00:000Z<br> */
  granularity?: PostAdminApikeysMetricsBodyGranularity;
  /** 1 - All calls per hour/day/month<br>2 - All calls per key per hour/day/month<br>3 - All calls per key per route per hour/day/month<br>4 - All calls per key per route per status code per hour/day/month<br> */
  groupBy?: PostAdminApikeysMetricsBodyGroupBy;
  /** Array API keys */
  keys: string[];
  /** Get metrics after a particular time (allowed format YYYY-MM-DD HH:00)<br>Hourly default to last 24 hours<br>Daily default to last 7 days<br>Monthly default to last 12 months */
  startTime?: string;
};

export type PostOrderV4Params = {
signature?: string;
};

export type PostOrderV3Params = {
signature?: string;
};

export type PostOrderV2Params = {
signature?: string;
};

export type GetRedirectTokensTokenImageV1ImageSize = typeof GetRedirectTokensTokenImageV1ImageSize[keyof typeof GetRedirectTokensTokenImageV1ImageSize];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetRedirectTokensTokenImageV1ImageSize = {
  small: 'small',
  medium: 'medium',
  large: 'large',
} as const;

export type GetRedirectTokensTokenImageV1Params = {
/**
 * Image size: 'small', 'medium', or 'large'. 
 */
imageSize?: GetRedirectTokensTokenImageV1ImageSize;
};

export type GetOrdersUsersUserTopbidsV4SortDirection = typeof GetOrdersUsersUserTopbidsV4SortDirection[keyof typeof GetOrdersUsersUserTopbidsV4SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersUsersUserTopbidsV4SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetOrdersUsersUserTopbidsV4SortBy = typeof GetOrdersUsersUserTopbidsV4SortBy[keyof typeof GetOrdersUsersUserTopbidsV4SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersUsersUserTopbidsV4SortBy = {
  topBidValue: 'topBidValue',
  dateCreated: 'dateCreated',
  orderExpiry: 'orderExpiry',
  floorDifferencePercentage: 'floorDifferencePercentage',
} as const;

export type GetOrdersUsersUserTopbidsV4Params = {
collection?: string;
/**
 * Filter to a particular contracts set.
 */
contractsSetId?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * Filter to a particular collection set. Example: `8daa732ebe5db23f267e58d52f1c9b1879279bcdf4f78b8fb563390e6946ea65`
 */
collectionsSetId?: string;
/**
 * If true, urls will only be returned for optimized sources that support royalties.
 */
optimizeCheckoutURL?: boolean;
/**
 * If true, criteria metadata is included in the response.
 */
includeCriteriaMetadata?: boolean;
/**
 * Exclude orders that can only be filled by EOAs, to support filling with smart contracts.
 */
excludeEOA?: boolean;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * If true, will return the collection non flagged floor ask events.
 */
useNonFlaggedFloorAsk?: boolean;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Order of the items are returned in the response. Options are `topBidValue`, `dateCreated`, `orderExpiry`, and `floorDifferencePercentage`.
 */
sortBy?: GetOrdersUsersUserTopbidsV4SortBy;
sortDirection?: GetOrdersUsersUserTopbidsV4SortDirection;
/**
 * Amount of items returned in response. Max limit is 100
 */
limit?: number;
/**
 * Amount of tokens considered. Min is 1000, max is default.
 */
sampleSize?: number;
/**
 * Input any ERC20 address to return result in given currency
 */
displayCurrency?: string;
};

export type GetCollectionsCollectionAttributesAllV4Params = {
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetCollectionsCollectionAttributesExploreV5Params = {
/**
 * Filter to a particular token-id. Example: `1`
 */
tokenId?: string;
/**
 * If true, top bid will be returned in the response.
 */
includeTopBid?: boolean;
/**
 * If true, range traits will be excluded from the response.
 */
excludeRangeTraits?: boolean;
/**
 * If true, number traits will be excluded from the response.
 */
excludeNumberTraits?: boolean;
/**
 * Filter to a particular attribute key. Example: `Composition`
 */
attributeKey?: string;
/**
 * Max number of items returned in the response.
 */
maxFloorAskPrices?: number;
/**
 * Max number of items returned in the response.
 */
maxLastSells?: number;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response. Default limit is 20. Max limit is 5000.
 */
limit?: number;
};

export type GetUsersUserTokensV7SortDirection = typeof GetUsersUserTokensV7SortDirection[keyof typeof GetUsersUserTokensV7SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersUserTokensV7SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetUsersUserTokensV7SortBy = typeof GetUsersUserTokensV7SortBy[keyof typeof GetUsersUserTokensV7SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersUserTokensV7SortBy = {
  acquiredAt: 'acquiredAt',
  lastAppraisalValue: 'lastAppraisalValue',
} as const;

export type GetUsersUserTokensV7Params = {
/**
 * Filter to a particular community, e.g. `artblocks`
 */
community?: string;
/**
 * Filter to a particular collection set. Example: `8daa732ebe5db23f267e58d52f1c9b1879279bcdf4f78b8fb563390e6946ea65`
 */
collectionsSetId?: string;
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular contract, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Array of tokens. Max limit is 50. Example: `tokens[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:704 tokens[1]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:979`
 */
tokens?: string[];
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * Order the items are returned in the response. Options are `acquiredAt` and `lastAppraisalValue`. `lastAppraisalValue` is the value of the last sale.
 */
sortBy?: GetUsersUserTokensV7SortBy;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetUsersUserTokensV7SortDirection;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response. Max limit is 200.
 */
limit?: number;
/**
 * If true, top bid will be returned in the response.
 */
includeTopBid?: boolean;
/**
 * If true, attributes will be returned in the response.
 */
includeAttributes?: boolean;
/**
 * If true, last sale data including royalties paid will be returned in the response.
 */
includeLastSale?: boolean;
/**
 * If true, raw data is included in the response.
 */
includeRawData?: boolean;
/**
 * If true, will filter any tokens marked as spam.
 */
excludeSpam?: boolean;
/**
 * If true, will return the collection non flagged floor ask.
 */
useNonFlaggedFloorAsk?: boolean;
/**
 * Input any ERC20 address to return result in given currency. Applies to `topBid` and `floorAsk`.
 */
displayCurrency?: string;
};

export type GetUsersUserPositionsV1Status = typeof GetUsersUserPositionsV1Status[keyof typeof GetUsersUserPositionsV1Status];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersUserPositionsV1Status = {
  valid: 'valid',
  invalid: 'invalid',
} as const;

export type GetUsersUserPositionsV1Side = typeof GetUsersUserPositionsV1Side[keyof typeof GetUsersUserPositionsV1Side];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersUserPositionsV1Side = {
  buy: 'buy',
  sell: 'sell',
} as const;

export type GetUsersUserPositionsV1Params = {
side: GetUsersUserPositionsV1Side;
status: GetUsersUserPositionsV1Status;
offset?: number;
limit?: number;
};

export type GetUsersUserCollectionsV3Params = {
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * Filter to a particular collection set. Example: `8daa732ebe5db23f267e58d52f1c9b1879279bcdf4f78b8fb563390e6946ea65`
 */
collectionsSetId?: string;
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * If true, top bid will be returned in the response.
 */
includeTopBid?: boolean;
/**
 * If true, number of tokens with bids will be returned in the response.
 */
includeLiquidCount?: boolean;
/**
 * If true, will filter any collections marked as spam.
 */
excludeSpam?: boolean;
/**
 * Use offset to request the next batch of items. Max is 10,000.
 */
offset?: number;
/**
 * Amount of items returned in response. max limit is 100.
 */
limit?: number;
/**
 * Input any ERC20 address to return result in given currency. Applies to `topBid` and `floorAsk`.
 */
displayCurrency?: string;
};

export type GetTokensTokenActivityV5TypesItem = typeof GetTokensTokenActivityV5TypesItem[keyof typeof GetTokensTokenActivityV5TypesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensTokenActivityV5TypesItem = {
  sale: 'sale',
  ask: 'ask',
  transfer: 'transfer',
  mint: 'mint',
  bid: 'bid',
  bid_cancel: 'bid_cancel',
  ask_cancel: 'ask_cancel',
} as const;

export type GetTokensTokenActivityV5SortBy = typeof GetTokensTokenActivityV5SortBy[keyof typeof GetTokensTokenActivityV5SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensTokenActivityV5SortBy = {
  eventTimestamp: 'eventTimestamp',
  createdAt: 'createdAt',
} as const;

export type GetTokensTokenActivityV5Params = {
/**
 * Amount of items returned. Default and max is 20.
 */
limit?: number;
/**
 * Order the items are returned in the response. The blockchain event time is `eventTimestamp`. The event time recorded is `createdAt`.
 */
sortBy?: GetTokensTokenActivityV5SortBy;
/**
 * If true, metadata is included in the response.
 */
includeMetadata?: boolean;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * If true, will filter any activities marked as spam.
 */
excludeSpam?: boolean;
types?: GetTokensTokenActivityV5TypesItem[];
/**
 * Input any ERC20 address to return result in given currency
 */
displayCurrency?: string;
};

export type GetTokensFlagChangesV1FlagStatus = typeof GetTokensFlagChangesV1FlagStatus[keyof typeof GetTokensFlagChangesV1FlagStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensFlagChangesV1FlagStatus = {
  NUMBER_MINUS_1: -1,
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;

export type GetTokensFlagChangesV1Params = {
/**
 * -1 = All tokens (default)
0 = Non flagged tokens
1 = Flagged tokens
 */
flagStatus?: GetTokensFlagChangesV1FlagStatus;
/**
 * Amount of items returned in response. Max is 200.
 */
limit?: number;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
};

export type GetOracleTokensStatusV3Signer = typeof GetOracleTokensStatusV3Signer[keyof typeof GetOracleTokensStatusV3Signer];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOracleTokensStatusV3Signer = {
  '0x32da57e736e05f75aa4fae2e9be60fd904492726': '0x32da57e736e05f75aa4fae2e9be60fd904492726',
  '0xaeb1d03929bf87f69888f381e73fbf75753d75af': '0xaeb1d03929bf87f69888f381e73fbf75753d75af',
} as const;

export type GetOracleTokensStatusV3Params = {
tokens: string[];
signer?: GetOracleTokensStatusV3Signer;
};

export type GetOracleCollectionsTopbidV3Signer = typeof GetOracleCollectionsTopbidV3Signer[keyof typeof GetOracleCollectionsTopbidV3Signer];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOracleCollectionsTopbidV3Signer = {
  '0x32da57e736e05f75aa4fae2e9be60fd904492726': '0x32da57e736e05f75aa4fae2e9be60fd904492726',
  '0xaeb1d03929bf87f69888f381e73fbf75753d75af': '0xaeb1d03929bf87f69888f381e73fbf75753d75af',
} as const;

export type GetOracleCollectionsTopbidV3Kind = typeof GetOracleCollectionsTopbidV3Kind[keyof typeof GetOracleCollectionsTopbidV3Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOracleCollectionsTopbidV3Kind = {
  spot: 'spot',
  twap: 'twap',
  lower: 'lower',
  upper: 'upper',
} as const;

export type GetOracleCollectionsTopbidV3Params = {
kind?: GetOracleCollectionsTopbidV3Kind;
currency?: string;
twapSeconds?: number;
collection?: string;
token?: string;
signer?: GetOracleCollectionsTopbidV3Signer;
};

export type GetOracleCollectionsFlooraskV6Signer = typeof GetOracleCollectionsFlooraskV6Signer[keyof typeof GetOracleCollectionsFlooraskV6Signer];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOracleCollectionsFlooraskV6Signer = {
  '0x32da57e736e05f75aa4fae2e9be60fd904492726': '0x32da57e736e05f75aa4fae2e9be60fd904492726',
  '0xaeb1d03929bf87f69888f381e73fbf75753d75af': '0xaeb1d03929bf87f69888f381e73fbf75753d75af',
} as const;

export type GetOracleCollectionsFlooraskV6Kind = typeof GetOracleCollectionsFlooraskV6Kind[keyof typeof GetOracleCollectionsFlooraskV6Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOracleCollectionsFlooraskV6Kind = {
  spot: 'spot',
  twap: 'twap',
  lower: 'lower',
  upper: 'upper',
} as const;

export type GetOracleCollectionsFlooraskV6Params = {
kind?: GetOracleCollectionsFlooraskV6Kind;
currency?: string;
twapSeconds?: number;
eip3668Calldata?: string;
collection?: string;
token?: string;
/**
 * If true, will use the collection non flagged floor ask events.
 */
useNonFlaggedFloorAsk?: boolean;
signer?: GetOracleCollectionsFlooraskV6Signer;
};

export type GetOracleCollectionsBidaskmidpointV1Signer = typeof GetOracleCollectionsBidaskmidpointV1Signer[keyof typeof GetOracleCollectionsBidaskmidpointV1Signer];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOracleCollectionsBidaskmidpointV1Signer = {
  '0x32da57e736e05f75aa4fae2e9be60fd904492726': '0x32da57e736e05f75aa4fae2e9be60fd904492726',
  '0xaeb1d03929bf87f69888f381e73fbf75753d75af': '0xaeb1d03929bf87f69888f381e73fbf75753d75af',
} as const;

export type GetOracleCollectionsBidaskmidpointV1Kind = typeof GetOracleCollectionsBidaskmidpointV1Kind[keyof typeof GetOracleCollectionsBidaskmidpointV1Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOracleCollectionsBidaskmidpointV1Kind = {
  spot: 'spot',
  twap: 'twap',
  lower: 'lower',
  upper: 'upper',
} as const;

export type GetOracleCollectionsBidaskmidpointV1Params = {
kind?: GetOracleCollectionsBidaskmidpointV1Kind;
currency?: string;
twapSeconds?: number;
collection?: string;
token?: string;
signer?: GetOracleCollectionsBidaskmidpointV1Signer;
};

export type GetEventsTokensFlooraskV4SortDirection = typeof GetEventsTokensFlooraskV4SortDirection[keyof typeof GetEventsTokensFlooraskV4SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEventsTokensFlooraskV4SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetEventsTokensFlooraskV4Params = {
contract?: string;
/**
 * Filter to a particular token, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
sortDirection?: GetEventsTokensFlooraskV4SortDirection;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
continuation?: string;
/**
 * Amount of items returned in response. Max limit is 1000.
 */
limit?: number;
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetEventsCollectionsTopbidV2SortDirection = typeof GetEventsCollectionsTopbidV2SortDirection[keyof typeof GetEventsCollectionsTopbidV2SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEventsCollectionsTopbidV2SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetEventsCollectionsTopbidV2Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetEventsCollectionsTopbidV2SortDirection;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response. Max limit is 1000.
 */
limit?: number;
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetEventsCollectionsFlooraskV2SortDirection = typeof GetEventsCollectionsFlooraskV2SortDirection[keyof typeof GetEventsCollectionsFlooraskV2SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEventsCollectionsFlooraskV2SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetEventsCollectionsFlooraskV2Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * If true, will exclude floor asks on flagged tokens. (only supported when `normalizeRoyalties` is false)
 */
excludeFlaggedTokens?: boolean;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetEventsCollectionsFlooraskV2SortDirection;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response. Max limit is 1000.
 */
limit?: number;
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetCollectionsCollectionMarketplaceconfigurationsV1Params = {
/**
 * When set, token-level royalties will be returned in the response
 */
tokenId?: string;
};

export type GetCollectionsCollectionAttributesV1SortBy = typeof GetCollectionsCollectionAttributesV1SortBy[keyof typeof GetCollectionsCollectionAttributesV1SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsCollectionAttributesV1SortBy = {
  floorAskPrice: 'floorAskPrice',
  topBidValue: 'topBidValue',
} as const;

export type GetCollectionsCollectionAttributesV1Params = {
attributeKey?: string;
sortBy?: GetCollectionsCollectionAttributesV1SortBy;
offset?: number;
limit?: number;
};

export type GetCollectionsCollectionSupportedmarketplacesV1Params = {
/**
 * When set, token-level royalties will be returned in the response
 */
tokenId?: string;
};

export type GetUsersActivityV6TypesItem = typeof GetUsersActivityV6TypesItem[keyof typeof GetUsersActivityV6TypesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersActivityV6TypesItem = {
  sale: 'sale',
  ask: 'ask',
  transfer: 'transfer',
  mint: 'mint',
  bid: 'bid',
  bid_cancel: 'bid_cancel',
  ask_cancel: 'ask_cancel',
} as const;

export type GetUsersActivityV6SortBy = typeof GetUsersActivityV6SortBy[keyof typeof GetUsersActivityV6SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersActivityV6SortBy = {
  eventTimestamp: 'eventTimestamp',
  createdAt: 'createdAt',
} as const;

export type GetUsersActivityV6Params = {
/**
 * Array of users addresses. Max is 50. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
users: string[];
collection?: string[];
/**
 * If true, will filter any activities marked as spam.
 */
excludeSpam?: boolean;
/**
 * Filter to a particular collection set.
 */
collectionsSetId?: string;
/**
 * Filter to a particular contracts set.
 */
contractsSetId?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * Order the items are returned in the response. The blockchain event time is `eventTimestamp`. The event time recorded is `createdAt`.
 */
sortBy?: GetUsersActivityV6SortBy;
/**
 * If true, metadata is included in the response.
 */
includeMetadata?: boolean;
/**
 * Amount of items returned in response. If `includeMetadata=true` max limit is 20, otherwise max limit is 1,000.
 */
limit?: number;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
types?: GetUsersActivityV6TypesItem[];
/**
 * Input any ERC20 address to return result in given currency.
 */
displayCurrency?: string;
};

export type GetTransfersBulkV2SortBy = typeof GetTransfersBulkV2SortBy[keyof typeof GetTransfersBulkV2SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTransfersBulkV2SortBy = {
  timestamp: 'timestamp',
  updatedAt: 'updatedAt',
} as const;

export type GetTransfersBulkV2Params = {
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
txHash?: string[];
/**
 * Amount of items returned in response. Max limit is 1000.
 */
limit?: number;
/**
 * Order the items are returned in the response. Options are `timestamp`, and `updatedAt`. Default is `timestamp`.
 */
sortBy?: GetTransfersBulkV2SortBy;
sortDirection?: string;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
};

export type GetTokensIdsV1FlagStatus = typeof GetTokensIdsV1FlagStatus[keyof typeof GetTokensIdsV1FlagStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensIdsV1FlagStatus = {
  NUMBER_MINUS_1: -1,
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;

export type GetTokensIdsV1Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Filter to a particular token set. Example: `token:CONTRACT:TOKEN_ID` representing a single token within contract, `contract:CONTRACT` representing a whole contract, `range:CONTRACT:START_TOKEN_ID:END_TOKEN_ID` representing a continuous token id range within a contract and `list:CONTRACT:TOKEN_IDS_HASH` representing a list of token ids within a contract.
 */
tokenSetId?: string;
/**
 * -1 = All tokens (default)
0 = Non flagged tokens
1 = Flagged tokens
 */
flagStatus?: GetTokensIdsV1FlagStatus;
/**
 * Amount of items returned in response. Max limit is 10,000.
 */
limit?: number;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
};

export type GetTokensFloorV1Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
};

export type GetTokensDetailsV4SortDirection = typeof GetTokensDetailsV4SortDirection[keyof typeof GetTokensDetailsV4SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensDetailsV4SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetTokensDetailsV4SortBy = typeof GetTokensDetailsV4SortBy[keyof typeof GetTokensDetailsV4SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensDetailsV4SortBy = {
  floorAskPrice: 'floorAskPrice',
  tokenId: 'tokenId',
} as const;

export type GetTokensDetailsV4Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Array of tokens. Example: `tokens[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:704 tokens[1]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:979`
 */
tokens?: string[];
/**
 * Filter to a particular token set. `Example: token:0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270:129000685`
 */
tokenSetId?: string;
/**
 * Filter to a particular attribute. Example: `attributes[Type]=Original`
 */
attributes?: string;
/**
 * Domain of the order source. Example `opensea.io`
 */
source?: string;
/**
 * Order the items are returned in the response.
 */
sortBy?: GetTokensDetailsV4SortBy;
sortDirection?: GetTokensDetailsV4SortDirection;
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * If true, top bid will be returned in the response.
 */
includeTopBid?: boolean;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
};

export type GetTokensBootstrapV1Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response.
 */
limit?: number;
};

export type GetSyncAsksV1Params = {
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
};

export type GetSearchCollectionsV2Params = {
/**
 * Lightweight search for collections that match a string. Example: `bored`
 */
name?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * Return result in given currency
 */
displayCurrency?: string;
/**
 * Filter to a particular collection set
 */
collectionsSetId?: string;
/**
 * If true, will filter any collections marked as spam.
 */
excludeSpam?: boolean;
/**
 * Use offset to request the next batch of items.
 */
offset?: number;
/**
 * Amount of items returned in response.
 */
limit?: number;
};

export type GetSearchActivitiesV1TypesItem = typeof GetSearchActivitiesV1TypesItem[keyof typeof GetSearchActivitiesV1TypesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSearchActivitiesV1TypesItem = {
  sale: 'sale',
  ask: 'ask',
  transfer: 'transfer',
  mint: 'mint',
  bid: 'bid',
  bid_cancel: 'bid_cancel',
  ask_cancel: 'ask_cancel',
} as const;

export type GetSearchActivitiesV1SortBy = typeof GetSearchActivitiesV1SortBy[keyof typeof GetSearchActivitiesV1SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSearchActivitiesV1SortBy = {
  timestamp: 'timestamp',
  createdAt: 'createdAt',
} as const;

export type GetSearchActivitiesV1Params = {
/**
 * Array of tokens. Max limit is 50. Example: `tokens[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:704 tokens[1]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:979`
 */
tokens?: string[];
/**
 * Array of collections. Max limit is 50. Example: `collections[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collections?: string[];
/**
 * Filter to a particular contracts set.
 */
contractsSetId?: string;
/**
 * Filter to a particular collection set.
 */
collectionsSetId?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * Filter to a particular attribute. Note: Our docs do not support this parameter correctly. To test, you can use the following URL in your browser. Example: `https://api.reservoir.tools/collections/activity/v6?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attribute[Type]=Original` or `https://api.reservoir.tools/collections/activity/v6?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attribute[Type]=Original&attribute[Type]=Sibling`
 */
attributes?: string;
/**
 * Array of source domains. Max limit is 50. Example: `sources[0]: opensea.io`
 */
sources?: string[];
/**
 * Array of users addresses. Max is 50. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
users?: string[];
/**
 * Amount of items returned. Max limit is 50 when `includedMetadata=true` otherwise max limit is 1000.
 */
limit?: number;
/**
 * Order the items are returned in the response. The blockchain event time is `timestamp`. The event time recorded is `createdAt`.
 */
sortBy?: GetSearchActivitiesV1SortBy;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
types?: GetSearchActivitiesV1TypesItem[];
/**
 * Input any ERC20 address to return result in given currency
 */
displayCurrency?: string;
};

export type GetSalesBulkV1Params = {
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
};

export type GetOwnersCrosscollectionsV1Params = {
/**
 * Filter to one or more collections. Max limit is 5. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collections: string[];
/**
 * Amount of owners returned in response. Max limit is 50.
 */
limit?: number;
};

export type GetOwnersCountV1Params = {
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
};

export type GetOwnersCommoncollectionsV1Params = {
/**
 * Array of owner addresses. Max limit is 50. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
owners: string[];
/**
 * Amount of collections returned in response. Max limit is 100.
 */
limit?: number;
};

export type GetOrdersExecutedV1Params = {
ids: string[];
};

export type GetOrdersDepthV1Side = typeof GetOrdersDepthV1Side[keyof typeof GetOrdersDepthV1Side];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersDepthV1Side = {
  buy: 'buy',
  sell: 'sell',
} as const;

export type GetOrdersDepthV1Params = {
side: GetOrdersDepthV1Side;
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`.
 */
token?: string;
/**
 * Filter to a particular collection. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`.
 */
collection?: string;
/**
 * Return all prices in this currency.
 */
displayCurrency?: string;
};

export type GetOrdersBidsV6SortBy = typeof GetOrdersBidsV6SortBy[keyof typeof GetOrdersBidsV6SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersBidsV6SortBy = {
  createdAt: 'createdAt',
  price: 'price',
  updatedAt: 'updatedAt',
} as const;

export type GetOrdersBidsV6Params = {
ids?: string[];
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular set. Example: `token:CONTRACT:TOKEN_ID` representing a single token within contract, `contract:CONTRACT` representing a whole contract, `range:CONTRACT:START_TOKEN_ID:END_TOKEN_ID` representing a continuous token id range within a contract and `list:CONTRACT:TOKEN_IDS_HASH` representing a list of token ids within a contract.
 */
tokenSetId?: string;
/**
 * Filter to a particular user. Must set `sources=blur.io` to reveal maker's blur bids. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00`
 */
maker?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * Filter to a particular collection set. Example: `8daa732ebe5db23f267e58d52f1c9b1879279bcdf4f78b8fb563390e6946ea65`
 */
collectionsSetId?: string;
/**
 * Filter to a particular contracts set.
 */
contractsSetId?: string;
/**
 * Filter to a particular collection bids with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular attribute. Note: Our docs do not support this parameter correctly. To test, you can use the following URL in your browser. Example: `https://api.reservoir.tools/orders/bids/v5?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attribute[Type]=Original` or `https://api.reservoir.tools/orders/bids/v5?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attribute[Type]=Original&attribute[Type]=Sibling`(Collection must be passed as well when filtering by attribute)
 */
attribute?: string;
/**
 * Filter to an array of contracts. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contracts?: string[];
sources?: string[];
/**
 * If true, results will filter only Reservoir orders.
 */
native?: boolean;
/**
 * If true, private orders are included in the response.
 */
includePrivate?: boolean;
/**
 * If true, criteria metadata is included in the response.
 */
includeCriteriaMetadata?: boolean;
/**
 * If true, raw data is included in the response. Set `sources=blur.io` and make this `true` to reveal individual blur bids.
 */
includeRawData?: boolean;
/**
 * If true, the depth of each order is included in the response.
 */
includeDepth?: boolean;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
/**
 * Exclude orders that can only be filled by EOAs, to support filling with smart contracts.
 */
excludeEOA?: boolean;
excludeSources?: string[];
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * Order the items are returned in the response. Sorting by `price` defaults sorting direction to descending. 
 */
sortBy?: GetOrdersBidsV6SortBy;
/**
 * activeª^º = currently valid
inactiveª^ = temporarily invalid
expiredª^, cancelledª^, filledª^ = permanently invalid
anyªº = any status
ª when an `id` is passed
^ when a `maker` is passed
º when a `contract` is passed
 */
status?: string;
sortDirection?: string;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response. Max limit is 1000.
 */
limit?: number;
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetOrdersAsksV5SortBy = typeof GetOrdersAsksV5SortBy[keyof typeof GetOrdersAsksV5SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersAsksV5SortBy = {
  createdAt: 'createdAt',
  price: 'price',
  updatedAt: 'updatedAt',
} as const;

export type GetOrdersAsksV5Params = {
ids?: string[];
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular set, e.g. `contract:0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
tokenSetId?: string;
/**
 * Filter to a particular user. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00`
 */
maker?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * Filter to a particular collection set. Example: `8daa732ebe5db23f267e58d52f1c9b1879279bcdf4f78b8fb563390e6946ea65`
 */
collectionsSetId?: string;
/**
 * Filter to a particular contracts set.
 */
contractsSetId?: string;
contracts?: string[];
sources?: string[];
/**
 * If true, results will filter only Reservoir orders.
 */
native?: boolean;
/**
 * If true, private orders are included in the response.
 */
includePrivate?: boolean;
/**
 * If true, criteria metadata is included in the response.
 */
includeCriteriaMetadata?: boolean;
/**
 * If true, raw data is included in the response.
 */
includeRawData?: boolean;
/**
 * If true, dynamic pricing data will be returned in the response.
 */
includeDynamicPricing?: boolean;
/**
 * Exclude orders that can only be filled by EOAs, to support filling with smart contracts.
 */
excludeEOA?: boolean;
excludeSources?: string[];
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * Order the items are returned in the response. Sorting by `price` is ascending order only.
 */
sortBy?: GetOrdersAsksV5SortBy;
/**
 * activeª^º = currently valid
inactiveª^ = temporarily invalid
expiredª^, cancelledª^, filledª^ = permanently invalid
anyªº = any status
ª when an `id` is passed
^ when a `maker` is passed
º when a `contract` is passed
 */
status?: string;
sortDirection?: string;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response. Max limit is 1000.
 */
limit?: number;
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetOrdersAllV2Side = typeof GetOrdersAllV2Side[keyof typeof GetOrdersAllV2Side];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersAllV2Side = {
  sell: 'sell',
  buy: 'buy',
} as const;

export type GetOrdersAllV2Params = {
id?: string;
/**
 * Filter to a source by domain. Example: `opensea.io`
 */
source?: string;
/**
 * If true, results will filter only Reservoir orders.
 */
native?: boolean;
/**
 * Sell or buy side.
 */
side?: GetOrdersAllV2Side;
/**
 * If true, metadata will be included in the response.
 */
includeMetadata?: boolean;
/**
 * If true, raw data will be included in the response.
 */
includeRawData?: boolean;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetLiquidityUsersV2Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection: string;
/**
 * Use offset to request the next batch of items.
 */
offset?: number;
/**
 * Amount of items returned in response.
 */
limit?: number;
};

export type GetExecuteCancelV2Params = {
/**
 * Order Id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
id: string;
/**
 * Optional. Set custom gas price
 */
maxFeePerGas?: string;
/**
 * Optional. Set custom gas price
 */
maxPriorityFeePerGas?: string;
};

export type GetEventsBidsV3SortDirection = typeof GetEventsBidsV3SortDirection[keyof typeof GetEventsBidsV3SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEventsBidsV3SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetEventsBidsV3Params = {
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
/**
 * If true, criteria metadata is included in the response.
 */
includeCriteriaMetadata?: boolean;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetEventsBidsV3SortDirection;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response. Max limit is 1000.
 */
limit?: number;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetEventsAsksV3SortDirection = typeof GetEventsAsksV3SortDirection[keyof typeof GetEventsAsksV3SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEventsAsksV3SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetEventsAsksV3Params = {
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
/**
 * If true, criteria metadata is included in the response.
 */
includeCriteriaMetadata?: boolean;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetEventsAsksV3SortDirection;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response. Max is 1000
 */
limit?: number;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * Input any ERC20 address to return result in given currency
 */
displayCurrency?: string;
};

export type GetCurrenciesConversionV1Params = {
/**
 * Currency address or fiat symbol to convert from
 */
from?: string;
/**
 * Currency address or fiat symbol to convert to
 */
to?: string;
};

export type GetCollectionsTrendingmintsV1Type = typeof GetCollectionsTrendingmintsV1Type[keyof typeof GetCollectionsTrendingmintsV1Type];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsTrendingmintsV1Type = {
  free: 'free',
  paid: 'paid',
  any: 'any',
} as const;

export type GetCollectionsTrendingmintsV1Period = typeof GetCollectionsTrendingmintsV1Period[keyof typeof GetCollectionsTrendingmintsV1Period];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsTrendingmintsV1Period = {
  '5m': '5m',
  '10m': '10m',
  '30m': '30m',
  '1h': '1h',
  '2h': '2h',
  '6h': '6h',
  '24h': '24h',
} as const;

export type GetCollectionsTrendingmintsV1Params = {
/**
 * Time window to aggregate.
 */
period?: GetCollectionsTrendingmintsV1Period;
/**
 * The type of the mint (free/paid).
 */
type?: GetCollectionsTrendingmintsV1Type;
/**
 * Amount of items returned in response. Default is 50 and max is 50. Expected to be sorted and filtered on client side.
 */
limit?: number;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * If true, return the non flagged floor ask. Supported only when `normalizeRoyalties` is false.
 */
useNonFlaggedFloorAsk?: boolean;
};

export type GetCollectionsTrendingV1SortBy = typeof GetCollectionsTrendingV1SortBy[keyof typeof GetCollectionsTrendingV1SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsTrendingV1SortBy = {
  volume: 'volume',
  sales: 'sales',
} as const;

export type GetCollectionsTrendingV1Period = typeof GetCollectionsTrendingV1Period[keyof typeof GetCollectionsTrendingV1Period];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsTrendingV1Period = {
  '5m': '5m',
  '10m': '10m',
  '30m': '30m',
  '1h': '1h',
  '6h': '6h',
  '1d': '1d',
  '24h': '24h',
  '7d': '7d',
  '30d': '30d',
} as const;

export type GetCollectionsTrendingV1Params = {
/**
 * Time window to aggregate.
 */
period?: GetCollectionsTrendingV1Period;
/**
 * Amount of items returned in response. Default is 50 and max is 1000. Expected to be sorted and filtered on client side.
 */
limit?: number;
sortBy?: GetCollectionsTrendingV1SortBy;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * If true, return the non flagged floor ask. Supported only when `normalizeRoyalties` is false.
 */
useNonFlaggedFloorAsk?: boolean;
};

export type GetCollectionsTopsellingV2SortBy = typeof GetCollectionsTopsellingV2SortBy[keyof typeof GetCollectionsTopsellingV2SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsTopsellingV2SortBy = {
  volume: 'volume',
  sales: 'sales',
} as const;

export type GetCollectionsTopsellingV2FillType = typeof GetCollectionsTopsellingV2FillType[keyof typeof GetCollectionsTopsellingV2FillType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsTopsellingV2FillType = {
  sale: 'sale',
  mint: 'mint',
  any: 'any',
} as const;

export type GetCollectionsTopsellingV2Period = typeof GetCollectionsTopsellingV2Period[keyof typeof GetCollectionsTopsellingV2Period];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsTopsellingV2Period = {
  '5m': '5m',
  '10m': '10m',
  '30m': '30m',
  '1h': '1h',
  '6h': '6h',
  '1d': '1d',
  '24h': '24h',
} as const;

export type GetCollectionsTopsellingV2Params = {
/**
 * Time window to aggregate.
 */
period?: GetCollectionsTopsellingV2Period;
/**
 * Fill types to aggregate from (sale, mint, any)
 */
fillType?: GetCollectionsTopsellingV2FillType;
/**
 * Amount of items returned in response. Default is 25 and max is 50
 */
limit?: number;
sortBy?: GetCollectionsTopsellingV2SortBy;
/**
 * If true, 8 recent sales will be included in the response
 */
includeRecentSales?: boolean;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * If true, return the non flagged floor ask. Supported only when `normalizeRoyalties` is false.
 */
useNonFlaggedFloorAsk?: boolean;
};

export type GetCollectionsDailyvolumesV1Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
id: string;
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * The start timestamp you want to filter on (UTC)
 */
startTimestamp?: number;
/**
 * The end timestamp you want to filter on (UTC)
 */
endTimestamp?: number;
};

export type GetCollectionsActivityV6TypesItem = typeof GetCollectionsActivityV6TypesItem[keyof typeof GetCollectionsActivityV6TypesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsActivityV6TypesItem = {
  sale: 'sale',
  ask: 'ask',
  transfer: 'transfer',
  mint: 'mint',
  bid: 'bid',
  bid_cancel: 'bid_cancel',
  ask_cancel: 'ask_cancel',
} as const;

export type GetCollectionsActivityV6SortBy = typeof GetCollectionsActivityV6SortBy[keyof typeof GetCollectionsActivityV6SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsActivityV6SortBy = {
  eventTimestamp: 'eventTimestamp',
  createdAt: 'createdAt',
} as const;

export type GetCollectionsActivityV6Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular collection set.
 */
collectionsSetId?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * Filter to a particular attribute. Note: Our docs do not support this parameter correctly. To test, you can use the following URL in your browser. Example: `https://api.reservoir.tools/collections/activity/v6?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attribute[Type]=Original` or `https://api.reservoir.tools/collections/activity/v6?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attribute[Type]=Original&attribute[Type]=Sibling`
 */
attributes?: string;
/**
 * Filter to a particular range attribute. Range attributes are case sensitive and inclusive. Note: Our docs do not support this parameter correctly. To test, you can use the following URL in your browser. Example: `https://api.reservoir.tools/tokens/v6?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&range_attributes[Type]=0,100` or `https://api.reservoir.tools/tokens/v6?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&range_attributes[Type]=0,100&range_attributes[Type]=1,999` where first number is minRange and second number after comma is maxRange
 */
range_attributes?: string;
/**
 * If true, will filter any activities marked as spam.
 */
excludeSpam?: boolean;
/**
 * Order the items are returned in the response. The blockchain event time is `eventTimestamp`. The event time recorded is `createdAt`.
 */
sortBy?: GetCollectionsActivityV6SortBy;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * If true, metadata is included in the response. If true, max limit is 50.
 */
includeMetadata?: boolean;
/**
 * Amount of items returned. Max limit is 50 when `includedMetadata=true` otherwise max limit is 1000.
 */
limit?: number;
types?: GetCollectionsActivityV6TypesItem[];
/**
 * Input any ERC20 address to return result in given currency
 */
displayCurrency?: string;
};

export type GetAdminMetadataTypeParams = {
/**
 * Array of tokens. Max limit is 50. Example: `tokens[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:704 tokens[1]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:979`
 */
tokens: string[];
};

export type GetTransfersV4SortBy = typeof GetTransfersV4SortBy[keyof typeof GetTransfersV4SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTransfersV4SortBy = {
  timestamp: 'timestamp',
  updatedAt: 'updatedAt',
} as const;

export type GetTransfersV4Params = {
/**
 * Filter to a particular contract, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Filter to a particular token, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular collection, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular attribute. Note: Our docs do not support this parameter correctly. To test, you can use the following URL in your browser. Example: `https://api.reservoir.tools/transfers/v2?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original` or `https://api.reservoir.tools/transfers/v2?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original&attributes[Type]=Sibling`
 */
attributes?: string;
/**
 * Filter to a particular transaction. Example: `0x04654cc4c81882ed4d20b958e0eeb107915d75730110cce65333221439de6afc`
 */
txHash?: string;
/**
 * Order the items are returned in the response. Options are `timestamp`, and `updatedAt`. Default is `timestamp`.
 */
sortBy?: GetTransfersV4SortBy;
sortDirection?: string;
/**
 * Max limit is 100.
 */
limit?: number;
continuation?: string;
/**
 * Input any ERC20 address to return result in given currency
 */
displayCurrency?: string;
};

export type GetTokensV6SortDirection = typeof GetTokensV6SortDirection[keyof typeof GetTokensV6SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensV6SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetTokensV6SortBy = typeof GetTokensV6SortBy[keyof typeof GetTokensV6SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensV6SortBy = {
  floorAskPrice: 'floorAskPrice',
  tokenId: 'tokenId',
  rarity: 'rarity',
  updatedAt: 'updatedAt',
  mintedAt: 'mintedAt',
} as const;

export type GetTokensV6FlagStatus = typeof GetTokensV6FlagStatus[keyof typeof GetTokensV6FlagStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensV6FlagStatus = {
  NUMBER_MINUS_1: -1,
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;

export type GetTokensV6Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular token by name. This is case sensitive. Example: `token #1`
 */
tokenName?: string;
/**
 * Array of tokens. Max limit is 50. Example: `tokens[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:704 tokens[1]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:979`
 */
tokens?: string[];
/**
 * Address of an owner to filter tokens by. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
owner?: string;
/**
 * Filter to a particular attribute. Attributes are case sensitive. Note: Our docs do not support this parameter correctly. To test, you can use the following URL in your browser. Example: `https://api.reservoir.tools/tokens/v6?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original` or `https://api.reservoir.tools/tokens/v6?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original&attributes[Type]=Sibling`
 */
attributes?: string;
/**
 * Filter to a particular range attribute. Range attributes are case sensitive and inclusive. Note: Our docs do not support this parameter correctly. To test, you can use the following URL in your browser. Example: `https://api.reservoir.tools/tokens/v6?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&range_attributes[Type]=0,100` or `https://api.reservoir.tools/tokens/v6?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&range_attributes[Type]=0,100&range_attributes[Type]=1,999` where first number is minRange and second number after comma is maxRange
 */
range_attributes?: string;
/**
 * Domain of the order source. Example `opensea.io` (Only listed tokens are returned when filtering by source)
 */
source?: string;
/**
 * Domain of the order source. Example `www.apecoinmarketplace.com`. For a native marketplace, return all tokens listed on this marketplace, even if better prices are available on other marketplaces.
 */
nativeSource?: string;
/**
 * Get tokens with a min rarity rank (inclusive), no rarity rank for collections over 100k
 */
minRarityRank?: number;
/**
 * Get tokens with a max rarity rank (inclusive), no rarity rank for collections over 100k
 */
maxRarityRank?: number;
/**
 * Get tokens with a min floor ask price (inclusive); use native currency
 */
minFloorAskPrice?: number;
/**
 * Get tokens with a max floor ask price (inclusive); use native currency
 */
maxFloorAskPrice?: number;
/**
 * Allowed only with collection and tokens filtering!
-1 = All tokens (default)
0 = Non flagged tokens
1 = Flagged tokens
 */
flagStatus?: GetTokensV6FlagStatus;
/**
 * Filter to a particular collection set. Example: `8daa732ebe5db23f267e58d52f1c9b1879279bcdf4f78b8fb563390e6946ea65`
 */
collectionsSetId?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
contract?: string[];
/**
 * Filter to a particular token set. `Example: token:0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270:129000685`
 */
tokenSetId?: string;
/**
 * Order the items are returned in the response. Options are `floorAskPrice`, `tokenId`, `rarity`, `updatedAt` and `mintedAt`. No rarity rank for collections over 100k.
 */
sortBy?: GetTokensV6SortBy;
sortDirection?: GetTokensV6SortDirection;
/**
 * Filter to tokens with a listing in a particular currency. Max limit is 50. `Example: currencies[0]: 0x0000000000000000000000000000000000000000`
 */
currencies?: string[];
/**
 * Amount of items returned in response. Max limit is 100, except when sorting by `updatedAt` which has a limit of 1000.
 */
limit?: number;
/**
 * When sorting by `updatedAt`, the start timestamp you want to filter on (UTC).
 */
startTimestamp?: number;
/**
 * When sorting by `updatedAt`, the end timestamp you want to filter on (UTC).
 */
endTimestamp?: number;
/**
 * If true, top bid will be returned in the response.
 */
includeTopBid?: boolean;
/**
 * If true, mint data for the tokens will be included in the response.
 */
includeMintStages?: boolean;
/**
 * Exclude orders that can only be filled by EOAs, to support filling with smart contracts. defaults to false
 */
excludeEOA?: boolean;
/**
 * If true, will filter any tokens marked as spam.
 */
excludeSpam?: boolean;
/**
 * Exclude orders that have been burned, defaults to false
 */
excludeBurns?: boolean;
/**
 * If true, attributes will be returned in the response.
 */
includeAttributes?: boolean;
/**
 * If true, quantity filled and quantity remaining will be returned in the response.
 */
includeQuantity?: boolean;
/**
 * If true, dynamic pricing data will be returned in the response.
 */
includeDynamicPricing?: boolean;
/**
 * If true, last sale data including royalties paid will be returned in the response.
 */
includeLastSale?: boolean;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Input any ERC20 address to return result in given currency. Applies to `topBid` and `floorAsk`.
 */
displayCurrency?: string;
};

export type GetStatsV2Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular attribute. Attributes are case sensitive. Note: Our docs do not support this parameter correctly. To test, you can use the following URL in your browser. Example: `https://api.reservoir.tools/stats/v2?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original` or `https://api.reservoir.tools/stats/v2?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original&attributes[Type]=Sibling`
 */
attributes?: string;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * Input any ERC20 address to return result in given currency
 */
displayCurrency?: string;
};

export type GetSourcesV1SortDirection = typeof GetSourcesV1SortDirection[keyof typeof GetSourcesV1SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSourcesV1SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetSourcesV1SortBy = typeof GetSourcesV1SortBy[keyof typeof GetSourcesV1SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSourcesV1SortBy = {
  domain: 'domain',
  createdAt: 'createdAt',
} as const;

export type GetSourcesV1Params = {
/**
 * Order of the items are returned in the response.
 */
sortBy?: GetSourcesV1SortBy;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetSourcesV1SortDirection;
/**
 * Filter to a particular domain. Example: `x2y2.io`
 */
domain?: string;
/**
 * Amount of items returned in response.
 */
limit?: number;
continuation?: string;
};

export type GetSalesV6SortDirection = typeof GetSalesV6SortDirection[keyof typeof GetSalesV6SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSalesV6SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetSalesV6SortBy = typeof GetSalesV6SortBy[keyof typeof GetSalesV6SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSalesV6SortBy = {
  price: 'price',
  time: 'time',
  updatedAt: 'updatedAt',
} as const;

export type GetSalesV6Params = {
contract?: string[];
/**
 * Array of tokens. Max limit is 20. Example: `tokens[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:704tokens[1]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:979`
 */
tokens?: string[];
/**
 * If enabled, also include token metadata in the response. Default is false.
 */
includeTokenMetadata?: boolean;
/**
 * If enabled, include sales that have been deleted. In some cases the backfilling process deletes sales that are no longer relevant or have been reverted.
 */
includeDeleted?: boolean;
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular attribute. Attributes are case sensitive. Note: Our docs do not support this parameter correctly. To test, you can use the following URL in your browser. Example: `https://api.reservoir.tools/sales/v6?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original` or `https://api.reservoir.tools/sales/v6?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original&attributes[Type]=Sibling`
 */
attributes?: string;
/**
 * Order the items are returned in the response. Options are `price`, `time`, and `updatedAt`. Default is `time`.
 */
sortBy?: GetSalesV6SortBy;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetSalesV6SortDirection;
/**
 * Filter to a particular transaction. Example: `0x04654cc4c81882ed4d20b958e0eeb107915d75730110cce65333221439de6afc`
 */
txHash?: string;
/**
 * Get events after a particular unix timestamp (inclusive). Relative to the sortBy time filters.
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive). Relative to the sortBy time filters.
 */
endTimestamp?: number;
/**
 * Amount of items returned in response. Max limit is 1000.
 */
limit?: number;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
};

export type GetOwnersV2Params = {
/**
 * Filter to a particular collection set id. Example: `8daa732ebe5db23f267e58d52f1c9b1879279bcdf4f78b8fb563390e6946ea65`
 */
collectionsSetId?: string;
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular attribute. Attributes are case sensitive. Note: Our docs do not support this parameter correctly. To test, you can use the following URL in your browser. Example: `https://api.reservoir.tools/owners/v1?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attribute[Type]=Original` or `https://api.reservoir.tools/owners/v1?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attribute[Type]=Original&attribute[Type]=Sibling`
 */
attributes?: string;
/**
 * Use offset to request the next batch of items.
 */
offset?: number;
/**
 * Amount of items returned in response. Max limit is 500.
 */
limit?: number;
/**
 * Input any ERC20 address to return result in given currency
 */
displayCurrency?: string;
};

export type GetOrdersV2Params = {
id?: string;
/**
 * Filter to a particular token, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular set. Example: `token:CONTRACT:TOKEN_ID` representing a single token within contract, `contract:CONTRACT` representing a whole contract, `range:CONTRACT:START_TOKEN_ID:END_TOKEN_ID` representing a continuous token id range within a contract and `list:CONTRACT:TOKEN_IDS_HASH` representing a list of token ids within a contract.
 */
tokenSetId?: string;
offset?: number;
limit?: number;
};

export type GetCrosspostingordersV1Params = {
ids?: number[];
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response.
 */
limit?: number;
};

export type GetCollectionsV7SortBy = typeof GetCollectionsV7SortBy[keyof typeof GetCollectionsV7SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsV7SortBy = {
  '1DayVolume': '1DayVolume',
  '7DayVolume': '7DayVolume',
  '30DayVolume': '30DayVolume',
  allTimeVolume: 'allTimeVolume',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  floorAskPrice: 'floorAskPrice',
} as const;

export type GetCollectionsV7Params = {
/**
 * Filter to a particular collection with collection id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
id?: string;
/**
 * Filter to a particular collection slug. Example: `boredapeyachtclub`
 */
slug?: string;
/**
 * Filter to a particular collection set. Example: `8daa732ebe5db23f267e58d52f1c9b1879279bcdf4f78b8fb563390e6946ea65`
 */
collectionsSetId?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
contract?: string[];
/**
 * Filter by creator
 */
creator?: string;
/**
 * Search for collections that match a string. Example: `bored`
 */
name?: string;
/**
 * Maximum floor price of the collection
 */
maxFloorAskPrice?: number;
/**
 * Minumum floor price of the collection
 */
minFloorAskPrice?: number;
/**
 * If true, attributes will be included in the response. Must filter by `id` or `slug` to a particular collection.
 */
includeAttributes?: boolean;
/**
 * If true, sales count (1 day, 7 day, 30 day, all time) will be included in the response. Must filter by `id` or `slug` to a particular collection.
 */
includeSalesCount?: boolean;
/**
 * If true, mint data for the collection will be included in the response.
 */
includeMintStages?: boolean;
/**
 * If true, security configuration data (e.g. ERC721C configuration) will be included in the response.
 */
includeSecurityConfigs?: boolean;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * If true, return the non flagged floor ask. Supported only when `normalizeRoyalties` is false.
 */
useNonFlaggedFloorAsk?: boolean;
/**
 * Order the items are returned in the response. Options are `#DayVolume`, `createdAt`, `updatedAt`, or `floorAskPrice`
 */
sortBy?: GetCollectionsV7SortBy;
sortDirection?: string;
/**
 * Amount of items returned in response. Default and max limit is 20, unless sorting by `updatedAt` which has a max limit of 1000.
 */
limit?: number;
/**
 * If true, will filter any collections marked as spam.
 */
excludeSpam?: boolean;
/**
 * If true, will filter any collections that have no tokens.
 */
excludeEmptyCollections?: boolean;
/**
 * When sorting by `updatedAt`, the start timestamp you want to filter on (UTC).
 */
startTimestamp?: number;
/**
 * When sorting by `updatedAt`, the end timestamp you want to filter on (UTC).
 */
endTimestamp?: number;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Input any ERC20 address to return result in given currency. Applies to `topBid` and `floorAsk`.
 */
displayCurrency?: string;
};

export type GetCollectionV3Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
id?: string;
/**
 * Filter to a particular collection slug. Example: `boredapeyachtclub`
 */
slug?: string;
/**
 * If true, top bid will be returned in the response.
 */
includeTopBid?: boolean;
};

export type GetAttributesV1Params = {
/**
 * Filter to a particular collection, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
};

export type GetAssetsV1Params = {
asset: string;
};

export type GetAdminCollectionmetadatadetailsParams = {
/**
 * The collection to see metadata details for. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * If false, will also include collections and tokens marked as spam.
 */
excludeSpam?: boolean;
/**
 * The amount of results to return (max 25).
 */
limit?: number;
};

export interface HiddenModel { [key: string]: any }

export type HiddenModelBody = HiddenModel;

export interface PostManagementOrdersSimulateV1Response {
  message?: string;
}

export interface PostManagementOrdersSimulateV1Payload {
  id: string;
  skipRevalidation?: boolean;
}

export interface PostExecuteSolveCapacityV1Response {
  maxItems: number;
  maxPricePerItem: string;
}

export type PostExecuteSolveCapacityV1PayloadOption1Kind = typeof PostExecuteSolveCapacityV1PayloadOption1Kind[keyof typeof PostExecuteSolveCapacityV1PayloadOption1Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteSolveCapacityV1PayloadOption1Kind = {
  'seaport-intent': 'seaport-intent',
} as const;

export interface PostExecuteSolveCapacityV1PayloadOptions {
  kind: PostExecuteSolveCapacityV1PayloadOption1Kind;
}

export interface PostCollectionsCollectionOverrideV1Response {
  message?: string;
}

export interface PostCollectionsCollectionOverrideV1PayloadRoyaltiesItem {
  bps?: number;
  recipient?: string;
}

export type PostCollectionsCollectionOverrideV1PayloadRoyalties = PostCollectionsCollectionOverrideV1PayloadRoyaltiesItem[];

export type PostCollectionsCollectionOverrideV1PayloadVerificationStatus = typeof PostCollectionsCollectionOverrideV1PayloadVerificationStatus[keyof typeof PostCollectionsCollectionOverrideV1PayloadVerificationStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostCollectionsCollectionOverrideV1PayloadVerificationStatus = {
  verified: 'verified',
  unverified: 'unverified',
} as const;

/**
 * Params that can be passed in order to override existing ones, to disable override pass null
 */
export interface PostCollectionsCollectionOverrideV1Payload {
  bannerImageUrl?: string;
  creator?: string;
  description?: string;
  discordUrl?: string;
  externalUrl?: string;
  imageUrl?: string;
  name?: string;
  royalties?: PostCollectionsCollectionOverrideV1PayloadRoyalties;
  twitterUrl?: string;
  verificationStatus?: PostCollectionsCollectionOverrideV1PayloadVerificationStatus;
}

export interface PostWebhooksImtblZkevmOrderUpdatedEventV1Response {
  message?: string;
}

export interface PostWebhooksImtblZkevmNftUpdatedEventV1Response {
  message?: string;
}

export interface PostWebhooksImtblZkevmMetadataUpdatedEventV1Response {
  message?: string;
}

export interface PostWebhooksImtblZkevmCollectionUpdatedEventV1Response {
  message?: string;
}

export interface PostWebhooksImtblZkevmActivityTransferEventV1Response {
  message?: string;
}

export interface PostTokensSpamStatusV1Response {
  message?: string;
}

/**
 * Array of tokens. Max limit is 50. Example: `tokens[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:704 tokens[1]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:979`
 */
export type PostTokensSpamStatusV1PayloadTokensOptions = string[];

export interface PostTokensSpamStatusV1Payload {
  /** API to update the spam status of a token */
  spam?: boolean;
  tokens: PostTokensSpamStatusV1PayloadTokensOptions;
}

export interface PostTokensSimulateTopBidV1Response {
  message?: string;
}

export interface PostTokensSimulateTopBidV1Payload {
  token?: string;
}

export interface PostTokensSimulateFloorV1Response {
  message?: string;
}

export type PostTokensSimulateFloorV1PayloadRouter = typeof PostTokensSimulateFloorV1PayloadRouter[keyof typeof PostTokensSimulateFloorV1PayloadRouter];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostTokensSimulateFloorV1PayloadRouter = {
  v5: 'v5',
  v6: 'v6',
} as const;

export interface PostTokensSimulateFloorV1Payload {
  router?: PostTokensSimulateFloorV1PayloadRouter;
  token?: string;
}

export interface PostTokensRefreshV1Response {
  message?: string;
}

export interface PostTokensRefreshV1Payload {
  /** If true, only liquidity data will be refreshed. */
  liquidityOnly?: boolean;
  /** If true, will force a refresh regardless of cool down. Requires an authorized api key to be passed. */
  overrideCoolDown?: boolean;
  /** Refresh the given token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123` */
  token: string;
}

export interface PostTokensFlagV1Response {
  message?: string;
}

/**
 * 0 - Token is not flagged, 1 - Token is flagged
 */
export type PostTokensFlagV1PayloadFlag = typeof PostTokensFlagV1PayloadFlag[keyof typeof PostTokensFlagV1PayloadFlag];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostTokensFlagV1PayloadFlag = {
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;

export interface PostTokensFlagV1Payload {
  /** 0 - Token is not flagged, 1 - Token is flagged */
  flag: PostTokensFlagV1PayloadFlag;
  /** The token to update the flag status for. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123` */
  token: string;
}

export interface PostTokensDisableMetadataV1Response {
  message?: string;
}

/**
 * Array of tokens to disable or reenable metadata for. Max limit is 50. Example: `tokens[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:704 tokens[1]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:979`
 */
export type PostTokensDisableMetadataV1PayloadTokensOptions = string[];

export interface PostTokensDisableMetadataV1Payload {
  /** Whether to disable or reenable the metadata. Defaults to true (disable) */
  disable?: boolean;
  tokens: PostTokensDisableMetadataV1PayloadTokensOptions;
}

export interface PostOrdersCancelImtblV1Payload {
  /** Chain where orders were cancelled */
  chainId: number;
  orders?: PostOrdersCancelImtblV1PayloadOrders;
}

export type PostOrdersCancelImtblV1PayloadOrdersItemSource = typeof PostOrdersCancelImtblV1PayloadOrdersItemSource[keyof typeof PostOrdersCancelImtblV1PayloadOrdersItemSource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostOrdersCancelImtblV1PayloadOrdersItemSource = {
  'imtbl-orderbook': 'imtbl-orderbook',
} as const;

export interface PostOrdersCancelImtblV1PayloadOrdersItem {
  id: string;
  source: PostOrdersCancelImtblV1PayloadOrdersItemSource;
}

export type PostOrdersCancelImtblV1PayloadOrders = PostOrdersCancelImtblV1PayloadOrdersItem[];

export type PostExecuteTransferV1ResponseSteps = PostExecuteTransferV1ResponseStepsItem[];

export interface PostExecuteTransferV1Response {
  steps?: PostExecuteTransferV1ResponseSteps;
}

export interface PostExecuteTransferV1ResponseStepsItemItemsItemData { [key: string]: any }

/**
 * Returns `complete` or `incomplete`.
 */
export type PostExecuteTransferV1ResponseStepsItemItemsItemStatus = typeof PostExecuteTransferV1ResponseStepsItemItemsItemStatus[keyof typeof PostExecuteTransferV1ResponseStepsItemItemsItemStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteTransferV1ResponseStepsItemItemsItemStatus = {
  complete: 'complete',
  incomplete: 'incomplete',
} as const;

export interface PostExecuteTransferV1ResponseStepsItemItemsItem {
  data?: PostExecuteTransferV1ResponseStepsItemItemsItemData;
  status: PostExecuteTransferV1ResponseStepsItemItemsItemStatus;
}

export type PostExecuteTransferV1ResponseStepsItemItems = PostExecuteTransferV1ResponseStepsItemItemsItem[];

/**
 * Returns `transaction`
 */
export type PostExecuteTransferV1ResponseStepsItemKind = typeof PostExecuteTransferV1ResponseStepsItemKind[keyof typeof PostExecuteTransferV1ResponseStepsItemKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteTransferV1ResponseStepsItemKind = {
  transaction: 'transaction',
} as const;

export interface PostExecuteTransferV1ResponseStepsItem {
  action: string;
  description: string;
  /** Returns `nft-approval` or `transfer` */
  id: string;
  items: PostExecuteTransferV1ResponseStepsItemItems;
  kind: PostExecuteTransferV1ResponseStepsItemKind;
}

export interface PostExecuteTransferV1PayloadItemsItem {
  quantity?: number;
  token: string;
}

export type PostExecuteTransferV1PayloadItems = PostExecuteTransferV1PayloadItemsItem[];

export interface PostExecuteTransferV1Payload {
  from: string;
  items?: PostExecuteTransferV1PayloadItems;
  to: string;
}

export type PostExecuteStatusV1ResponseTxHashes = string[];

export type PostExecuteStatusV1ResponseStatus = typeof PostExecuteStatusV1ResponseStatus[keyof typeof PostExecuteStatusV1ResponseStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteStatusV1ResponseStatus = {
  unknown: 'unknown',
  pending: 'pending',
  received: 'received',
  success: 'success',
  failure: 'failure',
} as const;

export interface PostExecuteStatusV1Response {
  details?: string;
  status: PostExecuteStatusV1ResponseStatus;
  time?: number;
  txHashes?: PostExecuteStatusV1ResponseTxHashes;
}

/**
 * Execution kind
 */
export type PostExecuteStatusV1PayloadKind = typeof PostExecuteStatusV1PayloadKind[keyof typeof PostExecuteStatusV1PayloadKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteStatusV1PayloadKind = {
  'cross-chain-intent': 'cross-chain-intent',
  'cross-chain-transaction': 'cross-chain-transaction',
  'seaport-intent': 'seaport-intent',
  transaction: 'transaction',
} as const;

export interface PostExecuteStatusV1Payload {
  /** Chain id where the action is happening (only relevant for 'cross-chain-transaction' actions) */
  chainId?: number;
  /** The id of the execution (eg. transaction / order / intent hash) */
  id: string;
  kind: PostExecuteStatusV1PayloadKind;
}

export type PostExecuteSolveV1ResponseStatusMethod = typeof PostExecuteSolveV1ResponseStatusMethod[keyof typeof PostExecuteSolveV1ResponseStatusMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteSolveV1ResponseStatusMethod = {
  POST: 'POST',
} as const;

export interface PostExecuteSolveV1ResponseStatus {
  body?: string;
  endpoint: string;
  method: PostExecuteSolveV1ResponseStatusMethod;
}

export interface PostExecuteSolveV1Response {
  status?: PostExecuteSolveV1ResponseStatus;
}

export type PostExecuteSolveV1PayloadOption1Kind = typeof PostExecuteSolveV1PayloadOption1Kind[keyof typeof PostExecuteSolveV1PayloadOption1Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteSolveV1PayloadOption1Kind = {
  'seaport-intent': 'seaport-intent',
} as const;

export interface PostExecuteSolveV1PayloadOptions {
  kind: PostExecuteSolveV1PayloadOption1Kind;
  order: string;
}

export interface PostExecuteSellV7ResponsePathItemFeesOnTopItem {
  amount?: number;
  bps?: number;
  kind?: string;
  rawAmount?: string;
  recipient?: string;
}

/**
 * Can be referral fees.
 */
export type PostExecuteSellV7ResponsePathItemFeesOnTop = PostExecuteSellV7ResponsePathItemFeesOnTopItem[];

export interface PostExecuteSellV7ResponsePathItem {
  builtInFees?: PostExecuteSellV7ResponsePathItemBuiltInFees;
  contract?: string;
  currency?: string;
  currencyDecimals?: number;
  currencySymbol?: string;
  feesOnTop?: PostExecuteSellV7ResponsePathItemFeesOnTop;
  orderId?: string;
  quantity?: number;
  quote?: number;
  rawQuote?: string;
  source?: string;
  tokenId?: string;
  totalPrice?: number;
  totalRawPrice?: string;
}

export type PostExecuteSellV7ResponsePath = PostExecuteSellV7ResponsePathItem[];

export interface PostExecuteSellV7ResponsePathItemBuiltInFeesItem {
  amount?: number;
  bps?: number;
  kind?: string;
  rawAmount?: string;
  recipient?: string;
}

/**
 * Can be marketplace fees or royalties
 */
export type PostExecuteSellV7ResponsePathItemBuiltInFees = PostExecuteSellV7ResponsePathItemBuiltInFeesItem[];

export interface PostExecuteSellV7ResponseErrorsItem {
  message?: string;
  orderId?: string;
}

export type PostExecuteSellV7ResponseErrors = PostExecuteSellV7ResponseErrorsItem[];

export interface PostExecuteSellV7ResponseStepsItem {
  action: string;
  description: string;
  /** Returns `auth` or `nft-approval` */
  id: string;
  items: PostExecuteSellV7ResponseStepsItemItems;
  kind: PostExecuteSellV7ResponseStepsItemKind;
}

export type PostExecuteSellV7ResponseSteps = PostExecuteSellV7ResponseStepsItem[];

export interface PostExecuteSellV7Response {
  errors?: PostExecuteSellV7ResponseErrors;
  path?: PostExecuteSellV7ResponsePath;
  requestId?: string;
  steps?: PostExecuteSellV7ResponseSteps;
}

export interface PostExecuteSellV7ResponseStepsItemItemsItemData { [key: string]: any }

export type PostExecuteSellV7ResponseStepsItemItemsItemOrderIds = string[];

/**
 * Returns `complete` or `incomplete`.
 */
export type PostExecuteSellV7ResponseStepsItemItemsItemStatus = typeof PostExecuteSellV7ResponseStepsItemItemsItemStatus[keyof typeof PostExecuteSellV7ResponseStepsItemItemsItemStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteSellV7ResponseStepsItemItemsItemStatus = {
  complete: 'complete',
  incomplete: 'incomplete',
} as const;

export interface PostExecuteSellV7ResponseStepsItemItemsItem {
  data?: PostExecuteSellV7ResponseStepsItemItemsItemData;
  /** Approximation of gas used (only applies to `transaction` items) */
  gasEstimate?: number;
  orderIds?: PostExecuteSellV7ResponseStepsItemItemsItemOrderIds;
  status: PostExecuteSellV7ResponseStepsItemItemsItemStatus;
  tip?: string;
}

export type PostExecuteSellV7ResponseStepsItemItems = PostExecuteSellV7ResponseStepsItemItemsItem[];

/**
 * Returns `signature` or `transaction`.
 */
export type PostExecuteSellV7ResponseStepsItemKind = typeof PostExecuteSellV7ResponseStepsItemKind[keyof typeof PostExecuteSellV7ResponseStepsItemKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteSellV7ResponseStepsItemKind = {
  signature: 'signature',
  transaction: 'transaction',
} as const;

/**
 * List of fees (formatted as `feeRecipient:feeAmount`) to be taken when filling.
The currency used for any fees on top is always the wrapped native currency of the chain.
Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00:1000000000000000`
 */
export type PostExecuteSellV7PayloadFeesOnTop = string[];

export interface PostExecuteSellV7Payload {
  /** If true, inactive orders will not be skipped over (only relevant when filling via a specific order id). */
  allowInactiveOrderIds?: boolean;
  /** Optional Blur auth used for filling */
  blurAuth?: string;
  /** Exclude orders that can only be filled by EOAs, to support filling with smart contracts. */
  excludeEOA?: boolean;
  feesOnTop?: PostExecuteSellV7PayloadFeesOnTop;
  /** If true, filling will be forced to use the common 'approval + transfer' method instead of the approval-less 'on-received hook' method */
  forceRouter?: boolean;
  items: PostExecuteSellV7PayloadItems;
  /** Optional custom gas settings. Includes base fee & priority fee in this limit. */
  maxFeePerGas?: string;
  /** Optional custom gas settings. */
  maxPriorityFeePerGas?: string;
  /** Charge any missing royalties. */
  normalizeRoyalties?: boolean;
  /** If true, only the filling path will be returned. */
  onlyPath?: boolean;
  /** Optional OpenSea API key used for filling. You don't need to pass your own key, but if you don't, you are more likely to be rate-limited. */
  openseaApiKey?: string;
  /** If true, any off-chain or on-chain errors will be skipped. */
  partial?: boolean;
  /** Filling source used for attribution. */
  source?: string;
  /** Address of wallet filling. */
  taker: string;
  /** Optional X2Y2 API key used for filling. */
  x2y2ApiKey?: string;
}

export interface PostExecuteSellV7PayloadItemsItemExclusionsItem {
  orderId: string;
  price?: string;
}

/**
 * Items to exclude
 */
export type PostExecuteSellV7PayloadItemsItemExclusions = PostExecuteSellV7PayloadItemsItemExclusionsItem[];

export interface PostExecuteSellV7PayloadItemsItemRawOrderData { [key: string]: any }

export type PostExecuteSellV7PayloadItemsItemRawOrderKind = typeof PostExecuteSellV7PayloadItemsItemRawOrderKind[keyof typeof PostExecuteSellV7PayloadItemsItemRawOrderKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteSellV7PayloadItemsItemRawOrderKind = {
  'blur-partial': 'blur-partial',
  opensea: 'opensea',
  'looks-rare': 'looks-rare',
  'zeroex-v4': 'zeroex-v4',
  seaport: 'seaport',
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  x2y2: 'x2y2',
  rarible: 'rarible',
  sudoswap: 'sudoswap',
  nftx: 'nftx',
} as const;

/**
 * Optional raw order to sell into.
 */
export interface PostExecuteSellV7PayloadItemsItemRawOrder {
  data?: PostExecuteSellV7PayloadItemsItemRawOrderData;
  kind?: PostExecuteSellV7PayloadItemsItemRawOrderKind;
}

export interface PostExecuteSellV7PayloadItemsItem {
  /** Only consider orders from this source. */
  exactOrderSource?: string;
  exclusions?: PostExecuteSellV7PayloadItemsItemExclusions;
  /** Optional order id to sell into. */
  orderId?: string;
  /** Quantity of tokens to sell. */
  quantity?: number;
  rawOrder?: PostExecuteSellV7PayloadItemsItemRawOrder;
  /** Token to sell. */
  token: string;
}

/**
 * List of items to sell.
 */
export type PostExecuteSellV7PayloadItems = PostExecuteSellV7PayloadItemsItem[];

export interface PostExecuteSellV6ResponsePathItem {
  contract?: string;
  currency?: string;
  orderId?: string;
  quantity?: number;
  quote?: number;
  rawQuote?: string;
  source?: string;
  tokenId?: string;
}

export type PostExecuteSellV6ResponsePath = PostExecuteSellV6ResponsePathItem[];

export interface PostExecuteSellV6ResponseErrorsItem {
  message?: string;
  orderId?: number;
}

export type PostExecuteSellV6ResponseErrors = PostExecuteSellV6ResponseErrorsItem[];

export type PostExecuteSellV6ResponseStepsItemItems = PostExecuteSellV6ResponseStepsItemItemsItem[];

export interface PostExecuteSellV6ResponseStepsItem {
  action: string;
  description: string;
  id: string;
  items: PostExecuteSellV6ResponseStepsItemItems;
  kind: PostExecuteSellV6ResponseStepsItemKind;
}

export type PostExecuteSellV6ResponseSteps = PostExecuteSellV6ResponseStepsItem[];

export interface PostExecuteSellV6Response {
  errors?: PostExecuteSellV6ResponseErrors;
  path?: PostExecuteSellV6ResponsePath;
  steps?: PostExecuteSellV6ResponseSteps;
}

export interface PostExecuteSellV6ResponseStepsItemItemsItemData { [key: string]: any }

export type PostExecuteSellV6ResponseStepsItemItemsItemStatus = typeof PostExecuteSellV6ResponseStepsItemItemsItemStatus[keyof typeof PostExecuteSellV6ResponseStepsItemItemsItemStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteSellV6ResponseStepsItemItemsItemStatus = {
  complete: 'complete',
  incomplete: 'incomplete',
} as const;

export interface PostExecuteSellV6ResponseStepsItemItemsItem {
  data?: PostExecuteSellV6ResponseStepsItemItemsItemData;
  status: PostExecuteSellV6ResponseStepsItemItemsItemStatus;
}

export type PostExecuteSellV6ResponseStepsItemKind = typeof PostExecuteSellV6ResponseStepsItemKind[keyof typeof PostExecuteSellV6ResponseStepsItemKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteSellV6ResponseStepsItemKind = {
  signature: 'signature',
  transaction: 'transaction',
} as const;

/**
 * List of fees (formatted as `feeRecipient:feeAmount`) to be taken when filling.
The currency used for any fees on top matches the accepted bid's currency.
Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00:1000000000000000`
 */
export type PostExecuteSellV6PayloadFeesOnTop = string[];

export interface PostExecuteSellV6Payload {
  /** If true, do not filter out inactive orders (only relevant for order id filtering). */
  allowInactiveOrderIds?: boolean;
  /** Exclude orders that can only be filled by EOAs, to support filling with smart contracts. */
  excludeEOA?: boolean;
  feesOnTop?: PostExecuteSellV6PayloadFeesOnTop;
  /** Optional. Set custom gas price. */
  maxFeePerGas?: string;
  /** Optional. Set custom gas price. */
  maxPriorityFeePerGas?: string;
  normalizeRoyalties?: boolean;
  /** If true, only the path will be returned. */
  onlyPath?: boolean;
  orderId?: string;
  /** Quantity of tokens user is selling. Only compatible when selling a single ERC1155 token. Example: `5` */
  quantity?: number;
  rawOrder?: PostExecuteSellV6PayloadRawOrder;
  /** Filling source used for attribution. Example: `reservoir.market` */
  source?: string;
  /** Address of wallet filling the order. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00` */
  taker: string;
  /** Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123` */
  token: string;
  /** Override the X2Y2 API key used for filling. */
  x2y2ApiKey?: string;
}

export interface PostExecuteSellV6PayloadRawOrderData { [key: string]: any }

export type PostExecuteSellV6PayloadRawOrderKind = typeof PostExecuteSellV6PayloadRawOrderKind[keyof typeof PostExecuteSellV6PayloadRawOrderKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteSellV6PayloadRawOrderKind = {
  opensea: 'opensea',
  'looks-rare': 'looks-rare',
  'zeroex-v4': 'zeroex-v4',
  seaport: 'seaport',
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  x2y2: 'x2y2',
} as const;

export interface PostExecuteSellV6PayloadRawOrder {
  data: PostExecuteSellV6PayloadRawOrderData;
  kind: PostExecuteSellV6PayloadRawOrderKind;
}

export interface PostExecuteResultsV1Response {
  message: string;
}

export interface PostExecuteResultsV1Payload {
  /** Associated error message */
  errorMessage?: string;
  /** Request id of the associate execute API request */
  requestId: string;
  /** Step id of the relevant execute item */
  stepId: string;
  /** Associated transaction hash */
  txHash?: string;
}

export interface PostExecutePreSignatureV1Response {
  message?: string;
}

export interface PostExecutePreSignatureV1Payload {
  /** Id of the pre-signature */
  id: string;
}

export interface PostExecutePermitSignatureV1Response {
  message?: string;
}

export interface PostExecutePermitSignatureV1Payload {
  /** Id of the permit */
  id: string;
  /** Whether to persist the permit or not */
  persist?: boolean;
}

export interface PostExecuteMintV1ResponseMaxQuantitiesItem {
  itemIndex: number;
  maxQuantity?: string;
}

export type PostExecuteMintV1ResponseMaxQuantities = PostExecuteMintV1ResponseMaxQuantitiesItem[];

export interface PostExecuteMintV1Response {
  errors?: PostExecuteMintV1ResponseErrors;
  gasEstimate?: number;
  maxQuantities?: PostExecuteMintV1ResponseMaxQuantities;
  path?: PostExecuteMintV1ResponsePath;
  requestId?: string;
  steps?: PostExecuteMintV1ResponseSteps;
}

export interface PostExecuteMintV1ResponsePathItemFeesOnTopItem {
  amount?: number;
  bps?: number;
  kind?: string;
  rawAmount?: string;
  recipient?: string;
}

/**
 * Can be referral fees.
 */
export type PostExecuteMintV1ResponsePathItemFeesOnTop = PostExecuteMintV1ResponsePathItemFeesOnTopItem[];

export type PostExecuteMintV1ResponsePathItemBuyIn = PostExecuteMintV1ResponsePathItemBuyInItem[];

export interface PostExecuteMintV1ResponsePathItem {
  buyIn?: PostExecuteMintV1ResponsePathItemBuyIn;
  contract?: string;
  currency?: string;
  currencyDecimals?: number;
  currencySymbol?: string;
  feesOnTop?: PostExecuteMintV1ResponsePathItemFeesOnTop;
  /** Chain id buying from */
  fromChainId?: number;
  gasCost?: string;
  orderId?: string;
  quantity?: number;
  quote?: number;
  rawQuote?: string;
  source?: string;
  tokenId?: string;
  totalPrice?: number;
  totalRawPrice?: string;
}

export type PostExecuteMintV1ResponsePath = PostExecuteMintV1ResponsePathItem[];

/**
 * Amount with fees & royalties removed.
 */
export interface PostExecuteMintV1ResponsePathItemBuyInItemNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface PostExecuteMintV1ResponsePathItemBuyInItemAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface PostExecuteMintV1ResponsePathItemBuyInItemCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface PostExecuteMintV1ResponsePathItemBuyInItem {
  amount?: PostExecuteMintV1ResponsePathItemBuyInItemAmount;
  currency?: PostExecuteMintV1ResponsePathItemBuyInItemCurrency;
  netAmount?: PostExecuteMintV1ResponsePathItemBuyInItemNetAmount;
}

export interface PostExecuteMintV1ResponseErrorsItem {
  message?: string;
  orderId?: string;
}

export type PostExecuteMintV1ResponseErrors = PostExecuteMintV1ResponseErrorsItem[];

export type PostExecuteMintV1ResponseStepsItemItems = PostExecuteMintV1ResponseStepsItemItemsItem[];

export type PostExecuteMintV1ResponseStepsItemItemsItemCheckMethod = typeof PostExecuteMintV1ResponseStepsItemItemsItemCheckMethod[keyof typeof PostExecuteMintV1ResponseStepsItemItemsItemCheckMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteMintV1ResponseStepsItemItemsItemCheckMethod = {
  POST: 'POST',
} as const;

/**
 * The details of the endpoint for checking the status of the step
 */
export interface PostExecuteMintV1ResponseStepsItemItemsItemCheck {
  body?: string;
  endpoint: string;
  method: PostExecuteMintV1ResponseStepsItemItemsItemCheckMethod;
}

export interface PostExecuteMintV1ResponseStepsItemItemsItemData { [key: string]: any }

export type PostExecuteMintV1ResponseStepsItemItemsItemOrderIds = string[];

/**
 * Response is `complete` or `incomplete`.
 */
export type PostExecuteMintV1ResponseStepsItemItemsItemStatus = typeof PostExecuteMintV1ResponseStepsItemItemsItemStatus[keyof typeof PostExecuteMintV1ResponseStepsItemItemsItemStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteMintV1ResponseStepsItemItemsItemStatus = {
  complete: 'complete',
  incomplete: 'incomplete',
} as const;

export interface PostExecuteMintV1ResponseStepsItemItemsItem {
  check?: PostExecuteMintV1ResponseStepsItemItemsItemCheck;
  data?: PostExecuteMintV1ResponseStepsItemItemsItemData;
  /** Approximation of gas used (only applies to `transaction` items) */
  gasEstimate?: number;
  orderIds?: PostExecuteMintV1ResponseStepsItemItemsItemOrderIds;
  status: PostExecuteMintV1ResponseStepsItemItemsItemStatus;
  tip?: string;
}

export type PostExecuteMintV1ResponseStepsItemKind = typeof PostExecuteMintV1ResponseStepsItemKind[keyof typeof PostExecuteMintV1ResponseStepsItemKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteMintV1ResponseStepsItemKind = {
  signature: 'signature',
  transaction: 'transaction',
} as const;

export interface PostExecuteMintV1ResponseStepsItem {
  action: string;
  description: string;
  id: string;
  items: PostExecuteMintV1ResponseStepsItemItems;
  kind: PostExecuteMintV1ResponseStepsItemKind;
}

export type PostExecuteMintV1ResponseSteps = PostExecuteMintV1ResponseStepsItem[];

/**
 * List of fees (formatted as `feeRecipient:feeAmount`) to be taken when minting.
Unless overridden via the `currency` param, the currency used for any fees on top matches the buy-in currency detected by the backend.
Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00:1000000000000000`
 */
export type PostExecuteMintV1PayloadFeesOnTop = string[];

/**
 * Alternative currencies to return the quote in.
 */
export type PostExecuteMintV1PayloadAlternativeCurrencies = string[];

export interface PostExecuteMintV1Payload {
  alternativeCurrencies?: PostExecuteMintV1PayloadAlternativeCurrencies;
  /** Mint comment (where suported). */
  comment?: string;
  /** The chain id of the purchase currency. */
  currencyChainId?: number;
  feesOnTop?: PostExecuteMintV1PayloadFeesOnTop;
  items: PostExecuteMintV1PayloadItems;
  /** If true, only the path will be returned. */
  onlyPath?: boolean;
  /** If true, any off-chain or on-chain errors will be skipped. */
  partial?: boolean;
  /** Referrer address (where supported). */
  referrer?: string;
  /** Address of wallet relaying the mint transaction(s) (paying for the NFT). */
  relayer?: string;
  /** If true, balance checks will be skipped. */
  skipBalanceCheck?: boolean;
  /** Filling source used for attribution. Example: `reservoir.market` */
  source?: string;
  /** Address of wallet minting (receiver of the NFT). */
  taker: string;
}

export interface PostExecuteMintV1PayloadItemsItemCustomDetails {
  tx?: PostExecuteMintV1PayloadItemsItemCustomDetailsTx;
}

/**
 * Optional custom details to use for minting.
 */
export interface PostExecuteMintV1PayloadItemsItemCustom {
  contract: string;
  details: PostExecuteMintV1PayloadItemsItemCustomDetails;
  price: string;
}

export interface PostExecuteMintV1PayloadItemsItem {
  /** Collection to mint. */
  collection?: string;
  custom?: PostExecuteMintV1PayloadItemsItemCustom;
  /** Quantity of tokens to mint. */
  quantity?: number;
  /** Token to mint. */
  token?: string;
}

/**
 * List of items to mint.
 */
export type PostExecuteMintV1PayloadItems = PostExecuteMintV1PayloadItemsItem[];

export interface PostExecuteMintV1PayloadItemsItemCustomDetailsTxDataParamsItem {
  abiType: string;
  abiValue: string;
}

export type PostExecuteMintV1PayloadItemsItemCustomDetailsTxDataParams = PostExecuteMintV1PayloadItemsItemCustomDetailsTxDataParamsItem[];

export interface PostExecuteMintV1PayloadItemsItemCustomDetailsTxData {
  params?: PostExecuteMintV1PayloadItemsItemCustomDetailsTxDataParams;
  signature: string;
}

export interface PostExecuteMintV1PayloadItemsItemCustomDetailsTx {
  data?: PostExecuteMintV1PayloadItemsItemCustomDetailsTxData;
  to: string;
}

export interface PostExecuteListV5ResponseErrorsItem {
  message?: string;
  orderIndex?: number;
}

export type PostExecuteListV5ResponseErrors = PostExecuteListV5ResponseErrorsItem[];

export interface PostExecuteListV5Response {
  errors: PostExecuteListV5ResponseErrors;
  steps: PostExecuteListV5ResponseSteps;
}

export interface PostExecuteListV5ResponseStepsItem {
  action: string;
  description: string;
  /** Returns `nft-approval` or `order-signature` */
  id: string;
  items: PostExecuteListV5ResponseStepsItemItems;
  kind: PostExecuteListV5ResponseStepsItemKind;
}

export type PostExecuteListV5ResponseSteps = PostExecuteListV5ResponseStepsItem[];

export type PostExecuteListV5ResponseStepsItemItemsItemOrderIndexes = number[];

export interface PostExecuteListV5ResponseStepsItemItemsItemData { [key: string]: any }

/**
 * Returns `complete` or `incomplete`.
 */
export type PostExecuteListV5ResponseStepsItemItemsItemStatus = typeof PostExecuteListV5ResponseStepsItemItemsItemStatus[keyof typeof PostExecuteListV5ResponseStepsItemItemsItemStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteListV5ResponseStepsItemItemsItemStatus = {
  complete: 'complete',
  incomplete: 'incomplete',
} as const;

export interface PostExecuteListV5ResponseStepsItemItemsItem {
  data?: PostExecuteListV5ResponseStepsItemItemsItemData;
  orderIndexes?: PostExecuteListV5ResponseStepsItemItemsItemOrderIndexes;
  status: PostExecuteListV5ResponseStepsItemItemsItemStatus;
  tip?: string;
}

export type PostExecuteListV5ResponseStepsItemItems = PostExecuteListV5ResponseStepsItemItemsItem[];

/**
 * Returns `request`, `signature`, or `transaction`.
 */
export type PostExecuteListV5ResponseStepsItemKind = typeof PostExecuteListV5ResponseStepsItemKind[keyof typeof PostExecuteListV5ResponseStepsItemKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteListV5ResponseStepsItemKind = {
  request: 'request',
  signature: 'signature',
  transaction: 'transaction',
} as const;

export type PostExecuteListV5PayloadParams = PostExecuteListV5PayloadParamsItem[];

export interface PostExecuteListV5Payload {
  /** Advanced use case to pass personal blurAuthToken; the API will generate one if left empty. */
  blurAuth?: string;
  /** Address of wallet making the order. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00` */
  maker: string;
  params?: PostExecuteListV5PayloadParams;
  /** Domain of your app that is creating the order, e.g. `myapp.xyz`. This is used for filtering, and to attribute the "order source" of sales in on-chain analytics, to help your app get discovered. Lean more <a href='https://docs.reservoir.tools/docs/calldata-attribution'>here</a> */
  source?: string;
}

/**
 * List of custom royalties (formatted as `feeRecipient:feeBps`) to be bundled within the order. 1 BPS = 0.01% Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00:100`
 */
export type PostExecuteListV5PayloadParamsItemCustomRoyalties = string[];

/**
 * List of marketplace fees (formatted as `feeRecipient:feeBps`) to be bundled within the order. 1 BPS = 0.01% Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00:100`
 */
export type PostExecuteListV5PayloadParamsItemMarketplaceFees = string[];

/**
 * Deprecated, use `marketplaceFees` and/or `customRoyalties`
 */
export type PostExecuteListV5PayloadParamsItemFees = string[];

/**
 * Orderbook where order is placed. Example: `Reservoir`
 */
export type PostExecuteListV5PayloadParamsItemOrderbook = typeof PostExecuteListV5PayloadParamsItemOrderbook[keyof typeof PostExecuteListV5PayloadParamsItemOrderbook];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteListV5PayloadParamsItemOrderbook = {
  blur: 'blur',
  opensea: 'opensea',
  'looks-rare': 'looks-rare',
  reservoir: 'reservoir',
  x2y2: 'x2y2',
} as const;

export interface PostExecuteListV5PayloadParamsItem {
  /** If true, royalty amounts and recipients will be set automatically. */
  automatedRoyalties?: boolean;
  currency?: string;
  customRoyalties?: PostExecuteListV5PayloadParamsItemCustomRoyalties;
  /** Amount seller is willing to sell for Dutch auction in the largest denomination for the specific currency. Example: `2000000000000000000` */
  endWeiPrice?: string;
  /** Unix timestamp (seconds) indicating when listing will expire. Example: `1656080318` */
  expirationTime?: string;
  fees?: PostExecuteListV5PayloadParamsItemFees;
  /** Unix timestamp (seconds) indicating when listing will be listed. Example: `1656080318` */
  listingTime?: string;
  marketplaceFees?: PostExecuteListV5PayloadParamsItemMarketplaceFees;
  /** Optional. Set a custom nonce */
  nonce?: string;
  options?: PostExecuteListV5PayloadParamsItemOptions;
  orderbook?: PostExecuteListV5PayloadParamsItemOrderbook;
  /** Optional API key for the target orderbook */
  orderbookApiKey?: string;
  orderKind?: PostExecuteListV5PayloadParamsItemOrderKind;
  /** Quantity of tokens user is listing. Only compatible with ERC1155 tokens. Example: `5` */
  quantity?: number;
  /** Set a maximum amount of royalties to pay, rather than the full amount. Only relevant when using automated royalties. 1 BPS = 0.01% Note: OpenSea does not support values below 50 bps. */
  royaltyBps?: number;
  /** Optional. Random string to make the order unique */
  salt?: string;
  /** Address of wallet taking the private order. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00` */
  taker?: string;
  /** Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123` */
  token: string;
  /** Amount seller is willing to sell for in the smallest denomination for the specific currency. Example: `1000000000000000000` */
  weiPrice: string;
}

export interface PostExecuteListV5PayloadParamsItemOptionsAlienswap {
  replaceOrderId?: string;
  useOffChainCancellation: boolean;
}

export interface PostExecuteListV5PayloadParamsItemOptionsPaymentProcessorV2 {
  useOffChainCancellation: boolean;
}

export interface PostExecuteListV5PayloadParamsItemOptionsSeaportV15 {
  conduitKey?: string;
  replaceOrderId?: string;
  useOffChainCancellation: boolean;
}

export interface PostExecuteListV5PayloadParamsItemOptionsSeaportV14 {
  conduitKey?: string;
  replaceOrderId?: string;
  useOffChainCancellation: boolean;
}

/**
 * Additional options.
 */
export interface PostExecuteListV5PayloadParamsItemOptions {
  alienswap?: PostExecuteListV5PayloadParamsItemOptionsAlienswap;
  'payment-processor-v2'?: PostExecuteListV5PayloadParamsItemOptionsPaymentProcessorV2;
  'seaport-v1.4'?: PostExecuteListV5PayloadParamsItemOptionsSeaportV14;
  'seaport-v1.5'?: PostExecuteListV5PayloadParamsItemOptionsSeaportV15;
}

/**
 * Exchange protocol used to create order. Example: `seaport-v1.5`
 */
export type PostExecuteListV5PayloadParamsItemOrderKind = typeof PostExecuteListV5PayloadParamsItemOrderKind[keyof typeof PostExecuteListV5PayloadParamsItemOrderKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteListV5PayloadParamsItemOrderKind = {
  blur: 'blur',
  'looks-rare': 'looks-rare',
  'looks-rare-v2': 'looks-rare-v2',
  'zeroex-v4': 'zeroex-v4',
  seaport: 'seaport',
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  x2y2: 'x2y2',
  alienswap: 'alienswap',
  'payment-processor': 'payment-processor',
  'payment-processor-v2': 'payment-processor-v2',
} as const;

export interface PostExecuteListV4Response {
  steps?: PostExecuteListV4ResponseSteps;
}

export interface PostExecuteListV4ResponseStepsItemItemsItemData { [key: string]: any }

export type PostExecuteListV4ResponseStepsItemItemsItemStatus = typeof PostExecuteListV4ResponseStepsItemItemsItemStatus[keyof typeof PostExecuteListV4ResponseStepsItemItemsItemStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteListV4ResponseStepsItemItemsItemStatus = {
  complete: 'complete',
  incomplete: 'incomplete',
} as const;

export interface PostExecuteListV4ResponseStepsItemItemsItem {
  data?: PostExecuteListV4ResponseStepsItemItemsItemData;
  orderIndex?: number;
  status: PostExecuteListV4ResponseStepsItemItemsItemStatus;
}

export type PostExecuteListV4ResponseStepsItemItems = PostExecuteListV4ResponseStepsItemItemsItem[];

export type PostExecuteListV4ResponseStepsItemKind = typeof PostExecuteListV4ResponseStepsItemKind[keyof typeof PostExecuteListV4ResponseStepsItemKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteListV4ResponseStepsItemKind = {
  request: 'request',
  signature: 'signature',
  transaction: 'transaction',
} as const;

export interface PostExecuteListV4ResponseStepsItem {
  action: string;
  description: string;
  id: string;
  items: PostExecuteListV4ResponseStepsItemItems;
  kind: PostExecuteListV4ResponseStepsItemKind;
}

export type PostExecuteListV4ResponseSteps = PostExecuteListV4ResponseStepsItem[];

/**
 * List of fees (formatted as `feeRecipient:feeBps`) to be bundled within the order. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00:100`
 */
export type PostExecuteListV4PayloadParamsItemFees = string[];

export interface PostExecuteListV4PayloadParamsItem {
  /** If true, royalties will be automatically included. */
  automatedRoyalties?: boolean;
  currency?: string;
  /** Unix timestamp (seconds) indicating when listing will expire. Example: `1656080318` */
  expirationTime?: string;
  fees?: PostExecuteListV4PayloadParamsItemFees;
  /** Unix timestamp (seconds) indicating when listing will be listed. Example: `1656080318` */
  listingTime?: string;
  /** Optional. Set a custom nonce */
  nonce?: string;
  orderbook?: PostExecuteListV4PayloadParamsItemOrderbook;
  /** Optional API key for the target orderbook */
  orderbookApiKey?: string;
  orderKind?: PostExecuteListV4PayloadParamsItemOrderKind;
  /** Quantity of tokens user is listing. Only compatible with ERC1155 tokens. Example: `5` */
  quantity?: number;
  /** The royalty percentage to pay. Only relevant when using automated royalties. */
  royaltyBps?: number;
  /** Optional. Random string to make the order unique */
  salt?: string;
  /** Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123` */
  token: string;
  /** Amount seller is willing to sell for in wei. Example: `1000000000000000000` */
  weiPrice: string;
}

export type PostExecuteListV4PayloadParams = PostExecuteListV4PayloadParamsItem[];

export interface PostExecuteListV4Payload {
  /** Address of wallet making the order. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00` */
  maker: string;
  params?: PostExecuteListV4PayloadParams;
  /** Domain of your app that is creating the order, e.g. `myapp.xyz`. This is used for filtering, and to attribute the "order source" of sales in on-chain analytics, to help your app get discovered. Lean more <a href='https://docs.reservoir.tools/docs/calldata-attribution'>here</a> */
  source?: string;
}

/**
 * Orderbook where order is placed. Example: `Reservoir`
 */
export type PostExecuteListV4PayloadParamsItemOrderbook = typeof PostExecuteListV4PayloadParamsItemOrderbook[keyof typeof PostExecuteListV4PayloadParamsItemOrderbook];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteListV4PayloadParamsItemOrderbook = {
  opensea: 'opensea',
  'looks-rare': 'looks-rare',
  reservoir: 'reservoir',
  x2y2: 'x2y2',
} as const;

/**
 * Exchange protocol used to create order. Example: `seaport-v1.5`
 */
export type PostExecuteListV4PayloadParamsItemOrderKind = typeof PostExecuteListV4PayloadParamsItemOrderKind[keyof typeof PostExecuteListV4PayloadParamsItemOrderKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteListV4PayloadParamsItemOrderKind = {
  'looks-rare': 'looks-rare',
  'looks-rare-v2': 'looks-rare-v2',
  'zeroex-v4': 'zeroex-v4',
  seaport: 'seaport',
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  x2y2: 'x2y2',
} as const;

export interface PostExecuteCancelSignatureV1Response {
  message?: string;
}

/**
 * Exchange protocol used to bulk cancel order. Example: `seaport-v1.5`
 */
export type PostExecuteCancelSignatureV1PayloadOrderKind = typeof PostExecuteCancelSignatureV1PayloadOrderKind[keyof typeof PostExecuteCancelSignatureV1PayloadOrderKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteCancelSignatureV1PayloadOrderKind = {
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  alienswap: 'alienswap',
  'blur-bid': 'blur-bid',
  'payment-processor-v2': 'payment-processor-v2',
} as const;

/**
 * Ids of the orders to cancel
 */
export type PostExecuteCancelSignatureV1PayloadOrderIds = string[];

export interface PostExecuteCancelSignatureV1Payload {
  orderIds: PostExecuteCancelSignatureV1PayloadOrderIds;
  orderKind: PostExecuteCancelSignatureV1PayloadOrderKind;
}

export interface PostExecuteCancelImtblV1Response {
  isPendingCancellation?: boolean;
  message?: string;
}

export interface PostExecuteCancelImtblV1PayloadOrdersItem {
  /** Id of the order to cancel */
  id: string;
  /** Corresponding Imtbl orderId to cancel */
  imtblOrderId: string;
}

/**
 * Orders to cancel
 */
export type PostExecuteCancelImtblV1PayloadOrders = PostExecuteCancelImtblV1PayloadOrdersItem[];

export interface PostExecuteCancelImtblV1Payload {
  /** Chain where orders were created */
  chainId: number;
  /** Address of wallet cancelling the orders */
  makerAddress: string;
  orders: PostExecuteCancelImtblV1PayloadOrders;
}

export interface PostExecuteCancelV3Response {
  steps: PostExecuteCancelV3ResponseSteps;
}

export type PostExecuteCancelV3ResponseStepsItemItems = PostExecuteCancelV3ResponseStepsItemItemsItem[];

export interface PostExecuteCancelV3ResponseStepsItem {
  action: string;
  description: string;
  id: string;
  items: PostExecuteCancelV3ResponseStepsItemItems;
  kind: PostExecuteCancelV3ResponseStepsItemKind;
}

export type PostExecuteCancelV3ResponseSteps = PostExecuteCancelV3ResponseStepsItem[];

export interface PostExecuteCancelV3ResponseStepsItemItemsItemData { [key: string]: any }

export type PostExecuteCancelV3ResponseStepsItemItemsItemStatus = typeof PostExecuteCancelV3ResponseStepsItemItemsItemStatus[keyof typeof PostExecuteCancelV3ResponseStepsItemItemsItemStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteCancelV3ResponseStepsItemItemsItemStatus = {
  complete: 'complete',
  incomplete: 'incomplete',
} as const;

export interface PostExecuteCancelV3ResponseStepsItemItemsItem {
  data?: PostExecuteCancelV3ResponseStepsItemItemsItemData;
  status: PostExecuteCancelV3ResponseStepsItemItemsItemStatus;
  tip?: string;
}

export type PostExecuteCancelV3ResponseStepsItemKind = typeof PostExecuteCancelV3ResponseStepsItemKind[keyof typeof PostExecuteCancelV3ResponseStepsItemKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteCancelV3ResponseStepsItemKind = {
  signature: 'signature',
  transaction: 'transaction',
} as const;

export type PostExecuteCancelV3PayloadOrderKind = typeof PostExecuteCancelV3PayloadOrderKind[keyof typeof PostExecuteCancelV3PayloadOrderKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteCancelV3PayloadOrderKind = {
  blur: 'blur',
  seaport: 'seaport',
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  'looks-rare-v2': 'looks-rare-v2',
  'zeroex-v4-erc721': 'zeroex-v4-erc721',
  'zeroex-v4-erc1155': 'zeroex-v4-erc1155',
  'payment-processor-v2': 'payment-processor-v2',
  rarible: 'rarible',
  alienswap: 'alienswap',
} as const;

export type PostExecuteCancelV3PayloadOrderIds = string[];

export interface PostExecuteCancelV3Payload {
  blurAuth?: string;
  maker?: string;
  /** Optional. Set custom gas price */
  maxFeePerGas?: string;
  /** Optional. Set custom gas price */
  maxPriorityFeePerGas?: string;
  orderIds?: PostExecuteCancelV3PayloadOrderIds;
  orderKind?: PostExecuteCancelV3PayloadOrderKind;
  token?: string;
}

export interface PostExecuteBuyV7ResponseMaxQuantitiesItem {
  itemIndex: number;
  maxQuantity?: string;
}

export type PostExecuteBuyV7ResponseMaxQuantities = PostExecuteBuyV7ResponseMaxQuantitiesItem[];

export type PostExecuteBuyV7ResponsePath = PostExecuteBuyV7ResponsePathItem[];

export interface PostExecuteBuyV7Response {
  errors?: PostExecuteBuyV7ResponseErrors;
  gasEstimate?: number;
  maxQuantities?: PostExecuteBuyV7ResponseMaxQuantities;
  path?: PostExecuteBuyV7ResponsePath;
  requestId?: string;
  steps?: PostExecuteBuyV7ResponseSteps;
}

export interface PostExecuteBuyV7ResponsePathItemFeesOnTopItem {
  amount?: number;
  bps?: number;
  kind?: string;
  rawAmount?: string;
  recipient?: string;
}

/**
 * Can be referral fees.
 */
export type PostExecuteBuyV7ResponsePathItemFeesOnTop = PostExecuteBuyV7ResponsePathItemFeesOnTopItem[];

export interface PostExecuteBuyV7ResponsePathItemBuiltInFeesItem {
  amount?: number;
  bps?: number;
  kind?: string;
  rawAmount?: string;
  recipient?: string;
}

/**
 * Can be marketplace fees or royalties
 */
export type PostExecuteBuyV7ResponsePathItemBuiltInFees = PostExecuteBuyV7ResponsePathItemBuiltInFeesItem[];

export type PostExecuteBuyV7ResponsePathItemBuyIn = PostExecuteBuyV7ResponsePathItemBuyInItem[];

export interface PostExecuteBuyV7ResponsePathItem {
  builtInFees?: PostExecuteBuyV7ResponsePathItemBuiltInFees;
  buyIn?: PostExecuteBuyV7ResponsePathItemBuyIn;
  buyInCurrency?: string;
  buyInCurrencyDecimals?: number;
  buyInCurrencySymbol?: string;
  buyInQuote?: number;
  buyInRawQuote?: string;
  contract?: string;
  currency?: string;
  currencyDecimals?: number;
  currencySymbol?: string;
  feesOnTop?: PostExecuteBuyV7ResponsePathItemFeesOnTop;
  /** Chain id buying from */
  fromChainId?: number;
  gasCost?: string;
  orderId?: string;
  quantity?: number;
  quote?: number;
  rawQuote?: string;
  source?: string;
  tokenId?: string;
  totalPrice?: number;
  totalRawPrice?: string;
}

/**
 * Amount with fees & royalties removed.
 */
export interface PostExecuteBuyV7ResponsePathItemBuyInItemNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface PostExecuteBuyV7ResponsePathItemBuyInItemAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface PostExecuteBuyV7ResponsePathItemBuyInItemCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface PostExecuteBuyV7ResponsePathItemBuyInItem {
  amount?: PostExecuteBuyV7ResponsePathItemBuyInItemAmount;
  currency?: PostExecuteBuyV7ResponsePathItemBuyInItemCurrency;
  netAmount?: PostExecuteBuyV7ResponsePathItemBuyInItemNetAmount;
}

export interface PostExecuteBuyV7ResponseErrorsItem {
  message?: string;
  orderId?: string;
}

export type PostExecuteBuyV7ResponseErrors = PostExecuteBuyV7ResponseErrorsItem[];

export type PostExecuteBuyV7ResponseStepsItemItems = PostExecuteBuyV7ResponseStepsItemItemsItem[];

export interface PostExecuteBuyV7ResponseStepsItem {
  action: string;
  description: string;
  id: string;
  items: PostExecuteBuyV7ResponseStepsItemItems;
  kind: PostExecuteBuyV7ResponseStepsItemKind;
}

export type PostExecuteBuyV7ResponseSteps = PostExecuteBuyV7ResponseStepsItem[];

export type PostExecuteBuyV7ResponseStepsItemItemsItemCheckMethod = typeof PostExecuteBuyV7ResponseStepsItemItemsItemCheckMethod[keyof typeof PostExecuteBuyV7ResponseStepsItemItemsItemCheckMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteBuyV7ResponseStepsItemItemsItemCheckMethod = {
  POST: 'POST',
} as const;

/**
 * The details of the endpoint for checking the status of the step
 */
export interface PostExecuteBuyV7ResponseStepsItemItemsItemCheck {
  body?: string;
  endpoint: string;
  method: PostExecuteBuyV7ResponseStepsItemItemsItemCheckMethod;
}

export interface PostExecuteBuyV7ResponseStepsItemItemsItemData { [key: string]: any }

export type PostExecuteBuyV7ResponseStepsItemItemsItemOrderIds = string[];

/**
 * Response is `complete` or `incomplete`.
 */
export type PostExecuteBuyV7ResponseStepsItemItemsItemStatus = typeof PostExecuteBuyV7ResponseStepsItemItemsItemStatus[keyof typeof PostExecuteBuyV7ResponseStepsItemItemsItemStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteBuyV7ResponseStepsItemItemsItemStatus = {
  complete: 'complete',
  incomplete: 'incomplete',
} as const;

export interface PostExecuteBuyV7ResponseStepsItemItemsItem {
  check?: PostExecuteBuyV7ResponseStepsItemItemsItemCheck;
  data?: PostExecuteBuyV7ResponseStepsItemItemsItemData;
  /** Approximation of gas used (only applies to `transaction` items) */
  gasEstimate?: number;
  orderIds?: PostExecuteBuyV7ResponseStepsItemItemsItemOrderIds;
  status: PostExecuteBuyV7ResponseStepsItemItemsItemStatus;
  tip?: string;
}

export type PostExecuteBuyV7ResponseStepsItemKind = typeof PostExecuteBuyV7ResponseStepsItemKind[keyof typeof PostExecuteBuyV7ResponseStepsItemKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteBuyV7ResponseStepsItemKind = {
  signature: 'signature',
  transaction: 'transaction',
} as const;

export type PostExecuteBuyV7PayloadExecutionMethod = typeof PostExecuteBuyV7PayloadExecutionMethod[keyof typeof PostExecuteBuyV7PayloadExecutionMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteBuyV7PayloadExecutionMethod = {
  'seaport-intent': 'seaport-intent',
} as const;

/**
 * Choose a specific swapping provider when buying in a different currency (defaults to `uniswap`)
 */
export type PostExecuteBuyV7PayloadSwapProvider = typeof PostExecuteBuyV7PayloadSwapProvider[keyof typeof PostExecuteBuyV7PayloadSwapProvider];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteBuyV7PayloadSwapProvider = {
  uniswap: 'uniswap',
  '1inch': '1inch',
} as const;

/**
 * List of fees (formatted as `feeRecipient:feeAmount`) to be taken when filling.
Unless overridden via the `currency` param, the currency used for any fees on top matches the buy-in currency detected by the backend.
Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00:1000000000000000`
 */
export type PostExecuteBuyV7PayloadFeesOnTop = string[];

/**
 * Alternative currencies to return the quote in.
 */
export type PostExecuteBuyV7PayloadAlternativeCurrencies = string[];

export interface PostExecuteBuyV7PayloadItemsItemExclusionsItem {
  orderId: string;
  price?: string;
}

/**
 * Items to exclude
 */
export type PostExecuteBuyV7PayloadItemsItemExclusions = PostExecuteBuyV7PayloadItemsItemExclusionsItem[];

/**
 * Optionally specify a particular fill method. Only relevant when filling via `collection`.
 */
export type PostExecuteBuyV7PayloadItemsItemFillType = typeof PostExecuteBuyV7PayloadItemsItemFillType[keyof typeof PostExecuteBuyV7PayloadItemsItemFillType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteBuyV7PayloadItemsItemFillType = {
  trade: 'trade',
  mint: 'mint',
  preferMint: 'preferMint',
} as const;

export interface PostExecuteBuyV7PayloadItemsItem {
  /** Collection to buy. */
  collection?: string;
  /** Only consider orders from this source. */
  exactOrderSource?: string;
  exclusions?: PostExecuteBuyV7PayloadItemsItemExclusions;
  fillType?: PostExecuteBuyV7PayloadItemsItemFillType;
  /** Optional order id to fill. */
  orderId?: string;
  /** If there are multiple listings with equal best price, prefer this source over others.
NOTE: if you want to fill a listing that is not the best priced, you need to pass a specific order id or use `exactOrderSource`. */
  preferredOrderSource?: string;
  /** Quantity of tokens to buy. */
  quantity?: number;
  rawOrder?: PostExecuteBuyV7PayloadItemsItemRawOrder;
  /** Token to buy. */
  token?: string;
}

/**
 * List of items to buy.
 */
export type PostExecuteBuyV7PayloadItems = PostExecuteBuyV7PayloadItemsItem[];

export interface PostExecuteBuyV7Payload {
  /** If true, inactive orders will not be skipped over (only relevant when filling via a specific order id). */
  allowInactiveOrderIds?: boolean;
  alternativeCurrencies?: PostExecuteBuyV7PayloadAlternativeCurrencies;
  /** Advanced use case to pass personal blurAuthToken; the API will generate one if left empty. */
  blurAuth?: string;
  /** Mint comment (where suported) */
  comment?: string;
  /** Currency to be used for purchases. */
  currency?: string;
  /** The chain id of the purchase currency */
  currencyChainId?: number;
  /** Exclude orders that can only be filled by EOAs, to support filling with smart contracts. If marked `true`, blur will be excluded. */
  excludeEOA?: boolean;
  executionMethod?: PostExecuteBuyV7PayloadExecutionMethod;
  feesOnTop?: PostExecuteBuyV7PayloadFeesOnTop;
  /** If true, all fills will be executed through the router (where possible) */
  forceRouter?: boolean;
  items: PostExecuteBuyV7PayloadItems;
  /** Optional custom gas settings. Includes base fee & priority fee in this limit. */
  maxFeePerGas?: string;
  /** Optional custom gas settings. */
  maxPriorityFeePerGas?: string;
  /** Charge any missing royalties. */
  normalizeRoyalties?: boolean;
  /** If true, only the path will be returned. */
  onlyPath?: boolean;
  /** Optional OpenSea API key used for filling. You don't need to pass your own key, but if you don't, you are more likely to be rate-limited. */
  openseaApiKey?: string;
  /** If true, any off-chain or on-chain errors will be skipped. */
  partial?: boolean;
  /** Referrer address (where supported) */
  referrer?: string;
  /** Address of wallet relaying the fill transaction (paying for the NFT). */
  relayer?: string;
  /** If true, balance check will be skipped. */
  skipBalanceCheck?: boolean;
  /** Filling source used for attribution. Example: `reservoir.market` */
  source?: string;
  swapProvider?: PostExecuteBuyV7PayloadSwapProvider;
  /** Address of wallet filling (receiver of the NFT). */
  taker: string;
  /** When true, will use permit to avoid approvals. */
  usePermit?: boolean;
  /** Optional X2Y2 API key used for filling. */
  x2y2ApiKey?: string;
}

export interface PostExecuteBuyV7PayloadItemsItemRawOrderData { [key: string]: any }

export type PostExecuteBuyV7PayloadItemsItemRawOrderKind = typeof PostExecuteBuyV7PayloadItemsItemRawOrderKind[keyof typeof PostExecuteBuyV7PayloadItemsItemRawOrderKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteBuyV7PayloadItemsItemRawOrderKind = {
  opensea: 'opensea',
  'blur-partial': 'blur-partial',
  'looks-rare': 'looks-rare',
  'zeroex-v4': 'zeroex-v4',
  seaport: 'seaport',
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  x2y2: 'x2y2',
  rarible: 'rarible',
  sudoswap: 'sudoswap',
  nftx: 'nftx',
  alienswap: 'alienswap',
  mint: 'mint',
} as const;

/**
 * Optional raw order to fill.
 */
export interface PostExecuteBuyV7PayloadItemsItemRawOrder {
  data?: PostExecuteBuyV7PayloadItemsItemRawOrderData;
  kind?: PostExecuteBuyV7PayloadItemsItemRawOrderKind;
}

export interface PostExecuteBuyV6ResponsePathItem {
  contract?: string;
  currency?: string;
  orderId?: string;
  quantity?: number;
  quote?: number;
  rawQuote?: string;
  source?: string;
  tokenId?: string;
}

export type PostExecuteBuyV6ResponsePath = PostExecuteBuyV6ResponsePathItem[];

export interface PostExecuteBuyV6Response {
  errors?: PostExecuteBuyV6ResponseErrors;
  path?: PostExecuteBuyV6ResponsePath;
  steps?: PostExecuteBuyV6ResponseSteps;
}

export interface PostExecuteBuyV6ResponseErrorsItem {
  message?: string;
  orderId?: number;
}

export type PostExecuteBuyV6ResponseErrors = PostExecuteBuyV6ResponseErrorsItem[];

export type PostExecuteBuyV6ResponseStepsItemItems = PostExecuteBuyV6ResponseStepsItemItemsItem[];

export interface PostExecuteBuyV6ResponseStepsItem {
  action: string;
  description: string;
  id: string;
  items: PostExecuteBuyV6ResponseStepsItemItems;
  kind: PostExecuteBuyV6ResponseStepsItemKind;
}

export type PostExecuteBuyV6ResponseSteps = PostExecuteBuyV6ResponseStepsItem[];

export interface PostExecuteBuyV6ResponseStepsItemItemsItemData { [key: string]: any }

export type PostExecuteBuyV6ResponseStepsItemItemsItemStatus = typeof PostExecuteBuyV6ResponseStepsItemItemsItemStatus[keyof typeof PostExecuteBuyV6ResponseStepsItemItemsItemStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteBuyV6ResponseStepsItemItemsItemStatus = {
  complete: 'complete',
  incomplete: 'incomplete',
} as const;

export interface PostExecuteBuyV6ResponseStepsItemItemsItem {
  data?: PostExecuteBuyV6ResponseStepsItemItemsItemData;
  status: PostExecuteBuyV6ResponseStepsItemItemsItemStatus;
}

export type PostExecuteBuyV6ResponseStepsItemKind = typeof PostExecuteBuyV6ResponseStepsItemKind[keyof typeof PostExecuteBuyV6ResponseStepsItemKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteBuyV6ResponseStepsItemKind = {
  signature: 'signature',
  transaction: 'transaction',
} as const;

/**
 * List of fees (formatted as `feeRecipient:feeAmount`) to be taken when filling.
Unless overridden via the `currency` param, the currency used for any fees on top matches the buy-in currency detected by the backend.
Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00:1000000000000000`
 */
export type PostExecuteBuyV6PayloadFeesOnTop = string[];

/**
 * Currency to buy all listings in.
 */
export type PostExecuteBuyV6PayloadCurrency = typeof PostExecuteBuyV6PayloadCurrency[keyof typeof PostExecuteBuyV6PayloadCurrency];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteBuyV6PayloadCurrency = {
  '0x0000000000000000000000000000000000000000': '0x0000000000000000000000000000000000000000',
} as const;

/**
 * Array of tokens user is buying. Example: `tokens[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:704 tokens[1]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:979`
 */
export type PostExecuteBuyV6PayloadTokens = string[];

export type PostExecuteBuyV6PayloadRawOrders = PostExecuteBuyV6PayloadRawOrdersItem[];

export interface PostExecuteBuyV6Payload {
  /** If true, do not filter out inactive orders (only relevant for order id filtering). */
  allowInactiveOrderIds?: boolean;
  currency?: PostExecuteBuyV6PayloadCurrency;
  /** Exclude orders that can only be filled by EOAs, to support filling with smart contracts. */
  excludeEOA?: boolean;
  feesOnTop?: PostExecuteBuyV6PayloadFeesOnTop;
  /** If true, all fills will be executed through the router. */
  forceRouter?: boolean;
  /** Optional. Set custom gas price. */
  maxFeePerGas?: string;
  /** Optional. Set custom gas price. */
  maxPriorityFeePerGas?: string;
  normalizeRoyalties?: boolean;
  /** If true, only the path will be returned. */
  onlyPath?: boolean;
  orderIds?: PostExecuteBuyV6PayloadOrderIds;
  /** If true, any off-chain or on-chain errors will be skipped. */
  partial?: boolean;
  /** If there are multiple listings with equal best price, prefer this source over others.
NOTE: if you want to fill a listing that is not the best priced, you need to pass a specific order ID. */
  preferredOrderSource?: string;
  /** Quantity of tokens user is buying. Only compatible when buying a single ERC1155 token. Example: `5` */
  quantity?: number;
  rawOrders?: PostExecuteBuyV6PayloadRawOrders;
  /** Address of wallet relaying the filling transaction */
  relayer?: string;
  /** If true, balance check will be skipped. */
  skipBalanceCheck?: boolean;
  /** Filling source used for attribution. Example: `reservoir.market` */
  source?: string;
  /** Address of wallet filling the order. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00` */
  taker: string;
  tokens?: PostExecuteBuyV6PayloadTokens;
  /** Override the X2Y2 API key used for filling. */
  x2y2ApiKey?: string;
}

export interface PostExecuteBuyV6PayloadRawOrdersItemData { [key: string]: any }

export type PostExecuteBuyV6PayloadRawOrdersItemKind = typeof PostExecuteBuyV6PayloadRawOrdersItemKind[keyof typeof PostExecuteBuyV6PayloadRawOrdersItemKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteBuyV6PayloadRawOrdersItemKind = {
  opensea: 'opensea',
  'looks-rare': 'looks-rare',
  'zeroex-v4': 'zeroex-v4',
  seaport: 'seaport',
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  x2y2: 'x2y2',
  rarible: 'rarible',
  sudoswap: 'sudoswap',
  nftx: 'nftx',
} as const;

export interface PostExecuteBuyV6PayloadRawOrdersItem {
  data: PostExecuteBuyV6PayloadRawOrdersItemData;
  kind: PostExecuteBuyV6PayloadRawOrdersItemKind;
}

export type PostExecuteBuyV6PayloadOrderIds = string[];

export interface PostExecuteBuyV5ResponsePathItem {
  contract?: string;
  currency?: string;
  orderId?: string;
  quantity?: number;
  quote?: number;
  rawQuote?: string;
  source?: string;
  tokenId?: string;
}

export type PostExecuteBuyV5ResponsePath = PostExecuteBuyV5ResponsePathItem[];

export type PostExecuteBuyV5ResponseSteps = PostExecuteBuyV5ResponseStepsItem[];

export interface PostExecuteBuyV5Response {
  path?: PostExecuteBuyV5ResponsePath;
  steps?: PostExecuteBuyV5ResponseSteps;
}

export interface PostExecuteBuyV5ResponseStepsItemItemsItemData { [key: string]: any }

export type PostExecuteBuyV5ResponseStepsItemItemsItemStatus = typeof PostExecuteBuyV5ResponseStepsItemItemsItemStatus[keyof typeof PostExecuteBuyV5ResponseStepsItemItemsItemStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteBuyV5ResponseStepsItemItemsItemStatus = {
  complete: 'complete',
  incomplete: 'incomplete',
} as const;

export interface PostExecuteBuyV5ResponseStepsItemItemsItem {
  data?: PostExecuteBuyV5ResponseStepsItemItemsItemData;
  status: PostExecuteBuyV5ResponseStepsItemItemsItemStatus;
}

export type PostExecuteBuyV5ResponseStepsItemItems = PostExecuteBuyV5ResponseStepsItemItemsItem[];

export type PostExecuteBuyV5ResponseStepsItemKind = typeof PostExecuteBuyV5ResponseStepsItemKind[keyof typeof PostExecuteBuyV5ResponseStepsItemKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteBuyV5ResponseStepsItemKind = {
  transaction: 'transaction',
} as const;

export interface PostExecuteBuyV5ResponseStepsItem {
  action: string;
  description: string;
  items: PostExecuteBuyV5ResponseStepsItemItems;
  kind: PostExecuteBuyV5ResponseStepsItemKind;
}

/**
 * List of fees (formatted as `feeRecipient:feeAmount`) to be taken when filling. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00:1000000000000000`
 */
export type PostExecuteBuyV5PayloadFeesOnTop = string[];

/**
 * Array of tokens user is buying. Example: `tokens[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:704 tokens[1]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:979`
 */
export type PostExecuteBuyV5PayloadTokens = string[];

export type PostExecuteBuyV5PayloadRawOrders = PostExecuteBuyV5PayloadRawOrdersItem[];

export interface PostExecuteBuyV5Payload {
  currency?: string;
  feesOnTop?: PostExecuteBuyV5PayloadFeesOnTop;
  /** If true, all fills will be executed through the router. */
  forceRouter?: boolean;
  /** Optional. Set custom gas price. */
  maxFeePerGas?: string;
  /** Optional. Set custom gas price. */
  maxPriorityFeePerGas?: string;
  normalizeRoyalties?: boolean;
  /** If true, only the path will be returned. */
  onlyPath?: boolean;
  orderIds?: PostExecuteBuyV5PayloadOrderIds;
  /** If true, any off-chain or on-chain errors will be skipped. */
  partial?: boolean;
  /** If there are multiple listings with equal best price, prefer this source over others.
NOTE: if you want to fill a listing that is not the best priced, you need to pass a specific order ID. */
  preferredOrderSource?: string;
  /** Quantity of tokens user is buying. Only compatible when buying a single ERC1155 token. Example: `5` */
  quantity?: number;
  rawOrders?: PostExecuteBuyV5PayloadRawOrders;
  /** Address of wallet relaying the filling transaction */
  relayer?: string;
  /** If true, balance check will be skipped. */
  skipBalanceCheck?: boolean;
  /** Filling source used for attribution. Example: `reservoir.market` */
  source?: string;
  /** Address of wallet filling the order. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00` */
  taker: string;
  tokens?: PostExecuteBuyV5PayloadTokens;
  /** Override the X2Y2 API key used for filling. */
  x2y2ApiKey?: string;
}

export interface PostExecuteBuyV5PayloadRawOrdersItemData { [key: string]: any }

export type PostExecuteBuyV5PayloadRawOrdersItemKind = typeof PostExecuteBuyV5PayloadRawOrdersItemKind[keyof typeof PostExecuteBuyV5PayloadRawOrdersItemKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteBuyV5PayloadRawOrdersItemKind = {
  opensea: 'opensea',
  'looks-rare': 'looks-rare',
  'zeroex-v4': 'zeroex-v4',
  seaport: 'seaport',
  x2y2: 'x2y2',
} as const;

export interface PostExecuteBuyV5PayloadRawOrdersItem {
  data: PostExecuteBuyV5PayloadRawOrdersItemData;
  kind: PostExecuteBuyV5PayloadRawOrdersItemKind;
}

export type PostExecuteBuyV5PayloadOrderIds = string[];

export interface PostExecuteBidV5ResponseErrorsItem {
  message?: string;
  orderIndex?: number;
}

export type PostExecuteBidV5ResponseErrors = PostExecuteBidV5ResponseErrorsItem[];

export type PostExecuteBidV5ResponseSteps = PostExecuteBidV5ResponseStepsItem[];

export interface PostExecuteBidV5Response {
  errors?: PostExecuteBidV5ResponseErrors;
  steps?: PostExecuteBidV5ResponseSteps;
}

export type PostExecuteBidV5ResponseStepsItemItemsItemOrderIndexes = number[];

export interface PostExecuteBidV5ResponseStepsItemItemsItemData { [key: string]: any }

/**
 * Returns `complete` or `incomplete`
 */
export type PostExecuteBidV5ResponseStepsItemItemsItemStatus = typeof PostExecuteBidV5ResponseStepsItemItemsItemStatus[keyof typeof PostExecuteBidV5ResponseStepsItemItemsItemStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteBidV5ResponseStepsItemItemsItemStatus = {
  complete: 'complete',
  incomplete: 'incomplete',
} as const;

export interface PostExecuteBidV5ResponseStepsItemItemsItem {
  data?: PostExecuteBidV5ResponseStepsItemItemsItemData;
  orderIndexes?: PostExecuteBidV5ResponseStepsItemItemsItemOrderIndexes;
  status: PostExecuteBidV5ResponseStepsItemItemsItemStatus;
  tip?: string;
}

export type PostExecuteBidV5ResponseStepsItemItems = PostExecuteBidV5ResponseStepsItemItemsItem[];

/**
 * Returns `request`, `signature`, or `transaction`.
 */
export type PostExecuteBidV5ResponseStepsItemKind = typeof PostExecuteBidV5ResponseStepsItemKind[keyof typeof PostExecuteBidV5ResponseStepsItemKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteBidV5ResponseStepsItemKind = {
  request: 'request',
  signature: 'signature',
  transaction: 'transaction',
} as const;

export interface PostExecuteBidV5ResponseStepsItem {
  action: string;
  description: string;
  /** Returns `currency-wrapping`, `currency-approval`, or `order-signature`. */
  id: string;
  items: PostExecuteBidV5ResponseStepsItemItems;
  kind: PostExecuteBidV5ResponseStepsItemKind;
}

export interface PostExecuteBidV5Payload {
  /** Advanced use case to pass personal blurAuthToken; the API will generate one if left empty. */
  blurAuth?: string;
  /** Address of wallet making the order. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00` */
  maker: string;
  params?: PostExecuteBidV5PayloadParams;
  /** Domain of your app that is creating the order, e.g. `myapp.xyz`. This is used for filtering, and to attribute the "order source" of sales in on-chain analytics, to help your app get discovered. Lean more <a href='https://docs.reservoir.tools/docs/calldata-attribution'>here</a> */
  source?: string;
}

/**
 * List of custom royalties (formatted as `feeRecipient:feeBps`) to be bundled within the order. 1 BPS = 0.01% Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00:100`
 */
export type PostExecuteBidV5PayloadParamsItemCustomRoyalties = string[];

/**
 * List of marketplace fees (formatted as `feeRecipient:feeBps`) to be bundled within the order. 1 BPS = 0.01% Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00:100`
 */
export type PostExecuteBidV5PayloadParamsItemMarketplaceFees = string[];

/**
 * Deprecated, use `marketplaceFees` and/or `customRoyalties`
 */
export type PostExecuteBidV5PayloadParamsItemFees = string[];

/**
 * Orderbook where order is placed. Example: `Reservoir`
 */
export type PostExecuteBidV5PayloadParamsItemOrderbook = typeof PostExecuteBidV5PayloadParamsItemOrderbook[keyof typeof PostExecuteBidV5PayloadParamsItemOrderbook];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteBidV5PayloadParamsItemOrderbook = {
  blur: 'blur',
  reservoir: 'reservoir',
  opensea: 'opensea',
  'looks-rare': 'looks-rare',
  x2y2: 'x2y2',
} as const;

export interface PostExecuteBidV5PayloadParamsItem {
  /** Bid on a particular attribute key. This is case sensitive. Example: `Composition` */
  attributeKey?: string;
  /** Bid on a particular attribute value. This is case sensitive. Example: `Teddy (#33)` */
  attributeValue?: string;
  /** If true, royalty amounts and recipients will be set automatically. */
  automatedRoyalties?: boolean;
  /** Bid on a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63` */
  collection?: string;
  currency?: string;
  customRoyalties?: PostExecuteBidV5PayloadParamsItemCustomRoyalties;
  /** If true flagged tokens will be excluded */
  excludeFlaggedTokens?: boolean;
  /** Unix timestamp (seconds) indicating when listing will expire. Example: `1656080318` */
  expirationTime?: string;
  fees?: PostExecuteBidV5PayloadParamsItemFees;
  /** Unix timestamp (seconds) indicating when listing will be listed. Example: `1656080318` */
  listingTime?: string;
  marketplaceFees?: PostExecuteBidV5PayloadParamsItemMarketplaceFees;
  /** Optional. Set a custom nonce */
  nonce?: string;
  options?: PostExecuteBidV5PayloadParamsItemOptions;
  orderbook?: PostExecuteBidV5PayloadParamsItemOrderbook;
  /** Optional API key for the target orderbook */
  orderbookApiKey?: string;
  orderKind?: PostExecuteBidV5PayloadParamsItemOrderKind;
  /** Quantity of tokens to bid on. */
  quantity?: number;
  /** Set a maximum amount of royalties to pay, rather than the full amount. Only relevant when using automated royalties. 1 BPS = 0.01% Note: OpenSea does not support values below 50 bps. */
  royaltyBps?: number;
  /** Optional. Random string to make the order unique */
  salt?: string;
  /** Bid on a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123` */
  token?: string;
  /** Bid on a particular token set. Cannot be used with cross-posting to OpenSea. Example: `token:CONTRACT:TOKEN_ID` representing a single token within contract, `contract:CONTRACT` representing a whole contract, `range:CONTRACT:START_TOKEN_ID:END_TOKEN_ID` representing a continuous token id range within a contract and `list:CONTRACT:TOKEN_IDS_HASH` representing a list of token ids within a contract. */
  tokenSetId?: string;
  /** When true, will use permit to avoid approvals. */
  usePermit?: boolean;
  /** Amount bidder is willing to offer in the smallest denomination for the specific currency. Example: `1000000000000000000` */
  weiPrice: string;
}

export type PostExecuteBidV5PayloadParams = PostExecuteBidV5PayloadParamsItem[];

export interface PostExecuteBidV5PayloadParamsItemOptionsPaymentProcessorV2 {
  useOffChainCancellation: boolean;
}

export interface PostExecuteBidV5PayloadParamsItemOptionsSeaportV15 {
  conduitKey?: string;
  replaceOrderId?: string;
  useOffChainCancellation: boolean;
}

export interface PostExecuteBidV5PayloadParamsItemOptionsSeaportV14 {
  conduitKey?: string;
  replaceOrderId?: string;
  useOffChainCancellation: boolean;
}

/**
 * Additional options.
 */
export interface PostExecuteBidV5PayloadParamsItemOptions {
  'payment-processor-v2'?: PostExecuteBidV5PayloadParamsItemOptionsPaymentProcessorV2;
  'seaport-v1.4'?: PostExecuteBidV5PayloadParamsItemOptionsSeaportV14;
  'seaport-v1.5'?: PostExecuteBidV5PayloadParamsItemOptionsSeaportV15;
}

/**
 * Exchange protocol used to create order. Example: `seaport-v1.5`
 */
export type PostExecuteBidV5PayloadParamsItemOrderKind = typeof PostExecuteBidV5PayloadParamsItemOrderKind[keyof typeof PostExecuteBidV5PayloadParamsItemOrderKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteBidV5PayloadParamsItemOrderKind = {
  blur: 'blur',
  'zeroex-v4': 'zeroex-v4',
  seaport: 'seaport',
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  'looks-rare': 'looks-rare',
  'looks-rare-v2': 'looks-rare-v2',
  x2y2: 'x2y2',
  alienswap: 'alienswap',
  'payment-processor': 'payment-processor',
  'payment-processor-v2': 'payment-processor-v2',
} as const;

export interface PostExecuteBidV4ResponseQuery { [key: string]: any }

export type PostExecuteBidV4ResponseSteps = PostExecuteBidV4ResponseStepsItem[];

export interface PostExecuteBidV4Response {
  query?: PostExecuteBidV4ResponseQuery;
  steps?: PostExecuteBidV4ResponseSteps;
}

export interface PostExecuteBidV4ResponseStepsItemItemsItemData { [key: string]: any }

export type PostExecuteBidV4ResponseStepsItemItemsItemStatus = typeof PostExecuteBidV4ResponseStepsItemItemsItemStatus[keyof typeof PostExecuteBidV4ResponseStepsItemItemsItemStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteBidV4ResponseStepsItemItemsItemStatus = {
  complete: 'complete',
  incomplete: 'incomplete',
} as const;

export interface PostExecuteBidV4ResponseStepsItemItemsItem {
  data?: PostExecuteBidV4ResponseStepsItemItemsItemData;
  orderIndex?: number;
  status: PostExecuteBidV4ResponseStepsItemItemsItemStatus;
}

export type PostExecuteBidV4ResponseStepsItemItems = PostExecuteBidV4ResponseStepsItemItemsItem[];

export type PostExecuteBidV4ResponseStepsItemKind = typeof PostExecuteBidV4ResponseStepsItemKind[keyof typeof PostExecuteBidV4ResponseStepsItemKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteBidV4ResponseStepsItemKind = {
  request: 'request',
  signature: 'signature',
  transaction: 'transaction',
} as const;

export interface PostExecuteBidV4ResponseStepsItem {
  action: string;
  description: string;
  id: string;
  items: PostExecuteBidV4ResponseStepsItemItems;
  kind: PostExecuteBidV4ResponseStepsItemKind;
}

export interface PostExecuteBidV4Payload {
  /** Address of wallet making the order. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00` */
  maker: string;
  params?: PostExecuteBidV4PayloadParams;
  /** Domain of your app that is creating the order, e.g. `myapp.xyz`. This is used for filtering, and to attribute the "order source" of sales in on-chain analytics, to help your app get discovered. Lean more <a href='https://docs.reservoir.tools/docs/calldata-attribution'>here</a> */
  source?: string;
}

/**
 * List of fees (formatted as `feeRecipient:feeBps`) to be bundled within the order. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00:100`
 */
export type PostExecuteBidV4PayloadParamsItemFees = string[];

/**
 * Orderbook where order is placed. Example: `Reservoir`
 */
export type PostExecuteBidV4PayloadParamsItemOrderbook = typeof PostExecuteBidV4PayloadParamsItemOrderbook[keyof typeof PostExecuteBidV4PayloadParamsItemOrderbook];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteBidV4PayloadParamsItemOrderbook = {
  reservoir: 'reservoir',
  opensea: 'opensea',
  'looks-rare': 'looks-rare',
  x2y2: 'x2y2',
} as const;

/**
 * Exchange protocol used to create order. Example: `seaport-v1.5`
 */
export type PostExecuteBidV4PayloadParamsItemOrderKind = typeof PostExecuteBidV4PayloadParamsItemOrderKind[keyof typeof PostExecuteBidV4PayloadParamsItemOrderKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteBidV4PayloadParamsItemOrderKind = {
  'zeroex-v4': 'zeroex-v4',
  seaport: 'seaport',
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  'looks-rare': 'looks-rare',
  'looks-rare-v2': 'looks-rare-v2',
  x2y2: 'x2y2',
} as const;

export interface PostExecuteBidV4PayloadParamsItem {
  /** Bid on a particular attribute key. Example: `Composition` */
  attributeKey?: string;
  /** Bid on a particular attribute value. Example: `Teddy (#33)` */
  attributeValue?: string;
  /** If true, royalties will be automatically included. */
  automatedRoyalties?: boolean;
  /** Bid on a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63` */
  collection?: string;
  currency?: string;
  /** If true flagged tokens will be excluded */
  excludeFlaggedTokens?: boolean;
  /** Unix timestamp (seconds) indicating when listing will expire. Example: `1656080318` */
  expirationTime?: string;
  fees?: PostExecuteBidV4PayloadParamsItemFees;
  /** Unix timestamp (seconds) indicating when listing will be listed. Example: `1656080318` */
  listingTime?: string;
  /** Optional. Set a custom nonce */
  nonce?: string;
  orderbook?: PostExecuteBidV4PayloadParamsItemOrderbook;
  /** Optional API key for the target orderbook */
  orderbookApiKey?: string;
  orderKind?: PostExecuteBidV4PayloadParamsItemOrderKind;
  /** Quantity of tokens user is buying. Only compatible with ERC1155 tokens. Example: `5` */
  quantity?: number;
  /** The royalty percentage to pay. Only relevant when using automated royalties. */
  royaltyBps?: number;
  /** Optional. Random string to make the order unique */
  salt?: string;
  /** Bid on a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123` */
  token?: string;
  /** Bid on a particular token set. */
  tokenSetId?: string;
  /** Amount bidder is willing to offer in wei. Example: `1000000000000000000` */
  weiPrice: string;
}

export type PostExecuteBidV4PayloadParams = PostExecuteBidV4PayloadParamsItem[];

export interface PostExecuteAuthSignatureV1Response {
  auth?: string;
}

/**
 * Type of auth
 */
export type PostExecuteAuthSignatureV1PayloadKind = typeof PostExecuteAuthSignatureV1PayloadKind[keyof typeof PostExecuteAuthSignatureV1PayloadKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteAuthSignatureV1PayloadKind = {
  blur: 'blur',
  erc721c: 'erc721c',
  opensea: 'opensea',
} as const;

export interface PostExecuteAuthSignatureV1Payload {
  /** Id of the auth challenge */
  id: string;
  kind: PostExecuteAuthSignatureV1PayloadKind;
}

export interface PostCollectionsSpamStatusV1Response {
  message?: string;
}

/**
 * Update to one or more collections. Max limit is 50. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
export type PostCollectionsSpamStatusV1PayloadCollectionsOptions = string[];

export interface PostCollectionsSpamStatusV1Payload {
  collections: PostCollectionsSpamStatusV1PayloadCollectionsOptions;
  /** API to update the spam status of a collection */
  spam?: boolean;
}

export interface PostCollectionsRefreshV2Response {
  message?: string;
}

export interface PostCollectionsRefreshV2Payload {
  /** Refresh the given collection. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63` */
  collection: string;
  /** If true, will force a refresh regardless of cool down. Requires an authorized api key to be passed. */
  overrideCoolDown?: boolean;
  /** If true, will refresh the metadata for the tokens in the collection. */
  refreshTokens?: boolean;
}

export interface PostCollectionsDisableMetadataV1Response {
  message?: string;
}

/**
 * Array of collection ids to disable metadata for. Max limit is 50. Example: `collections[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63 collections[1]: 0x521f9c7505005cfa19a8e5786a9c3c9c9f5e6f42`
 */
export type PostCollectionsDisableMetadataV1PayloadCollectionsOptions = string[];

export interface PostCollectionsDisableMetadataV1Payload {
  collections: PostCollectionsDisableMetadataV1PayloadCollectionsOptions;
  /** Whether to disable or reenable the metadata. Defaults to true (disable) */
  disable?: boolean;
}

export interface PostAdminApiKeysMetricsResponseMetricsItem {
  apiCallsCount?: number;
  key?: string;
  pointsConsumed?: number;
  route?: string;
  statusCode?: number;
  time?: string;
}

export type PostAdminApiKeysMetricsResponseMetrics = PostAdminApiKeysMetricsResponseMetricsItem[];

export interface PostAdminApiKeysMetricsResponse {
  metrics?: PostAdminApiKeysMetricsResponseMetrics;
}

export interface PostTokenSetsV2Response {
  id?: string;
}

export type PostTokenSetsV2PayloadTokens = string[];

export interface PostTokenSetsV2Payload {
  tokens: PostTokenSetsV2PayloadTokens;
}

export interface PostSeaportOffersPayloadSeaportOffersItemProtocolData {
  parameters?: string;
  signature?: string;
}

export interface PostSeaportOffersPayloadSeaportOffersItem {
  protocol_data?: PostSeaportOffersPayloadSeaportOffersItemProtocolData;
}

export type PostSeaportOffersPayloadSeaportOffers = PostSeaportOffersPayloadSeaportOffersItem[];

export interface PostSeaportOffersPayload {
  seaport_offers?: PostSeaportOffersPayloadSeaportOffers;
}

export type PostOrdersV1PayloadOrders = PostOrdersV1PayloadOrdersItem[];

export interface PostOrdersV1Payload {
  orders?: PostOrdersV1PayloadOrders;
}

export interface PostOrdersV1PayloadOrdersItemData { [key: string]: any }

export type PostOrdersV1PayloadOrdersItemKind = typeof PostOrdersV1PayloadOrdersItemKind[keyof typeof PostOrdersV1PayloadOrdersItemKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostOrdersV1PayloadOrdersItemKind = {
  blur: 'blur',
  'zeroex-v4': 'zeroex-v4',
  x2y2: 'x2y2',
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  element: 'element',
  rarible: 'rarible',
  manifold: 'manifold',
  'looks-rare-v2': 'looks-rare-v2',
} as const;

export interface PostOrdersV1PayloadOrdersItem {
  data: PostOrdersV1PayloadOrdersItemData;
  kind: PostOrdersV1PayloadOrdersItemKind;
  originatedAt?: string;
  source?: string;
}

export interface PostOrderV4ResponseResultsItem {
  /** Only available when posting to external orderbook. Can be used to retrieve the status of a cross-post order. */
  crossPostingOrderId?: string;
  /** Current cross-post order status. Responses are `pending`, `posted`, or `failed`. */
  crossPostingOrderStatus?: string;
  message?: string;
  orderId?: string;
  orderIndex?: number;
}

export type PostOrderV4ResponseResults = PostOrderV4ResponseResultsItem[];

export interface PostOrderV4Response {
  results?: PostOrderV4ResponseResults;
}

export type PostOrderV4PayloadItems = PostOrderV4PayloadItemsItem[];

export interface PostOrderV4Payload {
  items?: PostOrderV4PayloadItems;
  /** The source domain */
  source?: string;
}

export type PostOrderV4PayloadItemsItemBulkDataDataMerkleProof = string[];

export interface PostOrderV4PayloadItemsItemBulkDataData {
  merkleProof: PostOrderV4PayloadItemsItemBulkDataDataMerkleProof;
  orderIndex: number;
}

export type PostOrderV4PayloadItemsItemBulkDataKind = typeof PostOrderV4PayloadItemsItemBulkDataKind[keyof typeof PostOrderV4PayloadItemsItemBulkDataKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostOrderV4PayloadItemsItemBulkDataKind = {
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  alienswap: 'alienswap',
} as const;

export interface PostOrderV4PayloadItemsItemBulkData {
  data?: PostOrderV4PayloadItemsItemBulkDataData;
  kind?: PostOrderV4PayloadItemsItemBulkDataKind;
}

export interface PostOrderV4PayloadItemsItemAttribute {
  collection: string;
  key: string;
  value: string;
}

export type PostOrderV4PayloadItemsItemOrderbook = typeof PostOrderV4PayloadItemsItemOrderbook[keyof typeof PostOrderV4PayloadItemsItemOrderbook];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostOrderV4PayloadItemsItemOrderbook = {
  blur: 'blur',
  reservoir: 'reservoir',
  opensea: 'opensea',
  'looks-rare': 'looks-rare',
  x2y2: 'x2y2',
} as const;

export interface PostOrderV4PayloadItemsItem {
  attribute?: PostOrderV4PayloadItemsItemAttribute;
  bulkData?: PostOrderV4PayloadItemsItemBulkData;
  collection?: string;
  isNonFlagged?: boolean;
  order?: PostOrderV4PayloadItemsItemOrder;
  orderbook?: PostOrderV4PayloadItemsItemOrderbook;
  /** Optional API key for the target orderbook */
  orderbookApiKey?: string;
  permitId?: string;
  permitIndex?: number;
  tokenSetId?: string;
}

export interface PostOrderV4PayloadItemsItemOrderData { [key: string]: any }

export type PostOrderV4PayloadItemsItemOrderKind = typeof PostOrderV4PayloadItemsItemOrderKind[keyof typeof PostOrderV4PayloadItemsItemOrderKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostOrderV4PayloadItemsItemOrderKind = {
  blur: 'blur',
  opensea: 'opensea',
  'looks-rare-v2': 'looks-rare-v2',
  'zeroex-v4': 'zeroex-v4',
  seaport: 'seaport',
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  x2y2: 'x2y2',
  alienswap: 'alienswap',
  'payment-processor': 'payment-processor',
  'payment-processor-v2': 'payment-processor-v2',
} as const;

export interface PostOrderV4PayloadItemsItemOrder {
  data: PostOrderV4PayloadItemsItemOrderData;
  kind: PostOrderV4PayloadItemsItemOrderKind;
}

export interface PostOrderV3Response {
  /** Only available when posting to external orderbook. Can be used to retrieve the status of a cross-post order. */
  crossPostingOrderId?: string;
  crossPostingOrderStatus?: string;
  message?: string;
  orderId?: string;
}

export interface PostOrderV3PayloadAttribute {
  collection: string;
  key: string;
  value: string;
}

export type PostOrderV3PayloadOrderbook = typeof PostOrderV3PayloadOrderbook[keyof typeof PostOrderV3PayloadOrderbook];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostOrderV3PayloadOrderbook = {
  reservoir: 'reservoir',
  opensea: 'opensea',
  'looks-rare': 'looks-rare',
  x2y2: 'x2y2',
} as const;

export interface PostOrderV3Payload {
  attribute?: PostOrderV3PayloadAttribute;
  collection?: string;
  isNonFlagged?: boolean;
  order?: PostOrderV3PayloadOrder;
  orderbook?: PostOrderV3PayloadOrderbook;
  /** Optional API key for the target orderbook */
  orderbookApiKey?: string;
  permitId?: string;
  permitIndex?: number;
  /** The source domain */
  source?: string;
  tokenSetId?: string;
}

export interface PostOrderV3PayloadOrderData { [key: string]: any }

export type PostOrderV3PayloadOrderKind = typeof PostOrderV3PayloadOrderKind[keyof typeof PostOrderV3PayloadOrderKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostOrderV3PayloadOrderKind = {
  opensea: 'opensea',
  blur: 'blur',
  'looks-rare-v2': 'looks-rare-v2',
  'zeroex-v4': 'zeroex-v4',
  seaport: 'seaport',
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  x2y2: 'x2y2',
  alienswap: 'alienswap',
} as const;

export interface PostOrderV3PayloadOrder {
  data: PostOrderV3PayloadOrderData;
  kind: PostOrderV3PayloadOrderKind;
}

export interface PostOrderV2Response {
  /** Only available when posting to external orderbook. Can be used to retrieve the status of a cross-post order. */
  crossPostingOrderId?: string;
  crossPostingOrderStatus?: string;
  message?: string;
  orderId?: string;
}

export interface PostOrderV2PayloadAttribute {
  collection: string;
  key: string;
  value: string;
}

export type PostOrderV2PayloadOrderbook = typeof PostOrderV2PayloadOrderbook[keyof typeof PostOrderV2PayloadOrderbook];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostOrderV2PayloadOrderbook = {
  reservoir: 'reservoir',
  opensea: 'opensea',
} as const;

export interface PostOrderV2Payload {
  attribute?: PostOrderV2PayloadAttribute;
  collection?: string;
  isNonFlagged?: boolean;
  order?: PostOrderV2PayloadOrder;
  orderbook?: PostOrderV2PayloadOrderbook;
  orderbookApiKey?: string;
  /** The name of the source */
  source?: string;
  tokenSetId?: string;
}

export interface PostOrderV2PayloadOrderData { [key: string]: any }

export type PostOrderV2PayloadOrderKind = typeof PostOrderV2PayloadOrderKind[keyof typeof PostOrderV2PayloadOrderKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostOrderV2PayloadOrderKind = {
  opensea: 'opensea',
  'zeroex-v4': 'zeroex-v4',
  seaport: 'seaport',
  x2y2: 'x2y2',
} as const;

export interface PostOrderV2PayloadOrder {
  data: PostOrderV2PayloadOrderData;
  kind: PostOrderV2PayloadOrderKind;
}

export interface PostContractsSetsV1Response {
  contractsSetId?: string;
}

export type PostContractsSetsV1PayloadContracts = string[];

export interface PostContractsSetsV1Payload {
  contracts: PostContractsSetsV1PayloadContracts;
}

export interface PostCollectionsSetsV1Response {
  collectionsSetId?: string;
}

export type PostCollectionsSetsV1PayloadCollections = string[];

export interface PostCollectionsSetsV1Payload {
  collections: PostCollectionsSetsV1PayloadCollections;
}

export interface PostAdminUpdateSourcePayload {
  icon?: string;
  optimized?: boolean;
  /** The source domain to sync. Example: `reservoir.market` */
  source?: string;
  title?: string;
}

export interface PostAdminUpdateImageVersionPayload {
  /** Refresh the given token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123` */
  token: string;
}

export interface PostAdminUpdateApiKeyPayloadPermissions {
  assign_collection_to_community?: boolean;
  entity_data_override?: boolean;
  override_collection_refresh_cool_down?: boolean;
  update_metadata_disabled?: boolean;
  update_spam_status?: boolean;
}

export type PostAdminUpdateApiKeyPayloadOrigins = string[];

export type PostAdminUpdateApiKeyPayloadIps = string[];

export interface PostAdminUpdateApiKeyPayload {
  active?: boolean;
  /** The api key to update */
  apiKey?: string;
  ips?: PostAdminUpdateApiKeyPayloadIps;
  origins?: PostAdminUpdateApiKeyPayloadOrigins;
  permissions?: PostAdminUpdateApiKeyPayloadPermissions;
  revShareBps?: number;
}

/**
 * Should be passed in array [{"contract": "...", "tokenId": "..."}]
 */
export interface PostAdminTriggerRabbitJobPayload {
  params?: string;
  path?: string;
}

export interface PostAdminTriggerJobPayload {
  params?: string;
  path?: string;
}

export type PostAdminSyncEventsPayloadSyncDetailsOption1Events = string[];

export type PostAdminSyncEventsPayloadSyncDetailsOption1Method = typeof PostAdminSyncEventsPayloadSyncDetailsOption1Method[keyof typeof PostAdminSyncEventsPayloadSyncDetailsOption1Method];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostAdminSyncEventsPayloadSyncDetailsOption1Method = {
  events: 'events',
} as const;

export interface PostAdminSyncEventsPayloadSyncDetailsOptions {
  events?: PostAdminSyncEventsPayloadSyncDetailsOption1Events;
  method?: PostAdminSyncEventsPayloadSyncDetailsOption1Method;
}

export interface PostAdminSyncEventsPayload {
  backfill?: boolean;
  blocks?: string;
  blocksPerBatch?: number;
  fromBlock: number;
  onlyMissingBlocks?: boolean;
  skipNonFillWrites?: boolean;
  syncDetails?: PostAdminSyncEventsPayloadSyncDetailsOptions;
  toBlock: number;
}

export interface PostAdminSyncDailyVolumesPayload {
  /** If no days are passed, will automatically resync from beginning of time. */
  days?: number;
}

export interface PostAdminSetCommunityPayload {
  /** Update community for a particular collection, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63` */
  collection: string;
  community: string;
  doRetries?: boolean;
}

export interface PostAdminRoutersPayloadRoutersItem {
  address: string;
  deploymentBlock: number;
  domain: string;
}

export type PostAdminRoutersPayloadRouters = PostAdminRoutersPayloadRoutersItem[];

export interface PostAdminRoutersPayload {
  routers: PostAdminRoutersPayloadRouters;
}

export type PostAdminRevalidateOrderPayloadStatus = typeof PostAdminRevalidateOrderPayloadStatus[keyof typeof PostAdminRevalidateOrderPayloadStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostAdminRevalidateOrderPayloadStatus = {
  active: 'active',
  inactive: 'inactive',
} as const;

export interface PostAdminRevalidateOrderPayload {
  id: string;
  status: PostAdminRevalidateOrderPayloadStatus;
}

export interface PostAdminRetryRabbitQueuePayload {
  /** The queue name to retry */
  queueName: string;
}

export interface PostAdminResyncUserBalancePayload {
  collection: string;
  user: string;
}

export interface PostAdminResyncSourcePayload {
  /** The source domain to sync. Example: `reservoir.market` */
  source?: string;
}

export interface PostAdminResyncSaleRoyaltiesPayloadOption1Data {
  blockRange?: number;
  fromBlock: number;
  toBlock: number;
}

export type PostAdminResyncSaleRoyaltiesPayloadOption1Kind = typeof PostAdminResyncSaleRoyaltiesPayloadOption1Kind[keyof typeof PostAdminResyncSaleRoyaltiesPayloadOption1Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostAdminResyncSaleRoyaltiesPayloadOption1Kind = {
  all: 'all',
} as const;

export interface PostAdminResyncSaleRoyaltiesPayloadOptions {
  data?: PostAdminResyncSaleRoyaltiesPayloadOption1Data;
  kind?: PostAdminResyncSaleRoyaltiesPayloadOption1Kind;
}

export interface PostAdminResyncNftBalancesPayload {
  fromBlock: number;
  toBlock: number;
}

export interface PostAdminResyncFloorEventsPayload {
  collection?: string;
  token?: string;
}

export interface PostAdminResumeRabbitQueuePayload {
  /** The queue name to resume */
  queueName: string;
}

export interface PostAdminRefreshTokenPayload {
  /** Refresh the given token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123` */
  token: string;
}

/**
 * The kind of refresh to perform. `missing-metadata` will only refresh tokens missing metadata, `full-collection` will refresh all tokens in the entire collection.
 */
export type PostAdminRefreshCollectionPayloadRefreshKind = typeof PostAdminRefreshCollectionPayloadRefreshKind[keyof typeof PostAdminRefreshCollectionPayloadRefreshKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostAdminRefreshCollectionPayloadRefreshKind = {
  'full-collection': 'full-collection',
  'missing-metadata': 'missing-metadata',
} as const;

export interface PostAdminRefreshCollectionPayload {
  /** If true, will only refresh the collection cache. */
  cacheOnly?: boolean;
  /** Refresh the given collection. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63` */
  collection: string;
  refreshKind?: PostAdminRefreshCollectionPayloadRefreshKind;
}

export interface PostAdminPurgeRabbitQueuePayload {
  /** The queue name to purge */
  queueName: string;
}

export interface PostAdminPauseRabbitQueuePayload {
  /** The queue name to pause */
  queueName: string;
}

export interface PostAdminPauseCatchupPayload {
  /** The queue name to pause */
  queueName: string;
}

export interface PostAdminIndexCollectionMetadataPayload {
  token: string;
}

export type PostAdminFixTokenCachePayloadKind = typeof PostAdminFixTokenCachePayloadKind[keyof typeof PostAdminFixTokenCachePayloadKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostAdminFixTokenCachePayloadKind = {
  'tokens-floor-sell': 'tokens-floor-sell',
  'tokens-top-buy': 'tokens-top-buy',
} as const;

export interface PostAdminFixTokenCachePayload {
  kind: PostAdminFixTokenCachePayloadKind;
  token: string;
}

export type PostAdminFixOrdersPayloadBy = typeof PostAdminFixOrdersPayloadBy[keyof typeof PostAdminFixOrdersPayloadBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostAdminFixOrdersPayloadBy = {
  id: 'id',
  maker: 'maker',
  token: 'token',
  contract: 'contract',
} as const;

export interface PostAdminFixOrdersPayload {
  by: PostAdminFixOrdersPayloadBy;
  contract: string;
  id: string;
  maker: string;
  token: string;
}

export type PostAdminFixCachePayloadContracts = string[];

export type PostAdminFixCachePayloadKind = typeof PostAdminFixCachePayloadKind[keyof typeof PostAdminFixCachePayloadKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostAdminFixCachePayloadKind = {
  'tokens-floor-sell': 'tokens-floor-sell',
  'tokens-top-buy': 'tokens-top-buy',
} as const;

export interface PostAdminFixCachePayload {
  contracts?: PostAdminFixCachePayloadContracts;
  kind: PostAdminFixCachePayloadKind;
}

export interface PostAdminFixBlocksPayload {
  fromBlock: number;
  toBlock: number;
}

export interface PostAdminCountRabbitQueuePayload {
  /** The queue name to count */
  queueName: string;
}

export interface PostAdminCalcRarityPayload {
  /** The collection for which to calculate the rarity, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63` */
  collection: string;
}

export interface PutCollectionsCollectionCommunityV1Response {
  message?: string;
}

export interface PutCollectionsCollectionCommunityV1Payload {
  community: string;
}

export interface GetOrdersUsersUserTopBidsV4ResponseTopBidsItemToken {
  collection?: GetOrdersUsersUserTopBidsV4ResponseTopBidsItemTokenCollection;
  contract?: string;
  floorAskPrice?: GetOrdersUsersUserTopBidsV4ResponseTopBidsItemTokenFloorAskPrice;
  image?: string;
  lastSalePrice?: GetOrdersUsersUserTopBidsV4ResponseTopBidsItemTokenLastSalePrice;
  name?: string;
  tokenId?: string;
}

export interface GetOrdersUsersUserTopBidsV4ResponseTopBidsItem {
  /** Time when added to indexer */
  createdAt?: string;
  criteria?: GetOrdersUsersUserTopBidsV4ResponseTopBidsItemCriteriaOptions;
  feeBreakdown?: GetOrdersUsersUserTopBidsV4ResponseTopBidsItemFeeBreakdown;
  /** Percentage difference between this bid and the current floor price. */
  floorDifferencePercentage?: number;
  id?: string;
  maker?: string;
  price?: GetOrdersUsersUserTopBidsV4ResponseTopBidsItemPrice;
  source?: GetOrdersUsersUserTopBidsV4ResponseTopBidsItemSource;
  token?: GetOrdersUsersUserTopBidsV4ResponseTopBidsItemToken;
  validFrom?: number;
  validUntil?: number;
}

export type GetOrdersUsersUserTopBidsV4ResponseTopBids = GetOrdersUsersUserTopBidsV4ResponseTopBidsItem[];

export interface GetOrdersUsersUserTopBidsV4Response {
  continuation?: string;
  topBids?: GetOrdersUsersUserTopBidsV4ResponseTopBids;
  /** Amount of currency from all token bids; native currency unless `displayCurrency` passed */
  totalAmount?: number;
  /** Amount of token with bids. */
  totalTokensWithBids?: number;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetOrdersUsersUserTopBidsV4ResponseTopBidsItemTokenCollectionFloorAskPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetOrdersUsersUserTopBidsV4ResponseTopBidsItemTokenCollectionFloorAskPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetOrdersUsersUserTopBidsV4ResponseTopBidsItemTokenCollectionFloorAskPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

/**
 * Native currency to chain unless displayCurrency is passed.
 */
export interface GetOrdersUsersUserTopBidsV4ResponseTopBidsItemTokenCollectionFloorAskPrice {
  amount?: GetOrdersUsersUserTopBidsV4ResponseTopBidsItemTokenCollectionFloorAskPriceAmount;
  currency?: GetOrdersUsersUserTopBidsV4ResponseTopBidsItemTokenCollectionFloorAskPriceCurrency;
  netAmount?: GetOrdersUsersUserTopBidsV4ResponseTopBidsItemTokenCollectionFloorAskPriceNetAmount;
}

export interface GetOrdersUsersUserTopBidsV4ResponseTopBidsItemTokenCollection {
  floorAskPrice?: GetOrdersUsersUserTopBidsV4ResponseTopBidsItemTokenCollectionFloorAskPrice;
  id?: string;
  imageUrl?: string;
  name?: string;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetOrdersUsersUserTopBidsV4ResponseTopBidsItemTokenLastSalePriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetOrdersUsersUserTopBidsV4ResponseTopBidsItemTokenLastSalePriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetOrdersUsersUserTopBidsV4ResponseTopBidsItemTokenLastSalePriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetOrdersUsersUserTopBidsV4ResponseTopBidsItemTokenLastSalePrice {
  amount?: GetOrdersUsersUserTopBidsV4ResponseTopBidsItemTokenLastSalePriceAmount;
  currency?: GetOrdersUsersUserTopBidsV4ResponseTopBidsItemTokenLastSalePriceCurrency;
  netAmount?: GetOrdersUsersUserTopBidsV4ResponseTopBidsItemTokenLastSalePriceNetAmount;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetOrdersUsersUserTopBidsV4ResponseTopBidsItemTokenFloorAskPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetOrdersUsersUserTopBidsV4ResponseTopBidsItemTokenFloorAskPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetOrdersUsersUserTopBidsV4ResponseTopBidsItemTokenFloorAskPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetOrdersUsersUserTopBidsV4ResponseTopBidsItemTokenFloorAskPrice {
  amount?: GetOrdersUsersUserTopBidsV4ResponseTopBidsItemTokenFloorAskPriceAmount;
  currency?: GetOrdersUsersUserTopBidsV4ResponseTopBidsItemTokenFloorAskPriceCurrency;
  netAmount?: GetOrdersUsersUserTopBidsV4ResponseTopBidsItemTokenFloorAskPriceNetAmount;
}

export interface GetOrdersUsersUserTopBidsV4ResponseTopBidsItemCriteriaOption1DataCollection {
  id?: string;
  image?: string;
  isSpam?: boolean;
  name?: string;
}

export interface GetOrdersUsersUserTopBidsV4ResponseTopBidsItemCriteriaOption1DataToken {
  image?: string;
  isSpam?: boolean;
  name?: string;
  tokenId?: string;
}

export interface GetOrdersUsersUserTopBidsV4ResponseTopBidsItemCriteriaOption1Data {
  collection?: GetOrdersUsersUserTopBidsV4ResponseTopBidsItemCriteriaOption1DataCollection;
  token?: GetOrdersUsersUserTopBidsV4ResponseTopBidsItemCriteriaOption1DataToken;
}

export type GetOrdersUsersUserTopBidsV4ResponseTopBidsItemCriteriaOption1Kind = typeof GetOrdersUsersUserTopBidsV4ResponseTopBidsItemCriteriaOption1Kind[keyof typeof GetOrdersUsersUserTopBidsV4ResponseTopBidsItemCriteriaOption1Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersUsersUserTopBidsV4ResponseTopBidsItemCriteriaOption1Kind = {
  token: 'token',
} as const;

export interface GetOrdersUsersUserTopBidsV4ResponseTopBidsItemCriteriaOptions {
  data?: GetOrdersUsersUserTopBidsV4ResponseTopBidsItemCriteriaOption1Data;
  kind?: GetOrdersUsersUserTopBidsV4ResponseTopBidsItemCriteriaOption1Kind;
}

export interface GetOrdersUsersUserTopBidsV4ResponseTopBidsItemFeeBreakdownItem {
  bps?: number;
  /** Can be marketplace or royalty */
  kind?: string;
  recipient?: string;
}

export type GetOrdersUsersUserTopBidsV4ResponseTopBidsItemFeeBreakdown = GetOrdersUsersUserTopBidsV4ResponseTopBidsItemFeeBreakdownItem[];

export interface GetOrdersUsersUserTopBidsV4ResponseTopBidsItemSource {
  domain?: string;
  icon?: string;
  id?: string;
  name?: string;
  url?: string;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetOrdersUsersUserTopBidsV4ResponseTopBidsItemPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetOrdersUsersUserTopBidsV4ResponseTopBidsItemPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetOrdersUsersUserTopBidsV4ResponseTopBidsItemPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

/**
 * Return native currency unless displayCurrency contract was passed.
 */
export interface GetOrdersUsersUserTopBidsV4ResponseTopBidsItemPrice {
  amount?: GetOrdersUsersUserTopBidsV4ResponseTopBidsItemPriceAmount;
  currency?: GetOrdersUsersUserTopBidsV4ResponseTopBidsItemPriceCurrency;
  netAmount?: GetOrdersUsersUserTopBidsV4ResponseTopBidsItemPriceNetAmount;
}

/**
 * Returned only for attributes with less than 10k tokens
 */
export interface GetCollectionsCollectionAttributesAllV4ResponseAttributesItemValuesItemFloorAskPrice {
  amount?: GetCollectionsCollectionAttributesAllV4ResponseAttributesItemValuesItemFloorAskPriceAmount;
  currency?: GetCollectionsCollectionAttributesAllV4ResponseAttributesItemValuesItemFloorAskPriceCurrency;
  netAmount?: GetCollectionsCollectionAttributesAllV4ResponseAttributesItemValuesItemFloorAskPriceNetAmount;
}

export interface GetCollectionsCollectionAttributesAllV4ResponseAttributesItemValuesItem {
  count?: number;
  floorAskPrice?: GetCollectionsCollectionAttributesAllV4ResponseAttributesItemValuesItemFloorAskPrice;
  /** Case sensitive */
  value: string;
}

export type GetCollectionsCollectionAttributesAllV4ResponseAttributesItemValues = GetCollectionsCollectionAttributesAllV4ResponseAttributesItemValuesItem[];

export interface GetCollectionsCollectionAttributesAllV4ResponseAttributesItem {
  /** Number of possible attribute kinds */
  attributeCount?: number;
  /** Case sensitive */
  key: string;
  kind: GetCollectionsCollectionAttributesAllV4ResponseAttributesItemKind;
  maxRange?: number;
  minRange?: number;
  values?: GetCollectionsCollectionAttributesAllV4ResponseAttributesItemValues;
}

export type GetCollectionsCollectionAttributesAllV4ResponseAttributes = GetCollectionsCollectionAttributesAllV4ResponseAttributesItem[];

export interface GetCollectionsCollectionAttributesAllV4Response {
  attributes?: GetCollectionsCollectionAttributesAllV4ResponseAttributes;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetCollectionsCollectionAttributesAllV4ResponseAttributesItemValuesItemFloorAskPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetCollectionsCollectionAttributesAllV4ResponseAttributesItemValuesItemFloorAskPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetCollectionsCollectionAttributesAllV4ResponseAttributesItemValuesItemFloorAskPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export type GetCollectionsCollectionAttributesAllV4ResponseAttributesItemKind = typeof GetCollectionsCollectionAttributesAllV4ResponseAttributesItemKind[keyof typeof GetCollectionsCollectionAttributesAllV4ResponseAttributesItemKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsCollectionAttributesAllV4ResponseAttributesItemKind = {
  string: 'string',
  number: 'number',
  date: 'date',
  range: 'range',
} as const;

export interface GetCollectionsCollectionAttributesStaticV1Response {
  attributes?: GetCollectionsCollectionAttributesStaticV1ResponseAttributes;
}

export type GetCollectionsCollectionAttributesStaticV1ResponseAttributesItemValuesItemTokens = string[];

export interface GetCollectionsCollectionAttributesStaticV1ResponseAttributesItemValuesItem {
  count?: number;
  tokens?: GetCollectionsCollectionAttributesStaticV1ResponseAttributesItemValuesItemTokens;
  value: string;
}

export type GetCollectionsCollectionAttributesStaticV1ResponseAttributesItemValues = GetCollectionsCollectionAttributesStaticV1ResponseAttributesItemValuesItem[];

export type GetCollectionsCollectionAttributesStaticV1ResponseAttributesItemKind = typeof GetCollectionsCollectionAttributesStaticV1ResponseAttributesItemKind[keyof typeof GetCollectionsCollectionAttributesStaticV1ResponseAttributesItemKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsCollectionAttributesStaticV1ResponseAttributesItemKind = {
  string: 'string',
  number: 'number',
  date: 'date',
  range: 'range',
} as const;

export interface GetCollectionsCollectionAttributesStaticV1ResponseAttributesItem {
  key: string;
  kind: GetCollectionsCollectionAttributesStaticV1ResponseAttributesItemKind;
  values?: GetCollectionsCollectionAttributesStaticV1ResponseAttributesItemValues;
}

export type GetCollectionsCollectionAttributesStaticV1ResponseAttributes = GetCollectionsCollectionAttributesStaticV1ResponseAttributesItem[];

export type GetCollectionsCollectionAttributesExploreV5ResponseAttributes = GetCollectionsCollectionAttributesExploreV5ResponseAttributesItem[];

export interface GetCollectionsCollectionAttributesExploreV5Response {
  attributes?: GetCollectionsCollectionAttributesExploreV5ResponseAttributes;
  continuation?: string;
}

export interface GetCollectionsCollectionAttributesExploreV5ResponseAttributesItemTopBid {
  id?: string;
  maker?: string;
  validFrom?: number;
  validUntil?: number;
  value?: number;
}

export interface GetCollectionsCollectionAttributesExploreV5ResponseAttributesItemLastSellsItem {
  timestamp: number;
  tokenId: string;
  value: number;
}

export type GetCollectionsCollectionAttributesExploreV5ResponseAttributesItemLastSells = GetCollectionsCollectionAttributesExploreV5ResponseAttributesItemLastSellsItem[];

export interface GetCollectionsCollectionAttributesExploreV5ResponseAttributesItemLastBuysItem {
  timestamp: number;
  tokenId: string;
  value: number;
}

export type GetCollectionsCollectionAttributesExploreV5ResponseAttributesItemLastBuys = GetCollectionsCollectionAttributesExploreV5ResponseAttributesItemLastBuysItem[];

export type GetCollectionsCollectionAttributesExploreV5ResponseAttributesItemFloorAskPrices = number[];

export type GetCollectionsCollectionAttributesExploreV5ResponseAttributesItemSampleImages = string[];

export interface GetCollectionsCollectionAttributesExploreV5ResponseAttributesItem {
  floorAskPrices?: GetCollectionsCollectionAttributesExploreV5ResponseAttributesItemFloorAskPrices;
  key: string;
  lastBuys?: GetCollectionsCollectionAttributesExploreV5ResponseAttributesItemLastBuys;
  lastSells?: GetCollectionsCollectionAttributesExploreV5ResponseAttributesItemLastSells;
  onSaleCount: number;
  sampleImages?: GetCollectionsCollectionAttributesExploreV5ResponseAttributesItemSampleImages;
  tokenCount: number;
  topBid?: GetCollectionsCollectionAttributesExploreV5ResponseAttributesItemTopBid;
  value: string;
}

export interface GetUsersUserTokensV7ResponseTokensItemOwnership {
  acquiredAt?: string;
  floorAsk?: GetUsersUserTokensV7ResponseTokensItemOwnershipFloorAsk;
  onSaleCount?: string;
  tokenCount?: string;
}

export interface GetUsersUserTokensV7ResponseTokensItem {
  ownership?: GetUsersUserTokensV7ResponseTokensItemOwnership;
  token?: GetUsersUserTokensV7ResponseTokensItemToken;
}

export type GetUsersUserTokensV7ResponseTokens = GetUsersUserTokensV7ResponseTokensItem[];

export interface GetUsersUserTokensV7Response {
  continuation?: string;
  tokens?: GetUsersUserTokensV7ResponseTokens;
}

export interface GetUsersUserTokensV7ResponseTokensItemOwnershipFloorAskRawData { [key: string]: any }

export interface GetUsersUserTokensV7ResponseTokensItemOwnershipFloorAskSource {
  domain?: string;
  icon?: string;
  id?: string;
  name?: string;
  url?: string;
}

/**
 * Can be null if no asks.
 */
export interface GetUsersUserTokensV7ResponseTokensItemOwnershipFloorAsk {
  id?: string;
  isNativeOffChainCancellable?: boolean;
  kind?: string;
  maker?: string;
  price?: GetUsersUserTokensV7ResponseTokensItemOwnershipFloorAskPrice;
  rawData?: GetUsersUserTokensV7ResponseTokensItemOwnershipFloorAskRawData;
  source?: GetUsersUserTokensV7ResponseTokensItemOwnershipFloorAskSource;
  validFrom?: number;
  validUntil?: number;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetUsersUserTokensV7ResponseTokensItemOwnershipFloorAskPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetUsersUserTokensV7ResponseTokensItemOwnershipFloorAskPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetUsersUserTokensV7ResponseTokensItemOwnershipFloorAskPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetUsersUserTokensV7ResponseTokensItemOwnershipFloorAskPrice {
  amount?: GetUsersUserTokensV7ResponseTokensItemOwnershipFloorAskPriceAmount;
  currency?: GetUsersUserTokensV7ResponseTokensItemOwnershipFloorAskPriceCurrency;
  netAmount?: GetUsersUserTokensV7ResponseTokensItemOwnershipFloorAskPriceNetAmount;
}

export interface GetUsersUserTokensV7ResponseTokensItemTokenAttributesItem {
  createdAt?: string;
  /** Can be null. */
  floorAskPrice?: number;
  /** Case sensitive */
  key?: string;
  /** Can be `string`, `number, `date, or `range`. */
  kind?: string;
  onSaleCount?: number;
  tokenCount?: number;
  /** Can be null. */
  topBidValue?: number;
  /** Case sensitive. */
  value: string;
}

export type GetUsersUserTokensV7ResponseTokensItemTokenAttributes = GetUsersUserTokensV7ResponseTokensItemTokenAttributesItem[];

export interface GetUsersUserTokensV7ResponseTokensItemTokenTopBidSource {
  domain?: string;
  icon?: string;
  id?: string;
  name?: string;
  url?: string;
}

/**
 * Can be null if not active bids.
 */
export interface GetUsersUserTokensV7ResponseTokensItemTokenTopBid {
  id?: string;
  price?: GetUsersUserTokensV7ResponseTokensItemTokenTopBidPrice;
  source?: GetUsersUserTokensV7ResponseTokensItemTokenTopBidSource;
}

export interface GetUsersUserTokensV7ResponseTokensItemToken {
  attributes?: GetUsersUserTokensV7ResponseTokensItemTokenAttributes;
  chainId: number;
  collection?: GetUsersUserTokensV7ResponseTokensItemTokenCollection;
  contract?: string;
  description?: string;
  image?: string;
  imageLarge?: string;
  imageMedium?: string;
  imageSmall?: string;
  isFlagged?: boolean;
  isSpam?: boolean;
  /** Can be erc721, erc115, etc. */
  kind?: string;
  /** The value of the last sale.Can be null. */
  lastAppraisalValue?: number;
  lastFlagChange?: string;
  lastFlagUpdate?: string;
  lastSale?: GetUsersUserTokensV7ResponseTokensItemTokenLastSale;
  media?: string;
  metadata?: GetUsersUserTokensV7ResponseTokensItemTokenMetadata;
  metadataDisabled?: boolean;
  name?: string;
  /** No rarity rank for collections over 100k */
  rarityRank?: number;
  /** No rarity for collections over 100k */
  rarityScore?: number;
  remainingSupply?: number;
  /** Can be higher than one if erc1155. */
  supply?: number;
  tokenId?: string;
  topBid?: GetUsersUserTokensV7ResponseTokensItemTokenTopBid;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetUsersUserTokensV7ResponseTokensItemTokenTopBidPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetUsersUserTokensV7ResponseTokensItemTokenTopBidPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetUsersUserTokensV7ResponseTokensItemTokenTopBidPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetUsersUserTokensV7ResponseTokensItemTokenTopBidPrice {
  amount?: GetUsersUserTokensV7ResponseTokensItemTokenTopBidPriceAmount;
  currency?: GetUsersUserTokensV7ResponseTokensItemTokenTopBidPriceCurrency;
  netAmount?: GetUsersUserTokensV7ResponseTokensItemTokenTopBidPriceNetAmount;
}

export interface GetUsersUserTokensV7ResponseTokensItemTokenLastSaleFeeBreakdownItem {
  bps?: number;
  kind?: string;
  rawAmount?: string;
  recipient?: string;
  source?: string;
}

/**
 * `kind` can be `marketplace` or `royalty`
 */
export type GetUsersUserTokensV7ResponseTokensItemTokenLastSaleFeeBreakdown = GetUsersUserTokensV7ResponseTokensItemTokenLastSaleFeeBreakdownItem[];

export interface GetUsersUserTokensV7ResponseTokensItemTokenLastSale {
  amount?: string;
  batchIndex?: number;
  block?: number;
  /** Time when added to indexer */
  createdAt?: string;
  feeBreakdown?: GetUsersUserTokensV7ResponseTokensItemTokenLastSaleFeeBreakdown;
  fillSource?: string;
  from?: string;
  /** Deprecated. Use `saleId` instead. */
  id?: string;
  isDeleted?: boolean;
  logIndex?: number;
  marketplaceFeeBps?: number;
  orderId?: string;
  orderKind?: string;
  orderSide?: GetUsersUserTokensV7ResponseTokensItemTokenLastSaleOrderSide;
  orderSource?: string;
  paidFullRoyalty?: boolean;
  price?: GetUsersUserTokensV7ResponseTokensItemTokenLastSalePrice;
  royaltyFeeBps?: number;
  /** Unique identifier made from txn hash, price, etc. */
  saleId?: string;
  /** Time added on the blockchain */
  timestamp?: number;
  to?: string;
  token?: GetUsersUserTokensV7ResponseTokensItemTokenLastSaleToken;
  txHash?: string;
  /** Time when updated in indexer */
  updatedAt?: string;
  washTradingScore?: number;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetUsersUserTokensV7ResponseTokensItemTokenLastSalePriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetUsersUserTokensV7ResponseTokensItemTokenLastSalePriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetUsersUserTokensV7ResponseTokensItemTokenLastSalePriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetUsersUserTokensV7ResponseTokensItemTokenLastSalePrice {
  amount?: GetUsersUserTokensV7ResponseTokensItemTokenLastSalePriceAmount;
  currency?: GetUsersUserTokensV7ResponseTokensItemTokenLastSalePriceCurrency;
  netAmount?: GetUsersUserTokensV7ResponseTokensItemTokenLastSalePriceNetAmount;
}

/**
 * Can be `ask` or `bid`.
 */
export type GetUsersUserTokensV7ResponseTokensItemTokenLastSaleOrderSide = typeof GetUsersUserTokensV7ResponseTokensItemTokenLastSaleOrderSide[keyof typeof GetUsersUserTokensV7ResponseTokensItemTokenLastSaleOrderSide];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersUserTokensV7ResponseTokensItemTokenLastSaleOrderSide = {
  ask: 'ask',
  bid: 'bid',
} as const;

export interface GetUsersUserTokensV7ResponseTokensItemTokenLastSaleTokenCollection {
  id?: string;
  name?: string;
}

export interface GetUsersUserTokensV7ResponseTokensItemTokenLastSaleToken {
  collection?: GetUsersUserTokensV7ResponseTokensItemTokenLastSaleTokenCollection;
  contract?: string;
  image?: string;
  name?: string;
  tokenId?: string;
}

export interface GetUsersUserTokensV7ResponseTokensItemTokenCollectionRoyaltiesItem {
  bps?: number;
  recipient?: string;
}

export type GetUsersUserTokensV7ResponseTokensItemTokenCollectionRoyalties = GetUsersUserTokensV7ResponseTokensItemTokenCollectionRoyaltiesItem[];

export interface GetUsersUserTokensV7ResponseTokensItemTokenCollection {
  floorAskPrice?: GetUsersUserTokensV7ResponseTokensItemTokenCollectionFloorAskPrice;
  id?: string;
  imageUrl?: string;
  isSpam?: boolean;
  metadataDisabled?: boolean;
  name?: string;
  openseaVerificationStatus?: string;
  royalties?: GetUsersUserTokensV7ResponseTokensItemTokenCollectionRoyalties;
  royaltiesBps?: number;
  /** Open Sea slug */
  slug?: string;
  symbol?: string;
  verificationStatus?: GetUsersUserTokensV7ResponseTokensItemTokenCollectionVerificationStatus;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetUsersUserTokensV7ResponseTokensItemTokenCollectionFloorAskPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetUsersUserTokensV7ResponseTokensItemTokenCollectionFloorAskPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetUsersUserTokensV7ResponseTokensItemTokenCollectionFloorAskPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

/**
 * Can be null if no active asks.
 */
export interface GetUsersUserTokensV7ResponseTokensItemTokenCollectionFloorAskPrice {
  amount?: GetUsersUserTokensV7ResponseTokensItemTokenCollectionFloorAskPriceAmount;
  currency?: GetUsersUserTokensV7ResponseTokensItemTokenCollectionFloorAskPriceCurrency;
  netAmount?: GetUsersUserTokensV7ResponseTokensItemTokenCollectionFloorAskPriceNetAmount;
}

export type GetUsersUserTokensV7ResponseTokensItemTokenCollectionVerificationStatus = typeof GetUsersUserTokensV7ResponseTokensItemTokenCollectionVerificationStatus[keyof typeof GetUsersUserTokensV7ResponseTokensItemTokenCollectionVerificationStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersUserTokensV7ResponseTokensItemTokenCollectionVerificationStatus = {
  verified: 'verified',
  unverified: 'unverified',
} as const;

export interface GetUsersUserTokensV7ResponseTokensItemTokenMetadata { [key: string]: any }

export interface GetUsersUserPositionsV1ResponsePositionsItemPrimaryOrder {
  expiration?: number;
  id?: string;
  value?: number;
}

export interface GetUsersUserPositionsV1ResponsePositionsItem {
  primaryOrder?: GetUsersUserPositionsV1ResponsePositionsItemPrimaryOrder;
  set?: GetUsersUserPositionsV1ResponsePositionsItemSet;
  totalValid?: number;
}

export type GetUsersUserPositionsV1ResponsePositions = GetUsersUserPositionsV1ResponsePositionsItem[];

export interface GetUsersUserPositionsV1Response {
  positions?: GetUsersUserPositionsV1ResponsePositions;
}

export type GetUsersUserPositionsV1ResponsePositionsItemSetSampleImages = string[];

export interface GetUsersUserPositionsV1ResponsePositionsItemSetMetadataOption1Data {
  collectionName?: string;
  image?: string;
  tokenName?: string;
}

export type GetUsersUserPositionsV1ResponsePositionsItemSetMetadataOption1Kind = typeof GetUsersUserPositionsV1ResponsePositionsItemSetMetadataOption1Kind[keyof typeof GetUsersUserPositionsV1ResponsePositionsItemSetMetadataOption1Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersUserPositionsV1ResponsePositionsItemSetMetadataOption1Kind = {
  token: 'token',
} as const;

export interface GetUsersUserPositionsV1ResponsePositionsItemSetMetadataOptions {
  data?: GetUsersUserPositionsV1ResponsePositionsItemSetMetadataOption1Data;
  kind?: GetUsersUserPositionsV1ResponsePositionsItemSetMetadataOption1Kind;
}

export interface GetUsersUserPositionsV1ResponsePositionsItemSet {
  floorAskPrice?: number;
  id?: string;
  image?: string;
  metadata?: GetUsersUserPositionsV1ResponsePositionsItemSetMetadataOptions;
  sampleImages?: GetUsersUserPositionsV1ResponsePositionsItemSetSampleImages;
  topBidValue?: number;
}

export interface GetUsersUserCollectionsV3ResponseCollectionsItemOwnership {
  liquidCount?: string;
  onSaleCount?: string;
  tokenCount?: string;
}

export interface GetUsersUserCollectionsV3ResponseCollectionsItem {
  collection?: GetUsersUserCollectionsV3ResponseCollectionsItemCollection;
  ownership?: GetUsersUserCollectionsV3ResponseCollectionsItemOwnership;
}

export type GetUsersUserCollectionsV3ResponseCollections = GetUsersUserCollectionsV3ResponseCollectionsItem[];

export interface GetUsersUserCollectionsV3Response {
  collections?: GetUsersUserCollectionsV3ResponseCollections;
}

/**
 * The floor sale from X-days ago.
 */
export interface GetUsersUserCollectionsV3ResponseCollectionsItemCollectionFloorSale {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
}

/**
 * Total volume change X-days vs previous X-days. (e.g. 7day [days 1-7] vs 7day prior [days 8-14]). A value over 1 is a positive gain, under 1 is a negative loss. e.g. 1 means no change; 1.1 means 10% increase; 0.9 means 10% decrease.
 */
export interface GetUsersUserCollectionsV3ResponseCollectionsItemCollectionVolumeChange {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
}

/**
 * Total volume in given time period.
 */
export interface GetUsersUserCollectionsV3ResponseCollectionsItemCollectionVolume {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
  allTime?: number;
}

/**
 * Current rank based from overall volume
 */
export interface GetUsersUserCollectionsV3ResponseCollectionsItemCollectionRank {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
  allTime?: number;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetUsersUserCollectionsV3ResponseCollectionsItemCollectionTopBidValueNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetUsersUserCollectionsV3ResponseCollectionsItemCollectionTopBidValueAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetUsersUserCollectionsV3ResponseCollectionsItemCollectionTopBidValueCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

/**
 * Top bid offer currently if offer is valid
 */
export interface GetUsersUserCollectionsV3ResponseCollectionsItemCollectionTopBidValue {
  amount?: GetUsersUserCollectionsV3ResponseCollectionsItemCollectionTopBidValueAmount;
  currency?: GetUsersUserCollectionsV3ResponseCollectionsItemCollectionTopBidValueCurrency;
  netAmount?: GetUsersUserCollectionsV3ResponseCollectionsItemCollectionTopBidValueNetAmount;
}

export interface GetUsersUserCollectionsV3ResponseCollectionsItemCollection {
  banner?: string;
  /** Returns `erc721`, `erc1155`, etc. */
  contractKind?: string;
  description?: string;
  discordUrl?: string;
  externalUrl?: string;
  floorAskPrice?: GetUsersUserCollectionsV3ResponseCollectionsItemCollectionFloorAskPrice;
  floorSale?: GetUsersUserCollectionsV3ResponseCollectionsItemCollectionFloorSale;
  /** Collection Id */
  id?: string;
  image?: string;
  isSpam?: boolean;
  metadataDisabled?: boolean;
  name?: string;
  openseaVerificationStatus?: string;
  primaryContract?: string;
  rank?: GetUsersUserCollectionsV3ResponseCollectionsItemCollectionRank;
  sampleImages?: GetUsersUserCollectionsV3ResponseCollectionsItemCollectionSampleImages;
  slug?: string;
  /** Total token count */
  tokenCount?: string;
  tokenSetId?: string;
  topBidMaker?: string;
  topBidSourceDomain?: string;
  topBidValue?: GetUsersUserCollectionsV3ResponseCollectionsItemCollectionTopBidValue;
  twitterUrl?: string;
  twitterUsername?: string;
  volume?: GetUsersUserCollectionsV3ResponseCollectionsItemCollectionVolume;
  volumeChange?: GetUsersUserCollectionsV3ResponseCollectionsItemCollectionVolumeChange;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetUsersUserCollectionsV3ResponseCollectionsItemCollectionFloorAskPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetUsersUserCollectionsV3ResponseCollectionsItemCollectionFloorAskPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetUsersUserCollectionsV3ResponseCollectionsItemCollectionFloorAskPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

/**
 * Current floor ask price
 */
export interface GetUsersUserCollectionsV3ResponseCollectionsItemCollectionFloorAskPrice {
  amount?: GetUsersUserCollectionsV3ResponseCollectionsItemCollectionFloorAskPriceAmount;
  currency?: GetUsersUserCollectionsV3ResponseCollectionsItemCollectionFloorAskPriceCurrency;
  netAmount?: GetUsersUserCollectionsV3ResponseCollectionsItemCollectionFloorAskPriceNetAmount;
}

export type GetUsersUserCollectionsV3ResponseCollectionsItemCollectionSampleImages = string[];

export interface GetTransactionsTxHashSyncedV1Response {
  synced: boolean;
}

export interface GetTokensTokenActivityV5Response {
  activities?: GetTokensTokenActivityV5ResponseActivities;
  continuation?: string;
}

export interface GetTokensTokenActivityV5ResponseActivitiesItemOrder {
  criteria?: GetTokensTokenActivityV5ResponseActivitiesItemOrderCriteriaOptions;
  id?: string;
  side?: GetTokensTokenActivityV5ResponseActivitiesItemOrderSide;
  source?: GetTokensTokenActivityV5ResponseActivitiesItemOrderSource;
}

export interface GetTokensTokenActivityV5ResponseActivitiesItemOrderCriteriaOption1DataCollection {
  id?: string;
  image?: string;
  isSpam?: boolean;
  name?: string;
}

export interface GetTokensTokenActivityV5ResponseActivitiesItemOrderCriteriaOption1DataToken {
  image?: string;
  isSpam?: boolean;
  name?: string;
  tokenId?: string;
}

export interface GetTokensTokenActivityV5ResponseActivitiesItemOrderCriteriaOption1Data {
  collection?: GetTokensTokenActivityV5ResponseActivitiesItemOrderCriteriaOption1DataCollection;
  token?: GetTokensTokenActivityV5ResponseActivitiesItemOrderCriteriaOption1DataToken;
}

export type GetTokensTokenActivityV5ResponseActivitiesItemOrderCriteriaOption1Kind = typeof GetTokensTokenActivityV5ResponseActivitiesItemOrderCriteriaOption1Kind[keyof typeof GetTokensTokenActivityV5ResponseActivitiesItemOrderCriteriaOption1Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensTokenActivityV5ResponseActivitiesItemOrderCriteriaOption1Kind = {
  token: 'token',
} as const;

export interface GetTokensTokenActivityV5ResponseActivitiesItemOrderCriteriaOptions {
  data?: GetTokensTokenActivityV5ResponseActivitiesItemOrderCriteriaOption1Data;
  kind?: GetTokensTokenActivityV5ResponseActivitiesItemOrderCriteriaOption1Kind;
}

export interface GetTokensTokenActivityV5ResponseActivitiesItemOrderSource { [key: string]: any }

export type GetTokensTokenActivityV5ResponseActivitiesItemOrderSide = typeof GetTokensTokenActivityV5ResponseActivitiesItemOrderSide[keyof typeof GetTokensTokenActivityV5ResponseActivitiesItemOrderSide];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensTokenActivityV5ResponseActivitiesItemOrderSide = {
  ask: 'ask',
  bid: 'bid',
} as const;

export interface GetTokensTokenActivityV5ResponseActivitiesItemFillSource {
  domain?: string;
  icon?: string;
  id?: string;
  name?: string;
  url?: string;
}

export interface GetTokensTokenActivityV5ResponseActivitiesItemCollection {
  collectionId?: string;
  collectionImage?: string;
  collectionName?: string;
  isSpam?: boolean;
}

export interface GetTokensTokenActivityV5ResponseActivitiesItemToken {
  isSpam?: boolean;
  tokenId?: string;
  tokenImage?: string;
  tokenName?: string;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetTokensTokenActivityV5ResponseActivitiesItemPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetTokensTokenActivityV5ResponseActivitiesItemPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetTokensTokenActivityV5ResponseActivitiesItemPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

/**
 * Return native currency unless displayCurrency contract was passed.
 */
export interface GetTokensTokenActivityV5ResponseActivitiesItemPrice {
  amount?: GetTokensTokenActivityV5ResponseActivitiesItemPriceAmount;
  currency?: GetTokensTokenActivityV5ResponseActivitiesItemPriceCurrency;
  netAmount?: GetTokensTokenActivityV5ResponseActivitiesItemPriceNetAmount;
}

export interface GetTokensTokenActivityV5ResponseActivitiesItem {
  amount?: number;
  batchIndex?: number;
  collection?: GetTokensTokenActivityV5ResponseActivitiesItemCollection;
  contract?: string;
  /** Time when added in the indexer. */
  createdAt?: string;
  fillSource?: GetTokensTokenActivityV5ResponseActivitiesItemFillSource;
  fromAddress?: string;
  logIndex?: number;
  order?: GetTokensTokenActivityV5ResponseActivitiesItemOrder;
  price?: GetTokensTokenActivityV5ResponseActivitiesItemPrice;
  /** Time when added on the blockchain. */
  timestamp?: number;
  toAddress?: string;
  token?: GetTokensTokenActivityV5ResponseActivitiesItemToken;
  /** Txn hash from the blockchain. */
  txHash?: string;
  /** Possible types returned: `ask`, `ask_cancel`, `bid`, `bid_cancel`, `sale`, `mint, and `transfer`. */
  type?: string;
}

export type GetTokensTokenActivityV5ResponseActivities = GetTokensTokenActivityV5ResponseActivitiesItem[];

export interface GetTokensFlagChangesV1ResponseTokensItem {
  contract: string;
  isFlagged?: boolean;
  lastFlagChange?: string;
  tokenId: string;
}

export type GetTokensFlagChangesV1ResponseTokens = GetTokensFlagChangesV1ResponseTokensItem[];

export interface GetTokensFlagChangesV1Response {
  continuation?: string;
  tokens?: GetTokensFlagChangesV1ResponseTokens;
}

export interface GetOracleTokensStatusV3ResponseMessagesItemMessage {
  chainId: string;
  id: string;
  payload: string;
  signature: string;
  timestamp: number;
}

export interface GetOracleTokensStatusV3ResponseMessagesItem {
  isFlagged: boolean;
  lastTransferTime: number;
  message?: GetOracleTokensStatusV3ResponseMessagesItemMessage;
  token: string;
}

export type GetOracleTokensStatusV3ResponseMessages = GetOracleTokensStatusV3ResponseMessagesItem[];

export interface GetOracleTokensStatusV3Response {
  messages?: GetOracleTokensStatusV3ResponseMessages;
}

export interface GetOracleCollectionsTopBidV3ResponseMessage {
  chainId: string;
  id: string;
  payload: string;
  signature: string;
  timestamp: number;
}

export interface GetOracleCollectionsTopBidV3Response {
  message?: GetOracleCollectionsTopBidV3ResponseMessage;
  price: number;
}

export interface GetOracleCollectionsFloorAskV6ResponseMessage {
  chainId: string;
  id: string;
  payload: string;
  signature: string;
  timestamp: number;
}

export interface GetOracleCollectionsFloorAskV6Response {
  data?: string;
  message?: GetOracleCollectionsFloorAskV6ResponseMessage;
  price: number;
}

export interface GetOracleCollectionsBidAskMidpointV1ResponseMessage {
  chainId: string;
  id: string;
  payload: string;
  signature: string;
  timestamp: number;
}

export interface GetOracleCollectionsBidAskMidpointV1Response {
  message?: GetOracleCollectionsBidAskMidpointV1ResponseMessage;
  price: number;
}

export interface GetEventsTokensFloorAskV4Response {
  continuation?: string;
  events?: GetEventsTokensFloorAskV4ResponseEvents;
}

export type GetEventsTokensFloorAskV4ResponseEventsItemEventKind = typeof GetEventsTokensFloorAskV4ResponseEventsItemEventKind[keyof typeof GetEventsTokensFloorAskV4ResponseEventsItemEventKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEventsTokensFloorAskV4ResponseEventsItemEventKind = {
  'new-order': 'new-order',
  expiry: 'expiry',
  sale: 'sale',
  cancel: 'cancel',
  'balance-change': 'balance-change',
  'approval-change': 'approval-change',
  bootstrap: 'bootstrap',
  revalidation: 'revalidation',
  reprice: 'reprice',
} as const;

export interface GetEventsTokensFloorAskV4ResponseEventsItemEvent {
  /** Time when added to indexer */
  createdAt?: string;
  id?: number;
  kind?: GetEventsTokensFloorAskV4ResponseEventsItemEventKind;
  previousPrice?: number;
  txHash?: string;
  /** Time when added on the blockchain. */
  txTimestamp?: number;
}

export interface GetEventsTokensFloorAskV4ResponseEventsItem {
  event?: GetEventsTokensFloorAskV4ResponseEventsItemEvent;
  floorAsk?: GetEventsTokensFloorAskV4ResponseEventsItemFloorAsk;
  token?: GetEventsTokensFloorAskV4ResponseEventsItemToken;
}

export type GetEventsTokensFloorAskV4ResponseEvents = GetEventsTokensFloorAskV4ResponseEventsItem[];

export interface GetEventsTokensFloorAskV4ResponseEventsItemFloorAskDynamicPricingData { [key: string]: any }

export type GetEventsTokensFloorAskV4ResponseEventsItemFloorAskDynamicPricingKind = typeof GetEventsTokensFloorAskV4ResponseEventsItemFloorAskDynamicPricingKind[keyof typeof GetEventsTokensFloorAskV4ResponseEventsItemFloorAskDynamicPricingKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEventsTokensFloorAskV4ResponseEventsItemFloorAskDynamicPricingKind = {
  dutch: 'dutch',
  pool: 'pool',
} as const;

export interface GetEventsTokensFloorAskV4ResponseEventsItemFloorAskDynamicPricing {
  data?: GetEventsTokensFloorAskV4ResponseEventsItemFloorAskDynamicPricingData;
  kind?: GetEventsTokensFloorAskV4ResponseEventsItemFloorAskDynamicPricingKind;
}

export interface GetEventsTokensFloorAskV4ResponseEventsItemFloorAskSource {
  domain?: string;
  icon?: string;
  id?: string;
  name?: string;
  url?: string;
}

export interface GetEventsTokensFloorAskV4ResponseEventsItemFloorAsk {
  dynamicPricing?: GetEventsTokensFloorAskV4ResponseEventsItemFloorAskDynamicPricing;
  isDynamic?: boolean;
  maker?: string;
  nonce?: string;
  orderId?: string;
  price?: GetEventsTokensFloorAskV4ResponseEventsItemFloorAskPrice;
  source?: GetEventsTokensFloorAskV4ResponseEventsItemFloorAskSource;
  validFrom?: number;
  validUntil?: number;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetEventsTokensFloorAskV4ResponseEventsItemFloorAskPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetEventsTokensFloorAskV4ResponseEventsItemFloorAskPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetEventsTokensFloorAskV4ResponseEventsItemFloorAskPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetEventsTokensFloorAskV4ResponseEventsItemFloorAskPrice {
  amount?: GetEventsTokensFloorAskV4ResponseEventsItemFloorAskPriceAmount;
  currency?: GetEventsTokensFloorAskV4ResponseEventsItemFloorAskPriceCurrency;
  netAmount?: GetEventsTokensFloorAskV4ResponseEventsItemFloorAskPriceNetAmount;
}

export interface GetEventsTokensFloorAskV4ResponseEventsItemToken {
  contract?: string;
  tokenId?: string;
}

export interface GetEventsCollectionsTopBidV2Response {
  continuation?: string;
  events?: GetEventsCollectionsTopBidV2ResponseEvents;
}

export type GetEventsCollectionsTopBidV2ResponseEventsItemEventKind = typeof GetEventsCollectionsTopBidV2ResponseEventsItemEventKind[keyof typeof GetEventsCollectionsTopBidV2ResponseEventsItemEventKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEventsCollectionsTopBidV2ResponseEventsItemEventKind = {
  'new-order': 'new-order',
  expiry: 'expiry',
  sale: 'sale',
  cancel: 'cancel',
  'balance-change': 'balance-change',
  'approval-change': 'approval-change',
  bootstrap: 'bootstrap',
  revalidation: 'revalidation',
  reprice: 'reprice',
} as const;

export interface GetEventsCollectionsTopBidV2ResponseEventsItemEvent {
  /** Time when added to indexer */
  createdAt?: string;
  id?: number;
  kind?: GetEventsCollectionsTopBidV2ResponseEventsItemEventKind;
  previousPrice?: number;
  txHash?: string;
  /** Time when added on the blockchain. */
  txTimestamp?: number;
}

export interface GetEventsCollectionsTopBidV2ResponseEventsItemTopBid {
  contract?: string;
  maker?: string;
  orderId?: string;
  price?: GetEventsCollectionsTopBidV2ResponseEventsItemTopBidPrice;
  source?: string;
  tokenSetId?: string;
  validUntil?: number;
}

export interface GetEventsCollectionsTopBidV2ResponseEventsItem {
  collection?: GetEventsCollectionsTopBidV2ResponseEventsItemCollection;
  event?: GetEventsCollectionsTopBidV2ResponseEventsItemEvent;
  topBid?: GetEventsCollectionsTopBidV2ResponseEventsItemTopBid;
}

export type GetEventsCollectionsTopBidV2ResponseEvents = GetEventsCollectionsTopBidV2ResponseEventsItem[];

/**
 * Amount with fees & royalties removed.
 */
export interface GetEventsCollectionsTopBidV2ResponseEventsItemTopBidPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetEventsCollectionsTopBidV2ResponseEventsItemTopBidPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetEventsCollectionsTopBidV2ResponseEventsItemTopBidPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetEventsCollectionsTopBidV2ResponseEventsItemTopBidPrice {
  amount?: GetEventsCollectionsTopBidV2ResponseEventsItemTopBidPriceAmount;
  currency?: GetEventsCollectionsTopBidV2ResponseEventsItemTopBidPriceCurrency;
  netAmount?: GetEventsCollectionsTopBidV2ResponseEventsItemTopBidPriceNetAmount;
}

export interface GetEventsCollectionsTopBidV2ResponseEventsItemCollection {
  id?: string;
}

export interface GetEventsCollectionsFloorAskV2Response {
  continuation?: string;
  events?: GetEventsCollectionsFloorAskV2ResponseEvents;
}

export type GetEventsCollectionsFloorAskV2ResponseEventsItemEventKind = typeof GetEventsCollectionsFloorAskV2ResponseEventsItemEventKind[keyof typeof GetEventsCollectionsFloorAskV2ResponseEventsItemEventKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEventsCollectionsFloorAskV2ResponseEventsItemEventKind = {
  'new-order': 'new-order',
  expiry: 'expiry',
  sale: 'sale',
  cancel: 'cancel',
  'balance-change': 'balance-change',
  'approval-change': 'approval-change',
  bootstrap: 'bootstrap',
  revalidation: 'revalidation',
  reprice: 'reprice',
} as const;

export interface GetEventsCollectionsFloorAskV2ResponseEventsItemEvent {
  /** Time when added to indexer */
  createdAt?: string;
  id?: number;
  kind?: GetEventsCollectionsFloorAskV2ResponseEventsItemEventKind;
  previousPrice?: number;
  txHash?: string;
  /** Time when added on the blockchain. */
  txTimestamp?: number;
}

export interface GetEventsCollectionsFloorAskV2ResponseEventsItemFloorAsk {
  contract?: string;
  maker?: string;
  orderId?: string;
  price?: GetEventsCollectionsFloorAskV2ResponseEventsItemFloorAskPrice;
  source?: string;
  tokenId?: string;
  validUntil?: number;
}

export interface GetEventsCollectionsFloorAskV2ResponseEventsItem {
  collection?: GetEventsCollectionsFloorAskV2ResponseEventsItemCollection;
  event?: GetEventsCollectionsFloorAskV2ResponseEventsItemEvent;
  floorAsk?: GetEventsCollectionsFloorAskV2ResponseEventsItemFloorAsk;
}

export type GetEventsCollectionsFloorAskV2ResponseEvents = GetEventsCollectionsFloorAskV2ResponseEventsItem[];

/**
 * Amount with fees & royalties removed.
 */
export interface GetEventsCollectionsFloorAskV2ResponseEventsItemFloorAskPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetEventsCollectionsFloorAskV2ResponseEventsItemFloorAskPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetEventsCollectionsFloorAskV2ResponseEventsItemFloorAskPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetEventsCollectionsFloorAskV2ResponseEventsItemFloorAskPrice {
  amount?: GetEventsCollectionsFloorAskV2ResponseEventsItemFloorAskPriceAmount;
  currency?: GetEventsCollectionsFloorAskV2ResponseEventsItemFloorAskPriceCurrency;
  netAmount?: GetEventsCollectionsFloorAskV2ResponseEventsItemFloorAskPriceNetAmount;
}

export interface GetEventsCollectionsFloorAskV2ResponseEventsItemCollection {
  id?: string;
}

export interface GetCollectionsSetsCollectionsSetIdOwnersDistributionV1ResponseOwnersDistributionItem {
  /** The amount of owners with the same `tokenCount`. */
  ownerCount?: number;
  tokenCount?: number;
}

export type GetCollectionsSetsCollectionsSetIdOwnersDistributionV1ResponseOwnersDistribution = GetCollectionsSetsCollectionsSetIdOwnersDistributionV1ResponseOwnersDistributionItem[];

export interface GetCollectionsSetsCollectionsSetIdOwnersDistributionV1Response {
  ownersDistribution?: GetCollectionsSetsCollectionsSetIdOwnersDistributionV1ResponseOwnersDistribution;
}

export interface GetCollectionsCollectionMarketplaceConfigurationsV1Response {
  marketplaces?: GetCollectionsCollectionMarketplaceConfigurationsV1ResponseMarketplaces;
}

export type GetCollectionsCollectionMarketplaceConfigurationsV1ResponseMarketplacesItemExchanges = {
  string?: String;
};

export interface GetCollectionsCollectionMarketplaceConfigurationsV1ResponseMarketplacesItem {
  domain?: string;
  exchanges?: GetCollectionsCollectionMarketplaceConfigurationsV1ResponseMarketplacesItemExchanges;
  fee?: GetCollectionsCollectionMarketplaceConfigurationsV1ResponseMarketplacesItemFee;
  imageUrl?: string;
  name?: string;
  orderbook?: string;
  royalties?: GetCollectionsCollectionMarketplaceConfigurationsV1ResponseMarketplacesItemRoyalties;
}

export type GetCollectionsCollectionMarketplaceConfigurationsV1ResponseMarketplaces = GetCollectionsCollectionMarketplaceConfigurationsV1ResponseMarketplacesItem[];

export interface Model1 {
  address?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export type PaymentTokens = Model1[];

/**
 * erc20 contract addresses
 */
export type SupportedBidCurrencies = string[];

export interface String {
  collectionBidSupported?: boolean;
  customFeesSupported?: boolean;
  enabled?: boolean;
  maxPriceRaw?: string;
  minimumBidExpiry?: number;
  minimumPrecision?: string;
  minPriceRaw?: string;
  orderKind?: string;
  /** This indicates whether or not multi quantity bidding is supported */
  partialOrderSupported?: boolean;
  paymentTokens?: PaymentTokens;
  supportedBidCurrencies?: SupportedBidCurrencies;
  traitBidSupported?: boolean;
}

export interface GetCollectionsCollectionMarketplaceConfigurationsV1ResponseMarketplacesItemRoyalties {
  maxBps?: number;
  minBps?: number;
}

/**
 * Marketplace Fee
 */
export interface GetCollectionsCollectionMarketplaceConfigurationsV1ResponseMarketplacesItemFee {
  bps?: number;
}

export interface GetCollectionsCollectionOwnersDistributionV1ResponseOwnersDistributionItem {
  /** The amount of owners that have the same `tokenCount`. */
  ownerCount?: number;
  tokenCount?: number;
}

export type GetCollectionsCollectionOwnersDistributionV1ResponseOwnersDistribution = GetCollectionsCollectionOwnersDistributionV1ResponseOwnersDistributionItem[];

export interface GetCollectionsCollectionOwnersDistributionV1Response {
  ownersDistribution?: GetCollectionsCollectionOwnersDistributionV1ResponseOwnersDistribution;
}

export interface GetCollectionsCollectionTopBidsV1ResponseTopBidsItem {
  quantity?: number;
  value?: number;
}

export type GetCollectionsCollectionTopBidsV1ResponseTopBids = GetCollectionsCollectionTopBidsV1ResponseTopBidsItem[];

export interface GetCollectionsCollectionTopBidsV1Response {
  topBids?: GetCollectionsCollectionTopBidsV1ResponseTopBids;
}

export type GetCollectionsCollectionAttributesV1ResponseAttributes = GetCollectionsCollectionAttributesV1ResponseAttributesItem[];

export interface GetCollectionsCollectionAttributesV1Response {
  attributes?: GetCollectionsCollectionAttributesV1ResponseAttributes;
}

export interface GetCollectionsCollectionAttributesV1ResponseAttributesItemTopBid {
  id?: string;
  maker?: string;
  validFrom?: number;
  validUntil?: number;
  value?: number;
}

export type GetCollectionsCollectionAttributesV1ResponseAttributesItemFloorAskPrices = number[];

export interface GetCollectionsCollectionAttributesV1ResponseAttributesItemLastSellsItem {
  timestamp: number;
  value: number;
}

export type GetCollectionsCollectionAttributesV1ResponseAttributesItemLastSells = GetCollectionsCollectionAttributesV1ResponseAttributesItemLastSellsItem[];

export interface GetCollectionsCollectionAttributesV1ResponseAttributesItemLastBuysItem {
  timestamp: number;
  value: number;
}

export type GetCollectionsCollectionAttributesV1ResponseAttributesItemLastBuys = GetCollectionsCollectionAttributesV1ResponseAttributesItemLastBuysItem[];

export type GetCollectionsCollectionAttributesV1ResponseAttributesItemSampleImages = string[];

export interface GetCollectionsCollectionAttributesV1ResponseAttributesItem {
  floorAskPrices?: GetCollectionsCollectionAttributesV1ResponseAttributesItemFloorAskPrices;
  key: string;
  lastBuys?: GetCollectionsCollectionAttributesV1ResponseAttributesItemLastBuys;
  lastSells?: GetCollectionsCollectionAttributesV1ResponseAttributesItemLastSells;
  sampleImages?: GetCollectionsCollectionAttributesV1ResponseAttributesItemSampleImages;
  tokenCount: number;
  topBid?: GetCollectionsCollectionAttributesV1ResponseAttributesItemTopBid;
  value: string;
}

export type GetCollectionsCollectionSupportedMarketplacesV1ResponseMarketplaces = GetCollectionsCollectionSupportedMarketplacesV1ResponseMarketplacesItem[];

export interface GetCollectionsCollectionSupportedMarketplacesV1Response {
  marketplaces?: GetCollectionsCollectionSupportedMarketplacesV1ResponseMarketplaces;
}

export interface GetCollectionsCollectionSupportedMarketplacesV1ResponseMarketplacesItemPaymentTokensItem {
  address?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export type GetCollectionsCollectionSupportedMarketplacesV1ResponseMarketplacesItemPaymentTokens = GetCollectionsCollectionSupportedMarketplacesV1ResponseMarketplacesItemPaymentTokensItem[];

/**
 * erc20 contract addresses
 */
export type GetCollectionsCollectionSupportedMarketplacesV1ResponseMarketplacesItemSupportedBidCurrencies = string[];

export interface GetCollectionsCollectionSupportedMarketplacesV1ResponseMarketplacesItemRoyalties {
  maxBps?: number;
  minBps?: number;
}

/**
 * Marketplace Fee
 */
export interface GetCollectionsCollectionSupportedMarketplacesV1ResponseMarketplacesItemFee {
  bps?: number;
}

export interface GetCollectionsCollectionSupportedMarketplacesV1ResponseMarketplacesItem {
  collectionBidSupported?: boolean;
  customFeesSupported?: boolean;
  domain?: string;
  fee?: GetCollectionsCollectionSupportedMarketplacesV1ResponseMarketplacesItemFee;
  imageUrl?: string;
  listingEnabled?: boolean;
  minimumBidExpiry?: number;
  minimumPrecision?: string;
  name?: string;
  orderbook?: string;
  orderKind?: string;
  /** This indicates whether or not multi quantity bidding is supported */
  partialBidSupported?: boolean;
  paymentTokens?: GetCollectionsCollectionSupportedMarketplacesV1ResponseMarketplacesItemPaymentTokens;
  royalties?: GetCollectionsCollectionSupportedMarketplacesV1ResponseMarketplacesItemRoyalties;
  supportedBidCurrencies?: GetCollectionsCollectionSupportedMarketplacesV1ResponseMarketplacesItemSupportedBidCurrencies;
  traitBidSupported?: boolean;
}

export interface GetUsersActivityV6ResponseActivitiesItemOrderCriteriaOption1DataCollection {
  id?: string;
  image?: string;
  isSpam?: boolean;
  name?: string;
}

export interface GetUsersActivityV6ResponseActivitiesItemOrderCriteriaOption1DataToken {
  image?: string;
  isSpam?: boolean;
  name?: string;
  tokenId?: string;
}

export interface GetUsersActivityV6ResponseActivitiesItemOrderCriteriaOption1Data {
  collection?: GetUsersActivityV6ResponseActivitiesItemOrderCriteriaOption1DataCollection;
  token?: GetUsersActivityV6ResponseActivitiesItemOrderCriteriaOption1DataToken;
}

export type GetUsersActivityV6ResponseActivitiesItemOrderCriteriaOption1Kind = typeof GetUsersActivityV6ResponseActivitiesItemOrderCriteriaOption1Kind[keyof typeof GetUsersActivityV6ResponseActivitiesItemOrderCriteriaOption1Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersActivityV6ResponseActivitiesItemOrderCriteriaOption1Kind = {
  token: 'token',
} as const;

export interface GetUsersActivityV6ResponseActivitiesItemOrderCriteriaOptions {
  data?: GetUsersActivityV6ResponseActivitiesItemOrderCriteriaOption1Data;
  kind?: GetUsersActivityV6ResponseActivitiesItemOrderCriteriaOption1Kind;
}

export interface GetUsersActivityV6ResponseActivitiesItemOrderSource { [key: string]: any }

export type GetUsersActivityV6ResponseActivitiesItemOrderSide = typeof GetUsersActivityV6ResponseActivitiesItemOrderSide[keyof typeof GetUsersActivityV6ResponseActivitiesItemOrderSide];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersActivityV6ResponseActivitiesItemOrderSide = {
  ask: 'ask',
  bid: 'bid',
} as const;

export interface GetUsersActivityV6ResponseActivitiesItemOrder {
  criteria?: GetUsersActivityV6ResponseActivitiesItemOrderCriteriaOptions;
  id?: string;
  side?: GetUsersActivityV6ResponseActivitiesItemOrderSide;
  source?: GetUsersActivityV6ResponseActivitiesItemOrderSource;
}

export interface GetUsersActivityV6ResponseActivitiesItemFillSource {
  domain?: string;
  icon?: string;
  id?: string;
  name?: string;
  url?: string;
}

export interface GetUsersActivityV6ResponseActivitiesItemCollection {
  collectionId?: string;
  collectionImage?: string;
  collectionName?: string;
  isSpam?: boolean;
}

export interface GetUsersActivityV6ResponseActivitiesItem {
  amount?: number;
  batchIndex?: number;
  collection?: GetUsersActivityV6ResponseActivitiesItemCollection;
  contract?: string;
  createdAt?: string;
  fillSource?: GetUsersActivityV6ResponseActivitiesItemFillSource;
  fromAddress?: string;
  logIndex?: number;
  order?: GetUsersActivityV6ResponseActivitiesItemOrder;
  price?: GetUsersActivityV6ResponseActivitiesItemPrice;
  /** Time when added on the blockchain. */
  timestamp?: number;
  toAddress?: string;
  token?: GetUsersActivityV6ResponseActivitiesItemToken;
  /** Txn hash from the blockchain. */
  txHash?: string;
  /** Possible types returned: `ask`, `ask_cancel`, `bid`, `bid_cancel`, `sale`, `mint, and `transfer`. */
  type?: string;
}

export type GetUsersActivityV6ResponseActivities = GetUsersActivityV6ResponseActivitiesItem[];

export interface GetUsersActivityV6Response {
  activities?: GetUsersActivityV6ResponseActivities;
  continuation?: string;
}

export interface GetUsersActivityV6ResponseActivitiesItemTokenLastSell {
  timestamp?: number;
  value?: number;
}

export interface GetUsersActivityV6ResponseActivitiesItemTokenLastBuy {
  timestamp?: number;
  value?: number;
}

export interface GetUsersActivityV6ResponseActivitiesItemToken {
  isSpam?: boolean;
  lastBuy?: GetUsersActivityV6ResponseActivitiesItemTokenLastBuy;
  lastSell?: GetUsersActivityV6ResponseActivitiesItemTokenLastSell;
  tokenId?: string;
  tokenImage?: string;
  tokenMedia?: string;
  tokenName?: string;
  /** No rarity rank for collections over 100k */
  tokenRarityRank?: number;
  /** No rarity for collections over 100k */
  tokenRarityScore?: number;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetUsersActivityV6ResponseActivitiesItemPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetUsersActivityV6ResponseActivitiesItemPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetUsersActivityV6ResponseActivitiesItemPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

/**
 * Return native currency unless displayCurrency contract was passed.
 */
export interface GetUsersActivityV6ResponseActivitiesItemPrice {
  amount?: GetUsersActivityV6ResponseActivitiesItemPriceAmount;
  currency?: GetUsersActivityV6ResponseActivitiesItemPriceCurrency;
  netAmount?: GetUsersActivityV6ResponseActivitiesItemPriceNetAmount;
}

export interface GetTransfersBulkV2Response {
  continuation?: string;
  transfers?: GetTransfersBulkV2ResponseTransfers;
}

export interface GetTransfersBulkV2ResponseTransfersItemToken {
  contract?: string;
  tokenId?: string;
}

export interface GetTransfersBulkV2ResponseTransfersItem {
  /** Can be more than 1 if erc1155. */
  amount?: string;
  batchIndex?: number;
  block?: number;
  from?: string;
  id?: string;
  isDeleted?: boolean;
  logIndex?: number;
  timestamp?: number;
  to?: string;
  token?: GetTransfersBulkV2ResponseTransfersItemToken;
  txHash?: string;
  /** Time when updated in indexer */
  updatedAt?: string;
}

export type GetTransfersBulkV2ResponseTransfers = GetTransfersBulkV2ResponseTransfersItem[];

export type GetTokensIdsV1ResponseTokens = string[];

export interface GetTokensIdsV1Response {
  continuation?: string;
  tokens?: GetTokensIdsV1ResponseTokens;
}

export type GetTokensFloorV1ResponseTokens = {
  string?: number;
};

export interface GetTokensFloorV1Response {
  tokens?: GetTokensFloorV1ResponseTokens;
}

export interface GetTokensDetailsV4Response {
  continuation?: string;
  tokens?: GetTokensDetailsV4ResponseTokens;
}

export interface GetTokensDetailsV4ResponseTokensItemMarketTopBid {
  id?: string;
  maker?: string;
  validFrom?: number;
  validUntil?: number;
  value?: number;
}

export interface GetTokensDetailsV4ResponseTokensItemMarket {
  floorAsk?: GetTokensDetailsV4ResponseTokensItemMarketFloorAsk;
  topBid?: GetTokensDetailsV4ResponseTokensItemMarketTopBid;
}

export interface GetTokensDetailsV4ResponseTokensItem {
  market?: GetTokensDetailsV4ResponseTokensItemMarket;
  token?: GetTokensDetailsV4ResponseTokensItemToken;
}

export type GetTokensDetailsV4ResponseTokens = GetTokensDetailsV4ResponseTokensItem[];

export interface GetTokensDetailsV4ResponseTokensItemMarketFloorAskSource {
  domain?: string;
  icon?: string;
  id?: string;
  name?: string;
  url?: string;
}

export interface GetTokensDetailsV4ResponseTokensItemMarketFloorAsk {
  id?: string;
  maker?: string;
  price?: number;
  source?: GetTokensDetailsV4ResponseTokensItemMarketFloorAskSource;
  validFrom?: number;
  validUntil?: number;
}

export interface GetTokensDetailsV4ResponseTokensItemTokenAttributesItem {
  floorAskPrice?: number;
  key?: string;
  onSaleCount?: number;
  tokenCount?: number;
  topBidValue?: number;
  value: string;
}

export type GetTokensDetailsV4ResponseTokensItemTokenAttributes = GetTokensDetailsV4ResponseTokensItemTokenAttributesItem[];

export interface GetTokensDetailsV4ResponseTokensItemTokenLastSell {
  timestamp?: number;
  value?: number;
}

export interface GetTokensDetailsV4ResponseTokensItemTokenLastBuy {
  timestamp?: number;
  value?: number;
}

export interface GetTokensDetailsV4ResponseTokensItemTokenCollection {
  id?: string;
  image?: string;
  name?: string;
  slug?: string;
}

export interface GetTokensDetailsV4ResponseTokensItemToken {
  attributes?: GetTokensDetailsV4ResponseTokensItemTokenAttributes;
  collection?: GetTokensDetailsV4ResponseTokensItemTokenCollection;
  contract: string;
  description?: string;
  image?: string;
  isFlagged?: boolean;
  kind?: string;
  lastBuy?: GetTokensDetailsV4ResponseTokensItemTokenLastBuy;
  lastFlagUpdate?: string;
  lastSell?: GetTokensDetailsV4ResponseTokensItemTokenLastSell;
  media?: string;
  name?: string;
  owner?: string;
  tokenId: string;
}

export interface GetTokensBootstrapV1ResponseTokensItem {
  contract?: string;
  image?: string;
  maker?: string;
  orderId?: string;
  /** Native currency of chain */
  price?: number;
  source?: string;
  tokenId?: string;
  validFrom?: number;
  validUntil?: number;
}

export type GetTokensBootstrapV1ResponseTokens = GetTokensBootstrapV1ResponseTokensItem[];

export interface GetTokensBootstrapV1Response {
  continuation?: string;
  tokens?: GetTokensBootstrapV1ResponseTokens;
}

export interface GetSyncAsksV1ResponseOrdersItem {
  contract?: string;
  createdAt: string;
  expiration: number;
  feeBps?: number;
  feeBreakdown?: GetSyncAsksV1ResponseOrdersItemFeeBreakdown;
  id: string;
  isDynamic?: boolean;
  isReservoir?: boolean;
  kind: string;
  maker: string;
  missingRoyalties?: GetSyncAsksV1ResponseOrdersItemMissingRoyalties;
  normalizedPrice?: GetSyncAsksV1ResponseOrdersItemNormalizedPrice;
  price?: GetSyncAsksV1ResponseOrdersItemPrice;
  quantityFilled?: number;
  quantityRemaining?: number;
  rawData?: GetSyncAsksV1ResponseOrdersItemRawData;
  side: GetSyncAsksV1ResponseOrdersItemSide;
  source?: GetSyncAsksV1ResponseOrdersItemSource;
  status?: string;
  taker: string;
  tokenSetId: string;
  tokenSetSchemaHash: string;
  updatedAt: string;
  validFrom: number;
  validUntil: number;
}

export type GetSyncAsksV1ResponseOrders = GetSyncAsksV1ResponseOrdersItem[];

export interface GetSyncAsksV1Response {
  continuation?: string;
  orders?: GetSyncAsksV1ResponseOrders;
}

export interface GetSyncAsksV1ResponseOrdersItemRawData { [key: string]: any }

export interface GetSyncAsksV1ResponseOrdersItemMissingRoyaltiesItem {
  amount?: string;
  bps?: number;
  recipient?: string;
}

export type GetSyncAsksV1ResponseOrdersItemMissingRoyalties = GetSyncAsksV1ResponseOrdersItemMissingRoyaltiesItem[];

export interface GetSyncAsksV1ResponseOrdersItemFeeBreakdownItem {
  bps?: number;
  kind?: string;
  recipient?: string;
}

export type GetSyncAsksV1ResponseOrdersItemFeeBreakdown = GetSyncAsksV1ResponseOrdersItemFeeBreakdownItem[];

export interface GetSyncAsksV1ResponseOrdersItemSource {
  domain?: string;
  icon?: string;
  id?: string;
  name?: string;
  url?: string;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetSyncAsksV1ResponseOrdersItemNormalizedPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetSyncAsksV1ResponseOrdersItemNormalizedPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetSyncAsksV1ResponseOrdersItemNormalizedPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetSyncAsksV1ResponseOrdersItemNormalizedPrice {
  amount?: GetSyncAsksV1ResponseOrdersItemNormalizedPriceAmount;
  currency?: GetSyncAsksV1ResponseOrdersItemNormalizedPriceCurrency;
  netAmount?: GetSyncAsksV1ResponseOrdersItemNormalizedPriceNetAmount;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetSyncAsksV1ResponseOrdersItemPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetSyncAsksV1ResponseOrdersItemPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetSyncAsksV1ResponseOrdersItemPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetSyncAsksV1ResponseOrdersItemPrice {
  amount?: GetSyncAsksV1ResponseOrdersItemPriceAmount;
  currency?: GetSyncAsksV1ResponseOrdersItemPriceCurrency;
  netAmount?: GetSyncAsksV1ResponseOrdersItemPriceNetAmount;
}

export type GetSyncAsksV1ResponseOrdersItemSide = typeof GetSyncAsksV1ResponseOrdersItemSide[keyof typeof GetSyncAsksV1ResponseOrdersItemSide];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSyncAsksV1ResponseOrdersItemSide = {
  buy: 'buy',
  sell: 'sell',
} as const;

export interface GetSearchCollectionsV2Response {
  collections?: GetSearchCollectionsV2ResponseCollections;
}

export type GetSearchCollectionsV2ResponseCollectionsItemVerificationStatus = typeof GetSearchCollectionsV2ResponseCollectionsItemVerificationStatus[keyof typeof GetSearchCollectionsV2ResponseCollectionsItemVerificationStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSearchCollectionsV2ResponseCollectionsItemVerificationStatus = {
  verified: 'verified',
  unverified: 'unverified',
} as const;

export interface GetSearchCollectionsV2ResponseCollectionsItem {
  allTimeVolume?: number;
  banner?: string;
  collectionId?: string;
  contract?: string;
  description?: string;
  floorAskPrice?: GetSearchCollectionsV2ResponseCollectionsItemFloorAskPrice;
  image?: string;
  isSpam?: boolean;
  metadataDisabled?: boolean;
  name?: string;
  openseaVerificationStatus?: string;
  slug?: string;
  verificationStatus?: GetSearchCollectionsV2ResponseCollectionsItemVerificationStatus;
}

export type GetSearchCollectionsV2ResponseCollections = GetSearchCollectionsV2ResponseCollectionsItem[];

/**
 * Amount with fees & royalties removed.
 */
export interface GetSearchCollectionsV2ResponseCollectionsItemFloorAskPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetSearchCollectionsV2ResponseCollectionsItemFloorAskPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetSearchCollectionsV2ResponseCollectionsItemFloorAskPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

/**
 * Current floor ask price.
 */
export interface GetSearchCollectionsV2ResponseCollectionsItemFloorAskPrice {
  amount?: GetSearchCollectionsV2ResponseCollectionsItemFloorAskPriceAmount;
  currency?: GetSearchCollectionsV2ResponseCollectionsItemFloorAskPriceCurrency;
  netAmount?: GetSearchCollectionsV2ResponseCollectionsItemFloorAskPriceNetAmount;
}

export type GetSearchActivitiesV1ResponseActivities = GetSearchActivitiesV1ResponseActivitiesItem[];

export interface GetSearchActivitiesV1Response {
  activities?: GetSearchActivitiesV1ResponseActivities;
  continuation?: string;
}

export interface GetSearchActivitiesV1ResponseActivitiesItemOrderCriteriaOption1DataCollection {
  id?: string;
  image?: string;
  isSpam?: boolean;
  name?: string;
}

export interface GetSearchActivitiesV1ResponseActivitiesItemOrderCriteriaOption1DataToken {
  image?: string;
  isSpam?: boolean;
  name?: string;
  tokenId?: string;
}

export interface GetSearchActivitiesV1ResponseActivitiesItemOrderCriteriaOption1Data {
  collection?: GetSearchActivitiesV1ResponseActivitiesItemOrderCriteriaOption1DataCollection;
  token?: GetSearchActivitiesV1ResponseActivitiesItemOrderCriteriaOption1DataToken;
}

export type GetSearchActivitiesV1ResponseActivitiesItemOrderCriteriaOption1Kind = typeof GetSearchActivitiesV1ResponseActivitiesItemOrderCriteriaOption1Kind[keyof typeof GetSearchActivitiesV1ResponseActivitiesItemOrderCriteriaOption1Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSearchActivitiesV1ResponseActivitiesItemOrderCriteriaOption1Kind = {
  token: 'token',
} as const;

export interface GetSearchActivitiesV1ResponseActivitiesItemOrderCriteriaOptions {
  data?: GetSearchActivitiesV1ResponseActivitiesItemOrderCriteriaOption1Data;
  kind?: GetSearchActivitiesV1ResponseActivitiesItemOrderCriteriaOption1Kind;
}

export interface GetSearchActivitiesV1ResponseActivitiesItemOrderSource { [key: string]: any }

export type GetSearchActivitiesV1ResponseActivitiesItemOrderSide = typeof GetSearchActivitiesV1ResponseActivitiesItemOrderSide[keyof typeof GetSearchActivitiesV1ResponseActivitiesItemOrderSide];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSearchActivitiesV1ResponseActivitiesItemOrderSide = {
  ask: 'ask',
  bid: 'bid',
} as const;

export interface GetSearchActivitiesV1ResponseActivitiesItemOrder {
  criteria?: GetSearchActivitiesV1ResponseActivitiesItemOrderCriteriaOptions;
  id?: string;
  side?: GetSearchActivitiesV1ResponseActivitiesItemOrderSide;
  source?: GetSearchActivitiesV1ResponseActivitiesItemOrderSource;
}

export interface GetSearchActivitiesV1ResponseActivitiesItemCollection {
  id?: string;
  image?: string;
  name?: string;
}

export interface GetSearchActivitiesV1ResponseActivitiesItemToken {
  id?: string;
  image?: string;
  media?: string;
  name?: string;
}

export interface GetSearchActivitiesV1ResponseActivitiesItem {
  amount?: number;
  batchIndex?: number;
  collection?: GetSearchActivitiesV1ResponseActivitiesItemCollection;
  contract?: string;
  createdAt?: string;
  fromAddress?: string;
  logIndex?: number;
  order?: GetSearchActivitiesV1ResponseActivitiesItemOrder;
  price?: GetSearchActivitiesV1ResponseActivitiesItemPrice;
  timestamp?: number;
  toAddress?: string;
  token?: GetSearchActivitiesV1ResponseActivitiesItemToken;
  txHash?: string;
  type?: string;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetSearchActivitiesV1ResponseActivitiesItemPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetSearchActivitiesV1ResponseActivitiesItemPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetSearchActivitiesV1ResponseActivitiesItemPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetSearchActivitiesV1ResponseActivitiesItemPrice {
  amount?: GetSearchActivitiesV1ResponseActivitiesItemPriceAmount;
  currency?: GetSearchActivitiesV1ResponseActivitiesItemPriceCurrency;
  netAmount?: GetSearchActivitiesV1ResponseActivitiesItemPriceNetAmount;
}

export interface GetSalesBulkV1Response {
  continuation?: string;
  sales?: GetSalesBulkV1ResponseSales;
}

export type GetSalesBulkV1ResponseSalesItemOrderSide = typeof GetSalesBulkV1ResponseSalesItemOrderSide[keyof typeof GetSalesBulkV1ResponseSalesItemOrderSide];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSalesBulkV1ResponseSalesItemOrderSide = {
  ask: 'ask',
  bid: 'bid',
} as const;

export interface GetSalesBulkV1ResponseSalesItemToken {
  contract?: string;
  tokenId?: string;
}

export interface GetSalesBulkV1ResponseSalesItem {
  amount?: string;
  batchIndex?: number;
  fillSource?: string;
  from?: string;
  id?: string;
  logIndex?: number;
  orderKind?: string;
  orderSide?: GetSalesBulkV1ResponseSalesItemOrderSide;
  orderSource?: string;
  orderSourceDomain?: string;
  price?: number;
  saleId?: string;
  timestamp?: number;
  to?: string;
  token?: GetSalesBulkV1ResponseSalesItemToken;
  txHash?: string;
}

export type GetSalesBulkV1ResponseSales = GetSalesBulkV1ResponseSalesItem[];

export type GetOwnersCrossCollectionsV1ResponseOwnersItemCollections = string[];

export interface GetOwnersCrossCollectionsV1ResponseOwnersItem {
  /** Wallet Address */
  address?: string;
  collections?: GetOwnersCrossCollectionsV1ResponseOwnersItemCollections;
  /** Token Count */
  count?: number;
}

export type GetOwnersCrossCollectionsV1ResponseOwners = GetOwnersCrossCollectionsV1ResponseOwnersItem[];

export interface GetOwnersCrossCollectionsV1Response {
  owners?: GetOwnersCrossCollectionsV1ResponseOwners;
}

export interface GetOwnersCountV1Response {
  owners?: number;
}

export interface GetOwnersCommonCollectionsV1Response {
  collections?: GetOwnersCommonCollectionsV1ResponseCollections;
}

export type GetOwnersCommonCollectionsV1ResponseCollectionsItemOwners = string[];

export interface GetOwnersCommonCollectionsV1ResponseCollectionsItem {
  /** Contract address */
  address?: string;
  /** Token count */
  count?: number;
  owners?: GetOwnersCommonCollectionsV1ResponseCollectionsItemOwners;
}

export type GetOwnersCommonCollectionsV1ResponseCollections = GetOwnersCommonCollectionsV1ResponseCollectionsItem[];

export interface GetOrdersDepthV1ResponseDepthItem {
  price?: number;
  quantity?: number;
}

export type GetOrdersDepthV1ResponseDepth = GetOrdersDepthV1ResponseDepthItem[];

export interface GetOrdersDepthV1Response {
  depth?: GetOrdersDepthV1ResponseDepth;
}

export type GetOrdersBidsV6ResponseOrders = GetOrdersBidsV6ResponseOrdersItem[];

export interface GetOrdersBidsV6Response {
  continuation?: string;
  orders: GetOrdersBidsV6ResponseOrders;
}

export interface GetOrdersBidsV6ResponseOrdersItemDepthItem {
  price?: number;
  quantity?: number;
}

export type GetOrdersBidsV6ResponseOrdersItemDepth = GetOrdersBidsV6ResponseOrdersItemDepthItem[];

export interface GetOrdersBidsV6ResponseOrdersItemRawData { [key: string]: any }

export interface GetOrdersBidsV6ResponseOrdersItemFeeBreakdownItem {
  bps?: number;
  /** Can be marketplace or royalty */
  kind?: string;
  recipient?: string;
}

export type GetOrdersBidsV6ResponseOrdersItemFeeBreakdown = GetOrdersBidsV6ResponseOrdersItemFeeBreakdownItem[];

export interface GetOrdersBidsV6ResponseOrdersItemSource { [key: string]: any }

export interface GetOrdersBidsV6ResponseOrdersItemCriteriaOption1DataCollection {
  id?: string;
  image?: string;
  isSpam?: boolean;
  name?: string;
}

export interface GetOrdersBidsV6ResponseOrdersItemCriteriaOption1DataToken {
  image?: string;
  isSpam?: boolean;
  name?: string;
  tokenId?: string;
}

export interface GetOrdersBidsV6ResponseOrdersItemCriteriaOption1Data {
  collection?: GetOrdersBidsV6ResponseOrdersItemCriteriaOption1DataCollection;
  token?: GetOrdersBidsV6ResponseOrdersItemCriteriaOption1DataToken;
}

export type GetOrdersBidsV6ResponseOrdersItemCriteriaOption1Kind = typeof GetOrdersBidsV6ResponseOrdersItemCriteriaOption1Kind[keyof typeof GetOrdersBidsV6ResponseOrdersItemCriteriaOption1Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersBidsV6ResponseOrdersItemCriteriaOption1Kind = {
  token: 'token',
} as const;

export interface GetOrdersBidsV6ResponseOrdersItemCriteriaOptions {
  data?: GetOrdersBidsV6ResponseOrdersItemCriteriaOption1Data;
  kind?: GetOrdersBidsV6ResponseOrdersItemCriteriaOption1Kind;
}

export interface GetOrdersBidsV6ResponseOrdersItem {
  contract?: string;
  contractKind?: string;
  /** Time when added to indexer */
  createdAt: string;
  criteria?: GetOrdersBidsV6ResponseOrdersItemCriteriaOptions;
  depth?: GetOrdersBidsV6ResponseOrdersItemDepth;
  dynamicPricing?: GetOrdersBidsV6ResponseOrdersItemDynamicPricingOptions;
  expiration: number;
  feeBps?: number;
  feeBreakdown?: GetOrdersBidsV6ResponseOrdersItemFeeBreakdown;
  id: string;
  isDynamic?: boolean;
  isNativeOffChainCancellable?: boolean;
  isReservoir?: boolean;
  /** This is the `orderKind`. */
  kind: string;
  maker: string;
  /** Time when created by maker */
  originatedAt?: string;
  price?: GetOrdersBidsV6ResponseOrdersItemPrice;
  /** With ERC1155s, quantity can be higher than 1 */
  quantityFilled?: number;
  /** With ERC1155s, quantity can be higher than 1 */
  quantityRemaining?: number;
  rawData?: GetOrdersBidsV6ResponseOrdersItemRawData;
  side: GetOrdersBidsV6ResponseOrdersItemSide;
  source?: GetOrdersBidsV6ResponseOrdersItemSource;
  /** Can be `active`, `inactive`, `expired`, `canceled`, or `filled` */
  status?: string;
  taker: string;
  tokenSetId: string;
  tokenSetSchemaHash: string;
  /** Time when updated in indexer */
  updatedAt: string;
  validFrom: number;
  validUntil: number;
}

export interface GetOrdersBidsV6ResponseOrdersItemDynamicPricingOption1DataTime {
  end?: number;
  start?: number;
}

export interface GetOrdersBidsV6ResponseOrdersItemDynamicPricingOption1DataPrice {
  end?: GetOrdersBidsV6ResponseOrdersItemDynamicPricingOption1DataPriceEnd;
  start?: GetOrdersBidsV6ResponseOrdersItemDynamicPricingOption1DataPriceStart;
}

export interface GetOrdersBidsV6ResponseOrdersItemDynamicPricingOption1Data {
  price?: GetOrdersBidsV6ResponseOrdersItemDynamicPricingOption1DataPrice;
  time?: GetOrdersBidsV6ResponseOrdersItemDynamicPricingOption1DataTime;
}

export interface GetOrdersBidsV6ResponseOrdersItemDynamicPricingOptions {
  data?: GetOrdersBidsV6ResponseOrdersItemDynamicPricingOption1Data;
  kind?: GetOrdersBidsV6ResponseOrdersItemDynamicPricingOption1Kind;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetOrdersBidsV6ResponseOrdersItemDynamicPricingOption1DataPriceEndNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetOrdersBidsV6ResponseOrdersItemDynamicPricingOption1DataPriceEndAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetOrdersBidsV6ResponseOrdersItemDynamicPricingOption1DataPriceEndCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetOrdersBidsV6ResponseOrdersItemDynamicPricingOption1DataPriceEnd {
  amount?: GetOrdersBidsV6ResponseOrdersItemDynamicPricingOption1DataPriceEndAmount;
  currency?: GetOrdersBidsV6ResponseOrdersItemDynamicPricingOption1DataPriceEndCurrency;
  netAmount?: GetOrdersBidsV6ResponseOrdersItemDynamicPricingOption1DataPriceEndNetAmount;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetOrdersBidsV6ResponseOrdersItemDynamicPricingOption1DataPriceStartNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetOrdersBidsV6ResponseOrdersItemDynamicPricingOption1DataPriceStartAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetOrdersBidsV6ResponseOrdersItemDynamicPricingOption1DataPriceStartCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetOrdersBidsV6ResponseOrdersItemDynamicPricingOption1DataPriceStart {
  amount?: GetOrdersBidsV6ResponseOrdersItemDynamicPricingOption1DataPriceStartAmount;
  currency?: GetOrdersBidsV6ResponseOrdersItemDynamicPricingOption1DataPriceStartCurrency;
  netAmount?: GetOrdersBidsV6ResponseOrdersItemDynamicPricingOption1DataPriceStartNetAmount;
}

export type GetOrdersBidsV6ResponseOrdersItemDynamicPricingOption1Kind = typeof GetOrdersBidsV6ResponseOrdersItemDynamicPricingOption1Kind[keyof typeof GetOrdersBidsV6ResponseOrdersItemDynamicPricingOption1Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersBidsV6ResponseOrdersItemDynamicPricingOption1Kind = {
  dutch: 'dutch',
} as const;

/**
 * Amount with fees & royalties removed.
 */
export interface GetOrdersBidsV6ResponseOrdersItemPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetOrdersBidsV6ResponseOrdersItemPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetOrdersBidsV6ResponseOrdersItemPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

/**
 * Return native currency unless displayCurrency contract was passed.
 */
export interface GetOrdersBidsV6ResponseOrdersItemPrice {
  amount?: GetOrdersBidsV6ResponseOrdersItemPriceAmount;
  currency?: GetOrdersBidsV6ResponseOrdersItemPriceCurrency;
  netAmount?: GetOrdersBidsV6ResponseOrdersItemPriceNetAmount;
}

/**
 * Either `buy` or `sell`
 */
export type GetOrdersBidsV6ResponseOrdersItemSide = typeof GetOrdersBidsV6ResponseOrdersItemSide[keyof typeof GetOrdersBidsV6ResponseOrdersItemSide];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersBidsV6ResponseOrdersItemSide = {
  buy: 'buy',
  sell: 'sell',
} as const;

export interface GetOrdersAsksV5Response {
  continuation?: string;
  orders: GetOrdersAsksV5ResponseOrders;
}

export interface GetOrdersAsksV5ResponseOrdersItemDepthItem {
  price?: number;
  quantity?: number;
}

export type GetOrdersAsksV5ResponseOrdersItemDepth = GetOrdersAsksV5ResponseOrdersItemDepthItem[];

export interface GetOrdersAsksV5ResponseOrdersItemRawData { [key: string]: any }

export interface GetOrdersAsksV5ResponseOrdersItem {
  contract?: string;
  contractKind?: string;
  /** Time when added to indexer */
  createdAt: string;
  criteria?: GetOrdersAsksV5ResponseOrdersItemCriteriaOptions;
  depth?: GetOrdersAsksV5ResponseOrdersItemDepth;
  dynamicPricing?: GetOrdersAsksV5ResponseOrdersItemDynamicPricingOptions;
  expiration: number;
  feeBps?: number;
  feeBreakdown?: GetOrdersAsksV5ResponseOrdersItemFeeBreakdown;
  id: string;
  isDynamic?: boolean;
  isNativeOffChainCancellable?: boolean;
  isReservoir?: boolean;
  /** This is the `orderKind`. */
  kind: string;
  maker: string;
  /** Time when created by maker */
  originatedAt?: string;
  price?: GetOrdersAsksV5ResponseOrdersItemPrice;
  /** With ERC1155s, quantity can be higher than 1 */
  quantityFilled?: number;
  /** With ERC1155s, quantity can be higher than 1 */
  quantityRemaining?: number;
  rawData?: GetOrdersAsksV5ResponseOrdersItemRawData;
  side: GetOrdersAsksV5ResponseOrdersItemSide;
  source?: GetOrdersAsksV5ResponseOrdersItemSource;
  /** Can be `active`, `inactive`, `expired`, `canceled`, or `filled` */
  status?: string;
  taker: string;
  tokenSetId: string;
  tokenSetSchemaHash: string;
  /** Time when updated in indexer */
  updatedAt: string;
  validFrom: number;
  validUntil: number;
}

/**
 * `taker` will have wallet address if private listing.
 */
export type GetOrdersAsksV5ResponseOrders = GetOrdersAsksV5ResponseOrdersItem[];

export interface GetOrdersAsksV5ResponseOrdersItemFeeBreakdownItem {
  bps?: number;
  /** Can be marketplace or royalty */
  kind?: string;
  recipient?: string;
}

export type GetOrdersAsksV5ResponseOrdersItemFeeBreakdown = GetOrdersAsksV5ResponseOrdersItemFeeBreakdownItem[];

export interface GetOrdersAsksV5ResponseOrdersItemSource { [key: string]: any }

export interface GetOrdersAsksV5ResponseOrdersItemCriteriaOption1DataCollection {
  id?: string;
  image?: string;
  isSpam?: boolean;
  name?: string;
}

export interface GetOrdersAsksV5ResponseOrdersItemCriteriaOption1DataToken {
  image?: string;
  isSpam?: boolean;
  name?: string;
  tokenId?: string;
}

export interface GetOrdersAsksV5ResponseOrdersItemCriteriaOption1Data {
  collection?: GetOrdersAsksV5ResponseOrdersItemCriteriaOption1DataCollection;
  token?: GetOrdersAsksV5ResponseOrdersItemCriteriaOption1DataToken;
}

export type GetOrdersAsksV5ResponseOrdersItemCriteriaOption1Kind = typeof GetOrdersAsksV5ResponseOrdersItemCriteriaOption1Kind[keyof typeof GetOrdersAsksV5ResponseOrdersItemCriteriaOption1Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersAsksV5ResponseOrdersItemCriteriaOption1Kind = {
  token: 'token',
} as const;

export interface GetOrdersAsksV5ResponseOrdersItemCriteriaOptions {
  data?: GetOrdersAsksV5ResponseOrdersItemCriteriaOption1Data;
  kind?: GetOrdersAsksV5ResponseOrdersItemCriteriaOption1Kind;
}

export interface GetOrdersAsksV5ResponseOrdersItemDynamicPricingOption1DataTime {
  end?: number;
  start?: number;
}

export interface GetOrdersAsksV5ResponseOrdersItemDynamicPricingOption1Data {
  price?: GetOrdersAsksV5ResponseOrdersItemDynamicPricingOption1DataPrice;
  time?: GetOrdersAsksV5ResponseOrdersItemDynamicPricingOption1DataTime;
}

export interface GetOrdersAsksV5ResponseOrdersItemDynamicPricingOptions {
  data?: GetOrdersAsksV5ResponseOrdersItemDynamicPricingOption1Data;
  kind?: GetOrdersAsksV5ResponseOrdersItemDynamicPricingOption1Kind;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetOrdersAsksV5ResponseOrdersItemDynamicPricingOption1DataPriceEndNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetOrdersAsksV5ResponseOrdersItemDynamicPricingOption1DataPriceEndAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetOrdersAsksV5ResponseOrdersItemDynamicPricingOption1DataPriceEndCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetOrdersAsksV5ResponseOrdersItemDynamicPricingOption1DataPriceEnd {
  amount?: GetOrdersAsksV5ResponseOrdersItemDynamicPricingOption1DataPriceEndAmount;
  currency?: GetOrdersAsksV5ResponseOrdersItemDynamicPricingOption1DataPriceEndCurrency;
  netAmount?: GetOrdersAsksV5ResponseOrdersItemDynamicPricingOption1DataPriceEndNetAmount;
}

export interface GetOrdersAsksV5ResponseOrdersItemDynamicPricingOption1DataPrice {
  end?: GetOrdersAsksV5ResponseOrdersItemDynamicPricingOption1DataPriceEnd;
  start?: GetOrdersAsksV5ResponseOrdersItemDynamicPricingOption1DataPriceStart;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetOrdersAsksV5ResponseOrdersItemDynamicPricingOption1DataPriceStartNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetOrdersAsksV5ResponseOrdersItemDynamicPricingOption1DataPriceStartAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetOrdersAsksV5ResponseOrdersItemDynamicPricingOption1DataPriceStartCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetOrdersAsksV5ResponseOrdersItemDynamicPricingOption1DataPriceStart {
  amount?: GetOrdersAsksV5ResponseOrdersItemDynamicPricingOption1DataPriceStartAmount;
  currency?: GetOrdersAsksV5ResponseOrdersItemDynamicPricingOption1DataPriceStartCurrency;
  netAmount?: GetOrdersAsksV5ResponseOrdersItemDynamicPricingOption1DataPriceStartNetAmount;
}

export type GetOrdersAsksV5ResponseOrdersItemDynamicPricingOption1Kind = typeof GetOrdersAsksV5ResponseOrdersItemDynamicPricingOption1Kind[keyof typeof GetOrdersAsksV5ResponseOrdersItemDynamicPricingOption1Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersAsksV5ResponseOrdersItemDynamicPricingOption1Kind = {
  dutch: 'dutch',
} as const;

/**
 * Amount with fees & royalties removed.
 */
export interface GetOrdersAsksV5ResponseOrdersItemPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetOrdersAsksV5ResponseOrdersItemPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetOrdersAsksV5ResponseOrdersItemPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

/**
 * Return native currency unless displayCurrency contract was passed.
 */
export interface GetOrdersAsksV5ResponseOrdersItemPrice {
  amount?: GetOrdersAsksV5ResponseOrdersItemPriceAmount;
  currency?: GetOrdersAsksV5ResponseOrdersItemPriceCurrency;
  netAmount?: GetOrdersAsksV5ResponseOrdersItemPriceNetAmount;
}

/**
 * Either `buy` or `sell`
 */
export type GetOrdersAsksV5ResponseOrdersItemSide = typeof GetOrdersAsksV5ResponseOrdersItemSide[keyof typeof GetOrdersAsksV5ResponseOrdersItemSide];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersAsksV5ResponseOrdersItemSide = {
  buy: 'buy',
  sell: 'sell',
} as const;

export interface GetOrdersAllV2Response {
  continuation?: string;
  orders?: GetOrdersAllV2ResponseOrders;
}

export interface GetOrdersAllV2ResponseOrdersItemRawData { [key: string]: any }

export interface GetOrdersAllV2ResponseOrdersItemMetadata { [key: string]: any }

export interface GetOrdersAllV2ResponseOrdersItem {
  contract?: string;
  createdAt: string;
  expiration: number;
  feeBps?: number;
  feeBreakdown?: GetOrdersAllV2ResponseOrdersItemFeeBreakdown;
  id: string;
  kind: string;
  maker: string;
  metadata?: GetOrdersAllV2ResponseOrdersItemMetadata;
  price?: GetOrdersAllV2ResponseOrdersItemPrice;
  rawData?: GetOrdersAllV2ResponseOrdersItemRawData;
  side: GetOrdersAllV2ResponseOrdersItemSide;
  source?: string;
  status?: string;
  taker: string;
  tokenSetId: string;
  tokenSetSchemaHash: string;
  updatedAt: string;
  validFrom: number;
  validUntil: number;
}

export type GetOrdersAllV2ResponseOrders = GetOrdersAllV2ResponseOrdersItem[];

export interface GetOrdersAllV2ResponseOrdersItemFeeBreakdownItem {
  bps?: string;
  kind?: string;
  recipient?: string;
}

export type GetOrdersAllV2ResponseOrdersItemFeeBreakdown = GetOrdersAllV2ResponseOrdersItemFeeBreakdownItem[];

/**
 * Amount with fees & royalties removed.
 */
export interface GetOrdersAllV2ResponseOrdersItemPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetOrdersAllV2ResponseOrdersItemPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetOrdersAllV2ResponseOrdersItemPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetOrdersAllV2ResponseOrdersItemPrice {
  amount?: GetOrdersAllV2ResponseOrdersItemPriceAmount;
  currency?: GetOrdersAllV2ResponseOrdersItemPriceCurrency;
  netAmount?: GetOrdersAllV2ResponseOrdersItemPriceNetAmount;
}

export type GetOrdersAllV2ResponseOrdersItemSide = typeof GetOrdersAllV2ResponseOrdersItemSide[keyof typeof GetOrdersAllV2ResponseOrdersItemSide];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersAllV2ResponseOrdersItemSide = {
  buy: 'buy',
  sell: 'sell',
} as const;

export interface GetLiquidityUsersV2ResponseLiquidityItem {
  liquidity: number;
  maxTopBuyValue: number;
  rank: number;
  tokenCount: string;
  user?: string;
  wethBalance: number;
}

export type GetLiquidityUsersV2ResponseLiquidity = GetLiquidityUsersV2ResponseLiquidityItem[];

export interface GetLiquidityUsersV2Response {
  liquidity?: GetLiquidityUsersV2ResponseLiquidity;
}

export interface GetExecuteCancelV2ResponseStepsItem {
  action: string;
  description: string;
  id: string;
  items: GetExecuteCancelV2ResponseStepsItemItems;
  kind: GetExecuteCancelV2ResponseStepsItemKind;
}

export type GetExecuteCancelV2ResponseSteps = GetExecuteCancelV2ResponseStepsItem[];

export interface GetExecuteCancelV2Response {
  steps?: GetExecuteCancelV2ResponseSteps;
}

export interface GetExecuteCancelV2ResponseStepsItemItemsItemData { [key: string]: any }

export type GetExecuteCancelV2ResponseStepsItemItemsItemStatus = typeof GetExecuteCancelV2ResponseStepsItemItemsItemStatus[keyof typeof GetExecuteCancelV2ResponseStepsItemItemsItemStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetExecuteCancelV2ResponseStepsItemItemsItemStatus = {
  complete: 'complete',
  incomplete: 'incomplete',
} as const;

export interface GetExecuteCancelV2ResponseStepsItemItemsItem {
  data?: GetExecuteCancelV2ResponseStepsItemItemsItemData;
  orderIndex?: number;
  status: GetExecuteCancelV2ResponseStepsItemItemsItemStatus;
}

export type GetExecuteCancelV2ResponseStepsItemItems = GetExecuteCancelV2ResponseStepsItemItemsItem[];

export type GetExecuteCancelV2ResponseStepsItemKind = typeof GetExecuteCancelV2ResponseStepsItemKind[keyof typeof GetExecuteCancelV2ResponseStepsItemKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetExecuteCancelV2ResponseStepsItemKind = {
  signature: 'signature',
  transaction: 'transaction',
} as const;

export type GetEventsBidsV3ResponseEvents = GetEventsBidsV3ResponseEventsItem[];

export interface GetEventsBidsV3Response {
  continuation?: string;
  events?: GetEventsBidsV3ResponseEvents;
}

export type GetEventsBidsV3ResponseEventsItemEventKind = typeof GetEventsBidsV3ResponseEventsItemEventKind[keyof typeof GetEventsBidsV3ResponseEventsItemEventKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEventsBidsV3ResponseEventsItemEventKind = {
  'new-order': 'new-order',
  expiry: 'expiry',
  sale: 'sale',
  cancel: 'cancel',
  'balance-change': 'balance-change',
  'approval-change': 'approval-change',
  bootstrap: 'bootstrap',
  revalidation: 'revalidation',
  reprice: 'reprice',
} as const;

export interface GetEventsBidsV3ResponseEventsItemEvent {
  /** Time when added to indexer */
  createdAt?: string;
  id?: number;
  kind?: GetEventsBidsV3ResponseEventsItemEventKind;
  txHash?: string;
  /** Time when added on the blockchain. */
  txTimestamp?: number;
}

export interface GetEventsBidsV3ResponseEventsItem {
  bid?: GetEventsBidsV3ResponseEventsItemBid;
  event?: GetEventsBidsV3ResponseEventsItemEvent;
}

export interface GetEventsBidsV3ResponseEventsItemBidCriteriaOption1DataCollection {
  id?: string;
  image?: string;
  isSpam?: boolean;
  name?: string;
}

export interface GetEventsBidsV3ResponseEventsItemBidCriteriaOption1DataToken {
  image?: string;
  isSpam?: boolean;
  name?: string;
  tokenId?: string;
}

export interface GetEventsBidsV3ResponseEventsItemBidCriteriaOption1Data {
  collection?: GetEventsBidsV3ResponseEventsItemBidCriteriaOption1DataCollection;
  token?: GetEventsBidsV3ResponseEventsItemBidCriteriaOption1DataToken;
}

export type GetEventsBidsV3ResponseEventsItemBidCriteriaOption1Kind = typeof GetEventsBidsV3ResponseEventsItemBidCriteriaOption1Kind[keyof typeof GetEventsBidsV3ResponseEventsItemBidCriteriaOption1Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEventsBidsV3ResponseEventsItemBidCriteriaOption1Kind = {
  token: 'token',
} as const;

export interface GetEventsBidsV3ResponseEventsItemBidCriteriaOptions {
  data?: GetEventsBidsV3ResponseEventsItemBidCriteriaOption1Data;
  kind?: GetEventsBidsV3ResponseEventsItemBidCriteriaOption1Kind;
}

export interface GetEventsBidsV3ResponseEventsItemBidRawData { [key: string]: any }

export interface GetEventsBidsV3ResponseEventsItemBid {
  contract?: string;
  criteria?: GetEventsBidsV3ResponseEventsItemBidCriteriaOptions;
  /** Order Id */
  id?: string;
  kind?: string;
  maker?: string;
  nonce?: string;
  price?: GetEventsBidsV3ResponseEventsItemBidPrice;
  /** With ERC1155s, quantity can be higher than 1 */
  quantityRemaining?: number;
  rawData?: GetEventsBidsV3ResponseEventsItemBidRawData;
  source?: string;
  /** Can return `active`,  inactive`, `expired`, `canceled`, or `filled`. */
  status?: string;
  validFrom?: number;
  validUntil?: number;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetEventsBidsV3ResponseEventsItemBidPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetEventsBidsV3ResponseEventsItemBidPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetEventsBidsV3ResponseEventsItemBidPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetEventsBidsV3ResponseEventsItemBidPrice {
  amount?: GetEventsBidsV3ResponseEventsItemBidPriceAmount;
  currency?: GetEventsBidsV3ResponseEventsItemBidPriceCurrency;
  netAmount?: GetEventsBidsV3ResponseEventsItemBidPriceNetAmount;
}

export interface GetEventsAsksV3Response {
  continuation?: string;
  events?: GetEventsAsksV3ResponseEvents;
}

export type GetEventsAsksV3ResponseEventsItemEventKind = typeof GetEventsAsksV3ResponseEventsItemEventKind[keyof typeof GetEventsAsksV3ResponseEventsItemEventKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEventsAsksV3ResponseEventsItemEventKind = {
  'new-order': 'new-order',
  expiry: 'expiry',
  sale: 'sale',
  cancel: 'cancel',
  'balance-change': 'balance-change',
  'approval-change': 'approval-change',
  bootstrap: 'bootstrap',
  revalidation: 'revalidation',
  reprice: 'reprice',
} as const;

export interface GetEventsAsksV3ResponseEventsItemEvent {
  /** Time when added to indexer */
  createdAt?: string;
  id?: number;
  kind?: GetEventsAsksV3ResponseEventsItemEventKind;
  txHash?: string;
  /** Time when added on the blockchain. */
  txTimestamp?: number;
}

export interface GetEventsAsksV3ResponseEventsItem {
  event?: GetEventsAsksV3ResponseEventsItemEvent;
  order?: GetEventsAsksV3ResponseEventsItemOrder;
}

export type GetEventsAsksV3ResponseEvents = GetEventsAsksV3ResponseEventsItem[];

export interface GetEventsAsksV3ResponseEventsItemOrderCriteriaOption1DataCollection {
  id?: string;
  image?: string;
  isSpam?: boolean;
  name?: string;
}

export interface GetEventsAsksV3ResponseEventsItemOrderCriteriaOption1DataToken {
  image?: string;
  isSpam?: boolean;
  name?: string;
  tokenId?: string;
}

export interface GetEventsAsksV3ResponseEventsItemOrderCriteriaOption1Data {
  collection?: GetEventsAsksV3ResponseEventsItemOrderCriteriaOption1DataCollection;
  token?: GetEventsAsksV3ResponseEventsItemOrderCriteriaOption1DataToken;
}

export type GetEventsAsksV3ResponseEventsItemOrderCriteriaOption1Kind = typeof GetEventsAsksV3ResponseEventsItemOrderCriteriaOption1Kind[keyof typeof GetEventsAsksV3ResponseEventsItemOrderCriteriaOption1Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEventsAsksV3ResponseEventsItemOrderCriteriaOption1Kind = {
  token: 'token',
} as const;

export interface GetEventsAsksV3ResponseEventsItemOrderCriteriaOptions {
  data?: GetEventsAsksV3ResponseEventsItemOrderCriteriaOption1Data;
  kind?: GetEventsAsksV3ResponseEventsItemOrderCriteriaOption1Kind;
}

export interface GetEventsAsksV3ResponseEventsItemOrderRawData { [key: string]: any }

export interface GetEventsAsksV3ResponseEventsItemOrder {
  contract?: string;
  criteria?: GetEventsAsksV3ResponseEventsItemOrderCriteriaOptions;
  /** Order Id */
  id?: string;
  isDynamic?: boolean;
  kind?: string;
  maker?: string;
  nonce?: string;
  price?: GetEventsAsksV3ResponseEventsItemOrderPrice;
  /** With ERC1155s, quantity can be higher than 1 */
  quantityRemaining?: number;
  rawData?: GetEventsAsksV3ResponseEventsItemOrderRawData;
  source?: string;
  status?: string;
  validFrom?: number;
  validUntil?: number;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetEventsAsksV3ResponseEventsItemOrderPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetEventsAsksV3ResponseEventsItemOrderPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetEventsAsksV3ResponseEventsItemOrderPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetEventsAsksV3ResponseEventsItemOrderPrice {
  amount?: GetEventsAsksV3ResponseEventsItemOrderPriceAmount;
  currency?: GetEventsAsksV3ResponseEventsItemOrderPriceCurrency;
  netAmount?: GetEventsAsksV3ResponseEventsItemOrderPriceNetAmount;
}

export interface GetCurrenciesConversionV1Response {
  conversion?: string;
  usd?: string;
}

export interface GetCollectionsCollectionOrSlugV1Response {
  collection?: GetCollectionsCollectionOrSlugV1ResponseCollection;
}

export interface GetCollectionsCollectionOrSlugV1ResponseCollectionVolume {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
  allTime?: number;
}

export interface GetCollectionsCollectionOrSlugV1ResponseCollectionRank {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
  allTime?: number;
}

export interface GetCollectionsCollectionOrSlugV1ResponseCollectionTopBid {
  id?: string;
  maker?: string;
  validFrom?: number;
  validUntil?: number;
  value?: number;
}

export interface GetCollectionsCollectionOrSlugV1ResponseCollectionFloorAskToken {
  contract?: string;
  image?: string;
  name?: string;
  tokenId?: string;
}

export interface GetCollectionsCollectionOrSlugV1ResponseCollectionFloorAsk {
  id?: string;
  maker?: string;
  price?: number;
  token?: GetCollectionsCollectionOrSlugV1ResponseCollectionFloorAskToken;
  validFrom?: number;
  validUntil?: number;
}

export interface GetCollectionsCollectionOrSlugV1ResponseCollectionLastSell {
  timestamp?: number;
  value?: number;
}

export interface GetCollectionsCollectionOrSlugV1ResponseCollectionLastBuy {
  timestamp?: number;
  value?: number;
}

export interface GetCollectionsCollectionOrSlugV1ResponseCollectionRoyalties {
  bps?: number;
  recipient?: string;
}

export type GetCollectionsCollectionOrSlugV1ResponseCollectionSampleImages = string[];

export interface GetCollectionsCollectionOrSlugV1ResponseCollectionMetadata { [key: string]: any }

export interface GetCollectionsCollectionOrSlugV1ResponseCollection {
  floorAsk?: GetCollectionsCollectionOrSlugV1ResponseCollectionFloorAsk;
  id?: string;
  lastBuy?: GetCollectionsCollectionOrSlugV1ResponseCollectionLastBuy;
  lastSell?: GetCollectionsCollectionOrSlugV1ResponseCollectionLastSell;
  metadata?: GetCollectionsCollectionOrSlugV1ResponseCollectionMetadata;
  name?: string;
  onSaleCount?: string;
  rank?: GetCollectionsCollectionOrSlugV1ResponseCollectionRank;
  royalties?: GetCollectionsCollectionOrSlugV1ResponseCollectionRoyalties;
  sampleImages?: GetCollectionsCollectionOrSlugV1ResponseCollectionSampleImages;
  slug?: string;
  tokenCount?: string;
  tokenSetId?: string;
  topBid?: GetCollectionsCollectionOrSlugV1ResponseCollectionTopBid;
  volume?: GetCollectionsCollectionOrSlugV1ResponseCollectionVolume;
}

export interface GetCollectionsTrendingMintsV1Response {
  mints?: GetCollectionsTrendingMintsV1ResponseMints;
}

/**
 * Total volume change X-days vs previous X-days. (e.g. 7day [days 1-7] vs 7day prior [days 8-14]). A value over 1 is a positive gain, under 1 is a negative loss. e.g. 1 means no change; 1.1 means 10% increase; 0.9 means 10% decrease.
 */
export interface GetCollectionsTrendingMintsV1ResponseMintsItemVolumeChange {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
}

/**
 * Total volume in given time period.
 */
export interface GetCollectionsTrendingMintsV1ResponseMintsItemCollectionVolume {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
  allTime?: number;
}

export interface GetCollectionsTrendingMintsV1ResponseMintsItem {
  banner?: string;
  collectionVolume?: GetCollectionsTrendingMintsV1ResponseMintsItemCollectionVolume;
  contract?: string;
  countPercentChange?: number;
  createdAt?: string;
  creator?: string;
  description?: string;
  endDate?: string;
  floorAsk?: GetCollectionsTrendingMintsV1ResponseMintsItemFloorAsk;
  /** Collection id */
  id?: string;
  image?: string;
  isSpam?: boolean;
  maxSupply?: number;
  mintCount?: number;
  mintPrice?: string;
  mintStages?: GetCollectionsTrendingMintsV1ResponseMintsItemMintStages;
  mintStatus?: string;
  mintType?: GetCollectionsTrendingMintsV1ResponseMintsItemMintType;
  mintVolume?: number;
  name?: string;
  oneHourCount?: number;
  onSaleCount?: number;
  openseaVerificationStatus?: string;
  /** Unique number of owners. */
  ownerCount?: number;
  primaryContract?: string;
  sampleImages?: GetCollectionsTrendingMintsV1ResponseMintsItemSampleImages;
  sixHourCount?: number;
  startDate?: string;
  /** Total tokens within the collection. */
  tokenCount?: number;
  volumeChange?: GetCollectionsTrendingMintsV1ResponseMintsItemVolumeChange;
  volumePercentChange?: number;
}

export type GetCollectionsTrendingMintsV1ResponseMints = GetCollectionsTrendingMintsV1ResponseMintsItem[];

export interface GetCollectionsTrendingMintsV1ResponseMintsItemMintStagesItem {
  endTime?: number;
  kind: string;
  maxMintsPerWallet?: number;
  price?: GetCollectionsTrendingMintsV1ResponseMintsItemMintStagesItemPrice;
  stage?: string;
  startTime?: number;
  tokenId?: string;
}

export type GetCollectionsTrendingMintsV1ResponseMintsItemMintStages = GetCollectionsTrendingMintsV1ResponseMintsItemMintStagesItem[];

/**
 * Amount with fees & royalties removed.
 */
export interface GetCollectionsTrendingMintsV1ResponseMintsItemMintStagesItemPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetCollectionsTrendingMintsV1ResponseMintsItemMintStagesItemPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetCollectionsTrendingMintsV1ResponseMintsItemMintStagesItemPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetCollectionsTrendingMintsV1ResponseMintsItemMintStagesItemPrice {
  amount?: GetCollectionsTrendingMintsV1ResponseMintsItemMintStagesItemPriceAmount;
  currency?: GetCollectionsTrendingMintsV1ResponseMintsItemMintStagesItemPriceCurrency;
  netAmount?: GetCollectionsTrendingMintsV1ResponseMintsItemMintStagesItemPriceNetAmount;
}

export type GetCollectionsTrendingMintsV1ResponseMintsItemMintType = typeof GetCollectionsTrendingMintsV1ResponseMintsItemMintType[keyof typeof GetCollectionsTrendingMintsV1ResponseMintsItemMintType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsTrendingMintsV1ResponseMintsItemMintType = {
  free: 'free',
  paid: 'paid',
} as const;

export type GetCollectionsTrendingMintsV1ResponseMintsItemSampleImages = string[];

/**
 * Lowest Ask Price.
 */
export interface GetCollectionsTrendingMintsV1ResponseMintsItemFloorAskToken {
  contract?: string;
  image?: string;
  name?: string;
  tokenId?: string;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetCollectionsTrendingMintsV1ResponseMintsItemFloorAskPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetCollectionsTrendingMintsV1ResponseMintsItemFloorAskPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetCollectionsTrendingMintsV1ResponseMintsItemFloorAskPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetCollectionsTrendingMintsV1ResponseMintsItemFloorAskPrice {
  amount?: GetCollectionsTrendingMintsV1ResponseMintsItemFloorAskPriceAmount;
  currency?: GetCollectionsTrendingMintsV1ResponseMintsItemFloorAskPriceCurrency;
  netAmount?: GetCollectionsTrendingMintsV1ResponseMintsItemFloorAskPriceNetAmount;
}

export interface GetCollectionsTrendingMintsV1ResponseMintsItemFloorAsk {
  id?: string;
  maker?: string;
  price?: GetCollectionsTrendingMintsV1ResponseMintsItemFloorAskPrice;
  sourceDomain?: string;
  token?: GetCollectionsTrendingMintsV1ResponseMintsItemFloorAskToken;
  validFrom?: number;
  validUntil?: number;
}

export type GetCollectionsTrendingV1ResponseCollections = GetCollectionsTrendingV1ResponseCollectionsItem[];

export interface GetCollectionsTrendingV1Response {
  collections?: GetCollectionsTrendingV1ResponseCollections;
}

/**
 * Total volume change X-days vs previous X-days. (e.g. 7day [days 1-7] vs 7day prior [days 8-14]). A value over 1 is a positive gain, under 1 is a negative loss. e.g. 1 means no change; 1.1 means 10% increase; 0.9 means 10% decrease.
 */
export interface GetCollectionsTrendingV1ResponseCollectionsItemVolumeChange {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
}

/**
 * Total volume in given time period.
 */
export interface GetCollectionsTrendingV1ResponseCollectionsItemCollectionVolume {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
  allTime?: number;
}

/**
 * Lowest Ask Price.
 */
export interface GetCollectionsTrendingV1ResponseCollectionsItemFloorAskToken {
  contract?: string;
  image?: string;
  name?: string;
  tokenId?: string;
}

export interface GetCollectionsTrendingV1ResponseCollectionsItemFloorAsk {
  id?: string;
  maker?: string;
  price?: GetCollectionsTrendingV1ResponseCollectionsItemFloorAskPrice;
  sourceDomain?: string;
  token?: GetCollectionsTrendingV1ResponseCollectionsItemFloorAskToken;
  validFrom?: number;
  validUntil?: number;
}

export interface GetCollectionsTrendingV1ResponseCollectionsItem {
  banner?: string;
  collectionVolume?: GetCollectionsTrendingV1ResponseCollectionsItemCollectionVolume;
  contract?: string;
  count?: number;
  countPercentChange?: number;
  creator?: string;
  description?: string;
  floorAsk?: GetCollectionsTrendingV1ResponseCollectionsItemFloorAsk;
  /** Collection id */
  id?: string;
  image?: string;
  isSpam?: boolean;
  name?: string;
  onSaleCount?: number;
  /** Unique number of owners. */
  ownerCount?: number;
  primaryContract?: string;
  /** Total tokens within the collection. */
  tokenCount?: number;
  volume?: number;
  volumeChange?: GetCollectionsTrendingV1ResponseCollectionsItemVolumeChange;
  volumePercentChange?: number;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetCollectionsTrendingV1ResponseCollectionsItemFloorAskPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetCollectionsTrendingV1ResponseCollectionsItemFloorAskPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetCollectionsTrendingV1ResponseCollectionsItemFloorAskPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetCollectionsTrendingV1ResponseCollectionsItemFloorAskPrice {
  amount?: GetCollectionsTrendingV1ResponseCollectionsItemFloorAskPriceAmount;
  currency?: GetCollectionsTrendingV1ResponseCollectionsItemFloorAskPriceCurrency;
  netAmount?: GetCollectionsTrendingV1ResponseCollectionsItemFloorAskPriceNetAmount;
}

export interface GetCollectionsTopSellingV2ResponseCollectionsItem {
  banner?: string;
  count?: number;
  countPercentChange?: number;
  description?: string;
  floorAsk?: GetCollectionsTopSellingV2ResponseCollectionsItemFloorAsk;
  /** Collection id */
  id?: string;
  image?: string;
  name?: string;
  /** Unique number of owners. */
  ownerCount?: number;
  primaryContract?: string;
  recentSales?: GetCollectionsTopSellingV2ResponseCollectionsItemRecentSales;
  /** Total tokens within the collection. */
  tokenCount?: number;
  volume?: number;
  volumeChange?: GetCollectionsTopSellingV2ResponseCollectionsItemVolumeChange;
  volumePercentChange?: number;
}

export type GetCollectionsTopSellingV2ResponseCollections = GetCollectionsTopSellingV2ResponseCollectionsItem[];

export interface GetCollectionsTopSellingV2Response {
  collections?: GetCollectionsTopSellingV2ResponseCollections;
}

export interface GetCollectionsTopSellingV2ResponseCollectionsItemRecentSalesItemToken {
  id?: string;
  image?: string;
  name?: string;
}

export interface GetCollectionsTopSellingV2ResponseCollectionsItemRecentSalesItemCollection {
  id?: string;
  image?: string;
  name?: string;
}

export interface GetCollectionsTopSellingV2ResponseCollectionsItemRecentSalesItem {
  collection?: GetCollectionsTopSellingV2ResponseCollectionsItemRecentSalesItemCollection;
  contract?: string;
  price?: GetCollectionsTopSellingV2ResponseCollectionsItemRecentSalesItemPrice;
  timestamp?: number;
  toAddress?: string;
  token?: GetCollectionsTopSellingV2ResponseCollectionsItemRecentSalesItemToken;
  type?: string;
}

export type GetCollectionsTopSellingV2ResponseCollectionsItemRecentSales = GetCollectionsTopSellingV2ResponseCollectionsItemRecentSalesItem[];

/**
 * Amount with fees & royalties removed.
 */
export interface GetCollectionsTopSellingV2ResponseCollectionsItemRecentSalesItemPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetCollectionsTopSellingV2ResponseCollectionsItemRecentSalesItemPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetCollectionsTopSellingV2ResponseCollectionsItemRecentSalesItemPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetCollectionsTopSellingV2ResponseCollectionsItemRecentSalesItemPrice {
  amount?: GetCollectionsTopSellingV2ResponseCollectionsItemRecentSalesItemPriceAmount;
  currency?: GetCollectionsTopSellingV2ResponseCollectionsItemRecentSalesItemPriceCurrency;
  netAmount?: GetCollectionsTopSellingV2ResponseCollectionsItemRecentSalesItemPriceNetAmount;
}

/**
 * Total volume change X-days vs previous X-days. (e.g. 7day [days 1-7] vs 7day prior [days 8-14]). A value over 1 is a positive gain, under 1 is a negative loss. e.g. 1 means no change; 1.1 means 10% increase; 0.9 means 10% decrease.
 */
export interface GetCollectionsTopSellingV2ResponseCollectionsItemVolumeChange {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
}

/**
 * Lowest Ask Price.
 */
export interface GetCollectionsTopSellingV2ResponseCollectionsItemFloorAskToken {
  contract?: string;
  image?: string;
  name?: string;
  tokenId?: string;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetCollectionsTopSellingV2ResponseCollectionsItemFloorAskPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetCollectionsTopSellingV2ResponseCollectionsItemFloorAskPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetCollectionsTopSellingV2ResponseCollectionsItemFloorAskPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetCollectionsTopSellingV2ResponseCollectionsItemFloorAskPrice {
  amount?: GetCollectionsTopSellingV2ResponseCollectionsItemFloorAskPriceAmount;
  currency?: GetCollectionsTopSellingV2ResponseCollectionsItemFloorAskPriceCurrency;
  netAmount?: GetCollectionsTopSellingV2ResponseCollectionsItemFloorAskPriceNetAmount;
}

export interface GetCollectionsTopSellingV2ResponseCollectionsItemFloorAsk {
  id?: string;
  maker?: string;
  price?: GetCollectionsTopSellingV2ResponseCollectionsItemFloorAskPrice;
  sourceDomain?: string;
  token?: GetCollectionsTopSellingV2ResponseCollectionsItemFloorAskToken;
  validFrom?: number;
  validUntil?: number;
}

export interface GetCollectionsDailyVolumesV1ResponseCollectionsItem {
  /** Native currency to chain. */
  floor_sell_value?: number;
  id?: string;
  rank?: number;
  sales_count?: number;
  timestamp?: number;
  volume?: number;
}

export type GetCollectionsDailyVolumesV1ResponseCollections = GetCollectionsDailyVolumesV1ResponseCollectionsItem[];

export interface GetCollectionsDailyVolumesV1Response {
  collections?: GetCollectionsDailyVolumesV1ResponseCollections;
}

export type GetCollectionsActivityV6ResponseActivities = GetCollectionsActivityV6ResponseActivitiesItem[];

export interface GetCollectionsActivityV6Response {
  activities?: GetCollectionsActivityV6ResponseActivities;
  continuation?: string;
}

export interface GetCollectionsActivityV6ResponseActivitiesItemOrderCriteriaOption1DataCollection {
  id?: string;
  image?: string;
  isSpam?: boolean;
  name?: string;
}

export interface GetCollectionsActivityV6ResponseActivitiesItemOrderCriteriaOption1DataToken {
  image?: string;
  isSpam?: boolean;
  name?: string;
  tokenId?: string;
}

export interface GetCollectionsActivityV6ResponseActivitiesItemOrderCriteriaOption1Data {
  collection?: GetCollectionsActivityV6ResponseActivitiesItemOrderCriteriaOption1DataCollection;
  token?: GetCollectionsActivityV6ResponseActivitiesItemOrderCriteriaOption1DataToken;
}

export type GetCollectionsActivityV6ResponseActivitiesItemOrderCriteriaOption1Kind = typeof GetCollectionsActivityV6ResponseActivitiesItemOrderCriteriaOption1Kind[keyof typeof GetCollectionsActivityV6ResponseActivitiesItemOrderCriteriaOption1Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsActivityV6ResponseActivitiesItemOrderCriteriaOption1Kind = {
  token: 'token',
} as const;

export interface GetCollectionsActivityV6ResponseActivitiesItemOrderCriteriaOptions {
  data?: GetCollectionsActivityV6ResponseActivitiesItemOrderCriteriaOption1Data;
  kind?: GetCollectionsActivityV6ResponseActivitiesItemOrderCriteriaOption1Kind;
}

export interface GetCollectionsActivityV6ResponseActivitiesItemOrderSource { [key: string]: any }

export type GetCollectionsActivityV6ResponseActivitiesItemOrderSide = typeof GetCollectionsActivityV6ResponseActivitiesItemOrderSide[keyof typeof GetCollectionsActivityV6ResponseActivitiesItemOrderSide];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsActivityV6ResponseActivitiesItemOrderSide = {
  ask: 'ask',
  bid: 'bid',
} as const;

export interface GetCollectionsActivityV6ResponseActivitiesItemOrder {
  criteria?: GetCollectionsActivityV6ResponseActivitiesItemOrderCriteriaOptions;
  id?: string;
  side?: GetCollectionsActivityV6ResponseActivitiesItemOrderSide;
  source?: GetCollectionsActivityV6ResponseActivitiesItemOrderSource;
}

export interface GetCollectionsActivityV6ResponseActivitiesItemFillSource {
  domain?: string;
  icon?: string;
  id?: string;
  name?: string;
  url?: string;
}

export interface GetCollectionsActivityV6ResponseActivitiesItemCollection {
  collectionId?: string;
  collectionImage?: string;
  collectionName?: string;
  isSpam?: boolean;
}

export interface GetCollectionsActivityV6ResponseActivitiesItemToken {
  isSpam?: boolean;
  tokenId?: string;
  tokenImage?: string;
  tokenName?: string;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetCollectionsActivityV6ResponseActivitiesItemPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetCollectionsActivityV6ResponseActivitiesItemPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetCollectionsActivityV6ResponseActivitiesItemPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

/**
 * Return native currency unless displayCurrency contract was passed.
 */
export interface GetCollectionsActivityV6ResponseActivitiesItemPrice {
  amount?: GetCollectionsActivityV6ResponseActivitiesItemPriceAmount;
  currency?: GetCollectionsActivityV6ResponseActivitiesItemPriceCurrency;
  netAmount?: GetCollectionsActivityV6ResponseActivitiesItemPriceNetAmount;
}

export interface GetCollectionsActivityV6ResponseActivitiesItem {
  amount?: number;
  batchIndex?: number;
  collection?: GetCollectionsActivityV6ResponseActivitiesItemCollection;
  contract?: string;
  /** Time when added in the indexer. */
  createdAt?: string;
  fillSource?: GetCollectionsActivityV6ResponseActivitiesItemFillSource;
  fromAddress?: string;
  logIndex?: number;
  order?: GetCollectionsActivityV6ResponseActivitiesItemOrder;
  price?: GetCollectionsActivityV6ResponseActivitiesItemPrice;
  /** Time when added on the blockchain. */
  timestamp?: number;
  toAddress?: string;
  token?: GetCollectionsActivityV6ResponseActivitiesItemToken;
  /** Txn hash from the blockchain. */
  txHash?: string;
  /** Possible types returned: `ask`, `ask_cancel`, `bid`, `bid_cancel`, `sale`, `mint, and `transfer`. */
  type?: string;
}

export interface GetChainStatsV1ResponseStats7Day {
  mintCount?: number;
  mintVolume?: number;
  saleCount?: number;
  saleVolume?: number;
  totalCount?: number;
  totalVolume?: number;
}

export interface GetChainStatsV1ResponseStats1Day {
  mintCount?: number;
  mintVolume?: number;
  saleCount?: number;
  saleVolume?: number;
  totalCount?: number;
  totalVolume?: number;
}

export interface GetChainStatsV1ResponseStats {
  '1day'?: GetChainStatsV1ResponseStats1Day;
  '7day'?: GetChainStatsV1ResponseStats7Day;
}

export interface GetChainStatsV1Response {
  stats?: GetChainStatsV1ResponseStats;
}

export interface GetAdminGetApiKeyKeyResponsePermissions { [key: string]: any }

export type GetAdminGetApiKeyKeyResponseOrigins = string[];

export type GetAdminGetApiKeyKeyResponseIps = string[];

export interface GetAdminGetApiKeyKeyResponse {
  active?: boolean;
  appName?: string;
  createdAt?: string;
  email?: string;
  ips?: GetAdminGetApiKeyKeyResponseIps;
  key?: string;
  origins?: GetAdminGetApiKeyKeyResponseOrigins;
  permissions?: GetAdminGetApiKeyKeyResponsePermissions;
  revShareBps?: number;
  website?: string;
}

export interface GetTransfersV4Response {
  continuation?: string;
  transfers?: GetTransfersV4ResponseTransfers;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetTransfersV4ResponseTransfersItemPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetTransfersV4ResponseTransfersItemPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetTransfersV4ResponseTransfersItemPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetTransfersV4ResponseTransfersItemPrice {
  amount?: GetTransfersV4ResponseTransfersItemPriceAmount;
  currency?: GetTransfersV4ResponseTransfersItemPriceCurrency;
  netAmount?: GetTransfersV4ResponseTransfersItemPriceNetAmount;
}

export interface GetTransfersV4ResponseTransfersItemTokenCollection {
  id?: string;
  name?: string;
}

export interface GetTransfersV4ResponseTransfersItemToken {
  collection?: GetTransfersV4ResponseTransfersItemTokenCollection;
  contract?: string;
  image?: string;
  name?: string;
  tokenId?: string;
}

export interface GetTransfersV4ResponseTransfersItem {
  /** Can be higher than 1 if erc1155. */
  amount?: string;
  batchIndex?: number;
  block?: number;
  from?: string;
  isDeleted?: boolean;
  logIndex?: number;
  price?: GetTransfersV4ResponseTransfersItemPrice;
  timestamp?: number;
  to?: string;
  token?: GetTransfersV4ResponseTransfersItemToken;
  txHash?: string;
  /** Time when last updated in indexer */
  updatedAt?: string;
}

export type GetTransfersV4ResponseTransfers = GetTransfersV4ResponseTransfersItem[];

export interface GetTokensV6ResponseTokensItemMarketTopBid {
  feeBreakdown?: GetTokensV6ResponseTokensItemMarketTopBidFeeBreakdown;
  id?: string;
  maker?: string;
  price?: GetTokensV6ResponseTokensItemMarketTopBidPrice;
  source?: GetTokensV6ResponseTokensItemMarketTopBidSource;
  validFrom?: number;
  validUntil?: number;
}

export interface GetTokensV6ResponseTokensItemMarket {
  floorAsk?: GetTokensV6ResponseTokensItemMarketFloorAsk;
  topBid?: GetTokensV6ResponseTokensItemMarketTopBid;
}

export interface GetTokensV6ResponseTokensItem {
  market?: GetTokensV6ResponseTokensItemMarket;
  token?: GetTokensV6ResponseTokensItemToken;
  updatedAt?: string;
}

export type GetTokensV6ResponseTokens = GetTokensV6ResponseTokensItem[];

export interface GetTokensV6Response {
  continuation?: string;
  tokens?: GetTokensV6ResponseTokens;
}

export interface GetTokensV6ResponseTokensItemMarketTopBidFeeBreakdownItem {
  bps?: number;
  /** Can be `marketplace` or `royalty`. */
  kind?: string;
  recipient?: string;
}

/**
 * Can be null if no active bids
 */
export type GetTokensV6ResponseTokensItemMarketTopBidFeeBreakdown = GetTokensV6ResponseTokensItemMarketTopBidFeeBreakdownItem[];

export interface GetTokensV6ResponseTokensItemMarketTopBidSource {
  domain?: string;
  icon?: string;
  id?: string;
  name?: string;
  url?: string;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetTokensV6ResponseTokensItemMarketTopBidPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetTokensV6ResponseTokensItemMarketTopBidPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetTokensV6ResponseTokensItemMarketTopBidPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetTokensV6ResponseTokensItemMarketTopBidPrice {
  amount?: GetTokensV6ResponseTokensItemMarketTopBidPriceAmount;
  currency?: GetTokensV6ResponseTokensItemMarketTopBidPriceCurrency;
  netAmount?: GetTokensV6ResponseTokensItemMarketTopBidPriceNetAmount;
}

export interface GetTokensV6ResponseTokensItemMarketFloorAskSource {
  domain?: string;
  icon?: string;
  id?: string;
  name?: string;
  url?: string;
}

export interface GetTokensV6ResponseTokensItemMarketFloorAsk {
  dynamicPricing?: GetTokensV6ResponseTokensItemMarketFloorAskDynamicPricing;
  id?: string;
  maker?: string;
  price?: GetTokensV6ResponseTokensItemMarketFloorAskPrice;
  quantityFilled?: number;
  quantityRemaining?: number;
  source?: GetTokensV6ResponseTokensItemMarketFloorAskSource;
  validFrom?: number;
  validUntil?: number;
}

export interface GetTokensV6ResponseTokensItemMarketFloorAskDynamicPricingData { [key: string]: any }

export type GetTokensV6ResponseTokensItemMarketFloorAskDynamicPricingKind = typeof GetTokensV6ResponseTokensItemMarketFloorAskDynamicPricingKind[keyof typeof GetTokensV6ResponseTokensItemMarketFloorAskDynamicPricingKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensV6ResponseTokensItemMarketFloorAskDynamicPricingKind = {
  dutch: 'dutch',
  pool: 'pool',
} as const;

/**
 * Can be null if no active ask.
 */
export interface GetTokensV6ResponseTokensItemMarketFloorAskDynamicPricing {
  data?: GetTokensV6ResponseTokensItemMarketFloorAskDynamicPricingData;
  kind?: GetTokensV6ResponseTokensItemMarketFloorAskDynamicPricingKind;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetTokensV6ResponseTokensItemMarketFloorAskPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetTokensV6ResponseTokensItemMarketFloorAskPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetTokensV6ResponseTokensItemMarketFloorAskPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetTokensV6ResponseTokensItemMarketFloorAskPrice {
  amount?: GetTokensV6ResponseTokensItemMarketFloorAskPriceAmount;
  currency?: GetTokensV6ResponseTokensItemMarketFloorAskPriceCurrency;
  netAmount?: GetTokensV6ResponseTokensItemMarketFloorAskPriceNetAmount;
}

export interface GetTokensV6ResponseTokensItemTokenMintStagesItem {
  endTime?: number;
  kind: string;
  maxMintsPerWallet?: number;
  price?: GetTokensV6ResponseTokensItemTokenMintStagesItemPrice;
  stage: string;
  startTime?: number;
  tokenId?: string;
}

export type GetTokensV6ResponseTokensItemTokenMintStages = GetTokensV6ResponseTokensItemTokenMintStagesItem[];

export interface GetTokensV6ResponseTokensItemToken {
  attributes?: GetTokensV6ResponseTokensItemTokenAttributes;
  chainId: number;
  collection?: GetTokensV6ResponseTokensItemTokenCollection;
  contract: string;
  description?: string;
  image?: string;
  imageLarge?: string;
  imageMedium?: string;
  imageSmall?: string;
  isFlagged?: boolean;
  isSpam?: boolean;
  /** Can be erc721, erc115, etc. */
  kind?: string;
  lastFlagChange?: string;
  lastFlagUpdate?: string;
  lastSale?: GetTokensV6ResponseTokensItemTokenLastSale;
  media?: string;
  metadata?: GetTokensV6ResponseTokensItemTokenMetadata;
  metadataDisabled?: boolean;
  mintedAt?: string;
  mintStages?: GetTokensV6ResponseTokensItemTokenMintStages;
  name?: string;
  owner?: string;
  /** No rarity for collections over 100k */
  rarity?: number;
  /** No rarity rank for collections over 100k */
  rarityRank?: number;
  remainingSupply?: number;
  /** Can be higher than 1 if erc1155 */
  supply?: number;
  tokenId: string;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetTokensV6ResponseTokensItemTokenMintStagesItemPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetTokensV6ResponseTokensItemTokenMintStagesItemPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetTokensV6ResponseTokensItemTokenMintStagesItemPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetTokensV6ResponseTokensItemTokenMintStagesItemPrice {
  amount?: GetTokensV6ResponseTokensItemTokenMintStagesItemPriceAmount;
  currency?: GetTokensV6ResponseTokensItemTokenMintStagesItemPriceCurrency;
  netAmount?: GetTokensV6ResponseTokensItemTokenMintStagesItemPriceNetAmount;
}

export interface GetTokensV6ResponseTokensItemTokenAttributesItem {
  createdAt?: string;
  floorAskPrice?: number;
  /** Case sensitive. */
  key?: string;
  /** Can be `string`, `number`, `date`, or `range`. */
  kind?: string;
  onSaleCount?: number;
  tokenCount?: number;
  topBidValue?: number;
  /** Case sensitive. */
  value: string;
}

export type GetTokensV6ResponseTokensItemTokenAttributes = GetTokensV6ResponseTokensItemTokenAttributesItem[];

export interface GetTokensV6ResponseTokensItemTokenLastSaleFeeBreakdownItem {
  bps?: number;
  kind?: string;
  rawAmount?: string;
  recipient?: string;
  source?: string;
}

/**
 * `kind` can be `marketplace` or `royalty`
 */
export type GetTokensV6ResponseTokensItemTokenLastSaleFeeBreakdown = GetTokensV6ResponseTokensItemTokenLastSaleFeeBreakdownItem[];

export interface GetTokensV6ResponseTokensItemTokenLastSale {
  amount?: string;
  batchIndex?: number;
  block?: number;
  /** Time when added to indexer */
  createdAt?: string;
  feeBreakdown?: GetTokensV6ResponseTokensItemTokenLastSaleFeeBreakdown;
  fillSource?: string;
  from?: string;
  /** Deprecated. Use `saleId` instead. */
  id?: string;
  isDeleted?: boolean;
  logIndex?: number;
  marketplaceFeeBps?: number;
  orderId?: string;
  orderKind?: string;
  orderSide?: GetTokensV6ResponseTokensItemTokenLastSaleOrderSide;
  orderSource?: string;
  paidFullRoyalty?: boolean;
  price?: GetTokensV6ResponseTokensItemTokenLastSalePrice;
  royaltyFeeBps?: number;
  /** Unique identifier made from txn hash, price, etc. */
  saleId?: string;
  /** Time added on the blockchain */
  timestamp?: number;
  to?: string;
  token?: GetTokensV6ResponseTokensItemTokenLastSaleToken;
  txHash?: string;
  /** Time when updated in indexer */
  updatedAt?: string;
  washTradingScore?: number;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetTokensV6ResponseTokensItemTokenLastSalePriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetTokensV6ResponseTokensItemTokenLastSalePriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetTokensV6ResponseTokensItemTokenLastSalePriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetTokensV6ResponseTokensItemTokenLastSalePrice {
  amount?: GetTokensV6ResponseTokensItemTokenLastSalePriceAmount;
  currency?: GetTokensV6ResponseTokensItemTokenLastSalePriceCurrency;
  netAmount?: GetTokensV6ResponseTokensItemTokenLastSalePriceNetAmount;
}

/**
 * Can be `ask` or `bid`.
 */
export type GetTokensV6ResponseTokensItemTokenLastSaleOrderSide = typeof GetTokensV6ResponseTokensItemTokenLastSaleOrderSide[keyof typeof GetTokensV6ResponseTokensItemTokenLastSaleOrderSide];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensV6ResponseTokensItemTokenLastSaleOrderSide = {
  ask: 'ask',
  bid: 'bid',
} as const;

export interface GetTokensV6ResponseTokensItemTokenLastSaleTokenCollection {
  id?: string;
  name?: string;
}

export interface GetTokensV6ResponseTokensItemTokenLastSaleToken {
  collection?: GetTokensV6ResponseTokensItemTokenLastSaleTokenCollection;
  contract?: string;
  image?: string;
  name?: string;
  tokenId?: string;
}

export type GetTokensV6ResponseTokensItemTokenCollectionVerificationStatus = typeof GetTokensV6ResponseTokensItemTokenCollectionVerificationStatus[keyof typeof GetTokensV6ResponseTokensItemTokenCollectionVerificationStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensV6ResponseTokensItemTokenCollectionVerificationStatus = {
  verified: 'verified',
  unverified: 'unverified',
} as const;

export interface GetTokensV6ResponseTokensItemTokenCollection {
  creator?: string;
  id?: string;
  image?: string;
  metadataDisabled?: boolean;
  name?: string;
  slug?: string;
  symbol?: string;
  tokenCount?: number;
  verificationStatus?: GetTokensV6ResponseTokensItemTokenCollectionVerificationStatus;
}

export interface GetTokensV6ResponseTokensItemTokenMetadata { [key: string]: any }

export interface GetStatsV2ResponseStats {
  flaggedTokenCount: number;
  market?: GetStatsV2ResponseStatsMarket;
  onSaleCount: number;
  sampleImages?: GetStatsV2ResponseStatsSampleImages;
  tokenCount: number;
}

export interface GetStatsV2Response {
  stats?: GetStatsV2ResponseStats;
}

/**
 * Can be null is not active bids
 */
export interface GetStatsV2ResponseStatsMarketTopBid {
  id?: string;
  maker?: string;
  price?: GetStatsV2ResponseStatsMarketTopBidPrice;
  validFrom?: number;
  validUntil?: number;
}

export interface GetStatsV2ResponseStatsMarket {
  floorAsk?: GetStatsV2ResponseStatsMarketFloorAsk;
  topBid?: GetStatsV2ResponseStatsMarketTopBid;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetStatsV2ResponseStatsMarketTopBidPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetStatsV2ResponseStatsMarketTopBidPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetStatsV2ResponseStatsMarketTopBidPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetStatsV2ResponseStatsMarketTopBidPrice {
  amount?: GetStatsV2ResponseStatsMarketTopBidPriceAmount;
  currency?: GetStatsV2ResponseStatsMarketTopBidPriceCurrency;
  netAmount?: GetStatsV2ResponseStatsMarketTopBidPriceNetAmount;
}

/**
 * Can be null if no active asks.
 */
export interface GetStatsV2ResponseStatsMarketFloorAskToken {
  contract?: string;
  image?: string;
  name?: string;
  tokenId?: string;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetStatsV2ResponseStatsMarketFloorAskPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetStatsV2ResponseStatsMarketFloorAskPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetStatsV2ResponseStatsMarketFloorAskPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetStatsV2ResponseStatsMarketFloorAskPrice {
  amount?: GetStatsV2ResponseStatsMarketFloorAskPriceAmount;
  currency?: GetStatsV2ResponseStatsMarketFloorAskPriceCurrency;
  netAmount?: GetStatsV2ResponseStatsMarketFloorAskPriceNetAmount;
}

export interface GetStatsV2ResponseStatsMarketFloorAsk {
  id?: string;
  maker?: string;
  price?: GetStatsV2ResponseStatsMarketFloorAskPrice;
  token?: GetStatsV2ResponseStatsMarketFloorAskToken;
  validFrom?: number;
  validUntil?: number;
}

export type GetStatsV2ResponseStatsSampleImages = string[];

export interface GetSourcesV1ResponseSourcesItem {
  description?: string;
  domain?: string;
  icon?: string;
  id?: string;
  name?: string;
  socialImage?: string;
  tokenUrl?: string;
  twitterUsername?: string;
}

export type GetSourcesV1ResponseSources = GetSourcesV1ResponseSourcesItem[];

export interface GetSourcesV1Response {
  continuation?: string;
  sources?: GetSourcesV1ResponseSources;
}

export type GetSalesV6ResponseSales = GetSalesV6ResponseSalesItem[];

export interface GetSalesV6Response {
  continuation?: string;
  sales?: GetSalesV6ResponseSales;
}

export interface GetSalesV6ResponseSalesItemFeeBreakdownItem {
  bps?: number;
  kind?: string;
  rawAmount?: string;
  recipient?: string;
  source?: string;
}

/**
 * `kind` can be `marketplace` or `royalty`
 */
export type GetSalesV6ResponseSalesItemFeeBreakdown = GetSalesV6ResponseSalesItemFeeBreakdownItem[];

/**
 * Amount with fees & royalties removed.
 */
export interface GetSalesV6ResponseSalesItemPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetSalesV6ResponseSalesItemPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetSalesV6ResponseSalesItemPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetSalesV6ResponseSalesItemPrice {
  amount?: GetSalesV6ResponseSalesItemPriceAmount;
  currency?: GetSalesV6ResponseSalesItemPriceCurrency;
  netAmount?: GetSalesV6ResponseSalesItemPriceNetAmount;
}

/**
 * Can be `ask` or `bid`.
 */
export type GetSalesV6ResponseSalesItemOrderSide = typeof GetSalesV6ResponseSalesItemOrderSide[keyof typeof GetSalesV6ResponseSalesItemOrderSide];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSalesV6ResponseSalesItemOrderSide = {
  ask: 'ask',
  bid: 'bid',
} as const;

export interface GetSalesV6ResponseSalesItemTokenCollection {
  id?: string;
  name?: string;
}

export interface GetSalesV6ResponseSalesItemToken {
  collection?: GetSalesV6ResponseSalesItemTokenCollection;
  contract?: string;
  image?: string;
  name?: string;
  tokenId?: string;
}

export interface GetSalesV6ResponseSalesItem {
  amount?: string;
  batchIndex?: number;
  block?: number;
  /** Time when added to indexer */
  createdAt?: string;
  feeBreakdown?: GetSalesV6ResponseSalesItemFeeBreakdown;
  fillSource?: string;
  from?: string;
  /** Deprecated. Use `saleId` instead. */
  id?: string;
  isDeleted?: boolean;
  logIndex?: number;
  marketplaceFeeBps?: number;
  orderId?: string;
  orderKind?: string;
  orderSide?: GetSalesV6ResponseSalesItemOrderSide;
  orderSource?: string;
  paidFullRoyalty?: boolean;
  price?: GetSalesV6ResponseSalesItemPrice;
  royaltyFeeBps?: number;
  /** Unique identifier made from txn hash, price, etc. */
  saleId?: string;
  /** Time added on the blockchain */
  timestamp?: number;
  to?: string;
  token?: GetSalesV6ResponseSalesItemToken;
  txHash?: string;
  /** Time when updated in indexer */
  updatedAt?: string;
  washTradingScore?: number;
}

export interface GetOwnersV2ResponseOwnersItemOwnership {
  floorAskPrice?: GetOwnersV2ResponseOwnersItemOwnershipFloorAskPrice;
  onSaleCount?: string;
  tokenCount?: string;
  topBidValue?: GetOwnersV2ResponseOwnersItemOwnershipTopBidValue;
  totalBidValue?: GetOwnersV2ResponseOwnersItemOwnershipTotalBidValue;
}

export interface GetOwnersV2ResponseOwnersItem {
  address?: string;
  ownership?: GetOwnersV2ResponseOwnersItemOwnership;
}

export type GetOwnersV2ResponseOwners = GetOwnersV2ResponseOwnersItem[];

export interface GetOwnersV2Response {
  owners?: GetOwnersV2ResponseOwners;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetOwnersV2ResponseOwnersItemOwnershipTotalBidValueNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetOwnersV2ResponseOwnersItemOwnershipTotalBidValueAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetOwnersV2ResponseOwnersItemOwnershipTotalBidValueCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

/**
 * Can return `null` if there are no bids
 */
export interface GetOwnersV2ResponseOwnersItemOwnershipTotalBidValue {
  amount?: GetOwnersV2ResponseOwnersItemOwnershipTotalBidValueAmount;
  currency?: GetOwnersV2ResponseOwnersItemOwnershipTotalBidValueCurrency;
  netAmount?: GetOwnersV2ResponseOwnersItemOwnershipTotalBidValueNetAmount;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetOwnersV2ResponseOwnersItemOwnershipTopBidValueNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetOwnersV2ResponseOwnersItemOwnershipTopBidValueAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetOwnersV2ResponseOwnersItemOwnershipTopBidValueCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

/**
 * Can return `null` if there are no bids
 */
export interface GetOwnersV2ResponseOwnersItemOwnershipTopBidValue {
  amount?: GetOwnersV2ResponseOwnersItemOwnershipTopBidValueAmount;
  currency?: GetOwnersV2ResponseOwnersItemOwnershipTopBidValueCurrency;
  netAmount?: GetOwnersV2ResponseOwnersItemOwnershipTopBidValueNetAmount;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetOwnersV2ResponseOwnersItemOwnershipFloorAskPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetOwnersV2ResponseOwnersItemOwnershipFloorAskPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetOwnersV2ResponseOwnersItemOwnershipFloorAskPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

/**
 * Can return `null` if there is no Floor Ask
 */
export interface GetOwnersV2ResponseOwnersItemOwnershipFloorAskPrice {
  amount?: GetOwnersV2ResponseOwnersItemOwnershipFloorAskPriceAmount;
  currency?: GetOwnersV2ResponseOwnersItemOwnershipFloorAskPriceCurrency;
  netAmount?: GetOwnersV2ResponseOwnersItemOwnershipFloorAskPriceNetAmount;
}

export interface GetOrdersV2Response {
  orders?: GetOrdersV2ResponseOrders;
}

export interface GetOrdersV2ResponseOrdersItemRawData { [key: string]: any }

export interface GetOrdersV2ResponseOrdersItemFeeBreakdownItem {
  bps?: number;
  kind?: string;
  recipient?: string;
}

export type GetOrdersV2ResponseOrdersItemFeeBreakdown = GetOrdersV2ResponseOrdersItemFeeBreakdownItem[];

export type GetOrdersV2ResponseOrdersItemSide = typeof GetOrdersV2ResponseOrdersItemSide[keyof typeof GetOrdersV2ResponseOrdersItemSide];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersV2ResponseOrdersItemSide = {
  buy: 'buy',
  sell: 'sell',
} as const;

export interface GetOrdersV2ResponseOrdersItem {
  approvalStatus: string;
  createdAt: string;
  expiration: number;
  feeBps?: number;
  feeBreakdown?: GetOrdersV2ResponseOrdersItemFeeBreakdown;
  fillabilityStatus: string;
  id: string;
  kind: string;
  maker: string;
  price: number;
  rawData?: GetOrdersV2ResponseOrdersItemRawData;
  side: GetOrdersV2ResponseOrdersItemSide;
  source?: string;
  taker: string;
  tokenSetId: string;
  tokenSetSchemaHash: string;
  updatedAt: string;
  validFrom: number;
  validUntil: number;
  value: number;
}

export type GetOrdersV2ResponseOrders = GetOrdersV2ResponseOrdersItem[];

export interface GetCrossPostingOrdersV1ResponseOrdersItem {
  /** Time when added to indexer */
  createdAt: string;
  id: number;
  orderbook: string;
  orderId: string;
  /** Possible values: pending - Waiting to be submitted. posted - Successfully submitted to the marketplace. posted - Failed to be submitted to the marketplace (see statusReason for detail). */
  status: string;
  statusReason: string;
  /** Time when updated in indexer */
  updatedAt: string;
}

export type GetCrossPostingOrdersV1ResponseOrders = GetCrossPostingOrdersV1ResponseOrdersItem[];

export interface GetCrossPostingOrdersV1Response {
  continuation?: string;
  orders?: GetCrossPostingOrdersV1ResponseOrders;
}

export type GetCollectionsV7ResponseCollections = GetCollectionsV7ResponseCollectionsItem[];

export interface GetCollectionsV7Response {
  collections?: GetCollectionsV7ResponseCollections;
  continuation?: string;
}

export type GetCollectionsV7ResponseCollectionsItemSecurityConfigReceiverAllowList = string[];

export type GetCollectionsV7ResponseCollectionsItemSecurityConfigOperatorWhitelist = string[];

export interface GetCollectionsV7ResponseCollectionsItemSecurityConfig {
  operatorWhitelist?: GetCollectionsV7ResponseCollectionsItemSecurityConfigOperatorWhitelist;
  receiverAllowList?: GetCollectionsV7ResponseCollectionsItemSecurityConfigReceiverAllowList;
  transferSecurityLevel?: number;
  transferValidator?: string;
}

export interface GetCollectionsV7ResponseCollectionsItemMintStagesItem {
  endTime?: number;
  kind: string;
  maxMintsPerWallet?: number;
  price?: GetCollectionsV7ResponseCollectionsItemMintStagesItemPrice;
  stage: string;
  startTime?: number;
  tokenId?: string;
}

export type GetCollectionsV7ResponseCollectionsItemMintStages = GetCollectionsV7ResponseCollectionsItemMintStagesItem[];

/**
 * Amount with fees & royalties removed.
 */
export interface GetCollectionsV7ResponseCollectionsItemMintStagesItemPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetCollectionsV7ResponseCollectionsItemMintStagesItemPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetCollectionsV7ResponseCollectionsItemMintStagesItemPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetCollectionsV7ResponseCollectionsItemMintStagesItemPrice {
  amount?: GetCollectionsV7ResponseCollectionsItemMintStagesItemPriceAmount;
  currency?: GetCollectionsV7ResponseCollectionsItemMintStagesItemPriceCurrency;
  netAmount?: GetCollectionsV7ResponseCollectionsItemMintStagesItemPriceNetAmount;
}

export interface GetCollectionsV7ResponseCollectionsItemAttributesItem {
  count?: number;
  /** Case sensitive */
  key?: string;
  /** `string`, `number`, `date`, or `range` */
  kind?: string;
}

export type GetCollectionsV7ResponseCollectionsItemAttributes = GetCollectionsV7ResponseCollectionsItemAttributesItem[];

/**
 * Number of sales of X-days period
 */
export interface GetCollectionsV7ResponseCollectionsItemSalesCount {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
  allTime?: number;
}

/**
 * Floor sale change from X-days vs X-days ago. (e.g. 7day floor sale vs floor sale 14 days ago). A value over 1 is a positive gain, under 1 is a negative loss. e.g. 1 means no change; 1.1 means 10% increase; 0.9 means 10% decrease.
 */
export interface GetCollectionsV7ResponseCollectionsItemFloorSaleChange {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
}

/**
 * The floor sale from X-days ago.
 */
export interface GetCollectionsV7ResponseCollectionsItemFloorSale {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
}

/**
 * Total volume change X-days vs previous X-days. (e.g. 7day [days 1-7] vs 7day prior [days 8-14]). A value over 1 is a positive gain, under 1 is a negative loss. e.g. 1 means no change; 1.1 means 10% increase; 0.9 means 10% decrease.
 */
export interface GetCollectionsV7ResponseCollectionsItemVolumeChange {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
}

/**
 * Total volume in given time period.
 */
export interface GetCollectionsV7ResponseCollectionsItemVolume {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
  allTime?: number;
}

/**
 * Current rank based from overall volume
 */
export interface GetCollectionsV7ResponseCollectionsItemRank {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
  allTime?: number;
}

/**
 * Highest current offer
 */
export interface GetCollectionsV7ResponseCollectionsItemTopBid {
  id?: string;
  maker?: string;
  price?: GetCollectionsV7ResponseCollectionsItemTopBidPrice;
  sourceDomain?: string;
  validFrom?: number;
  validUntil?: number;
}

export interface GetCollectionsV7ResponseCollectionsItem {
  allRoyalties?: GetCollectionsV7ResponseCollectionsItemAllRoyalties;
  attributes?: GetCollectionsV7ResponseCollectionsItemAttributes;
  banner?: string;
  chainId: number;
  /** true or false */
  collectionBidSupported?: boolean;
  /** Time when contract was deployed */
  contractDeployedAt?: string;
  /** Returns `erc721`, `erc1155`, etc. */
  contractKind?: string;
  /** Time when added to indexer */
  createdAt?: string;
  creator?: string;
  description?: string;
  discordUrl?: string;
  externalUrl?: string;
  floorAsk?: GetCollectionsV7ResponseCollectionsItemFloorAsk;
  floorSale?: GetCollectionsV7ResponseCollectionsItemFloorSale;
  floorSaleChange?: GetCollectionsV7ResponseCollectionsItemFloorSaleChange;
  /** Collection id */
  id?: string;
  image?: string;
  isSpam?: boolean;
  metadataDisabled?: boolean;
  mintedTimestamp?: number;
  mintStages?: GetCollectionsV7ResponseCollectionsItemMintStages;
  name?: string;
  /** Total tokens currently on sale. */
  onSaleCount?: string;
  openseaVerificationStatus?: string;
  /** Unique number of owners. */
  ownerCount?: number;
  primaryContract?: string;
  rank?: GetCollectionsV7ResponseCollectionsItemRank;
  royalties?: GetCollectionsV7ResponseCollectionsItemRoyalties;
  salesCount?: GetCollectionsV7ResponseCollectionsItemSalesCount;
  sampleImages?: GetCollectionsV7ResponseCollectionsItemSampleImages;
  securityConfig?: GetCollectionsV7ResponseCollectionsItemSecurityConfig;
  /** Open Sea slug */
  slug?: string;
  symbol?: string;
  /** Total tokens within the collection. */
  tokenCount?: string;
  tokenSetId?: string;
  topBid?: GetCollectionsV7ResponseCollectionsItemTopBid;
  twitterUrl?: string;
  twitterUsername?: string;
  /** Time when updated in indexer */
  updatedAt?: string;
  verificationStatus?: GetCollectionsV7ResponseCollectionsItemVerificationStatus;
  volume?: GetCollectionsV7ResponseCollectionsItemVolume;
  volumeChange?: GetCollectionsV7ResponseCollectionsItemVolumeChange;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetCollectionsV7ResponseCollectionsItemTopBidPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetCollectionsV7ResponseCollectionsItemTopBidPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetCollectionsV7ResponseCollectionsItemTopBidPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetCollectionsV7ResponseCollectionsItemTopBidPrice {
  amount?: GetCollectionsV7ResponseCollectionsItemTopBidPriceAmount;
  currency?: GetCollectionsV7ResponseCollectionsItemTopBidPriceCurrency;
  netAmount?: GetCollectionsV7ResponseCollectionsItemTopBidPriceNetAmount;
}

/**
 * Lowest Ask Price.
 */
export interface GetCollectionsV7ResponseCollectionsItemFloorAskToken {
  contract?: string;
  image?: string;
  name?: string;
  tokenId?: string;
}

export interface GetCollectionsV7ResponseCollectionsItemFloorAsk {
  id?: string;
  maker?: string;
  price?: GetCollectionsV7ResponseCollectionsItemFloorAskPrice;
  sourceDomain?: string;
  token?: GetCollectionsV7ResponseCollectionsItemFloorAskToken;
  validFrom?: number;
  validUntil?: number;
}

/**
 * Amount with fees & royalties removed.
 */
export interface GetCollectionsV7ResponseCollectionsItemFloorAskPriceNetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface GetCollectionsV7ResponseCollectionsItemFloorAskPriceAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface GetCollectionsV7ResponseCollectionsItemFloorAskPriceCurrency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface GetCollectionsV7ResponseCollectionsItemFloorAskPrice {
  amount?: GetCollectionsV7ResponseCollectionsItemFloorAskPriceAmount;
  currency?: GetCollectionsV7ResponseCollectionsItemFloorAskPriceCurrency;
  netAmount?: GetCollectionsV7ResponseCollectionsItemFloorAskPriceNetAmount;
}

export interface GetCollectionsV7ResponseCollectionsItemAllRoyalties { [key: string]: any }

export interface GetCollectionsV7ResponseCollectionsItemRoyaltiesBreakdownItem {
  bps?: number;
  recipient?: string;
}

export type GetCollectionsV7ResponseCollectionsItemRoyaltiesBreakdown = GetCollectionsV7ResponseCollectionsItemRoyaltiesBreakdownItem[];

export interface GetCollectionsV7ResponseCollectionsItemRoyalties {
  bps?: number;
  breakdown?: GetCollectionsV7ResponseCollectionsItemRoyaltiesBreakdown;
  recipient?: string;
}

export type GetCollectionsV7ResponseCollectionsItemSampleImages = string[];

export type GetCollectionsV7ResponseCollectionsItemVerificationStatus = typeof GetCollectionsV7ResponseCollectionsItemVerificationStatus[keyof typeof GetCollectionsV7ResponseCollectionsItemVerificationStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsV7ResponseCollectionsItemVerificationStatus = {
  verified: 'verified',
  unverified: 'unverified',
} as const;

export interface GetCollectionV3ResponseCollectionAttributesItem {
  count?: number;
  key?: string;
  kind?: string;
}

export type GetCollectionV3ResponseCollectionAttributes = GetCollectionV3ResponseCollectionAttributesItem[];

export interface GetCollectionV3ResponseCollectionFloorSaleChange {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
}

export interface GetCollectionV3ResponseCollectionFloorSale {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
}

export interface GetCollectionV3ResponseCollectionVolumeChange {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
}

export interface GetCollectionV3ResponseCollectionVolume {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
  allTime?: number;
}

export interface GetCollectionV3ResponseCollectionRank {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
  allTime?: number;
}

export interface GetCollectionV3ResponseCollectionTopBid {
  id?: string;
  maker?: string;
  validFrom?: number;
  validUntil?: number;
  value?: number;
}

export interface GetCollectionV3ResponseCollectionFloorAskToken {
  contract?: string;
  image?: string;
  name?: string;
  tokenId?: string;
}

export interface GetCollectionV3ResponseCollectionFloorAsk {
  id?: string;
  maker?: string;
  price?: number;
  sourceDomain?: string;
  token?: GetCollectionV3ResponseCollectionFloorAskToken;
  validFrom?: number;
  validUntil?: number;
}

export interface GetCollectionV3ResponseCollectionLastBuy {
  timestamp?: number;
  value?: number;
}

export interface GetCollectionV3ResponseCollectionRoyalties {
  bps?: number;
  recipient?: string;
}

export type GetCollectionV3ResponseCollectionSampleImages = string[];

export interface GetCollectionV3ResponseCollectionMetadata { [key: string]: any }

export interface GetCollectionV3ResponseCollection {
  attributes?: GetCollectionV3ResponseCollectionAttributes;
  collectionBidSupported?: boolean;
  floorAsk?: GetCollectionV3ResponseCollectionFloorAsk;
  floorSale?: GetCollectionV3ResponseCollectionFloorSale;
  floorSaleChange?: GetCollectionV3ResponseCollectionFloorSaleChange;
  id?: string;
  lastBuy?: GetCollectionV3ResponseCollectionLastBuy;
  metadata?: GetCollectionV3ResponseCollectionMetadata;
  name?: string;
  onSaleCount?: string;
  ownerCount?: number;
  primaryContract?: string;
  rank?: GetCollectionV3ResponseCollectionRank;
  royalties?: GetCollectionV3ResponseCollectionRoyalties;
  sampleImages?: GetCollectionV3ResponseCollectionSampleImages;
  /** Open Sea slug */
  slug?: string;
  tokenCount?: string;
  tokenSetId?: string;
  topBid?: GetCollectionV3ResponseCollectionTopBid;
  volume?: GetCollectionV3ResponseCollectionVolume;
  volumeChange?: GetCollectionV3ResponseCollectionVolumeChange;
}

export interface GetCollectionV3Response {
  collection?: GetCollectionV3ResponseCollection;
}

export type GetAttributesV1ResponseAttributes = GetAttributesV1ResponseAttributesItem[];

export interface GetAttributesV1Response {
  attributes?: GetAttributesV1ResponseAttributes;
}

export interface GetAttributesV1ResponseAttributesItemValuesItem {
  count?: number;
  value: string;
}

export type GetAttributesV1ResponseAttributesItemValues = GetAttributesV1ResponseAttributesItemValuesItem[];

export type GetAttributesV1ResponseAttributesItemKind = typeof GetAttributesV1ResponseAttributesItemKind[keyof typeof GetAttributesV1ResponseAttributesItemKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAttributesV1ResponseAttributesItemKind = {
  string: 'string',
  number: 'number',
  date: 'date',
  range: 'range',
} as const;

export interface GetAttributesV1ResponseAttributesItem {
  key: string;
  kind: GetAttributesV1ResponseAttributesItemKind;
  values?: GetAttributesV1ResponseAttributesItemValues;
}

export interface GetAdminGetMarketplacesResponse {
  marketplaces?: GetAdminGetMarketplacesResponseMarketplaces;
}

export interface GetAdminGetMarketplacesResponseMarketplacesItemFee {
  bps?: number;
  percent?: number;
}

export interface GetAdminGetMarketplacesResponseMarketplacesItem {
  fee?: GetAdminGetMarketplacesResponseMarketplacesItemFee;
  feeBps?: number;
  imageUrl?: string;
  listingEnabled?: boolean;
  name?: string;
  orderbook?: string;
  orderKind?: string;
}

export type GetAdminGetMarketplacesResponseMarketplaces = GetAdminGetMarketplacesResponseMarketplacesItem[];

export interface GetAdminCollectionMetadataDetailsResponseCollectionsItem {
  /** Collection Id */
  id?: string;
  isSpam?: boolean;
  name?: string;
  percentageTotalTokens?: string;
  tokensMissingMetadata?: number;
  totalTokens?: number;
}

export type GetAdminCollectionMetadataDetailsResponseCollections = GetAdminCollectionMetadataDetailsResponseCollectionsItem[];

export interface GetAdminCollectionMetadataDetailsResponse {
  collections?: GetAdminCollectionMetadataDetailsResponseCollections;
}




type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * @summary Get metadata details for the given collections
 */
export const getAdminCollectionmetadatadetails = (
    params?: GetAdminCollectionmetadatadetailsParams,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetAdminCollectionMetadataDetailsResponse>(
      {url: `/admin/collection-metadata-details`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetAdminCollectionmetadatadetailsQueryKey = (params?: GetAdminCollectionmetadatadetailsParams,) => {
    return [`/admin/collection-metadata-details`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAdminCollectionmetadatadetailsInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, GetAdminCollectionmetadatadetailsParams['continuation']>, TError = ErrorType<unknown>>(params?: GetAdminCollectionmetadatadetailsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, TError, TData, Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, QueryKey, GetAdminCollectionmetadatadetailsParams['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAdminCollectionmetadatadetailsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, QueryKey, GetAdminCollectionmetadatadetailsParams['continuation']> = ({ signal, pageParam }) => getAdminCollectionmetadatadetails({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, TError, TData, Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, QueryKey, GetAdminCollectionmetadatadetailsParams['continuation']> & { queryKey: QueryKey }
}

export type GetAdminCollectionmetadatadetailsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>>
export type GetAdminCollectionmetadatadetailsInfiniteQueryError = ErrorType<unknown>

/**
 * @summary Get metadata details for the given collections
 */
export const useGetAdminCollectionmetadatadetailsInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, GetAdminCollectionmetadatadetailsParams['continuation']>, TError = ErrorType<unknown>>(
 params?: GetAdminCollectionmetadatadetailsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, TError, TData, Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, QueryKey, GetAdminCollectionmetadatadetailsParams['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAdminCollectionmetadatadetailsInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAdminCollectionmetadatadetailsQueryOptions = <TData = Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, TError = ErrorType<unknown>>(params?: GetAdminCollectionmetadatadetailsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAdminCollectionmetadatadetailsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>> = ({ signal }) => getAdminCollectionmetadatadetails(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAdminCollectionmetadatadetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>>
export type GetAdminCollectionmetadatadetailsQueryError = ErrorType<unknown>

/**
 * @summary Get metadata details for the given collections
 */
export const useGetAdminCollectionmetadatadetails = <TData = Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, TError = ErrorType<unknown>>(
 params?: GetAdminCollectionmetadatadetailsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAdminCollectionmetadatadetailsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get supported marketplaces
 */
export const getAdminGetmarketplaces = (
    
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetAdminGetMarketplacesResponse>(
      {url: `/admin/get-marketplaces`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAdminGetmarketplacesQueryKey = () => {
    return [`/admin/get-marketplaces`] as const;
    }

    
export const getGetAdminGetmarketplacesInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getAdminGetmarketplaces>>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminGetmarketplaces>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAdminGetmarketplacesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminGetmarketplaces>>> = ({ signal }) => getAdminGetmarketplaces(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminGetmarketplaces>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAdminGetmarketplacesInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminGetmarketplaces>>>
export type GetAdminGetmarketplacesInfiniteQueryError = ErrorType<unknown>

/**
 * @summary Get supported marketplaces
 */
export const useGetAdminGetmarketplacesInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getAdminGetmarketplaces>>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminGetmarketplaces>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAdminGetmarketplacesInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAdminGetmarketplacesQueryOptions = <TData = Awaited<ReturnType<typeof getAdminGetmarketplaces>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminGetmarketplaces>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAdminGetmarketplacesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminGetmarketplaces>>> = ({ signal }) => getAdminGetmarketplaces(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAdminGetmarketplaces>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAdminGetmarketplacesQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminGetmarketplaces>>>
export type GetAdminGetmarketplacesQueryError = ErrorType<unknown>

/**
 * @summary Get supported marketplaces
 */
export const useGetAdminGetmarketplaces = <TData = Awaited<ReturnType<typeof getAdminGetmarketplaces>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminGetmarketplaces>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAdminGetmarketplacesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get swagger json in OpenApi V3
 */
export const getAdminOpenapi = (
    
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<string>(
      {url: `/admin/open-api`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAdminOpenapiQueryKey = () => {
    return [`/admin/open-api`] as const;
    }

    
export const getGetAdminOpenapiInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getAdminOpenapi>>>, TError = ErrorType<string>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminOpenapi>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAdminOpenapiQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminOpenapi>>> = ({ signal }) => getAdminOpenapi(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminOpenapi>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAdminOpenapiInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminOpenapi>>>
export type GetAdminOpenapiInfiniteQueryError = ErrorType<string>

/**
 * @summary Get swagger json in OpenApi V3
 */
export const useGetAdminOpenapiInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getAdminOpenapi>>>, TError = ErrorType<string>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminOpenapi>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAdminOpenapiInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAdminOpenapiQueryOptions = <TData = Awaited<ReturnType<typeof getAdminOpenapi>>, TError = ErrorType<string>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminOpenapi>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAdminOpenapiQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminOpenapi>>> = ({ signal }) => getAdminOpenapi(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAdminOpenapi>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAdminOpenapiQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminOpenapi>>>
export type GetAdminOpenapiQueryError = ErrorType<string>

/**
 * @summary Get swagger json in OpenApi V3
 */
export const useGetAdminOpenapi = <TData = Awaited<ReturnType<typeof getAdminOpenapi>>, TError = ErrorType<string>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminOpenapi>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAdminOpenapiQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Return the asset based on the given param
 */
export const getAssetsV1 = (
    params: GetAssetsV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<string>(
      {url: `/assets/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetAssetsV1QueryKey = (params: GetAssetsV1Params,) => {
    return [`/assets/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAssetsV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getAssetsV1>>, GetAssetsV1Params['continuation']>, TError = ErrorType<string>>(params: GetAssetsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAssetsV1>>, TError, TData, Awaited<ReturnType<typeof getAssetsV1>>, QueryKey, GetAssetsV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAssetsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssetsV1>>, QueryKey, GetAssetsV1Params['continuation']> = ({ signal, pageParam }) => getAssetsV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAssetsV1>>, TError, TData, Awaited<ReturnType<typeof getAssetsV1>>, QueryKey, GetAssetsV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetAssetsV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getAssetsV1>>>
export type GetAssetsV1InfiniteQueryError = ErrorType<string>

/**
 * @summary Return the asset based on the given param
 */
export const useGetAssetsV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getAssetsV1>>, GetAssetsV1Params['continuation']>, TError = ErrorType<string>>(
 params: GetAssetsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAssetsV1>>, TError, TData, Awaited<ReturnType<typeof getAssetsV1>>, QueryKey, GetAssetsV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAssetsV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAssetsV1QueryOptions = <TData = Awaited<ReturnType<typeof getAssetsV1>>, TError = ErrorType<string>>(params: GetAssetsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssetsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAssetsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssetsV1>>> = ({ signal }) => getAssetsV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAssetsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAssetsV1QueryResult = NonNullable<Awaited<ReturnType<typeof getAssetsV1>>>
export type GetAssetsV1QueryError = ErrorType<string>

/**
 * @summary Return the asset based on the given param
 */
export const useGetAssetsV1 = <TData = Awaited<ReturnType<typeof getAssetsV1>>, TError = ErrorType<string>>(
 params: GetAssetsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssetsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAssetsV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @deprecated
 * @summary List of attributes
 */
export const getAttributesV1 = (
    params?: GetAttributesV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetAttributesV1Response>(
      {url: `/attributes/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetAttributesV1QueryKey = (params?: GetAttributesV1Params,) => {
    return [`/attributes/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAttributesV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getAttributesV1>>, GetAttributesV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetAttributesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAttributesV1>>, TError, TData, Awaited<ReturnType<typeof getAttributesV1>>, QueryKey, GetAttributesV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAttributesV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAttributesV1>>, QueryKey, GetAttributesV1Params['continuation']> = ({ signal, pageParam }) => getAttributesV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAttributesV1>>, TError, TData, Awaited<ReturnType<typeof getAttributesV1>>, QueryKey, GetAttributesV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetAttributesV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getAttributesV1>>>
export type GetAttributesV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary List of attributes
 */
export const useGetAttributesV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getAttributesV1>>, GetAttributesV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetAttributesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAttributesV1>>, TError, TData, Awaited<ReturnType<typeof getAttributesV1>>, QueryKey, GetAttributesV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAttributesV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAttributesV1QueryOptions = <TData = Awaited<ReturnType<typeof getAttributesV1>>, TError = ErrorType<unknown>>(params?: GetAttributesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAttributesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAttributesV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAttributesV1>>> = ({ signal }) => getAttributesV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAttributesV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAttributesV1QueryResult = NonNullable<Awaited<ReturnType<typeof getAttributesV1>>>
export type GetAttributesV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary List of attributes
 */
export const useGetAttributesV1 = <TData = Awaited<ReturnType<typeof getAttributesV1>>, TError = ErrorType<unknown>>(
 params?: GetAttributesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAttributesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAttributesV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get detailed information about a single collection, including real-time stats.
 * @deprecated
 * @summary Single Collection
 */
export const getCollectionV3 = (
    params?: GetCollectionV3Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionV3Response>(
      {url: `/collection/v3`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionV3QueryKey = (params?: GetCollectionV3Params,) => {
    return [`/collection/v3`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionV3InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionV3>>, GetCollectionV3Params['continuation']>, TError = ErrorType<unknown>>(params?: GetCollectionV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionV3>>, TError, TData, Awaited<ReturnType<typeof getCollectionV3>>, QueryKey, GetCollectionV3Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionV3>>, QueryKey, GetCollectionV3Params['continuation']> = ({ signal, pageParam }) => getCollectionV3({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionV3>>, TError, TData, Awaited<ReturnType<typeof getCollectionV3>>, QueryKey, GetCollectionV3Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionV3InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionV3>>>
export type GetCollectionV3InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Single Collection
 */
export const useGetCollectionV3Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionV3>>, GetCollectionV3Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetCollectionV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionV3>>, TError, TData, Awaited<ReturnType<typeof getCollectionV3>>, QueryKey, GetCollectionV3Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionV3InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionV3QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionV3>>, TError = ErrorType<unknown>>(params?: GetCollectionV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionV3>>> = ({ signal }) => getCollectionV3(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionV3QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionV3>>>
export type GetCollectionV3QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Single Collection
 */
export const useGetCollectionV3 = <TData = Awaited<ReturnType<typeof getCollectionV3>>, TError = ErrorType<unknown>>(
 params?: GetCollectionV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionV3QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Use this API to explore a collection's metadata and statistics (sales, volume, etc).
 * @summary Collections
 */
export const getCollectionsV7 = (
    params?: GetCollectionsV7Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsV7Response>(
      {url: `/collections/v7`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsV7QueryKey = (params?: GetCollectionsV7Params,) => {
    return [`/collections/v7`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsV7InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsV7>>, GetCollectionsV7Params['continuation']>, TError = ErrorType<unknown>>(params?: GetCollectionsV7Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsV7>>, TError, TData, Awaited<ReturnType<typeof getCollectionsV7>>, QueryKey, GetCollectionsV7Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsV7QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsV7>>, QueryKey, GetCollectionsV7Params['continuation']> = ({ signal, pageParam }) => getCollectionsV7({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsV7>>, TError, TData, Awaited<ReturnType<typeof getCollectionsV7>>, QueryKey, GetCollectionsV7Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsV7InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsV7>>>
export type GetCollectionsV7InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Collections
 */
export const useGetCollectionsV7Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsV7>>, GetCollectionsV7Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetCollectionsV7Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsV7>>, TError, TData, Awaited<ReturnType<typeof getCollectionsV7>>, QueryKey, GetCollectionsV7Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsV7InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsV7QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsV7>>, TError = ErrorType<unknown>>(params?: GetCollectionsV7Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsV7>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsV7QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsV7>>> = ({ signal }) => getCollectionsV7(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsV7>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsV7QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsV7>>>
export type GetCollectionsV7QueryError = ErrorType<unknown>

/**
 * @summary Collections
 */
export const useGetCollectionsV7 = <TData = Awaited<ReturnType<typeof getCollectionsV7>>, TError = ErrorType<unknown>>(
 params?: GetCollectionsV7Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsV7>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsV7QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to check the status of cross posted listings and bids.

 Input your `crossPostingOrderId` into the `ids` param and submit for the status. 

 The `crossPostingOrderId` is returned in the `execute/bids` and `execute/asks` response as well as the `onProgess` callback for the SDK. 

 Note: ReservoirKit does not return a `crossPostingOrderId`.
 * @summary Check cross posting status
 */
export const getCrosspostingordersV1 = (
    params?: GetCrosspostingordersV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCrossPostingOrdersV1Response>(
      {url: `/cross-posting-orders/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCrosspostingordersV1QueryKey = (params?: GetCrosspostingordersV1Params,) => {
    return [`/cross-posting-orders/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCrosspostingordersV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCrosspostingordersV1>>, GetCrosspostingordersV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetCrosspostingordersV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCrosspostingordersV1>>, TError, TData, Awaited<ReturnType<typeof getCrosspostingordersV1>>, QueryKey, GetCrosspostingordersV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCrosspostingordersV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCrosspostingordersV1>>, QueryKey, GetCrosspostingordersV1Params['continuation']> = ({ signal, pageParam }) => getCrosspostingordersV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCrosspostingordersV1>>, TError, TData, Awaited<ReturnType<typeof getCrosspostingordersV1>>, QueryKey, GetCrosspostingordersV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetCrosspostingordersV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCrosspostingordersV1>>>
export type GetCrosspostingordersV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Check cross posting status
 */
export const useGetCrosspostingordersV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCrosspostingordersV1>>, GetCrosspostingordersV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetCrosspostingordersV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCrosspostingordersV1>>, TError, TData, Awaited<ReturnType<typeof getCrosspostingordersV1>>, QueryKey, GetCrosspostingordersV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCrosspostingordersV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCrosspostingordersV1QueryOptions = <TData = Awaited<ReturnType<typeof getCrosspostingordersV1>>, TError = ErrorType<unknown>>(params?: GetCrosspostingordersV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCrosspostingordersV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCrosspostingordersV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCrosspostingordersV1>>> = ({ signal }) => getCrosspostingordersV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCrosspostingordersV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCrosspostingordersV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCrosspostingordersV1>>>
export type GetCrosspostingordersV1QueryError = ErrorType<unknown>

/**
 * @summary Check cross posting status
 */
export const useGetCrosspostingordersV1 = <TData = Awaited<ReturnType<typeof getCrosspostingordersV1>>, TError = ErrorType<unknown>>(
 params?: GetCrosspostingordersV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCrosspostingordersV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCrosspostingordersV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Access orders with various filters applied. If you need orders created by a single user, use the positions API instead.
 * @deprecated
 * @summary Submit order batch
 */
export const getOrdersV2 = (
    params?: GetOrdersV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOrdersV2Response>(
      {url: `/orders/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersV2QueryKey = (params?: GetOrdersV2Params,) => {
    return [`/orders/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersV2>>, GetOrdersV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOrdersV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersV2>>, TError, TData, Awaited<ReturnType<typeof getOrdersV2>>, QueryKey, GetOrdersV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersV2>>, QueryKey, GetOrdersV2Params['continuation']> = ({ signal, pageParam }) => getOrdersV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersV2>>, TError, TData, Awaited<ReturnType<typeof getOrdersV2>>, QueryKey, GetOrdersV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersV2>>>
export type GetOrdersV2InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Submit order batch
 */
export const useGetOrdersV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersV2>>, GetOrdersV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOrdersV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersV2>>, TError, TData, Awaited<ReturnType<typeof getOrdersV2>>, QueryKey, GetOrdersV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersV2QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersV2>>, TError = ErrorType<unknown>>(params?: GetOrdersV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersV2>>> = ({ signal }) => getOrdersV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersV2QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersV2>>>
export type GetOrdersV2QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Submit order batch
 */
export const useGetOrdersV2 = <TData = Awaited<ReturnType<typeof getOrdersV2>>, TError = ErrorType<unknown>>(
 params?: GetOrdersV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get owners with various filters applied, and a summary of their ownership. Useful for exploring top owners in a collection or attribute.
 * @summary Owners
 */
export const getOwnersV2 = (
    params?: GetOwnersV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOwnersV2Response>(
      {url: `/owners/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOwnersV2QueryKey = (params?: GetOwnersV2Params,) => {
    return [`/owners/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOwnersV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOwnersV2>>, GetOwnersV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOwnersV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOwnersV2>>, TError, TData, Awaited<ReturnType<typeof getOwnersV2>>, QueryKey, GetOwnersV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOwnersV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOwnersV2>>, QueryKey, GetOwnersV2Params['continuation']> = ({ signal, pageParam }) => getOwnersV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOwnersV2>>, TError, TData, Awaited<ReturnType<typeof getOwnersV2>>, QueryKey, GetOwnersV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetOwnersV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOwnersV2>>>
export type GetOwnersV2InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Owners
 */
export const useGetOwnersV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOwnersV2>>, GetOwnersV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOwnersV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOwnersV2>>, TError, TData, Awaited<ReturnType<typeof getOwnersV2>>, QueryKey, GetOwnersV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOwnersV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOwnersV2QueryOptions = <TData = Awaited<ReturnType<typeof getOwnersV2>>, TError = ErrorType<unknown>>(params?: GetOwnersV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOwnersV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOwnersV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOwnersV2>>> = ({ signal }) => getOwnersV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOwnersV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOwnersV2QueryResult = NonNullable<Awaited<ReturnType<typeof getOwnersV2>>>
export type GetOwnersV2QueryError = ErrorType<unknown>

/**
 * @summary Owners
 */
export const useGetOwnersV2 = <TData = Awaited<ReturnType<typeof getOwnersV2>>, TError = ErrorType<unknown>>(
 params?: GetOwnersV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOwnersV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOwnersV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get recent sales for a contract or token. Paid mints are returned in this `sales` endpoint, free mints can be found in the `/activities/` endpoints. Array of contracts max limit is 20.
 * @summary Sales
 */
export const getSalesV6 = (
    params?: GetSalesV6Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetSalesV6Response>(
      {url: `/sales/v6`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetSalesV6QueryKey = (params?: GetSalesV6Params,) => {
    return [`/sales/v6`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSalesV6InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getSalesV6>>, GetSalesV6Params['continuation']>, TError = ErrorType<unknown>>(params?: GetSalesV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSalesV6>>, TError, TData, Awaited<ReturnType<typeof getSalesV6>>, QueryKey, GetSalesV6Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSalesV6QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalesV6>>, QueryKey, GetSalesV6Params['continuation']> = ({ signal, pageParam }) => getSalesV6({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSalesV6>>, TError, TData, Awaited<ReturnType<typeof getSalesV6>>, QueryKey, GetSalesV6Params['continuation']> & { queryKey: QueryKey }
}

export type GetSalesV6InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getSalesV6>>>
export type GetSalesV6InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Sales
 */
export const useGetSalesV6Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getSalesV6>>, GetSalesV6Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetSalesV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSalesV6>>, TError, TData, Awaited<ReturnType<typeof getSalesV6>>, QueryKey, GetSalesV6Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSalesV6InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSalesV6QueryOptions = <TData = Awaited<ReturnType<typeof getSalesV6>>, TError = ErrorType<unknown>>(params?: GetSalesV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSalesV6>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSalesV6QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalesV6>>> = ({ signal }) => getSalesV6(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSalesV6>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSalesV6QueryResult = NonNullable<Awaited<ReturnType<typeof getSalesV6>>>
export type GetSalesV6QueryError = ErrorType<unknown>

/**
 * @summary Sales
 */
export const useGetSalesV6 = <TData = Awaited<ReturnType<typeof getSalesV6>>, TError = ErrorType<unknown>>(
 params?: GetSalesV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSalesV6>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSalesV6QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API returns a list of sources
 * @summary Sources List
 */
export const getSourcesV1 = (
    params?: GetSourcesV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetSourcesV1Response>(
      {url: `/sources/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetSourcesV1QueryKey = (params?: GetSourcesV1Params,) => {
    return [`/sources/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSourcesV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getSourcesV1>>, GetSourcesV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetSourcesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSourcesV1>>, TError, TData, Awaited<ReturnType<typeof getSourcesV1>>, QueryKey, GetSourcesV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSourcesV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSourcesV1>>, QueryKey, GetSourcesV1Params['continuation']> = ({ signal, pageParam }) => getSourcesV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSourcesV1>>, TError, TData, Awaited<ReturnType<typeof getSourcesV1>>, QueryKey, GetSourcesV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetSourcesV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getSourcesV1>>>
export type GetSourcesV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Sources List
 */
export const useGetSourcesV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getSourcesV1>>, GetSourcesV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetSourcesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSourcesV1>>, TError, TData, Awaited<ReturnType<typeof getSourcesV1>>, QueryKey, GetSourcesV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSourcesV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSourcesV1QueryOptions = <TData = Awaited<ReturnType<typeof getSourcesV1>>, TError = ErrorType<unknown>>(params?: GetSourcesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSourcesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSourcesV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSourcesV1>>> = ({ signal }) => getSourcesV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSourcesV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSourcesV1QueryResult = NonNullable<Awaited<ReturnType<typeof getSourcesV1>>>
export type GetSourcesV1QueryError = ErrorType<unknown>

/**
 * @summary Sources List
 */
export const useGetSourcesV1 = <TData = Awaited<ReturnType<typeof getSourcesV1>>, TError = ErrorType<unknown>>(
 params?: GetSourcesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSourcesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSourcesV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get aggregate stats for a particular set (collection, attribute or single token)
 * @summary Stats
 */
export const getStatsV2 = (
    params?: GetStatsV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetStatsV2Response>(
      {url: `/stats/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetStatsV2QueryKey = (params?: GetStatsV2Params,) => {
    return [`/stats/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetStatsV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getStatsV2>>, GetStatsV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetStatsV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getStatsV2>>, TError, TData, Awaited<ReturnType<typeof getStatsV2>>, QueryKey, GetStatsV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStatsV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStatsV2>>, QueryKey, GetStatsV2Params['continuation']> = ({ signal, pageParam }) => getStatsV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getStatsV2>>, TError, TData, Awaited<ReturnType<typeof getStatsV2>>, QueryKey, GetStatsV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetStatsV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getStatsV2>>>
export type GetStatsV2InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Stats
 */
export const useGetStatsV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getStatsV2>>, GetStatsV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetStatsV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getStatsV2>>, TError, TData, Awaited<ReturnType<typeof getStatsV2>>, QueryKey, GetStatsV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetStatsV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetStatsV2QueryOptions = <TData = Awaited<ReturnType<typeof getStatsV2>>, TError = ErrorType<unknown>>(params?: GetStatsV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStatsV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStatsV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStatsV2>>> = ({ signal }) => getStatsV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStatsV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetStatsV2QueryResult = NonNullable<Awaited<ReturnType<typeof getStatsV2>>>
export type GetStatsV2QueryError = ErrorType<unknown>

/**
 * @summary Stats
 */
export const useGetStatsV2 = <TData = Awaited<ReturnType<typeof getStatsV2>>, TError = ErrorType<unknown>>(
 params?: GetStatsV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStatsV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetStatsV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a list of tokens with full metadata. This is useful for showing a single token page, or scenarios that require more metadata.
 * @summary Tokens
 */
export const getTokensV6 = (
    params?: GetTokensV6Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTokensV6Response>(
      {url: `/tokens/v6`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTokensV6QueryKey = (params?: GetTokensV6Params,) => {
    return [`/tokens/v6`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokensV6InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensV6>>, GetTokensV6Params['continuation']>, TError = ErrorType<unknown>>(params?: GetTokensV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensV6>>, TError, TData, Awaited<ReturnType<typeof getTokensV6>>, QueryKey, GetTokensV6Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensV6QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensV6>>, QueryKey, GetTokensV6Params['continuation']> = ({ signal, pageParam }) => getTokensV6({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensV6>>, TError, TData, Awaited<ReturnType<typeof getTokensV6>>, QueryKey, GetTokensV6Params['continuation']> & { queryKey: QueryKey }
}

export type GetTokensV6InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTokensV6>>>
export type GetTokensV6InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Tokens
 */
export const useGetTokensV6Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensV6>>, GetTokensV6Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetTokensV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensV6>>, TError, TData, Awaited<ReturnType<typeof getTokensV6>>, QueryKey, GetTokensV6Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensV6InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokensV6QueryOptions = <TData = Awaited<ReturnType<typeof getTokensV6>>, TError = ErrorType<unknown>>(params?: GetTokensV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensV6>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensV6QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensV6>>> = ({ signal }) => getTokensV6(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokensV6>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokensV6QueryResult = NonNullable<Awaited<ReturnType<typeof getTokensV6>>>
export type GetTokensV6QueryError = ErrorType<unknown>

/**
 * @summary Tokens
 */
export const useGetTokensV6 = <TData = Awaited<ReturnType<typeof getTokensV6>>, TError = ErrorType<unknown>>(
 params?: GetTokensV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensV6>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensV6QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get recent transfers for a contract or token.
 * @summary Historical token transfers
 */
export const getTransfersV4 = (
    params?: GetTransfersV4Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTransfersV4Response>(
      {url: `/transfers/v4`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTransfersV4QueryKey = (params?: GetTransfersV4Params,) => {
    return [`/transfers/v4`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTransfersV4InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTransfersV4>>, GetTransfersV4Params['continuation']>, TError = ErrorType<unknown>>(params?: GetTransfersV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTransfersV4>>, TError, TData, Awaited<ReturnType<typeof getTransfersV4>>, QueryKey, GetTransfersV4Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTransfersV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransfersV4>>, QueryKey, GetTransfersV4Params['continuation']> = ({ signal, pageParam }) => getTransfersV4({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTransfersV4>>, TError, TData, Awaited<ReturnType<typeof getTransfersV4>>, QueryKey, GetTransfersV4Params['continuation']> & { queryKey: QueryKey }
}

export type GetTransfersV4InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTransfersV4>>>
export type GetTransfersV4InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Historical token transfers
 */
export const useGetTransfersV4Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTransfersV4>>, GetTransfersV4Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetTransfersV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTransfersV4>>, TError, TData, Awaited<ReturnType<typeof getTransfersV4>>, QueryKey, GetTransfersV4Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTransfersV4InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTransfersV4QueryOptions = <TData = Awaited<ReturnType<typeof getTransfersV4>>, TError = ErrorType<unknown>>(params?: GetTransfersV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransfersV4>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTransfersV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransfersV4>>> = ({ signal }) => getTransfersV4(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTransfersV4>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTransfersV4QueryResult = NonNullable<Awaited<ReturnType<typeof getTransfersV4>>>
export type GetTransfersV4QueryError = ErrorType<unknown>

/**
 * @summary Historical token transfers
 */
export const useGetTransfersV4 = <TData = Awaited<ReturnType<typeof getTransfersV4>>, TError = ErrorType<unknown>>(
 params?: GetTransfersV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransfersV4>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTransfersV4QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get the associated info for the given API key
 */
export const getAdminGetapikeyKey = (
    key: string,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetAdminGetApiKeyKeyResponse>(
      {url: `/admin/get-api-key/${key}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAdminGetapikeyKeyQueryKey = (key: string,) => {
    return [`/admin/get-api-key/${key}`] as const;
    }

    
export const getGetAdminGetapikeyKeyInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getAdminGetapikeyKey>>>, TError = ErrorType<unknown>>(key: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminGetapikeyKey>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAdminGetapikeyKeyQueryKey(key);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminGetapikeyKey>>> = ({ signal }) => getAdminGetapikeyKey(key, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(key),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminGetapikeyKey>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAdminGetapikeyKeyInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminGetapikeyKey>>>
export type GetAdminGetapikeyKeyInfiniteQueryError = ErrorType<unknown>

/**
 * @summary Get the associated info for the given API key
 */
export const useGetAdminGetapikeyKeyInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getAdminGetapikeyKey>>>, TError = ErrorType<unknown>>(
 key: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminGetapikeyKey>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAdminGetapikeyKeyInfiniteQueryOptions(key,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAdminGetapikeyKeyQueryOptions = <TData = Awaited<ReturnType<typeof getAdminGetapikeyKey>>, TError = ErrorType<unknown>>(key: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminGetapikeyKey>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAdminGetapikeyKeyQueryKey(key);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminGetapikeyKey>>> = ({ signal }) => getAdminGetapikeyKey(key, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(key),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAdminGetapikeyKey>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAdminGetapikeyKeyQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminGetapikeyKey>>>
export type GetAdminGetapikeyKeyQueryError = ErrorType<unknown>

/**
 * @summary Get the associated info for the given API key
 */
export const useGetAdminGetapikeyKey = <TData = Awaited<ReturnType<typeof getAdminGetapikeyKey>>, TError = ErrorType<unknown>>(
 key: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminGetapikeyKey>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAdminGetapikeyKeyQueryOptions(key,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get metadata for a token or collection
 */
export const getAdminMetadataType = (
    type: 'token' | 'collection',
    params: GetAdminMetadataTypeParams,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<string>(
      {url: `/admin/metadata/${type}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetAdminMetadataTypeQueryKey = (type: 'token' | 'collection',
    params: GetAdminMetadataTypeParams,) => {
    return [`/admin/metadata/${type}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAdminMetadataTypeInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getAdminMetadataType>>, GetAdminMetadataTypeParams['continuation']>, TError = ErrorType<string>>(type: 'token' | 'collection',
    params: GetAdminMetadataTypeParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminMetadataType>>, TError, TData, Awaited<ReturnType<typeof getAdminMetadataType>>, QueryKey, GetAdminMetadataTypeParams['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAdminMetadataTypeQueryKey(type,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminMetadataType>>, QueryKey, GetAdminMetadataTypeParams['continuation']> = ({ signal, pageParam }) => getAdminMetadataType(type,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(type),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminMetadataType>>, TError, TData, Awaited<ReturnType<typeof getAdminMetadataType>>, QueryKey, GetAdminMetadataTypeParams['continuation']> & { queryKey: QueryKey }
}

export type GetAdminMetadataTypeInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminMetadataType>>>
export type GetAdminMetadataTypeInfiniteQueryError = ErrorType<string>

/**
 * @summary Get metadata for a token or collection
 */
export const useGetAdminMetadataTypeInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getAdminMetadataType>>, GetAdminMetadataTypeParams['continuation']>, TError = ErrorType<string>>(
 type: 'token' | 'collection',
    params: GetAdminMetadataTypeParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminMetadataType>>, TError, TData, Awaited<ReturnType<typeof getAdminMetadataType>>, QueryKey, GetAdminMetadataTypeParams['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAdminMetadataTypeInfiniteQueryOptions(type,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAdminMetadataTypeQueryOptions = <TData = Awaited<ReturnType<typeof getAdminMetadataType>>, TError = ErrorType<string>>(type: 'token' | 'collection',
    params: GetAdminMetadataTypeParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminMetadataType>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAdminMetadataTypeQueryKey(type,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminMetadataType>>> = ({ signal }) => getAdminMetadataType(type,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(type),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAdminMetadataType>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAdminMetadataTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminMetadataType>>>
export type GetAdminMetadataTypeQueryError = ErrorType<string>

/**
 * @summary Get metadata for a token or collection
 */
export const useGetAdminMetadataType = <TData = Awaited<ReturnType<typeof getAdminMetadataType>>, TError = ErrorType<string>>(
 type: 'token' | 'collection',
    params: GetAdminMetadataTypeParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminMetadataType>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAdminMetadataTypeQueryOptions(type,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get chain mint and sales stats for 1 and 7 days
 * @summary Chain Stats
 */
export const getChainStatsV1 = (
    
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetChainStatsV1Response>(
      {url: `/chain/stats/v1`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChainStatsV1QueryKey = () => {
    return [`/chain/stats/v1`] as const;
    }

    
export const getGetChainStatsV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getChainStatsV1>>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChainStatsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChainStatsV1QueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChainStatsV1>>> = ({ signal }) => getChainStatsV1(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChainStatsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetChainStatsV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getChainStatsV1>>>
export type GetChainStatsV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Chain Stats
 */
export const useGetChainStatsV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getChainStatsV1>>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChainStatsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChainStatsV1InfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetChainStatsV1QueryOptions = <TData = Awaited<ReturnType<typeof getChainStatsV1>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChainStatsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChainStatsV1QueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChainStatsV1>>> = ({ signal }) => getChainStatsV1(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChainStatsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetChainStatsV1QueryResult = NonNullable<Awaited<ReturnType<typeof getChainStatsV1>>>
export type GetChainStatsV1QueryError = ErrorType<unknown>

/**
 * @summary Chain Stats
 */
export const useGetChainStatsV1 = <TData = Awaited<ReturnType<typeof getChainStatsV1>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChainStatsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChainStatsV1QueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to build a feed for a collection including sales, asks, transfers, mints, bids, cancelled bids, and cancelled asks types.
 * @summary Collection activity
 */
export const getCollectionsActivityV6 = (
    params?: GetCollectionsActivityV6Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsActivityV6Response>(
      {url: `/collections/activity/v6`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsActivityV6QueryKey = (params?: GetCollectionsActivityV6Params,) => {
    return [`/collections/activity/v6`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsActivityV6InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsActivityV6>>, GetCollectionsActivityV6Params['continuation']>, TError = ErrorType<unknown>>(params?: GetCollectionsActivityV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsActivityV6>>, TError, TData, Awaited<ReturnType<typeof getCollectionsActivityV6>>, QueryKey, GetCollectionsActivityV6Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsActivityV6QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsActivityV6>>, QueryKey, GetCollectionsActivityV6Params['continuation']> = ({ signal, pageParam }) => getCollectionsActivityV6({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsActivityV6>>, TError, TData, Awaited<ReturnType<typeof getCollectionsActivityV6>>, QueryKey, GetCollectionsActivityV6Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsActivityV6InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsActivityV6>>>
export type GetCollectionsActivityV6InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Collection activity
 */
export const useGetCollectionsActivityV6Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsActivityV6>>, GetCollectionsActivityV6Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetCollectionsActivityV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsActivityV6>>, TError, TData, Awaited<ReturnType<typeof getCollectionsActivityV6>>, QueryKey, GetCollectionsActivityV6Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsActivityV6InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsActivityV6QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsActivityV6>>, TError = ErrorType<unknown>>(params?: GetCollectionsActivityV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsActivityV6>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsActivityV6QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsActivityV6>>> = ({ signal }) => getCollectionsActivityV6(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsActivityV6>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsActivityV6QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsActivityV6>>>
export type GetCollectionsActivityV6QueryError = ErrorType<unknown>

/**
 * @summary Collection activity
 */
export const useGetCollectionsActivityV6 = <TData = Awaited<ReturnType<typeof getCollectionsActivityV6>>, TError = ErrorType<unknown>>(
 params?: GetCollectionsActivityV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsActivityV6>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsActivityV6QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get date, volume, rank and sales count for each collection
 * @summary Daily collection volume
 */
export const getCollectionsDailyvolumesV1 = (
    params: GetCollectionsDailyvolumesV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsDailyVolumesV1Response>(
      {url: `/collections/daily-volumes/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsDailyvolumesV1QueryKey = (params: GetCollectionsDailyvolumesV1Params,) => {
    return [`/collections/daily-volumes/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsDailyvolumesV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, GetCollectionsDailyvolumesV1Params['continuation']>, TError = ErrorType<unknown>>(params: GetCollectionsDailyvolumesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, QueryKey, GetCollectionsDailyvolumesV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsDailyvolumesV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, QueryKey, GetCollectionsDailyvolumesV1Params['continuation']> = ({ signal, pageParam }) => getCollectionsDailyvolumesV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, QueryKey, GetCollectionsDailyvolumesV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsDailyvolumesV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>>
export type GetCollectionsDailyvolumesV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Daily collection volume
 */
export const useGetCollectionsDailyvolumesV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, GetCollectionsDailyvolumesV1Params['continuation']>, TError = ErrorType<unknown>>(
 params: GetCollectionsDailyvolumesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, QueryKey, GetCollectionsDailyvolumesV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsDailyvolumesV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsDailyvolumesV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, TError = ErrorType<unknown>>(params: GetCollectionsDailyvolumesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsDailyvolumesV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>> = ({ signal }) => getCollectionsDailyvolumesV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsDailyvolumesV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>>
export type GetCollectionsDailyvolumesV1QueryError = ErrorType<unknown>

/**
 * @summary Daily collection volume
 */
export const useGetCollectionsDailyvolumesV1 = <TData = Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, TError = ErrorType<unknown>>(
 params: GetCollectionsDailyvolumesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsDailyvolumesV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get top selling and minting collections
 * @deprecated
 * @summary Top selling collections
 */
export const getCollectionsTopsellingV2 = (
    params?: GetCollectionsTopsellingV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsTopSellingV2Response>(
      {url: `/collections/top-selling/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsTopsellingV2QueryKey = (params?: GetCollectionsTopsellingV2Params,) => {
    return [`/collections/top-selling/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsTopsellingV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, GetCollectionsTopsellingV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetCollectionsTopsellingV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, TError, TData, Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, QueryKey, GetCollectionsTopsellingV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsTopsellingV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, QueryKey, GetCollectionsTopsellingV2Params['continuation']> = ({ signal, pageParam }) => getCollectionsTopsellingV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, TError, TData, Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, QueryKey, GetCollectionsTopsellingV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsTopsellingV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsTopsellingV2>>>
export type GetCollectionsTopsellingV2InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Top selling collections
 */
export const useGetCollectionsTopsellingV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, GetCollectionsTopsellingV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetCollectionsTopsellingV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, TError, TData, Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, QueryKey, GetCollectionsTopsellingV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsTopsellingV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsTopsellingV2QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, TError = ErrorType<unknown>>(params?: GetCollectionsTopsellingV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsTopsellingV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsTopsellingV2>>> = ({ signal }) => getCollectionsTopsellingV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsTopsellingV2QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsTopsellingV2>>>
export type GetCollectionsTopsellingV2QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Top selling collections
 */
export const useGetCollectionsTopsellingV2 = <TData = Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, TError = ErrorType<unknown>>(
 params?: GetCollectionsTopsellingV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsTopsellingV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get top selling and minting collections
 * @summary Top Selling Collections
 */
export const getCollectionsTrendingV1 = (
    params?: GetCollectionsTrendingV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsTrendingV1Response>(
      {url: `/collections/trending/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsTrendingV1QueryKey = (params?: GetCollectionsTrendingV1Params,) => {
    return [`/collections/trending/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsTrendingV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsTrendingV1>>, GetCollectionsTrendingV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetCollectionsTrendingV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsTrendingV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsTrendingV1>>, QueryKey, GetCollectionsTrendingV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsTrendingV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsTrendingV1>>, QueryKey, GetCollectionsTrendingV1Params['continuation']> = ({ signal, pageParam }) => getCollectionsTrendingV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsTrendingV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsTrendingV1>>, QueryKey, GetCollectionsTrendingV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsTrendingV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsTrendingV1>>>
export type GetCollectionsTrendingV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Top Selling Collections
 */
export const useGetCollectionsTrendingV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsTrendingV1>>, GetCollectionsTrendingV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetCollectionsTrendingV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsTrendingV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsTrendingV1>>, QueryKey, GetCollectionsTrendingV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsTrendingV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsTrendingV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsTrendingV1>>, TError = ErrorType<unknown>>(params?: GetCollectionsTrendingV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsTrendingV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsTrendingV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsTrendingV1>>> = ({ signal }) => getCollectionsTrendingV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsTrendingV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsTrendingV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsTrendingV1>>>
export type GetCollectionsTrendingV1QueryError = ErrorType<unknown>

/**
 * @summary Top Selling Collections
 */
export const useGetCollectionsTrendingV1 = <TData = Awaited<ReturnType<typeof getCollectionsTrendingV1>>, TError = ErrorType<unknown>>(
 params?: GetCollectionsTrendingV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsTrendingV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsTrendingV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get top trending mints
 * @summary Top Trending Mints
 */
export const getCollectionsTrendingmintsV1 = (
    params?: GetCollectionsTrendingmintsV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsTrendingMintsV1Response>(
      {url: `/collections/trending-mints/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsTrendingmintsV1QueryKey = (params?: GetCollectionsTrendingmintsV1Params,) => {
    return [`/collections/trending-mints/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsTrendingmintsV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, GetCollectionsTrendingmintsV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetCollectionsTrendingmintsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, QueryKey, GetCollectionsTrendingmintsV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsTrendingmintsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, QueryKey, GetCollectionsTrendingmintsV1Params['continuation']> = ({ signal, pageParam }) => getCollectionsTrendingmintsV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, QueryKey, GetCollectionsTrendingmintsV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsTrendingmintsV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>>
export type GetCollectionsTrendingmintsV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Top Trending Mints
 */
export const useGetCollectionsTrendingmintsV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, GetCollectionsTrendingmintsV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetCollectionsTrendingmintsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, QueryKey, GetCollectionsTrendingmintsV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsTrendingmintsV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsTrendingmintsV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, TError = ErrorType<unknown>>(params?: GetCollectionsTrendingmintsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsTrendingmintsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>> = ({ signal }) => getCollectionsTrendingmintsV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsTrendingmintsV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>>
export type GetCollectionsTrendingmintsV1QueryError = ErrorType<unknown>

/**
 * @summary Top Trending Mints
 */
export const useGetCollectionsTrendingmintsV1 = <TData = Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, TError = ErrorType<unknown>>(
 params?: GetCollectionsTrendingmintsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsTrendingmintsV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get detailed information about a single collection, including real-time stats.
 * @deprecated
 * @summary Single collection
 */
export const getCollectionsCollectionorslugV1 = (
    collectionOrSlug: string,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsCollectionOrSlugV1Response>(
      {url: `/collections/${collectionOrSlug}/v1`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCollectionsCollectionorslugV1QueryKey = (collectionOrSlug: string,) => {
    return [`/collections/${collectionOrSlug}/v1`] as const;
    }

    
export const getGetCollectionsCollectionorslugV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>>, TError = ErrorType<unknown>>(collectionOrSlug: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionorslugV1QueryKey(collectionOrSlug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>> = ({ signal }) => getCollectionsCollectionorslugV1(collectionOrSlug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collectionOrSlug),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionorslugV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>>
export type GetCollectionsCollectionorslugV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Single collection
 */
export const useGetCollectionsCollectionorslugV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>>, TError = ErrorType<unknown>>(
 collectionOrSlug: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionorslugV1InfiniteQueryOptions(collectionOrSlug,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionorslugV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>, TError = ErrorType<unknown>>(collectionOrSlug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionorslugV1QueryKey(collectionOrSlug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>> = ({ signal }) => getCollectionsCollectionorslugV1(collectionOrSlug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collectionOrSlug),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionorslugV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>>
export type GetCollectionsCollectionorslugV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Single collection
 */
export const useGetCollectionsCollectionorslugV1 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>, TError = ErrorType<unknown>>(
 collectionOrSlug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionorslugV1QueryOptions(collectionOrSlug,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Convert an amount in one currency to another
 * @deprecated
 * @summary Currency Conversions
 */
export const getCurrenciesConversionV1 = (
    params?: GetCurrenciesConversionV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCurrenciesConversionV1Response>(
      {url: `/currencies/conversion/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCurrenciesConversionV1QueryKey = (params?: GetCurrenciesConversionV1Params,) => {
    return [`/currencies/conversion/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCurrenciesConversionV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCurrenciesConversionV1>>, GetCurrenciesConversionV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetCurrenciesConversionV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCurrenciesConversionV1>>, TError, TData, Awaited<ReturnType<typeof getCurrenciesConversionV1>>, QueryKey, GetCurrenciesConversionV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCurrenciesConversionV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCurrenciesConversionV1>>, QueryKey, GetCurrenciesConversionV1Params['continuation']> = ({ signal, pageParam }) => getCurrenciesConversionV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCurrenciesConversionV1>>, TError, TData, Awaited<ReturnType<typeof getCurrenciesConversionV1>>, QueryKey, GetCurrenciesConversionV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetCurrenciesConversionV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCurrenciesConversionV1>>>
export type GetCurrenciesConversionV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Currency Conversions
 */
export const useGetCurrenciesConversionV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCurrenciesConversionV1>>, GetCurrenciesConversionV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetCurrenciesConversionV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCurrenciesConversionV1>>, TError, TData, Awaited<ReturnType<typeof getCurrenciesConversionV1>>, QueryKey, GetCurrenciesConversionV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCurrenciesConversionV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCurrenciesConversionV1QueryOptions = <TData = Awaited<ReturnType<typeof getCurrenciesConversionV1>>, TError = ErrorType<unknown>>(params?: GetCurrenciesConversionV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCurrenciesConversionV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCurrenciesConversionV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCurrenciesConversionV1>>> = ({ signal }) => getCurrenciesConversionV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCurrenciesConversionV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCurrenciesConversionV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCurrenciesConversionV1>>>
export type GetCurrenciesConversionV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Currency Conversions
 */
export const useGetCurrenciesConversionV1 = <TData = Awaited<ReturnType<typeof getCurrenciesConversionV1>>, TError = ErrorType<unknown>>(
 params?: GetCurrenciesConversionV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCurrenciesConversionV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCurrenciesConversionV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Every time an ask status changes, an event is generated. This API is designed to be polled at high frequency, in order to keep an external system in sync with accurate prices for any token.

There are multiple event types, which describe what caused the change in price:

- `new-order` > new listing at a lower price

- `expiry` > the previous best listing expired

- `sale` > the previous best listing was filled

- `cancel` > the previous best listing was canceled

- `balance-change` > the best listing was invalidated due to no longer owning the NFT

- `approval-change` > the best listing was invalidated due to revoked approval

- `revalidation` > manual revalidation of orders (e.g. after a bug fixed)

- `reprice` > price update for dynamic orders (e.g. dutch auctions)

- `bootstrap` > initial loading of data, so that all tokens have a price associated

Note: Private listings (asks) will not appear in the results.
 * @summary Asks status changes
 */
export const getEventsAsksV3 = (
    params?: GetEventsAsksV3Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetEventsAsksV3Response>(
      {url: `/events/asks/v3`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetEventsAsksV3QueryKey = (params?: GetEventsAsksV3Params,) => {
    return [`/events/asks/v3`, ...(params ? [params]: [])] as const;
    }

    
export const getGetEventsAsksV3InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsAsksV3>>, GetEventsAsksV3Params['continuation']>, TError = ErrorType<unknown>>(params?: GetEventsAsksV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsAsksV3>>, TError, TData, Awaited<ReturnType<typeof getEventsAsksV3>>, QueryKey, GetEventsAsksV3Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsAsksV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsAsksV3>>, QueryKey, GetEventsAsksV3Params['continuation']> = ({ signal, pageParam }) => getEventsAsksV3({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsAsksV3>>, TError, TData, Awaited<ReturnType<typeof getEventsAsksV3>>, QueryKey, GetEventsAsksV3Params['continuation']> & { queryKey: QueryKey }
}

export type GetEventsAsksV3InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getEventsAsksV3>>>
export type GetEventsAsksV3InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Asks status changes
 */
export const useGetEventsAsksV3Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsAsksV3>>, GetEventsAsksV3Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetEventsAsksV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsAsksV3>>, TError, TData, Awaited<ReturnType<typeof getEventsAsksV3>>, QueryKey, GetEventsAsksV3Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsAsksV3InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetEventsAsksV3QueryOptions = <TData = Awaited<ReturnType<typeof getEventsAsksV3>>, TError = ErrorType<unknown>>(params?: GetEventsAsksV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsAsksV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsAsksV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsAsksV3>>> = ({ signal }) => getEventsAsksV3(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventsAsksV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEventsAsksV3QueryResult = NonNullable<Awaited<ReturnType<typeof getEventsAsksV3>>>
export type GetEventsAsksV3QueryError = ErrorType<unknown>

/**
 * @summary Asks status changes
 */
export const useGetEventsAsksV3 = <TData = Awaited<ReturnType<typeof getEventsAsksV3>>, TError = ErrorType<unknown>>(
 params?: GetEventsAsksV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsAsksV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsAsksV3QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Every time a bid status changes, an event is generated. This API is designed to be polled at high frequency, in order to keep an external system in sync with accurate prices for any token.

There are multiple event types, which describe what caused the change in price:

- `new-order` > new offer at a lower price

- `expiry` > the previous best offer expired

- `sale` > the previous best offer was filled

- `cancel` > the previous best offer was canceled

- `balance-change` > the best offer was invalidated due to no longer owning the NFT

- `approval-change` > the best offer was invalidated due to revoked approval

- `revalidation` > manual revalidation of orders (e.g. after a bug fixed)

- `reprice` > price update for dynamic orders (e.g. dutch auctions)

- `bootstrap` > initial loading of data, so that all tokens have a price associated

Some considerations to keep in mind

- Selling a partial quantity of available 1155 tokens in a listing will generate a `sale` and will have a new quantity.

- Due to the complex nature of monitoring off-chain liquidity across multiple marketplaces, including dealing with block re-orgs, events should be considered 'relative' to the perspective of the indexer, ie _when they were discovered_, rather than _when they happened_. A more deterministic historical record of price changes is in development, but in the meantime, this method is sufficent for keeping an external system in sync with the best available prices.
 * @summary Bid status changes
 */
export const getEventsBidsV3 = (
    params?: GetEventsBidsV3Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetEventsBidsV3Response>(
      {url: `/events/bids/v3`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetEventsBidsV3QueryKey = (params?: GetEventsBidsV3Params,) => {
    return [`/events/bids/v3`, ...(params ? [params]: [])] as const;
    }

    
export const getGetEventsBidsV3InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsBidsV3>>, GetEventsBidsV3Params['continuation']>, TError = ErrorType<unknown>>(params?: GetEventsBidsV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsBidsV3>>, TError, TData, Awaited<ReturnType<typeof getEventsBidsV3>>, QueryKey, GetEventsBidsV3Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsBidsV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsBidsV3>>, QueryKey, GetEventsBidsV3Params['continuation']> = ({ signal, pageParam }) => getEventsBidsV3({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsBidsV3>>, TError, TData, Awaited<ReturnType<typeof getEventsBidsV3>>, QueryKey, GetEventsBidsV3Params['continuation']> & { queryKey: QueryKey }
}

export type GetEventsBidsV3InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getEventsBidsV3>>>
export type GetEventsBidsV3InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Bid status changes
 */
export const useGetEventsBidsV3Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsBidsV3>>, GetEventsBidsV3Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetEventsBidsV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsBidsV3>>, TError, TData, Awaited<ReturnType<typeof getEventsBidsV3>>, QueryKey, GetEventsBidsV3Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsBidsV3InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetEventsBidsV3QueryOptions = <TData = Awaited<ReturnType<typeof getEventsBidsV3>>, TError = ErrorType<unknown>>(params?: GetEventsBidsV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsBidsV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsBidsV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsBidsV3>>> = ({ signal }) => getEventsBidsV3(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventsBidsV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEventsBidsV3QueryResult = NonNullable<Awaited<ReturnType<typeof getEventsBidsV3>>>
export type GetEventsBidsV3QueryError = ErrorType<unknown>

/**
 * @summary Bid status changes
 */
export const useGetEventsBidsV3 = <TData = Awaited<ReturnType<typeof getEventsBidsV3>>, TError = ErrorType<unknown>>(
 params?: GetEventsBidsV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsBidsV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsBidsV3QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Cancel an existing order on any marketplace
 * @deprecated
 * @summary Cancel order
 */
export const getExecuteCancelV2 = (
    params: GetExecuteCancelV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetExecuteCancelV2Response>(
      {url: `/execute/cancel/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetExecuteCancelV2QueryKey = (params: GetExecuteCancelV2Params,) => {
    return [`/execute/cancel/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetExecuteCancelV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getExecuteCancelV2>>, GetExecuteCancelV2Params['continuation']>, TError = ErrorType<unknown>>(params: GetExecuteCancelV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getExecuteCancelV2>>, TError, TData, Awaited<ReturnType<typeof getExecuteCancelV2>>, QueryKey, GetExecuteCancelV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExecuteCancelV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExecuteCancelV2>>, QueryKey, GetExecuteCancelV2Params['continuation']> = ({ signal, pageParam }) => getExecuteCancelV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getExecuteCancelV2>>, TError, TData, Awaited<ReturnType<typeof getExecuteCancelV2>>, QueryKey, GetExecuteCancelV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetExecuteCancelV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getExecuteCancelV2>>>
export type GetExecuteCancelV2InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Cancel order
 */
export const useGetExecuteCancelV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getExecuteCancelV2>>, GetExecuteCancelV2Params['continuation']>, TError = ErrorType<unknown>>(
 params: GetExecuteCancelV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getExecuteCancelV2>>, TError, TData, Awaited<ReturnType<typeof getExecuteCancelV2>>, QueryKey, GetExecuteCancelV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetExecuteCancelV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetExecuteCancelV2QueryOptions = <TData = Awaited<ReturnType<typeof getExecuteCancelV2>>, TError = ErrorType<unknown>>(params: GetExecuteCancelV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExecuteCancelV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExecuteCancelV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExecuteCancelV2>>> = ({ signal }) => getExecuteCancelV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExecuteCancelV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetExecuteCancelV2QueryResult = NonNullable<Awaited<ReturnType<typeof getExecuteCancelV2>>>
export type GetExecuteCancelV2QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Cancel order
 */
export const useGetExecuteCancelV2 = <TData = Awaited<ReturnType<typeof getExecuteCancelV2>>, TError = ErrorType<unknown>>(
 params: GetExecuteCancelV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExecuteCancelV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetExecuteCancelV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API calculates the total liquidity created by users, based on the number of tokens they are top bidder for.
 * @summary User bid liquidity rankings
 */
export const getLiquidityUsersV2 = (
    params: GetLiquidityUsersV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetLiquidityUsersV2Response>(
      {url: `/liquidity/users/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetLiquidityUsersV2QueryKey = (params: GetLiquidityUsersV2Params,) => {
    return [`/liquidity/users/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetLiquidityUsersV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getLiquidityUsersV2>>, GetLiquidityUsersV2Params['continuation']>, TError = ErrorType<unknown>>(params: GetLiquidityUsersV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getLiquidityUsersV2>>, TError, TData, Awaited<ReturnType<typeof getLiquidityUsersV2>>, QueryKey, GetLiquidityUsersV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLiquidityUsersV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLiquidityUsersV2>>, QueryKey, GetLiquidityUsersV2Params['continuation']> = ({ signal, pageParam }) => getLiquidityUsersV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getLiquidityUsersV2>>, TError, TData, Awaited<ReturnType<typeof getLiquidityUsersV2>>, QueryKey, GetLiquidityUsersV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetLiquidityUsersV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getLiquidityUsersV2>>>
export type GetLiquidityUsersV2InfiniteQueryError = ErrorType<unknown>

/**
 * @summary User bid liquidity rankings
 */
export const useGetLiquidityUsersV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getLiquidityUsersV2>>, GetLiquidityUsersV2Params['continuation']>, TError = ErrorType<unknown>>(
 params: GetLiquidityUsersV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getLiquidityUsersV2>>, TError, TData, Awaited<ReturnType<typeof getLiquidityUsersV2>>, QueryKey, GetLiquidityUsersV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetLiquidityUsersV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetLiquidityUsersV2QueryOptions = <TData = Awaited<ReturnType<typeof getLiquidityUsersV2>>, TError = ErrorType<unknown>>(params: GetLiquidityUsersV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLiquidityUsersV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLiquidityUsersV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLiquidityUsersV2>>> = ({ signal }) => getLiquidityUsersV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getLiquidityUsersV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetLiquidityUsersV2QueryResult = NonNullable<Awaited<ReturnType<typeof getLiquidityUsersV2>>>
export type GetLiquidityUsersV2QueryError = ErrorType<unknown>

/**
 * @summary User bid liquidity rankings
 */
export const useGetLiquidityUsersV2 = <TData = Awaited<ReturnType<typeof getLiquidityUsersV2>>, TError = ErrorType<unknown>>(
 params: GetLiquidityUsersV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLiquidityUsersV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetLiquidityUsersV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API is designed for efficiently ingesting large volumes of orders, for external processing
 * @deprecated
 * @summary Bulk historical orders
 */
export const getOrdersAllV2 = (
    params?: GetOrdersAllV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOrdersAllV2Response>(
      {url: `/orders/all/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersAllV2QueryKey = (params?: GetOrdersAllV2Params,) => {
    return [`/orders/all/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersAllV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersAllV2>>, GetOrdersAllV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOrdersAllV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersAllV2>>, TError, TData, Awaited<ReturnType<typeof getOrdersAllV2>>, QueryKey, GetOrdersAllV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersAllV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersAllV2>>, QueryKey, GetOrdersAllV2Params['continuation']> = ({ signal, pageParam }) => getOrdersAllV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersAllV2>>, TError, TData, Awaited<ReturnType<typeof getOrdersAllV2>>, QueryKey, GetOrdersAllV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersAllV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersAllV2>>>
export type GetOrdersAllV2InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Bulk historical orders
 */
export const useGetOrdersAllV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersAllV2>>, GetOrdersAllV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOrdersAllV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersAllV2>>, TError, TData, Awaited<ReturnType<typeof getOrdersAllV2>>, QueryKey, GetOrdersAllV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersAllV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersAllV2QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersAllV2>>, TError = ErrorType<unknown>>(params?: GetOrdersAllV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersAllV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersAllV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersAllV2>>> = ({ signal }) => getOrdersAllV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersAllV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersAllV2QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersAllV2>>>
export type GetOrdersAllV2QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Bulk historical orders
 */
export const useGetOrdersAllV2 = <TData = Awaited<ReturnType<typeof getOrdersAllV2>>, TError = ErrorType<unknown>>(
 params?: GetOrdersAllV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersAllV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersAllV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a list of asks (listings), filtered by token, collection or maker. This API is designed for efficiently ingesting large volumes of orders, for external processing.

 To get all orders unflitered, select `sortBy` to `updatedAt`. No need to pass any other param. This will return any orders for any collections, token, attribute, etc.

 Please mark `excludeEOA` as `true` to exclude Blur orders.
 * @summary Asks (listings)
 */
export const getOrdersAsksV5 = (
    params?: GetOrdersAsksV5Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOrdersAsksV5Response>(
      {url: `/orders/asks/v5`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersAsksV5QueryKey = (params?: GetOrdersAsksV5Params,) => {
    return [`/orders/asks/v5`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersAsksV5InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersAsksV5>>, GetOrdersAsksV5Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOrdersAsksV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV5>>, TError, TData, Awaited<ReturnType<typeof getOrdersAsksV5>>, QueryKey, GetOrdersAsksV5Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersAsksV5QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersAsksV5>>, QueryKey, GetOrdersAsksV5Params['continuation']> = ({ signal, pageParam }) => getOrdersAsksV5({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV5>>, TError, TData, Awaited<ReturnType<typeof getOrdersAsksV5>>, QueryKey, GetOrdersAsksV5Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersAsksV5InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersAsksV5>>>
export type GetOrdersAsksV5InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Asks (listings)
 */
export const useGetOrdersAsksV5Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersAsksV5>>, GetOrdersAsksV5Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOrdersAsksV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV5>>, TError, TData, Awaited<ReturnType<typeof getOrdersAsksV5>>, QueryKey, GetOrdersAsksV5Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersAsksV5InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersAsksV5QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersAsksV5>>, TError = ErrorType<unknown>>(params?: GetOrdersAsksV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV5>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersAsksV5QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersAsksV5>>> = ({ signal }) => getOrdersAsksV5(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV5>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersAsksV5QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersAsksV5>>>
export type GetOrdersAsksV5QueryError = ErrorType<unknown>

/**
 * @summary Asks (listings)
 */
export const useGetOrdersAsksV5 = <TData = Awaited<ReturnType<typeof getOrdersAsksV5>>, TError = ErrorType<unknown>>(
 params?: GetOrdersAsksV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV5>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersAsksV5QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a list of bids (offers), filtered by token, collection or maker. This API is designed for efficiently ingesting large volumes of orders, for external processing.

 There are a different kind of bids than can be returned:

- To get all orders unfiltered, select `sortBy` to `updatedAt`. No need to pass any other param. This will return any orders for any collections, token, attribute, etc. 

- Inputting a 'contract' will return token and attribute bids.

- Inputting a 'collection-id' will return collection wide bids.

- Please mark `excludeEOA` as `true` to exclude Blur orders.
 * @summary Bids (offers)
 */
export const getOrdersBidsV6 = (
    params?: GetOrdersBidsV6Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOrdersBidsV6Response>(
      {url: `/orders/bids/v6`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersBidsV6QueryKey = (params?: GetOrdersBidsV6Params,) => {
    return [`/orders/bids/v6`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersBidsV6InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersBidsV6>>, GetOrdersBidsV6Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOrdersBidsV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV6>>, TError, TData, Awaited<ReturnType<typeof getOrdersBidsV6>>, QueryKey, GetOrdersBidsV6Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersBidsV6QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersBidsV6>>, QueryKey, GetOrdersBidsV6Params['continuation']> = ({ signal, pageParam }) => getOrdersBidsV6({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV6>>, TError, TData, Awaited<ReturnType<typeof getOrdersBidsV6>>, QueryKey, GetOrdersBidsV6Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersBidsV6InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersBidsV6>>>
export type GetOrdersBidsV6InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Bids (offers)
 */
export const useGetOrdersBidsV6Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersBidsV6>>, GetOrdersBidsV6Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOrdersBidsV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV6>>, TError, TData, Awaited<ReturnType<typeof getOrdersBidsV6>>, QueryKey, GetOrdersBidsV6Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersBidsV6InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersBidsV6QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersBidsV6>>, TError = ErrorType<unknown>>(params?: GetOrdersBidsV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV6>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersBidsV6QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersBidsV6>>> = ({ signal }) => getOrdersBidsV6(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV6>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersBidsV6QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersBidsV6>>>
export type GetOrdersBidsV6QueryError = ErrorType<unknown>

/**
 * @summary Bids (offers)
 */
export const useGetOrdersBidsV6 = <TData = Awaited<ReturnType<typeof getOrdersBidsV6>>, TError = ErrorType<unknown>>(
 params?: GetOrdersBidsV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV6>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersBidsV6QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get the depth of a token or collection.
 * @summary Orders depth
 */
export const getOrdersDepthV1 = (
    params: GetOrdersDepthV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOrdersDepthV1Response>(
      {url: `/orders/depth/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersDepthV1QueryKey = (params: GetOrdersDepthV1Params,) => {
    return [`/orders/depth/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersDepthV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersDepthV1>>, GetOrdersDepthV1Params['continuation']>, TError = ErrorType<unknown>>(params: GetOrdersDepthV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersDepthV1>>, TError, TData, Awaited<ReturnType<typeof getOrdersDepthV1>>, QueryKey, GetOrdersDepthV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersDepthV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersDepthV1>>, QueryKey, GetOrdersDepthV1Params['continuation']> = ({ signal, pageParam }) => getOrdersDepthV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersDepthV1>>, TError, TData, Awaited<ReturnType<typeof getOrdersDepthV1>>, QueryKey, GetOrdersDepthV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersDepthV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersDepthV1>>>
export type GetOrdersDepthV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Orders depth
 */
export const useGetOrdersDepthV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersDepthV1>>, GetOrdersDepthV1Params['continuation']>, TError = ErrorType<unknown>>(
 params: GetOrdersDepthV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersDepthV1>>, TError, TData, Awaited<ReturnType<typeof getOrdersDepthV1>>, QueryKey, GetOrdersDepthV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersDepthV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersDepthV1QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersDepthV1>>, TError = ErrorType<unknown>>(params: GetOrdersDepthV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersDepthV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersDepthV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersDepthV1>>> = ({ signal }) => getOrdersDepthV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersDepthV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersDepthV1QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersDepthV1>>>
export type GetOrdersDepthV1QueryError = ErrorType<unknown>

/**
 * @summary Orders depth
 */
export const useGetOrdersDepthV1 = <TData = Awaited<ReturnType<typeof getOrdersDepthV1>>, TError = ErrorType<unknown>>(
 params: GetOrdersDepthV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersDepthV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersDepthV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @deprecated
 * @summary Order status
 */
export const getOrdersExecutedV1 = (
    params: GetOrdersExecutedV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<string>(
      {url: `/orders/executed/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersExecutedV1QueryKey = (params: GetOrdersExecutedV1Params,) => {
    return [`/orders/executed/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersExecutedV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersExecutedV1>>, GetOrdersExecutedV1Params['continuation']>, TError = ErrorType<string>>(params: GetOrdersExecutedV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersExecutedV1>>, TError, TData, Awaited<ReturnType<typeof getOrdersExecutedV1>>, QueryKey, GetOrdersExecutedV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersExecutedV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersExecutedV1>>, QueryKey, GetOrdersExecutedV1Params['continuation']> = ({ signal, pageParam }) => getOrdersExecutedV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersExecutedV1>>, TError, TData, Awaited<ReturnType<typeof getOrdersExecutedV1>>, QueryKey, GetOrdersExecutedV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersExecutedV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersExecutedV1>>>
export type GetOrdersExecutedV1InfiniteQueryError = ErrorType<string>

/**
 * @deprecated
 * @summary Order status
 */
export const useGetOrdersExecutedV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersExecutedV1>>, GetOrdersExecutedV1Params['continuation']>, TError = ErrorType<string>>(
 params: GetOrdersExecutedV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersExecutedV1>>, TError, TData, Awaited<ReturnType<typeof getOrdersExecutedV1>>, QueryKey, GetOrdersExecutedV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersExecutedV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersExecutedV1QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersExecutedV1>>, TError = ErrorType<string>>(params: GetOrdersExecutedV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersExecutedV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersExecutedV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersExecutedV1>>> = ({ signal }) => getOrdersExecutedV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersExecutedV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersExecutedV1QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersExecutedV1>>>
export type GetOrdersExecutedV1QueryError = ErrorType<string>

/**
 * @deprecated
 * @summary Order status
 */
export const useGetOrdersExecutedV1 = <TData = Awaited<ReturnType<typeof getOrdersExecutedV1>>, TError = ErrorType<string>>(
 params: GetOrdersExecutedV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersExecutedV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersExecutedV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to find top common collections from an array of owners.
 * @summary Common Collections
 */
export const getOwnersCommoncollectionsV1 = (
    params: GetOwnersCommoncollectionsV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOwnersCommonCollectionsV1Response>(
      {url: `/owners/common-collections/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOwnersCommoncollectionsV1QueryKey = (params: GetOwnersCommoncollectionsV1Params,) => {
    return [`/owners/common-collections/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOwnersCommoncollectionsV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, GetOwnersCommoncollectionsV1Params['continuation']>, TError = ErrorType<unknown>>(params: GetOwnersCommoncollectionsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, TError, TData, Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, QueryKey, GetOwnersCommoncollectionsV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOwnersCommoncollectionsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, QueryKey, GetOwnersCommoncollectionsV1Params['continuation']> = ({ signal, pageParam }) => getOwnersCommoncollectionsV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, TError, TData, Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, QueryKey, GetOwnersCommoncollectionsV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetOwnersCommoncollectionsV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>>
export type GetOwnersCommoncollectionsV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Common Collections
 */
export const useGetOwnersCommoncollectionsV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, GetOwnersCommoncollectionsV1Params['continuation']>, TError = ErrorType<unknown>>(
 params: GetOwnersCommoncollectionsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, TError, TData, Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, QueryKey, GetOwnersCommoncollectionsV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOwnersCommoncollectionsV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOwnersCommoncollectionsV1QueryOptions = <TData = Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, TError = ErrorType<unknown>>(params: GetOwnersCommoncollectionsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOwnersCommoncollectionsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>> = ({ signal }) => getOwnersCommoncollectionsV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOwnersCommoncollectionsV1QueryResult = NonNullable<Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>>
export type GetOwnersCommoncollectionsV1QueryError = ErrorType<unknown>

/**
 * @summary Common Collections
 */
export const useGetOwnersCommoncollectionsV1 = <TData = Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, TError = ErrorType<unknown>>(
 params: GetOwnersCommoncollectionsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOwnersCommoncollectionsV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get total owners count for a specific token. Useful for 1155 tokens, where you want to know the exact amount of owners.
 * @summary Owners Count
 */
export const getOwnersCountV1 = (
    params?: GetOwnersCountV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOwnersCountV1Response>(
      {url: `/owners/count/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOwnersCountV1QueryKey = (params?: GetOwnersCountV1Params,) => {
    return [`/owners/count/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOwnersCountV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOwnersCountV1>>, GetOwnersCountV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOwnersCountV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOwnersCountV1>>, TError, TData, Awaited<ReturnType<typeof getOwnersCountV1>>, QueryKey, GetOwnersCountV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOwnersCountV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOwnersCountV1>>, QueryKey, GetOwnersCountV1Params['continuation']> = ({ signal, pageParam }) => getOwnersCountV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOwnersCountV1>>, TError, TData, Awaited<ReturnType<typeof getOwnersCountV1>>, QueryKey, GetOwnersCountV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetOwnersCountV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOwnersCountV1>>>
export type GetOwnersCountV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Owners Count
 */
export const useGetOwnersCountV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOwnersCountV1>>, GetOwnersCountV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOwnersCountV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOwnersCountV1>>, TError, TData, Awaited<ReturnType<typeof getOwnersCountV1>>, QueryKey, GetOwnersCountV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOwnersCountV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOwnersCountV1QueryOptions = <TData = Awaited<ReturnType<typeof getOwnersCountV1>>, TError = ErrorType<unknown>>(params?: GetOwnersCountV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOwnersCountV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOwnersCountV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOwnersCountV1>>> = ({ signal }) => getOwnersCountV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOwnersCountV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOwnersCountV1QueryResult = NonNullable<Awaited<ReturnType<typeof getOwnersCountV1>>>
export type GetOwnersCountV1QueryError = ErrorType<unknown>

/**
 * @summary Owners Count
 */
export const useGetOwnersCountV1 = <TData = Awaited<ReturnType<typeof getOwnersCountV1>>, TError = ErrorType<unknown>>(
 params?: GetOwnersCountV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOwnersCountV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOwnersCountV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Find which addresses own the most of a group of collections.
 * @summary Owners intersection
 */
export const getOwnersCrosscollectionsV1 = (
    params: GetOwnersCrosscollectionsV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOwnersCrossCollectionsV1Response>(
      {url: `/owners/cross-collections/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOwnersCrosscollectionsV1QueryKey = (params: GetOwnersCrosscollectionsV1Params,) => {
    return [`/owners/cross-collections/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOwnersCrosscollectionsV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, GetOwnersCrosscollectionsV1Params['continuation']>, TError = ErrorType<unknown>>(params: GetOwnersCrosscollectionsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, TError, TData, Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, QueryKey, GetOwnersCrosscollectionsV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOwnersCrosscollectionsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, QueryKey, GetOwnersCrosscollectionsV1Params['continuation']> = ({ signal, pageParam }) => getOwnersCrosscollectionsV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, TError, TData, Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, QueryKey, GetOwnersCrosscollectionsV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetOwnersCrosscollectionsV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>>
export type GetOwnersCrosscollectionsV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Owners intersection
 */
export const useGetOwnersCrosscollectionsV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, GetOwnersCrosscollectionsV1Params['continuation']>, TError = ErrorType<unknown>>(
 params: GetOwnersCrosscollectionsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, TError, TData, Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, QueryKey, GetOwnersCrosscollectionsV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOwnersCrosscollectionsV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOwnersCrosscollectionsV1QueryOptions = <TData = Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, TError = ErrorType<unknown>>(params: GetOwnersCrosscollectionsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOwnersCrosscollectionsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>> = ({ signal }) => getOwnersCrosscollectionsV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOwnersCrosscollectionsV1QueryResult = NonNullable<Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>>
export type GetOwnersCrosscollectionsV1QueryError = ErrorType<unknown>

/**
 * @summary Owners intersection
 */
export const useGetOwnersCrosscollectionsV1 = <TData = Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, TError = ErrorType<unknown>>(
 params: GetOwnersCrosscollectionsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOwnersCrosscollectionsV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Note: this API is optimized for bulk access, and offers minimal filters/metadata. If you need more flexibility, try the `NFT API > Sales` endpoint
 * @deprecated
 * @summary Bulk historical sales
 */
export const getSalesBulkV1 = (
    params?: GetSalesBulkV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetSalesBulkV1Response>(
      {url: `/sales/bulk/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetSalesBulkV1QueryKey = (params?: GetSalesBulkV1Params,) => {
    return [`/sales/bulk/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSalesBulkV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getSalesBulkV1>>, GetSalesBulkV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetSalesBulkV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSalesBulkV1>>, TError, TData, Awaited<ReturnType<typeof getSalesBulkV1>>, QueryKey, GetSalesBulkV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSalesBulkV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalesBulkV1>>, QueryKey, GetSalesBulkV1Params['continuation']> = ({ signal, pageParam }) => getSalesBulkV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSalesBulkV1>>, TError, TData, Awaited<ReturnType<typeof getSalesBulkV1>>, QueryKey, GetSalesBulkV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetSalesBulkV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getSalesBulkV1>>>
export type GetSalesBulkV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Bulk historical sales
 */
export const useGetSalesBulkV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getSalesBulkV1>>, GetSalesBulkV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetSalesBulkV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSalesBulkV1>>, TError, TData, Awaited<ReturnType<typeof getSalesBulkV1>>, QueryKey, GetSalesBulkV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSalesBulkV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSalesBulkV1QueryOptions = <TData = Awaited<ReturnType<typeof getSalesBulkV1>>, TError = ErrorType<unknown>>(params?: GetSalesBulkV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSalesBulkV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSalesBulkV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalesBulkV1>>> = ({ signal }) => getSalesBulkV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSalesBulkV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSalesBulkV1QueryResult = NonNullable<Awaited<ReturnType<typeof getSalesBulkV1>>>
export type GetSalesBulkV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Bulk historical sales
 */
export const useGetSalesBulkV1 = <TData = Awaited<ReturnType<typeof getSalesBulkV1>>, TError = ErrorType<unknown>>(
 params?: GetSalesBulkV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSalesBulkV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSalesBulkV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to build a feed for a collection including sales, asks, transfers, mints, bids, cancelled bids, and cancelled asks types.
 * @summary Search activity
 */
export const getSearchActivitiesV1 = (
    params?: GetSearchActivitiesV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetSearchActivitiesV1Response>(
      {url: `/search/activities/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetSearchActivitiesV1QueryKey = (params?: GetSearchActivitiesV1Params,) => {
    return [`/search/activities/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSearchActivitiesV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getSearchActivitiesV1>>, GetSearchActivitiesV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetSearchActivitiesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSearchActivitiesV1>>, TError, TData, Awaited<ReturnType<typeof getSearchActivitiesV1>>, QueryKey, GetSearchActivitiesV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSearchActivitiesV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSearchActivitiesV1>>, QueryKey, GetSearchActivitiesV1Params['continuation']> = ({ signal, pageParam }) => getSearchActivitiesV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSearchActivitiesV1>>, TError, TData, Awaited<ReturnType<typeof getSearchActivitiesV1>>, QueryKey, GetSearchActivitiesV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetSearchActivitiesV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getSearchActivitiesV1>>>
export type GetSearchActivitiesV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Search activity
 */
export const useGetSearchActivitiesV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getSearchActivitiesV1>>, GetSearchActivitiesV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetSearchActivitiesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSearchActivitiesV1>>, TError, TData, Awaited<ReturnType<typeof getSearchActivitiesV1>>, QueryKey, GetSearchActivitiesV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSearchActivitiesV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSearchActivitiesV1QueryOptions = <TData = Awaited<ReturnType<typeof getSearchActivitiesV1>>, TError = ErrorType<unknown>>(params?: GetSearchActivitiesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchActivitiesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSearchActivitiesV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSearchActivitiesV1>>> = ({ signal }) => getSearchActivitiesV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSearchActivitiesV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSearchActivitiesV1QueryResult = NonNullable<Awaited<ReturnType<typeof getSearchActivitiesV1>>>
export type GetSearchActivitiesV1QueryError = ErrorType<unknown>

/**
 * @summary Search activity
 */
export const useGetSearchActivitiesV1 = <TData = Awaited<ReturnType<typeof getSearchActivitiesV1>>, TError = ErrorType<unknown>>(
 params?: GetSearchActivitiesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchActivitiesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSearchActivitiesV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Search collections
 */
export const getSearchCollectionsV2 = (
    params?: GetSearchCollectionsV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetSearchCollectionsV2Response>(
      {url: `/search/collections/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetSearchCollectionsV2QueryKey = (params?: GetSearchCollectionsV2Params,) => {
    return [`/search/collections/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSearchCollectionsV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getSearchCollectionsV2>>, GetSearchCollectionsV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetSearchCollectionsV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSearchCollectionsV2>>, TError, TData, Awaited<ReturnType<typeof getSearchCollectionsV2>>, QueryKey, GetSearchCollectionsV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSearchCollectionsV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSearchCollectionsV2>>, QueryKey, GetSearchCollectionsV2Params['continuation']> = ({ signal, pageParam }) => getSearchCollectionsV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSearchCollectionsV2>>, TError, TData, Awaited<ReturnType<typeof getSearchCollectionsV2>>, QueryKey, GetSearchCollectionsV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetSearchCollectionsV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getSearchCollectionsV2>>>
export type GetSearchCollectionsV2InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Search collections
 */
export const useGetSearchCollectionsV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getSearchCollectionsV2>>, GetSearchCollectionsV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetSearchCollectionsV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSearchCollectionsV2>>, TError, TData, Awaited<ReturnType<typeof getSearchCollectionsV2>>, QueryKey, GetSearchCollectionsV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSearchCollectionsV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSearchCollectionsV2QueryOptions = <TData = Awaited<ReturnType<typeof getSearchCollectionsV2>>, TError = ErrorType<unknown>>(params?: GetSearchCollectionsV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchCollectionsV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSearchCollectionsV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSearchCollectionsV2>>> = ({ signal }) => getSearchCollectionsV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSearchCollectionsV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSearchCollectionsV2QueryResult = NonNullable<Awaited<ReturnType<typeof getSearchCollectionsV2>>>
export type GetSearchCollectionsV2QueryError = ErrorType<unknown>

/**
 * @summary Search collections
 */
export const useGetSearchCollectionsV2 = <TData = Awaited<ReturnType<typeof getSearchCollectionsV2>>, TError = ErrorType<unknown>>(
 params?: GetSearchCollectionsV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchCollectionsV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSearchCollectionsV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API is optimized for bulk access to asks (listings) for syncing a remote database. Thus it offers minimal filters/metadata.
 * @summary Sync Asks (listings)
 */
export const getSyncAsksV1 = (
    params?: GetSyncAsksV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetSyncAsksV1Response>(
      {url: `/sync/asks/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetSyncAsksV1QueryKey = (params?: GetSyncAsksV1Params,) => {
    return [`/sync/asks/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSyncAsksV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getSyncAsksV1>>, GetSyncAsksV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetSyncAsksV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSyncAsksV1>>, TError, TData, Awaited<ReturnType<typeof getSyncAsksV1>>, QueryKey, GetSyncAsksV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSyncAsksV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSyncAsksV1>>, QueryKey, GetSyncAsksV1Params['continuation']> = ({ signal, pageParam }) => getSyncAsksV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSyncAsksV1>>, TError, TData, Awaited<ReturnType<typeof getSyncAsksV1>>, QueryKey, GetSyncAsksV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetSyncAsksV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getSyncAsksV1>>>
export type GetSyncAsksV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Sync Asks (listings)
 */
export const useGetSyncAsksV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getSyncAsksV1>>, GetSyncAsksV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetSyncAsksV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSyncAsksV1>>, TError, TData, Awaited<ReturnType<typeof getSyncAsksV1>>, QueryKey, GetSyncAsksV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSyncAsksV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSyncAsksV1QueryOptions = <TData = Awaited<ReturnType<typeof getSyncAsksV1>>, TError = ErrorType<unknown>>(params?: GetSyncAsksV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSyncAsksV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSyncAsksV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSyncAsksV1>>> = ({ signal }) => getSyncAsksV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSyncAsksV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSyncAsksV1QueryResult = NonNullable<Awaited<ReturnType<typeof getSyncAsksV1>>>
export type GetSyncAsksV1QueryError = ErrorType<unknown>

/**
 * @summary Sync Asks (listings)
 */
export const useGetSyncAsksV1 = <TData = Awaited<ReturnType<typeof getSyncAsksV1>>, TError = ErrorType<unknown>>(
 params?: GetSyncAsksV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSyncAsksV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSyncAsksV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get the latest price event per token in a collection, so that you can listen to future events and keep track of prices
 * @summary Token Events Bootstrap
 */
export const getTokensBootstrapV1 = (
    params?: GetTokensBootstrapV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTokensBootstrapV1Response>(
      {url: `/tokens/bootstrap/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTokensBootstrapV1QueryKey = (params?: GetTokensBootstrapV1Params,) => {
    return [`/tokens/bootstrap/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokensBootstrapV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensBootstrapV1>>, GetTokensBootstrapV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetTokensBootstrapV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensBootstrapV1>>, TError, TData, Awaited<ReturnType<typeof getTokensBootstrapV1>>, QueryKey, GetTokensBootstrapV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensBootstrapV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensBootstrapV1>>, QueryKey, GetTokensBootstrapV1Params['continuation']> = ({ signal, pageParam }) => getTokensBootstrapV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensBootstrapV1>>, TError, TData, Awaited<ReturnType<typeof getTokensBootstrapV1>>, QueryKey, GetTokensBootstrapV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetTokensBootstrapV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTokensBootstrapV1>>>
export type GetTokensBootstrapV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Token Events Bootstrap
 */
export const useGetTokensBootstrapV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensBootstrapV1>>, GetTokensBootstrapV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetTokensBootstrapV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensBootstrapV1>>, TError, TData, Awaited<ReturnType<typeof getTokensBootstrapV1>>, QueryKey, GetTokensBootstrapV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensBootstrapV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokensBootstrapV1QueryOptions = <TData = Awaited<ReturnType<typeof getTokensBootstrapV1>>, TError = ErrorType<unknown>>(params?: GetTokensBootstrapV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensBootstrapV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensBootstrapV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensBootstrapV1>>> = ({ signal }) => getTokensBootstrapV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokensBootstrapV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokensBootstrapV1QueryResult = NonNullable<Awaited<ReturnType<typeof getTokensBootstrapV1>>>
export type GetTokensBootstrapV1QueryError = ErrorType<unknown>

/**
 * @summary Token Events Bootstrap
 */
export const useGetTokensBootstrapV1 = <TData = Awaited<ReturnType<typeof getTokensBootstrapV1>>, TError = ErrorType<unknown>>(
 params?: GetTokensBootstrapV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensBootstrapV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensBootstrapV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a list of tokens with full metadata. This is useful for showing a single token page, or scenarios that require more metadata. If you don't need this metadata, you should use the <a href='#/tokens/getTokensV1'>tokens</a> API, which is much faster.
 * @deprecated
 * @summary Tokens (detailed response)
 */
export const getTokensDetailsV4 = (
    params?: GetTokensDetailsV4Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTokensDetailsV4Response>(
      {url: `/tokens/details/v4`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTokensDetailsV4QueryKey = (params?: GetTokensDetailsV4Params,) => {
    return [`/tokens/details/v4`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokensDetailsV4InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensDetailsV4>>, GetTokensDetailsV4Params['continuation']>, TError = ErrorType<unknown>>(params?: GetTokensDetailsV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensDetailsV4>>, TError, TData, Awaited<ReturnType<typeof getTokensDetailsV4>>, QueryKey, GetTokensDetailsV4Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensDetailsV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensDetailsV4>>, QueryKey, GetTokensDetailsV4Params['continuation']> = ({ signal, pageParam }) => getTokensDetailsV4({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensDetailsV4>>, TError, TData, Awaited<ReturnType<typeof getTokensDetailsV4>>, QueryKey, GetTokensDetailsV4Params['continuation']> & { queryKey: QueryKey }
}

export type GetTokensDetailsV4InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTokensDetailsV4>>>
export type GetTokensDetailsV4InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Tokens (detailed response)
 */
export const useGetTokensDetailsV4Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensDetailsV4>>, GetTokensDetailsV4Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetTokensDetailsV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensDetailsV4>>, TError, TData, Awaited<ReturnType<typeof getTokensDetailsV4>>, QueryKey, GetTokensDetailsV4Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensDetailsV4InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokensDetailsV4QueryOptions = <TData = Awaited<ReturnType<typeof getTokensDetailsV4>>, TError = ErrorType<unknown>>(params?: GetTokensDetailsV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensDetailsV4>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensDetailsV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensDetailsV4>>> = ({ signal }) => getTokensDetailsV4(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokensDetailsV4>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokensDetailsV4QueryResult = NonNullable<Awaited<ReturnType<typeof getTokensDetailsV4>>>
export type GetTokensDetailsV4QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Tokens (detailed response)
 */
export const useGetTokensDetailsV4 = <TData = Awaited<ReturnType<typeof getTokensDetailsV4>>, TError = ErrorType<unknown>>(
 params?: GetTokensDetailsV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensDetailsV4>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensDetailsV4QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API will return the best price of every token in a collection that is currently on sale. Note: Prices are returned in the native currency of the network.
 * @summary Token Prices
 */
export const getTokensFloorV1 = (
    params?: GetTokensFloorV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTokensFloorV1Response>(
      {url: `/tokens/floor/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTokensFloorV1QueryKey = (params?: GetTokensFloorV1Params,) => {
    return [`/tokens/floor/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokensFloorV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensFloorV1>>, GetTokensFloorV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetTokensFloorV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensFloorV1>>, TError, TData, Awaited<ReturnType<typeof getTokensFloorV1>>, QueryKey, GetTokensFloorV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensFloorV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensFloorV1>>, QueryKey, GetTokensFloorV1Params['continuation']> = ({ signal, pageParam }) => getTokensFloorV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensFloorV1>>, TError, TData, Awaited<ReturnType<typeof getTokensFloorV1>>, QueryKey, GetTokensFloorV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetTokensFloorV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTokensFloorV1>>>
export type GetTokensFloorV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Token Prices
 */
export const useGetTokensFloorV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensFloorV1>>, GetTokensFloorV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetTokensFloorV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensFloorV1>>, TError, TData, Awaited<ReturnType<typeof getTokensFloorV1>>, QueryKey, GetTokensFloorV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensFloorV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokensFloorV1QueryOptions = <TData = Awaited<ReturnType<typeof getTokensFloorV1>>, TError = ErrorType<unknown>>(params?: GetTokensFloorV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensFloorV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensFloorV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensFloorV1>>> = ({ signal }) => getTokensFloorV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokensFloorV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokensFloorV1QueryResult = NonNullable<Awaited<ReturnType<typeof getTokensFloorV1>>>
export type GetTokensFloorV1QueryError = ErrorType<unknown>

/**
 * @summary Token Prices
 */
export const useGetTokensFloorV1 = <TData = Awaited<ReturnType<typeof getTokensFloorV1>>, TError = ErrorType<unknown>>(
 params?: GetTokensFloorV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensFloorV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensFloorV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API is optimized for quickly fetching a list of tokens ids in by collection, contract, token set id. 
 * @summary Token IDs
 */
export const getTokensIdsV1 = (
    params?: GetTokensIdsV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTokensIdsV1Response>(
      {url: `/tokens/ids/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTokensIdsV1QueryKey = (params?: GetTokensIdsV1Params,) => {
    return [`/tokens/ids/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokensIdsV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensIdsV1>>, GetTokensIdsV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetTokensIdsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensIdsV1>>, TError, TData, Awaited<ReturnType<typeof getTokensIdsV1>>, QueryKey, GetTokensIdsV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensIdsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensIdsV1>>, QueryKey, GetTokensIdsV1Params['continuation']> = ({ signal, pageParam }) => getTokensIdsV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensIdsV1>>, TError, TData, Awaited<ReturnType<typeof getTokensIdsV1>>, QueryKey, GetTokensIdsV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetTokensIdsV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTokensIdsV1>>>
export type GetTokensIdsV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Token IDs
 */
export const useGetTokensIdsV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensIdsV1>>, GetTokensIdsV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetTokensIdsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensIdsV1>>, TError, TData, Awaited<ReturnType<typeof getTokensIdsV1>>, QueryKey, GetTokensIdsV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensIdsV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokensIdsV1QueryOptions = <TData = Awaited<ReturnType<typeof getTokensIdsV1>>, TError = ErrorType<unknown>>(params?: GetTokensIdsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensIdsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensIdsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensIdsV1>>> = ({ signal }) => getTokensIdsV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokensIdsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokensIdsV1QueryResult = NonNullable<Awaited<ReturnType<typeof getTokensIdsV1>>>
export type GetTokensIdsV1QueryError = ErrorType<unknown>

/**
 * @summary Token IDs
 */
export const useGetTokensIdsV1 = <TData = Awaited<ReturnType<typeof getTokensIdsV1>>, TError = ErrorType<unknown>>(
 params?: GetTokensIdsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensIdsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensIdsV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Note: this API is optimized for bulk access, and offers minimal filters/metadata. If you need more flexibility, try the `NFT API > Transfers` endpoint
 * @summary Bulk historical transfers
 */
export const getTransfersBulkV2 = (
    params?: GetTransfersBulkV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTransfersBulkV2Response>(
      {url: `/transfers/bulk/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTransfersBulkV2QueryKey = (params?: GetTransfersBulkV2Params,) => {
    return [`/transfers/bulk/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTransfersBulkV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTransfersBulkV2>>, GetTransfersBulkV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetTransfersBulkV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTransfersBulkV2>>, TError, TData, Awaited<ReturnType<typeof getTransfersBulkV2>>, QueryKey, GetTransfersBulkV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTransfersBulkV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransfersBulkV2>>, QueryKey, GetTransfersBulkV2Params['continuation']> = ({ signal, pageParam }) => getTransfersBulkV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTransfersBulkV2>>, TError, TData, Awaited<ReturnType<typeof getTransfersBulkV2>>, QueryKey, GetTransfersBulkV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetTransfersBulkV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTransfersBulkV2>>>
export type GetTransfersBulkV2InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Bulk historical transfers
 */
export const useGetTransfersBulkV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTransfersBulkV2>>, GetTransfersBulkV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetTransfersBulkV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTransfersBulkV2>>, TError, TData, Awaited<ReturnType<typeof getTransfersBulkV2>>, QueryKey, GetTransfersBulkV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTransfersBulkV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTransfersBulkV2QueryOptions = <TData = Awaited<ReturnType<typeof getTransfersBulkV2>>, TError = ErrorType<unknown>>(params?: GetTransfersBulkV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransfersBulkV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTransfersBulkV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransfersBulkV2>>> = ({ signal }) => getTransfersBulkV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTransfersBulkV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTransfersBulkV2QueryResult = NonNullable<Awaited<ReturnType<typeof getTransfersBulkV2>>>
export type GetTransfersBulkV2QueryError = ErrorType<unknown>

/**
 * @summary Bulk historical transfers
 */
export const useGetTransfersBulkV2 = <TData = Awaited<ReturnType<typeof getTransfersBulkV2>>, TError = ErrorType<unknown>>(
 params?: GetTransfersBulkV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransfersBulkV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTransfersBulkV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to build a feed for a user including sales, asks, transfers, mints, bids, cancelled bids, and cancelled asks types.
 * @summary Users activity
 */
export const getUsersActivityV6 = (
    params: GetUsersActivityV6Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUsersActivityV6Response>(
      {url: `/users/activity/v6`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUsersActivityV6QueryKey = (params: GetUsersActivityV6Params,) => {
    return [`/users/activity/v6`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUsersActivityV6InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersActivityV6>>, GetUsersActivityV6Params['continuation']>, TError = ErrorType<unknown>>(params: GetUsersActivityV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersActivityV6>>, TError, TData, Awaited<ReturnType<typeof getUsersActivityV6>>, QueryKey, GetUsersActivityV6Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersActivityV6QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersActivityV6>>, QueryKey, GetUsersActivityV6Params['continuation']> = ({ signal, pageParam }) => getUsersActivityV6({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersActivityV6>>, TError, TData, Awaited<ReturnType<typeof getUsersActivityV6>>, QueryKey, GetUsersActivityV6Params['continuation']> & { queryKey: QueryKey }
}

export type GetUsersActivityV6InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersActivityV6>>>
export type GetUsersActivityV6InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Users activity
 */
export const useGetUsersActivityV6Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersActivityV6>>, GetUsersActivityV6Params['continuation']>, TError = ErrorType<unknown>>(
 params: GetUsersActivityV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersActivityV6>>, TError, TData, Awaited<ReturnType<typeof getUsersActivityV6>>, QueryKey, GetUsersActivityV6Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersActivityV6InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUsersActivityV6QueryOptions = <TData = Awaited<ReturnType<typeof getUsersActivityV6>>, TError = ErrorType<unknown>>(params: GetUsersActivityV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersActivityV6>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersActivityV6QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersActivityV6>>> = ({ signal }) => getUsersActivityV6(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsersActivityV6>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersActivityV6QueryResult = NonNullable<Awaited<ReturnType<typeof getUsersActivityV6>>>
export type GetUsersActivityV6QueryError = ErrorType<unknown>

/**
 * @summary Users activity
 */
export const useGetUsersActivityV6 = <TData = Awaited<ReturnType<typeof getUsersActivityV6>>, TError = ErrorType<unknown>>(
 params: GetUsersActivityV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersActivityV6>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersActivityV6QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * The ReservoirKit `ListModal` client utilizes this API to identify the marketplace(s) it can list on.
 * @summary Supported marketplaces by collection
 */
export const getCollectionsCollectionSupportedmarketplacesV1 = (
    collection: string,
    params?: GetCollectionsCollectionSupportedmarketplacesV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsCollectionSupportedMarketplacesV1Response>(
      {url: `/collections/${collection}/supported-marketplaces/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsCollectionSupportedmarketplacesV1QueryKey = (collection: string,
    params?: GetCollectionsCollectionSupportedmarketplacesV1Params,) => {
    return [`/collections/${collection}/supported-marketplaces/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsCollectionSupportedmarketplacesV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, GetCollectionsCollectionSupportedmarketplacesV1Params['continuation']>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionSupportedmarketplacesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, QueryKey, GetCollectionsCollectionSupportedmarketplacesV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionSupportedmarketplacesV1QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, QueryKey, GetCollectionsCollectionSupportedmarketplacesV1Params['continuation']> = ({ signal, pageParam }) => getCollectionsCollectionSupportedmarketplacesV1(collection,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, QueryKey, GetCollectionsCollectionSupportedmarketplacesV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionSupportedmarketplacesV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>>
export type GetCollectionsCollectionSupportedmarketplacesV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Supported marketplaces by collection
 */
export const useGetCollectionsCollectionSupportedmarketplacesV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, GetCollectionsCollectionSupportedmarketplacesV1Params['continuation']>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionSupportedmarketplacesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, QueryKey, GetCollectionsCollectionSupportedmarketplacesV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionSupportedmarketplacesV1InfiniteQueryOptions(collection,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionSupportedmarketplacesV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionSupportedmarketplacesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionSupportedmarketplacesV1QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>> = ({ signal }) => getCollectionsCollectionSupportedmarketplacesV1(collection,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionSupportedmarketplacesV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>>
export type GetCollectionsCollectionSupportedmarketplacesV1QueryError = ErrorType<unknown>

/**
 * @summary Supported marketplaces by collection
 */
export const useGetCollectionsCollectionSupportedmarketplacesV1 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionSupportedmarketplacesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionSupportedmarketplacesV1QueryOptions(collection,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @deprecated
 * @summary Get detailed aggregate about attributes in a collection, e.g. trait floors
 */
export const getCollectionsCollectionAttributesV1 = (
    collection: string,
    params?: GetCollectionsCollectionAttributesV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsCollectionAttributesV1Response>(
      {url: `/collections/${collection}/attributes/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsCollectionAttributesV1QueryKey = (collection: string,
    params?: GetCollectionsCollectionAttributesV1Params,) => {
    return [`/collections/${collection}/attributes/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsCollectionAttributesV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, GetCollectionsCollectionAttributesV1Params['continuation']>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionAttributesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, QueryKey, GetCollectionsCollectionAttributesV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesV1QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, QueryKey, GetCollectionsCollectionAttributesV1Params['continuation']> = ({ signal, pageParam }) => getCollectionsCollectionAttributesV1(collection,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, QueryKey, GetCollectionsCollectionAttributesV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>>
export type GetCollectionsCollectionAttributesV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get detailed aggregate about attributes in a collection, e.g. trait floors
 */
export const useGetCollectionsCollectionAttributesV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, GetCollectionsCollectionAttributesV1Params['continuation']>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionAttributesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, QueryKey, GetCollectionsCollectionAttributesV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesV1InfiniteQueryOptions(collection,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionAttributesV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionAttributesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesV1QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>> = ({ signal }) => getCollectionsCollectionAttributesV1(collection,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>>
export type GetCollectionsCollectionAttributesV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get detailed aggregate about attributes in a collection, e.g. trait floors
 */
export const useGetCollectionsCollectionAttributesV1 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionAttributesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesV1QueryOptions(collection,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * When users are placing collection or trait bids, this API can be used to show them where the bid is in the context of other bids, and how many tokens it will be the top bid for.
 * @deprecated
 * @summary Bid distribution
 */
export const getCollectionsCollectionTopbidsV1 = (
    collection: string,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsCollectionTopBidsV1Response>(
      {url: `/collections/${collection}/top-bids/v1`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCollectionsCollectionTopbidsV1QueryKey = (collection: string,) => {
    return [`/collections/${collection}/top-bids/v1`] as const;
    }

    
export const getGetCollectionsCollectionTopbidsV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>>, TError = ErrorType<unknown>>(collection: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionTopbidsV1QueryKey(collection);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>> = ({ signal }) => getCollectionsCollectionTopbidsV1(collection, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionTopbidsV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>>
export type GetCollectionsCollectionTopbidsV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Bid distribution
 */
export const useGetCollectionsCollectionTopbidsV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>>, TError = ErrorType<unknown>>(
 collection: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionTopbidsV1InfiniteQueryOptions(collection,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionTopbidsV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>, TError = ErrorType<unknown>>(collection: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionTopbidsV1QueryKey(collection);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>> = ({ signal }) => getCollectionsCollectionTopbidsV1(collection, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionTopbidsV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>>
export type GetCollectionsCollectionTopbidsV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Bid distribution
 */
export const useGetCollectionsCollectionTopbidsV1 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>, TError = ErrorType<unknown>>(
 collection: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionTopbidsV1QueryOptions(collection,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to show what the distribution of owners in a collection looks like.
 * @summary Owners Collection Distribution
 */
export const getCollectionsCollectionOwnersdistributionV1 = (
    collection: string,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsCollectionOwnersDistributionV1Response>(
      {url: `/collections/${collection}/owners-distribution/v1`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCollectionsCollectionOwnersdistributionV1QueryKey = (collection: string,) => {
    return [`/collections/${collection}/owners-distribution/v1`] as const;
    }

    
export const getGetCollectionsCollectionOwnersdistributionV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>>, TError = ErrorType<unknown>>(collection: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionOwnersdistributionV1QueryKey(collection);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>> = ({ signal }) => getCollectionsCollectionOwnersdistributionV1(collection, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionOwnersdistributionV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>>
export type GetCollectionsCollectionOwnersdistributionV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Owners Collection Distribution
 */
export const useGetCollectionsCollectionOwnersdistributionV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>>, TError = ErrorType<unknown>>(
 collection: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionOwnersdistributionV1InfiniteQueryOptions(collection,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionOwnersdistributionV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>, TError = ErrorType<unknown>>(collection: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionOwnersdistributionV1QueryKey(collection);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>> = ({ signal }) => getCollectionsCollectionOwnersdistributionV1(collection, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionOwnersdistributionV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>>
export type GetCollectionsCollectionOwnersdistributionV1QueryError = ErrorType<unknown>

/**
 * @summary Owners Collection Distribution
 */
export const useGetCollectionsCollectionOwnersdistributionV1 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>, TError = ErrorType<unknown>>(
 collection: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionOwnersdistributionV1QueryOptions(collection,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API returns recommended marketplace configurations given a collection id
 * @summary Marketplace configurations by collection
 */
export const getCollectionsCollectionMarketplaceconfigurationsV1 = (
    collection: string,
    params?: GetCollectionsCollectionMarketplaceconfigurationsV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsCollectionMarketplaceConfigurationsV1Response>(
      {url: `/collections/${collection}/marketplace-configurations/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsCollectionMarketplaceconfigurationsV1QueryKey = (collection: string,
    params?: GetCollectionsCollectionMarketplaceconfigurationsV1Params,) => {
    return [`/collections/${collection}/marketplace-configurations/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsCollectionMarketplaceconfigurationsV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, GetCollectionsCollectionMarketplaceconfigurationsV1Params['continuation']>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionMarketplaceconfigurationsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, QueryKey, GetCollectionsCollectionMarketplaceconfigurationsV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionMarketplaceconfigurationsV1QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, QueryKey, GetCollectionsCollectionMarketplaceconfigurationsV1Params['continuation']> = ({ signal, pageParam }) => getCollectionsCollectionMarketplaceconfigurationsV1(collection,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, QueryKey, GetCollectionsCollectionMarketplaceconfigurationsV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionMarketplaceconfigurationsV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>>
export type GetCollectionsCollectionMarketplaceconfigurationsV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Marketplace configurations by collection
 */
export const useGetCollectionsCollectionMarketplaceconfigurationsV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, GetCollectionsCollectionMarketplaceconfigurationsV1Params['continuation']>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionMarketplaceconfigurationsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, QueryKey, GetCollectionsCollectionMarketplaceconfigurationsV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionMarketplaceconfigurationsV1InfiniteQueryOptions(collection,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionMarketplaceconfigurationsV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionMarketplaceconfigurationsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionMarketplaceconfigurationsV1QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>> = ({ signal }) => getCollectionsCollectionMarketplaceconfigurationsV1(collection,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionMarketplaceconfigurationsV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>>
export type GetCollectionsCollectionMarketplaceconfigurationsV1QueryError = ErrorType<unknown>

/**
 * @summary Marketplace configurations by collection
 */
export const useGetCollectionsCollectionMarketplaceconfigurationsV1 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionMarketplaceconfigurationsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionMarketplaceconfigurationsV1QueryOptions(collection,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to show what the distribution of owners in a collections set id looks like.
 * @summary Owners Collection Set Distribution
 */
export const getCollectionssetsCollectionssetidOwnersdistributionV1 = (
    collectionsSetId: string,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsSetsCollectionsSetIdOwnersDistributionV1Response>(
      {url: `/collections-sets/${collectionsSetId}/owners-distribution/v1`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCollectionssetsCollectionssetidOwnersdistributionV1QueryKey = (collectionsSetId: string,) => {
    return [`/collections-sets/${collectionsSetId}/owners-distribution/v1`] as const;
    }

    
export const getGetCollectionssetsCollectionssetidOwnersdistributionV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>>, TError = ErrorType<unknown>>(collectionsSetId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionssetsCollectionssetidOwnersdistributionV1QueryKey(collectionsSetId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>> = ({ signal }) => getCollectionssetsCollectionssetidOwnersdistributionV1(collectionsSetId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collectionsSetId),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionssetsCollectionssetidOwnersdistributionV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>>
export type GetCollectionssetsCollectionssetidOwnersdistributionV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Owners Collection Set Distribution
 */
export const useGetCollectionssetsCollectionssetidOwnersdistributionV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>>, TError = ErrorType<unknown>>(
 collectionsSetId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionssetsCollectionssetidOwnersdistributionV1InfiniteQueryOptions(collectionsSetId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionssetsCollectionssetidOwnersdistributionV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>, TError = ErrorType<unknown>>(collectionsSetId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionssetsCollectionssetidOwnersdistributionV1QueryKey(collectionsSetId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>> = ({ signal }) => getCollectionssetsCollectionssetidOwnersdistributionV1(collectionsSetId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collectionsSetId),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionssetsCollectionssetidOwnersdistributionV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>>
export type GetCollectionssetsCollectionssetidOwnersdistributionV1QueryError = ErrorType<unknown>

/**
 * @summary Owners Collection Set Distribution
 */
export const useGetCollectionssetsCollectionssetidOwnersdistributionV1 = <TData = Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>, TError = ErrorType<unknown>>(
 collectionsSetId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionssetsCollectionssetidOwnersdistributionV1QueryOptions(collectionsSetId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Every time the floor price of a collection changes (i.e. the 'floor ask'), an event is generated. This API is designed to be polled at high frequency, in order to keep an external system in sync with accurate prices for any token.

There are multiple event types, which describe what caused the change in price:

- `new-order` > new listing at a lower price

- `expiry` > the previous best listing expired

- `sale` > the previous best listing was filled

- `cancel` > the previous best listing was cancelled

- `balance-change` > the best listing was invalidated due to no longer owning the NFT

- `approval-change` > the best listing was invalidated due to revoked approval

- `revalidation` > manual revalidation of orders (e.g. after a bug fixed)

- `reprice` > price update for dynamic orders (e.g. dutch auctions)

- `bootstrap` > initial loading of data, so that all tokens have a price associated

Some considerations to keep in mind

- Selling a partial quantity of available 1155 tokens in a listing will generate a `sale` and will have a new quantity.

- Due to the complex nature of monitoring off-chain liquidity across multiple marketplaces, including dealing with block re-orgs, events should be considered 'relative' to the perspective of the indexer, ie _when they were discovered_, rather than _when they happened_. A more deterministic historical record of price changes is in development, but in the meantime, this method is sufficent for keeping an external system in sync with the best available prices.

- Events are only generated if the best price changes. So if a new order or sale happens without changing the best price, no event is generated. This is more common with 1155 tokens, which have multiple owners and more depth. For this reason, if you need sales data, use the Sales API.
 * @summary Collection floor changes
 */
export const getEventsCollectionsFlooraskV2 = (
    params?: GetEventsCollectionsFlooraskV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetEventsCollectionsFloorAskV2Response>(
      {url: `/events/collections/floor-ask/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetEventsCollectionsFlooraskV2QueryKey = (params?: GetEventsCollectionsFlooraskV2Params,) => {
    return [`/events/collections/floor-ask/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetEventsCollectionsFlooraskV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, GetEventsCollectionsFlooraskV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetEventsCollectionsFlooraskV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, TError, TData, Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, QueryKey, GetEventsCollectionsFlooraskV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsCollectionsFlooraskV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, QueryKey, GetEventsCollectionsFlooraskV2Params['continuation']> = ({ signal, pageParam }) => getEventsCollectionsFlooraskV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, TError, TData, Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, QueryKey, GetEventsCollectionsFlooraskV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetEventsCollectionsFlooraskV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>>
export type GetEventsCollectionsFlooraskV2InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Collection floor changes
 */
export const useGetEventsCollectionsFlooraskV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, GetEventsCollectionsFlooraskV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetEventsCollectionsFlooraskV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, TError, TData, Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, QueryKey, GetEventsCollectionsFlooraskV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsCollectionsFlooraskV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetEventsCollectionsFlooraskV2QueryOptions = <TData = Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, TError = ErrorType<unknown>>(params?: GetEventsCollectionsFlooraskV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsCollectionsFlooraskV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>> = ({ signal }) => getEventsCollectionsFlooraskV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEventsCollectionsFlooraskV2QueryResult = NonNullable<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>>
export type GetEventsCollectionsFlooraskV2QueryError = ErrorType<unknown>

/**
 * @summary Collection floor changes
 */
export const useGetEventsCollectionsFlooraskV2 = <TData = Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, TError = ErrorType<unknown>>(
 params?: GetEventsCollectionsFlooraskV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsCollectionsFlooraskV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Every time the top offer of a collection changes (i.e. the 'top bid'), an event is generated. This API is designed to be polled at high frequency.

There are multiple event types, which describe what caused the change in price:

- `new-order` > new bid at a higher price

- `expiry` > the previous top bid expired

- `sale` > the previous top bid was accepted

- `cancel` > the previous top bid was cancelled

- `balance-change` > the top bid was invalidated due NFT no longer available

- `approval-change` > the top bid was invalidated due to revoked approval

- `revalidation` > manual revalidation of orders (e.g. after a bug fixed)

- `reprice` > price update for dynamic orders (e.g. dutch auctions)

- `bootstrap` > initial loading of data, so that all tokens have a price associated

Some considerations to keep in mind

- Selling a partial quantity of available 1155 tokens in a listing will generate a `sale` and will have a new quantity.

- Due to the complex nature of monitoring off-chain liquidity across multiple marketplaces, including dealing with block re-orgs, events should be considered 'relative' to the perspective of the indexer, ie _when they were discovered_, rather than _when they happened_. A more deterministic historical record of price changes is in development, but in the meantime, this method is sufficent for keeping an external system in sync with the best available prices.

- Events are only generated if the top bid changes. So if a new order or sale happens without changing the top bid, no event is generated. This is more common with 1155 tokens, which have multiple owners and more depth. For this reason, if you need sales data, use the Sales API.
 * @summary Collection top bid changes
 */
export const getEventsCollectionsTopbidV2 = (
    params?: GetEventsCollectionsTopbidV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetEventsCollectionsTopBidV2Response>(
      {url: `/events/collections/top-bid/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetEventsCollectionsTopbidV2QueryKey = (params?: GetEventsCollectionsTopbidV2Params,) => {
    return [`/events/collections/top-bid/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetEventsCollectionsTopbidV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, GetEventsCollectionsTopbidV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetEventsCollectionsTopbidV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, TError, TData, Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, QueryKey, GetEventsCollectionsTopbidV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsCollectionsTopbidV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, QueryKey, GetEventsCollectionsTopbidV2Params['continuation']> = ({ signal, pageParam }) => getEventsCollectionsTopbidV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, TError, TData, Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, QueryKey, GetEventsCollectionsTopbidV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetEventsCollectionsTopbidV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>>
export type GetEventsCollectionsTopbidV2InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Collection top bid changes
 */
export const useGetEventsCollectionsTopbidV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, GetEventsCollectionsTopbidV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetEventsCollectionsTopbidV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, TError, TData, Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, QueryKey, GetEventsCollectionsTopbidV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsCollectionsTopbidV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetEventsCollectionsTopbidV2QueryOptions = <TData = Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, TError = ErrorType<unknown>>(params?: GetEventsCollectionsTopbidV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsCollectionsTopbidV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>> = ({ signal }) => getEventsCollectionsTopbidV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEventsCollectionsTopbidV2QueryResult = NonNullable<Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>>
export type GetEventsCollectionsTopbidV2QueryError = ErrorType<unknown>

/**
 * @summary Collection top bid changes
 */
export const useGetEventsCollectionsTopbidV2 = <TData = Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, TError = ErrorType<unknown>>(
 params?: GetEventsCollectionsTopbidV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsCollectionsTopbidV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Every time the best price of a token changes (i.e. the 'floor ask'), an event is generated. This API is designed to be polled at high frequency, in order to keep an external system in sync with accurate prices for any token.

There are multiple event types, which describe what caused the change in price:

- `new-order` > new listing at a lower price

- `expiry` > the previous best listing expired

- `sale` > the previous best listing was filled

- `cancel` > the previous best listing was cancelled

- `balance-change` > the best listing was invalidated due to no longer owning the NFT

- `approval-change` > the best listing was invalidated due to revoked approval

- `revalidation` > manual revalidation of orders (e.g. after a bug fixed)

- `reprice` > price update for dynamic orders (e.g. dutch auctions)

- `bootstrap` > initial loading of data, so that all tokens have a price associated

Some considerations to keep in mind

- Selling a partial quantity of available 1155 tokens in a listing will generate a `sale` and will have a new quantity.

- Due to the complex nature of monitoring off-chain liquidity across multiple marketplaces, including dealing with block re-orgs, events should be considered 'relative' to the perspective of the indexer, ie _when they were discovered_, rather than _when they happened_. A more deterministic historical record of price changes is in development, but in the meantime, this method is sufficent for keeping an external system in sync with the best available prices.

- Events are only generated if the best price changes. So if a new order or sale happens without changing the best price, no event is generated. This is more common with 1155 tokens, which have multiple owners and more depth. For this reason, if you need sales data, use the Sales API.
 * @summary Token price changes
 */
export const getEventsTokensFlooraskV4 = (
    params?: GetEventsTokensFlooraskV4Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetEventsTokensFloorAskV4Response>(
      {url: `/events/tokens/floor-ask/v4`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetEventsTokensFlooraskV4QueryKey = (params?: GetEventsTokensFlooraskV4Params,) => {
    return [`/events/tokens/floor-ask/v4`, ...(params ? [params]: [])] as const;
    }

    
export const getGetEventsTokensFlooraskV4InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, GetEventsTokensFlooraskV4Params['continuation']>, TError = ErrorType<unknown>>(params?: GetEventsTokensFlooraskV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, TError, TData, Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, QueryKey, GetEventsTokensFlooraskV4Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsTokensFlooraskV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, QueryKey, GetEventsTokensFlooraskV4Params['continuation']> = ({ signal, pageParam }) => getEventsTokensFlooraskV4({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, TError, TData, Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, QueryKey, GetEventsTokensFlooraskV4Params['continuation']> & { queryKey: QueryKey }
}

export type GetEventsTokensFlooraskV4InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>>
export type GetEventsTokensFlooraskV4InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Token price changes
 */
export const useGetEventsTokensFlooraskV4Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, GetEventsTokensFlooraskV4Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetEventsTokensFlooraskV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, TError, TData, Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, QueryKey, GetEventsTokensFlooraskV4Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsTokensFlooraskV4InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetEventsTokensFlooraskV4QueryOptions = <TData = Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, TError = ErrorType<unknown>>(params?: GetEventsTokensFlooraskV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsTokensFlooraskV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>> = ({ signal }) => getEventsTokensFlooraskV4(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEventsTokensFlooraskV4QueryResult = NonNullable<Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>>
export type GetEventsTokensFlooraskV4QueryError = ErrorType<unknown>

/**
 * @summary Token price changes
 */
export const useGetEventsTokensFlooraskV4 = <TData = Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, TError = ErrorType<unknown>>(
 params?: GetEventsTokensFlooraskV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsTokensFlooraskV4QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a signed message of any collection's bid-ask midpoint (spot or twap). This is approximation of the colletion price. The oracle's address is 0xAeB1D03929bF87F69888f381e73FBf75753d75AF. The address is the same for all chains.
 * @summary Collection bid-ask midpoint
 */
export const getOracleCollectionsBidaskmidpointV1 = (
    params?: GetOracleCollectionsBidaskmidpointV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOracleCollectionsBidAskMidpointV1Response>(
      {url: `/oracle/collections/bid-ask-midpoint/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOracleCollectionsBidaskmidpointV1QueryKey = (params?: GetOracleCollectionsBidaskmidpointV1Params,) => {
    return [`/oracle/collections/bid-ask-midpoint/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOracleCollectionsBidaskmidpointV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, GetOracleCollectionsBidaskmidpointV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOracleCollectionsBidaskmidpointV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, TError, TData, Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, QueryKey, GetOracleCollectionsBidaskmidpointV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOracleCollectionsBidaskmidpointV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, QueryKey, GetOracleCollectionsBidaskmidpointV1Params['continuation']> = ({ signal, pageParam }) => getOracleCollectionsBidaskmidpointV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, TError, TData, Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, QueryKey, GetOracleCollectionsBidaskmidpointV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetOracleCollectionsBidaskmidpointV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>>
export type GetOracleCollectionsBidaskmidpointV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Collection bid-ask midpoint
 */
export const useGetOracleCollectionsBidaskmidpointV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, GetOracleCollectionsBidaskmidpointV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOracleCollectionsBidaskmidpointV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, TError, TData, Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, QueryKey, GetOracleCollectionsBidaskmidpointV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOracleCollectionsBidaskmidpointV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOracleCollectionsBidaskmidpointV1QueryOptions = <TData = Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, TError = ErrorType<unknown>>(params?: GetOracleCollectionsBidaskmidpointV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOracleCollectionsBidaskmidpointV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>> = ({ signal }) => getOracleCollectionsBidaskmidpointV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOracleCollectionsBidaskmidpointV1QueryResult = NonNullable<Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>>
export type GetOracleCollectionsBidaskmidpointV1QueryError = ErrorType<unknown>

/**
 * @summary Collection bid-ask midpoint
 */
export const useGetOracleCollectionsBidaskmidpointV1 = <TData = Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, TError = ErrorType<unknown>>(
 params?: GetOracleCollectionsBidaskmidpointV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOracleCollectionsBidaskmidpointV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a signed message of any collection's floor price (spot or twap). The oracle's address is 0xAeB1D03929bF87F69888f381e73FBf75753d75AF. The address is the same for all chains.
 * @summary Collection floor
 */
export const getOracleCollectionsFlooraskV6 = (
    params?: GetOracleCollectionsFlooraskV6Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOracleCollectionsFloorAskV6Response>(
      {url: `/oracle/collections/floor-ask/v6`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOracleCollectionsFlooraskV6QueryKey = (params?: GetOracleCollectionsFlooraskV6Params,) => {
    return [`/oracle/collections/floor-ask/v6`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOracleCollectionsFlooraskV6InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, GetOracleCollectionsFlooraskV6Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOracleCollectionsFlooraskV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, TError, TData, Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, QueryKey, GetOracleCollectionsFlooraskV6Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOracleCollectionsFlooraskV6QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, QueryKey, GetOracleCollectionsFlooraskV6Params['continuation']> = ({ signal, pageParam }) => getOracleCollectionsFlooraskV6({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, TError, TData, Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, QueryKey, GetOracleCollectionsFlooraskV6Params['continuation']> & { queryKey: QueryKey }
}

export type GetOracleCollectionsFlooraskV6InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>>
export type GetOracleCollectionsFlooraskV6InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Collection floor
 */
export const useGetOracleCollectionsFlooraskV6Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, GetOracleCollectionsFlooraskV6Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOracleCollectionsFlooraskV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, TError, TData, Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, QueryKey, GetOracleCollectionsFlooraskV6Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOracleCollectionsFlooraskV6InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOracleCollectionsFlooraskV6QueryOptions = <TData = Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, TError = ErrorType<unknown>>(params?: GetOracleCollectionsFlooraskV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOracleCollectionsFlooraskV6QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>> = ({ signal }) => getOracleCollectionsFlooraskV6(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOracleCollectionsFlooraskV6QueryResult = NonNullable<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>>
export type GetOracleCollectionsFlooraskV6QueryError = ErrorType<unknown>

/**
 * @summary Collection floor
 */
export const useGetOracleCollectionsFlooraskV6 = <TData = Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, TError = ErrorType<unknown>>(
 params?: GetOracleCollectionsFlooraskV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOracleCollectionsFlooraskV6QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a signed message of any collection's top bid price (spot or twap). The oracle's address is 0xAeB1D03929bF87F69888f381e73FBf75753d75AF. The address is the same for all chains.
 * @summary Collection top bid oracle
 */
export const getOracleCollectionsTopbidV3 = (
    params?: GetOracleCollectionsTopbidV3Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOracleCollectionsTopBidV3Response>(
      {url: `/oracle/collections/top-bid/v3`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOracleCollectionsTopbidV3QueryKey = (params?: GetOracleCollectionsTopbidV3Params,) => {
    return [`/oracle/collections/top-bid/v3`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOracleCollectionsTopbidV3InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, GetOracleCollectionsTopbidV3Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOracleCollectionsTopbidV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, TError, TData, Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, QueryKey, GetOracleCollectionsTopbidV3Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOracleCollectionsTopbidV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, QueryKey, GetOracleCollectionsTopbidV3Params['continuation']> = ({ signal, pageParam }) => getOracleCollectionsTopbidV3({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, TError, TData, Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, QueryKey, GetOracleCollectionsTopbidV3Params['continuation']> & { queryKey: QueryKey }
}

export type GetOracleCollectionsTopbidV3InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>>
export type GetOracleCollectionsTopbidV3InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Collection top bid oracle
 */
export const useGetOracleCollectionsTopbidV3Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, GetOracleCollectionsTopbidV3Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOracleCollectionsTopbidV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, TError, TData, Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, QueryKey, GetOracleCollectionsTopbidV3Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOracleCollectionsTopbidV3InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOracleCollectionsTopbidV3QueryOptions = <TData = Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, TError = ErrorType<unknown>>(params?: GetOracleCollectionsTopbidV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOracleCollectionsTopbidV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>> = ({ signal }) => getOracleCollectionsTopbidV3(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOracleCollectionsTopbidV3QueryResult = NonNullable<Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>>
export type GetOracleCollectionsTopbidV3QueryError = ErrorType<unknown>

/**
 * @summary Collection top bid oracle
 */
export const useGetOracleCollectionsTopbidV3 = <TData = Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, TError = ErrorType<unknown>>(
 params?: GetOracleCollectionsTopbidV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOracleCollectionsTopbidV3QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a signed message of a token's details (flagged status and last transfer time). The oracle's address is 0xAeB1D03929bF87F69888f381e73FBf75753d75AF. The address is the same for all chains.
 * @summary Token status oracle
 */
export const getOracleTokensStatusV3 = (
    params: GetOracleTokensStatusV3Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOracleTokensStatusV3Response>(
      {url: `/oracle/tokens/status/v3`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOracleTokensStatusV3QueryKey = (params: GetOracleTokensStatusV3Params,) => {
    return [`/oracle/tokens/status/v3`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOracleTokensStatusV3InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOracleTokensStatusV3>>, GetOracleTokensStatusV3Params['continuation']>, TError = ErrorType<unknown>>(params: GetOracleTokensStatusV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleTokensStatusV3>>, TError, TData, Awaited<ReturnType<typeof getOracleTokensStatusV3>>, QueryKey, GetOracleTokensStatusV3Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOracleTokensStatusV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOracleTokensStatusV3>>, QueryKey, GetOracleTokensStatusV3Params['continuation']> = ({ signal, pageParam }) => getOracleTokensStatusV3({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleTokensStatusV3>>, TError, TData, Awaited<ReturnType<typeof getOracleTokensStatusV3>>, QueryKey, GetOracleTokensStatusV3Params['continuation']> & { queryKey: QueryKey }
}

export type GetOracleTokensStatusV3InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOracleTokensStatusV3>>>
export type GetOracleTokensStatusV3InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Token status oracle
 */
export const useGetOracleTokensStatusV3Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOracleTokensStatusV3>>, GetOracleTokensStatusV3Params['continuation']>, TError = ErrorType<unknown>>(
 params: GetOracleTokensStatusV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleTokensStatusV3>>, TError, TData, Awaited<ReturnType<typeof getOracleTokensStatusV3>>, QueryKey, GetOracleTokensStatusV3Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOracleTokensStatusV3InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOracleTokensStatusV3QueryOptions = <TData = Awaited<ReturnType<typeof getOracleTokensStatusV3>>, TError = ErrorType<unknown>>(params: GetOracleTokensStatusV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOracleTokensStatusV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOracleTokensStatusV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOracleTokensStatusV3>>> = ({ signal }) => getOracleTokensStatusV3(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOracleTokensStatusV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOracleTokensStatusV3QueryResult = NonNullable<Awaited<ReturnType<typeof getOracleTokensStatusV3>>>
export type GetOracleTokensStatusV3QueryError = ErrorType<unknown>

/**
 * @summary Token status oracle
 */
export const useGetOracleTokensStatusV3 = <TData = Awaited<ReturnType<typeof getOracleTokensStatusV3>>, TError = ErrorType<unknown>>(
 params: GetOracleTokensStatusV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOracleTokensStatusV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOracleTokensStatusV3QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API return the recent flagged/un-flagged tokens across all collections sorted by change time
 * @summary Flagged Tokens
 */
export const getTokensFlagChangesV1 = (
    params?: GetTokensFlagChangesV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTokensFlagChangesV1Response>(
      {url: `/tokens/flag/changes/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTokensFlagChangesV1QueryKey = (params?: GetTokensFlagChangesV1Params,) => {
    return [`/tokens/flag/changes/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokensFlagChangesV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensFlagChangesV1>>, GetTokensFlagChangesV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetTokensFlagChangesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensFlagChangesV1>>, TError, TData, Awaited<ReturnType<typeof getTokensFlagChangesV1>>, QueryKey, GetTokensFlagChangesV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensFlagChangesV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensFlagChangesV1>>, QueryKey, GetTokensFlagChangesV1Params['continuation']> = ({ signal, pageParam }) => getTokensFlagChangesV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensFlagChangesV1>>, TError, TData, Awaited<ReturnType<typeof getTokensFlagChangesV1>>, QueryKey, GetTokensFlagChangesV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetTokensFlagChangesV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTokensFlagChangesV1>>>
export type GetTokensFlagChangesV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Flagged Tokens
 */
export const useGetTokensFlagChangesV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensFlagChangesV1>>, GetTokensFlagChangesV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetTokensFlagChangesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensFlagChangesV1>>, TError, TData, Awaited<ReturnType<typeof getTokensFlagChangesV1>>, QueryKey, GetTokensFlagChangesV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensFlagChangesV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokensFlagChangesV1QueryOptions = <TData = Awaited<ReturnType<typeof getTokensFlagChangesV1>>, TError = ErrorType<unknown>>(params?: GetTokensFlagChangesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensFlagChangesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensFlagChangesV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensFlagChangesV1>>> = ({ signal }) => getTokensFlagChangesV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokensFlagChangesV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokensFlagChangesV1QueryResult = NonNullable<Awaited<ReturnType<typeof getTokensFlagChangesV1>>>
export type GetTokensFlagChangesV1QueryError = ErrorType<unknown>

/**
 * @summary Flagged Tokens
 */
export const useGetTokensFlagChangesV1 = <TData = Awaited<ReturnType<typeof getTokensFlagChangesV1>>, TError = ErrorType<unknown>>(
 params?: GetTokensFlagChangesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensFlagChangesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensFlagChangesV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to build a feed for a token activity including sales, asks, transfers, mints, bids, cancelled bids, and cancelled asks types.
 * @summary Token activity
 */
export const getTokensTokenActivityV5 = (
    token: string,
    params?: GetTokensTokenActivityV5Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTokensTokenActivityV5Response>(
      {url: `/tokens/${token}/activity/v5`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTokensTokenActivityV5QueryKey = (token: string,
    params?: GetTokensTokenActivityV5Params,) => {
    return [`/tokens/${token}/activity/v5`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokensTokenActivityV5InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensTokenActivityV5>>, GetTokensTokenActivityV5Params['continuation']>, TError = ErrorType<unknown>>(token: string,
    params?: GetTokensTokenActivityV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV5>>, TError, TData, Awaited<ReturnType<typeof getTokensTokenActivityV5>>, QueryKey, GetTokensTokenActivityV5Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensTokenActivityV5QueryKey(token,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensTokenActivityV5>>, QueryKey, GetTokensTokenActivityV5Params['continuation']> = ({ signal, pageParam }) => getTokensTokenActivityV5(token,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(token),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV5>>, TError, TData, Awaited<ReturnType<typeof getTokensTokenActivityV5>>, QueryKey, GetTokensTokenActivityV5Params['continuation']> & { queryKey: QueryKey }
}

export type GetTokensTokenActivityV5InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTokensTokenActivityV5>>>
export type GetTokensTokenActivityV5InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Token activity
 */
export const useGetTokensTokenActivityV5Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensTokenActivityV5>>, GetTokensTokenActivityV5Params['continuation']>, TError = ErrorType<unknown>>(
 token: string,
    params?: GetTokensTokenActivityV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV5>>, TError, TData, Awaited<ReturnType<typeof getTokensTokenActivityV5>>, QueryKey, GetTokensTokenActivityV5Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensTokenActivityV5InfiniteQueryOptions(token,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokensTokenActivityV5QueryOptions = <TData = Awaited<ReturnType<typeof getTokensTokenActivityV5>>, TError = ErrorType<unknown>>(token: string,
    params?: GetTokensTokenActivityV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV5>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensTokenActivityV5QueryKey(token,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensTokenActivityV5>>> = ({ signal }) => getTokensTokenActivityV5(token,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(token),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV5>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokensTokenActivityV5QueryResult = NonNullable<Awaited<ReturnType<typeof getTokensTokenActivityV5>>>
export type GetTokensTokenActivityV5QueryError = ErrorType<unknown>

/**
 * @summary Token activity
 */
export const useGetTokensTokenActivityV5 = <TData = Awaited<ReturnType<typeof getTokensTokenActivityV5>>, TError = ErrorType<unknown>>(
 token: string,
    params?: GetTokensTokenActivityV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV5>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensTokenActivityV5QueryOptions(token,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a boolean response on whether a particular transaction was synced or not.
 * @summary Transaction status
 */
export const getTransactionsTxhashSyncedV1 = (
    txHash: string,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTransactionsTxHashSyncedV1Response>(
      {url: `/transactions/${txHash}/synced/v1`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTransactionsTxhashSyncedV1QueryKey = (txHash: string,) => {
    return [`/transactions/${txHash}/synced/v1`] as const;
    }

    
export const getGetTransactionsTxhashSyncedV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>>, TError = ErrorType<unknown>>(txHash: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTransactionsTxhashSyncedV1QueryKey(txHash);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>> = ({ signal }) => getTransactionsTxhashSyncedV1(txHash, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(txHash),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTransactionsTxhashSyncedV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>>
export type GetTransactionsTxhashSyncedV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Transaction status
 */
export const useGetTransactionsTxhashSyncedV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>>, TError = ErrorType<unknown>>(
 txHash: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTransactionsTxhashSyncedV1InfiniteQueryOptions(txHash,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTransactionsTxhashSyncedV1QueryOptions = <TData = Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>, TError = ErrorType<unknown>>(txHash: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTransactionsTxhashSyncedV1QueryKey(txHash);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>> = ({ signal }) => getTransactionsTxhashSyncedV1(txHash, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(txHash),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTransactionsTxhashSyncedV1QueryResult = NonNullable<Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>>
export type GetTransactionsTxhashSyncedV1QueryError = ErrorType<unknown>

/**
 * @summary Transaction status
 */
export const useGetTransactionsTxhashSyncedV1 = <TData = Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>, TError = ErrorType<unknown>>(
 txHash: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTransactionsTxhashSyncedV1QueryOptions(txHash,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get aggregate stats for a user, grouped by collection. Useful for showing total portfolio information.
 * @summary User collections
 */
export const getUsersUserCollectionsV3 = (
    user: string,
    params?: GetUsersUserCollectionsV3Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUsersUserCollectionsV3Response>(
      {url: `/users/${user}/collections/v3`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUsersUserCollectionsV3QueryKey = (user: string,
    params?: GetUsersUserCollectionsV3Params,) => {
    return [`/users/${user}/collections/v3`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUsersUserCollectionsV3InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, GetUsersUserCollectionsV3Params['continuation']>, TError = ErrorType<unknown>>(user: string,
    params?: GetUsersUserCollectionsV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, TError, TData, Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, QueryKey, GetUsersUserCollectionsV3Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserCollectionsV3QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, QueryKey, GetUsersUserCollectionsV3Params['continuation']> = ({ signal, pageParam }) => getUsersUserCollectionsV3(user,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, TError, TData, Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, QueryKey, GetUsersUserCollectionsV3Params['continuation']> & { queryKey: QueryKey }
}

export type GetUsersUserCollectionsV3InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserCollectionsV3>>>
export type GetUsersUserCollectionsV3InfiniteQueryError = ErrorType<unknown>

/**
 * @summary User collections
 */
export const useGetUsersUserCollectionsV3Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, GetUsersUserCollectionsV3Params['continuation']>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetUsersUserCollectionsV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, TError, TData, Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, QueryKey, GetUsersUserCollectionsV3Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserCollectionsV3InfiniteQueryOptions(user,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUsersUserCollectionsV3QueryOptions = <TData = Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, TError = ErrorType<unknown>>(user: string,
    params?: GetUsersUserCollectionsV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserCollectionsV3QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserCollectionsV3>>> = ({ signal }) => getUsersUserCollectionsV3(user,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersUserCollectionsV3QueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserCollectionsV3>>>
export type GetUsersUserCollectionsV3QueryError = ErrorType<unknown>

/**
 * @summary User collections
 */
export const useGetUsersUserCollectionsV3 = <TData = Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetUsersUserCollectionsV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserCollectionsV3QueryOptions(user,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get aggregate user liquidity, grouped by collection. Useful for showing a summary of liquidity being provided (orders made).
 * @deprecated
 * @summary Get a summary of a users bids and asks
 */
export const getUsersUserPositionsV1 = (
    user: string,
    params: GetUsersUserPositionsV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUsersUserPositionsV1Response>(
      {url: `/users/${user}/positions/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUsersUserPositionsV1QueryKey = (user: string,
    params: GetUsersUserPositionsV1Params,) => {
    return [`/users/${user}/positions/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUsersUserPositionsV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserPositionsV1>>, GetUsersUserPositionsV1Params['continuation']>, TError = ErrorType<unknown>>(user: string,
    params: GetUsersUserPositionsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserPositionsV1>>, TError, TData, Awaited<ReturnType<typeof getUsersUserPositionsV1>>, QueryKey, GetUsersUserPositionsV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserPositionsV1QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserPositionsV1>>, QueryKey, GetUsersUserPositionsV1Params['continuation']> = ({ signal, pageParam }) => getUsersUserPositionsV1(user,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserPositionsV1>>, TError, TData, Awaited<ReturnType<typeof getUsersUserPositionsV1>>, QueryKey, GetUsersUserPositionsV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetUsersUserPositionsV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserPositionsV1>>>
export type GetUsersUserPositionsV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get a summary of a users bids and asks
 */
export const useGetUsersUserPositionsV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserPositionsV1>>, GetUsersUserPositionsV1Params['continuation']>, TError = ErrorType<unknown>>(
 user: string,
    params: GetUsersUserPositionsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserPositionsV1>>, TError, TData, Awaited<ReturnType<typeof getUsersUserPositionsV1>>, QueryKey, GetUsersUserPositionsV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserPositionsV1InfiniteQueryOptions(user,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUsersUserPositionsV1QueryOptions = <TData = Awaited<ReturnType<typeof getUsersUserPositionsV1>>, TError = ErrorType<unknown>>(user: string,
    params: GetUsersUserPositionsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserPositionsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserPositionsV1QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserPositionsV1>>> = ({ signal }) => getUsersUserPositionsV1(user,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsersUserPositionsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersUserPositionsV1QueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserPositionsV1>>>
export type GetUsersUserPositionsV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get a summary of a users bids and asks
 */
export const useGetUsersUserPositionsV1 = <TData = Awaited<ReturnType<typeof getUsersUserPositionsV1>>, TError = ErrorType<unknown>>(
 user: string,
    params: GetUsersUserPositionsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserPositionsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserPositionsV1QueryOptions(user,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get tokens held by a user, along with ownership information such as associated orders and date acquired.
 * @summary User Tokens
 */
export const getUsersUserTokensV7 = (
    user: string,
    params?: GetUsersUserTokensV7Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUsersUserTokensV7Response>(
      {url: `/users/${user}/tokens/v7`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUsersUserTokensV7QueryKey = (user: string,
    params?: GetUsersUserTokensV7Params,) => {
    return [`/users/${user}/tokens/v7`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUsersUserTokensV7InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserTokensV7>>, GetUsersUserTokensV7Params['continuation']>, TError = ErrorType<unknown>>(user: string,
    params?: GetUsersUserTokensV7Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV7>>, TError, TData, Awaited<ReturnType<typeof getUsersUserTokensV7>>, QueryKey, GetUsersUserTokensV7Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserTokensV7QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserTokensV7>>, QueryKey, GetUsersUserTokensV7Params['continuation']> = ({ signal, pageParam }) => getUsersUserTokensV7(user,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV7>>, TError, TData, Awaited<ReturnType<typeof getUsersUserTokensV7>>, QueryKey, GetUsersUserTokensV7Params['continuation']> & { queryKey: QueryKey }
}

export type GetUsersUserTokensV7InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserTokensV7>>>
export type GetUsersUserTokensV7InfiniteQueryError = ErrorType<unknown>

/**
 * @summary User Tokens
 */
export const useGetUsersUserTokensV7Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserTokensV7>>, GetUsersUserTokensV7Params['continuation']>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetUsersUserTokensV7Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV7>>, TError, TData, Awaited<ReturnType<typeof getUsersUserTokensV7>>, QueryKey, GetUsersUserTokensV7Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserTokensV7InfiniteQueryOptions(user,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUsersUserTokensV7QueryOptions = <TData = Awaited<ReturnType<typeof getUsersUserTokensV7>>, TError = ErrorType<unknown>>(user: string,
    params?: GetUsersUserTokensV7Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV7>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserTokensV7QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserTokensV7>>> = ({ signal }) => getUsersUserTokensV7(user,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV7>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersUserTokensV7QueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserTokensV7>>>
export type GetUsersUserTokensV7QueryError = ErrorType<unknown>

/**
 * @summary User Tokens
 */
export const useGetUsersUserTokensV7 = <TData = Awaited<ReturnType<typeof getUsersUserTokensV7>>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetUsersUserTokensV7Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV7>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserTokensV7QueryOptions(user,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Use this API to see stats on a specific attribute within a collection. This endpoint will return `tokenCount`, `onSaleCount`, `sampleImages`, and `floorAskPrices` by default.

- `floorAskPrices` will not be returned on attributes with more than 10k tokens.
 * @summary Explore attributes
 */
export const getCollectionsCollectionAttributesExploreV5 = (
    collection: string,
    params?: GetCollectionsCollectionAttributesExploreV5Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsCollectionAttributesExploreV5Response>(
      {url: `/collections/${collection}/attributes/explore/v5`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsCollectionAttributesExploreV5QueryKey = (collection: string,
    params?: GetCollectionsCollectionAttributesExploreV5Params,) => {
    return [`/collections/${collection}/attributes/explore/v5`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsCollectionAttributesExploreV5InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, GetCollectionsCollectionAttributesExploreV5Params['continuation']>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionAttributesExploreV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, QueryKey, GetCollectionsCollectionAttributesExploreV5Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesExploreV5QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, QueryKey, GetCollectionsCollectionAttributesExploreV5Params['continuation']> = ({ signal, pageParam }) => getCollectionsCollectionAttributesExploreV5(collection,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, QueryKey, GetCollectionsCollectionAttributesExploreV5Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesExploreV5InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>>
export type GetCollectionsCollectionAttributesExploreV5InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Explore attributes
 */
export const useGetCollectionsCollectionAttributesExploreV5Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, GetCollectionsCollectionAttributesExploreV5Params['continuation']>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionAttributesExploreV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, QueryKey, GetCollectionsCollectionAttributesExploreV5Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesExploreV5InfiniteQueryOptions(collection,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionAttributesExploreV5QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionAttributesExploreV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesExploreV5QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>> = ({ signal }) => getCollectionsCollectionAttributesExploreV5(collection,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesExploreV5QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>>
export type GetCollectionsCollectionAttributesExploreV5QueryError = ErrorType<unknown>

/**
 * @summary Explore attributes
 */
export const useGetCollectionsCollectionAttributesExploreV5 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionAttributesExploreV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesExploreV5QueryOptions(collection,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary All attributes + token ids
 */
export const getCollectionsCollectionAttributesStaticV1 = (
    collection: string,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsCollectionAttributesStaticV1Response>(
      {url: `/collections/${collection}/attributes/static/v1`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCollectionsCollectionAttributesStaticV1QueryKey = (collection: string,) => {
    return [`/collections/${collection}/attributes/static/v1`] as const;
    }

    
export const getGetCollectionsCollectionAttributesStaticV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>>, TError = ErrorType<unknown>>(collection: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesStaticV1QueryKey(collection);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>> = ({ signal }) => getCollectionsCollectionAttributesStaticV1(collection, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesStaticV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>>
export type GetCollectionsCollectionAttributesStaticV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary All attributes + token ids
 */
export const useGetCollectionsCollectionAttributesStaticV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>>, TError = ErrorType<unknown>>(
 collection: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesStaticV1InfiniteQueryOptions(collection,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionAttributesStaticV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>, TError = ErrorType<unknown>>(collection: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesStaticV1QueryKey(collection);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>> = ({ signal }) => getCollectionsCollectionAttributesStaticV1(collection, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesStaticV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>>
export type GetCollectionsCollectionAttributesStaticV1QueryError = ErrorType<unknown>

/**
 * @summary All attributes + token ids
 */
export const useGetCollectionsCollectionAttributesStaticV1 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>, TError = ErrorType<unknown>>(
 collection: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesStaticV1QueryOptions(collection,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Use this API to see all possible attributes within a collection.

- `floorAskPrice` for all attributes might not be returned on collections with more than 10k tokens. 

- Attributes are case sensitive. 

- Attributes will return a maximum of 500 values.
 * @summary All attributes
 */
export const getCollectionsCollectionAttributesAllV4 = (
    collection: string,
    params?: GetCollectionsCollectionAttributesAllV4Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsCollectionAttributesAllV4Response>(
      {url: `/collections/${collection}/attributes/all/v4`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsCollectionAttributesAllV4QueryKey = (collection: string,
    params?: GetCollectionsCollectionAttributesAllV4Params,) => {
    return [`/collections/${collection}/attributes/all/v4`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsCollectionAttributesAllV4InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, GetCollectionsCollectionAttributesAllV4Params['continuation']>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionAttributesAllV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, QueryKey, GetCollectionsCollectionAttributesAllV4Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesAllV4QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, QueryKey, GetCollectionsCollectionAttributesAllV4Params['continuation']> = ({ signal, pageParam }) => getCollectionsCollectionAttributesAllV4(collection,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, QueryKey, GetCollectionsCollectionAttributesAllV4Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesAllV4InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>>
export type GetCollectionsCollectionAttributesAllV4InfiniteQueryError = ErrorType<unknown>

/**
 * @summary All attributes
 */
export const useGetCollectionsCollectionAttributesAllV4Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, GetCollectionsCollectionAttributesAllV4Params['continuation']>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionAttributesAllV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, QueryKey, GetCollectionsCollectionAttributesAllV4Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesAllV4InfiniteQueryOptions(collection,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionAttributesAllV4QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionAttributesAllV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesAllV4QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>> = ({ signal }) => getCollectionsCollectionAttributesAllV4(collection,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesAllV4QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>>
export type GetCollectionsCollectionAttributesAllV4QueryError = ErrorType<unknown>

/**
 * @summary All attributes
 */
export const useGetCollectionsCollectionAttributesAllV4 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionAttributesAllV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesAllV4QueryOptions(collection,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Return the top bids for the given user tokens. Please mark `excludeEOA` as `true` to exclude Blur orders.
 * @summary User Top Bids
 */
export const getOrdersUsersUserTopbidsV4 = (
    user: string,
    params?: GetOrdersUsersUserTopbidsV4Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOrdersUsersUserTopBidsV4Response>(
      {url: `/orders/users/${user}/top-bids/v4`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersUsersUserTopbidsV4QueryKey = (user: string,
    params?: GetOrdersUsersUserTopbidsV4Params,) => {
    return [`/orders/users/${user}/top-bids/v4`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersUsersUserTopbidsV4InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, GetOrdersUsersUserTopbidsV4Params['continuation']>, TError = ErrorType<unknown>>(user: string,
    params?: GetOrdersUsersUserTopbidsV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, TError, TData, Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, QueryKey, GetOrdersUsersUserTopbidsV4Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersUsersUserTopbidsV4QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, QueryKey, GetOrdersUsersUserTopbidsV4Params['continuation']> = ({ signal, pageParam }) => getOrdersUsersUserTopbidsV4(user,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, TError, TData, Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, QueryKey, GetOrdersUsersUserTopbidsV4Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersUsersUserTopbidsV4InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>>
export type GetOrdersUsersUserTopbidsV4InfiniteQueryError = ErrorType<unknown>

/**
 * @summary User Top Bids
 */
export const useGetOrdersUsersUserTopbidsV4Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, GetOrdersUsersUserTopbidsV4Params['continuation']>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetOrdersUsersUserTopbidsV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, TError, TData, Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, QueryKey, GetOrdersUsersUserTopbidsV4Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersUsersUserTopbidsV4InfiniteQueryOptions(user,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersUsersUserTopbidsV4QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, TError = ErrorType<unknown>>(user: string,
    params?: GetOrdersUsersUserTopbidsV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersUsersUserTopbidsV4QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>> = ({ signal }) => getOrdersUsersUserTopbidsV4(user,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersUsersUserTopbidsV4QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>>
export type GetOrdersUsersUserTopbidsV4QueryError = ErrorType<unknown>

/**
 * @summary User Top Bids
 */
export const useGetOrdersUsersUserTopbidsV4 = <TData = Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetOrdersUsersUserTopbidsV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersUsersUserTopbidsV4QueryOptions(user,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Redirect to the given collection image
 */
export const getRedirectCollectionsCollectionImageV1 = (
    collection: string,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<string>(
      {url: `/redirect/collections/${collection}/image/v1`, method: 'GET', signal
    },
      options);
    }
  

export const getGetRedirectCollectionsCollectionImageV1QueryKey = (collection: string,) => {
    return [`/redirect/collections/${collection}/image/v1`] as const;
    }

    
export const getGetRedirectCollectionsCollectionImageV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>>, TError = ErrorType<string>>(collection: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRedirectCollectionsCollectionImageV1QueryKey(collection);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>> = ({ signal }) => getRedirectCollectionsCollectionImageV1(collection, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRedirectCollectionsCollectionImageV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>>
export type GetRedirectCollectionsCollectionImageV1InfiniteQueryError = ErrorType<string>

/**
 * @summary Redirect to the given collection image
 */
export const useGetRedirectCollectionsCollectionImageV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>>, TError = ErrorType<string>>(
 collection: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRedirectCollectionsCollectionImageV1InfiniteQueryOptions(collection,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetRedirectCollectionsCollectionImageV1QueryOptions = <TData = Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>, TError = ErrorType<string>>(collection: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRedirectCollectionsCollectionImageV1QueryKey(collection);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>> = ({ signal }) => getRedirectCollectionsCollectionImageV1(collection, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRedirectCollectionsCollectionImageV1QueryResult = NonNullable<Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>>
export type GetRedirectCollectionsCollectionImageV1QueryError = ErrorType<string>

/**
 * @summary Redirect to the given collection image
 */
export const useGetRedirectCollectionsCollectionImageV1 = <TData = Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>, TError = ErrorType<string>>(
 collection: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRedirectCollectionsCollectionImageV1QueryOptions(collection,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Redirect response to the given currency address icon
 */
export const getRedirectCurrencyAddressIconV1 = (
    address: string,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<string>(
      {url: `/redirect/currency/${address}/icon/v1`, method: 'GET', signal
    },
      options);
    }
  

export const getGetRedirectCurrencyAddressIconV1QueryKey = (address: string,) => {
    return [`/redirect/currency/${address}/icon/v1`] as const;
    }

    
export const getGetRedirectCurrencyAddressIconV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>>, TError = ErrorType<string>>(address: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRedirectCurrencyAddressIconV1QueryKey(address);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>> = ({ signal }) => getRedirectCurrencyAddressIconV1(address, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(address),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRedirectCurrencyAddressIconV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>>
export type GetRedirectCurrencyAddressIconV1InfiniteQueryError = ErrorType<string>

/**
 * @summary Redirect response to the given currency address icon
 */
export const useGetRedirectCurrencyAddressIconV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>>, TError = ErrorType<string>>(
 address: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRedirectCurrencyAddressIconV1InfiniteQueryOptions(address,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetRedirectCurrencyAddressIconV1QueryOptions = <TData = Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>, TError = ErrorType<string>>(address: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRedirectCurrencyAddressIconV1QueryKey(address);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>> = ({ signal }) => getRedirectCurrencyAddressIconV1(address, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(address),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRedirectCurrencyAddressIconV1QueryResult = NonNullable<Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>>
export type GetRedirectCurrencyAddressIconV1QueryError = ErrorType<string>

/**
 * @summary Redirect response to the given currency address icon
 */
export const useGetRedirectCurrencyAddressIconV1 = <TData = Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>, TError = ErrorType<string>>(
 address: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRedirectCurrencyAddressIconV1QueryOptions(address,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Redirect response to the given source logo
 */
export const getRedirectSourcesSourceLogoV2 = (
    source: string,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<string>(
      {url: `/redirect/sources/${source}/logo/v2`, method: 'GET', signal
    },
      options);
    }
  

export const getGetRedirectSourcesSourceLogoV2QueryKey = (source: string,) => {
    return [`/redirect/sources/${source}/logo/v2`] as const;
    }

    
export const getGetRedirectSourcesSourceLogoV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>>, TError = ErrorType<string>>(source: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRedirectSourcesSourceLogoV2QueryKey(source);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>> = ({ signal }) => getRedirectSourcesSourceLogoV2(source, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(source),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRedirectSourcesSourceLogoV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>>
export type GetRedirectSourcesSourceLogoV2InfiniteQueryError = ErrorType<string>

/**
 * @summary Redirect response to the given source logo
 */
export const useGetRedirectSourcesSourceLogoV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>>, TError = ErrorType<string>>(
 source: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRedirectSourcesSourceLogoV2InfiniteQueryOptions(source,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetRedirectSourcesSourceLogoV2QueryOptions = <TData = Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>, TError = ErrorType<string>>(source: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRedirectSourcesSourceLogoV2QueryKey(source);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>> = ({ signal }) => getRedirectSourcesSourceLogoV2(source, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(source),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRedirectSourcesSourceLogoV2QueryResult = NonNullable<Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>>
export type GetRedirectSourcesSourceLogoV2QueryError = ErrorType<string>

/**
 * @summary Redirect response to the given source logo
 */
export const useGetRedirectSourcesSourceLogoV2 = <TData = Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>, TError = ErrorType<string>>(
 source: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRedirectSourcesSourceLogoV2QueryOptions(source,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Redirect response to the given token image
 */
export const getRedirectTokensTokenImageV1 = (
    token: string,
    params?: GetRedirectTokensTokenImageV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<string>(
      {url: `/redirect/tokens/${token}/image/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetRedirectTokensTokenImageV1QueryKey = (token: string,
    params?: GetRedirectTokensTokenImageV1Params,) => {
    return [`/redirect/tokens/${token}/image/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetRedirectTokensTokenImageV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, GetRedirectTokensTokenImageV1Params['continuation']>, TError = ErrorType<string>>(token: string,
    params?: GetRedirectTokensTokenImageV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, TError, TData, Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, QueryKey, GetRedirectTokensTokenImageV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRedirectTokensTokenImageV1QueryKey(token,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, QueryKey, GetRedirectTokensTokenImageV1Params['continuation']> = ({ signal, pageParam }) => getRedirectTokensTokenImageV1(token,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(token),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, TError, TData, Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, QueryKey, GetRedirectTokensTokenImageV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetRedirectTokensTokenImageV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>>
export type GetRedirectTokensTokenImageV1InfiniteQueryError = ErrorType<string>

/**
 * @summary Redirect response to the given token image
 */
export const useGetRedirectTokensTokenImageV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, GetRedirectTokensTokenImageV1Params['continuation']>, TError = ErrorType<string>>(
 token: string,
    params?: GetRedirectTokensTokenImageV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, TError, TData, Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, QueryKey, GetRedirectTokensTokenImageV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRedirectTokensTokenImageV1InfiniteQueryOptions(token,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetRedirectTokensTokenImageV1QueryOptions = <TData = Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, TError = ErrorType<string>>(token: string,
    params?: GetRedirectTokensTokenImageV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRedirectTokensTokenImageV1QueryKey(token,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>> = ({ signal }) => getRedirectTokensTokenImageV1(token,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(token),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRedirectTokensTokenImageV1QueryResult = NonNullable<Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>>
export type GetRedirectTokensTokenImageV1QueryError = ErrorType<string>

/**
 * @summary Redirect response to the given token image
 */
export const useGetRedirectTokensTokenImageV1 = <TData = Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, TError = ErrorType<string>>(
 token: string,
    params?: GetRedirectTokensTokenImageV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRedirectTokensTokenImageV1QueryOptions(token,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Redirect response to the given source token page
 */
export const getRedirectSourcesSourceTokensTokenLinkV2 = (
    source: string,
    token: string,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<string>(
      {url: `/redirect/sources/${source}/tokens/${token}/link/v2`, method: 'GET', signal
    },
      options);
    }
  

export const getGetRedirectSourcesSourceTokensTokenLinkV2QueryKey = (source: string,
    token: string,) => {
    return [`/redirect/sources/${source}/tokens/${token}/link/v2`] as const;
    }

    
export const getGetRedirectSourcesSourceTokensTokenLinkV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>>, TError = ErrorType<string>>(source: string,
    token: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRedirectSourcesSourceTokensTokenLinkV2QueryKey(source,token);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>> = ({ signal }) => getRedirectSourcesSourceTokensTokenLinkV2(source,token, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(source && token),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRedirectSourcesSourceTokensTokenLinkV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>>
export type GetRedirectSourcesSourceTokensTokenLinkV2InfiniteQueryError = ErrorType<string>

/**
 * @summary Redirect response to the given source token page
 */
export const useGetRedirectSourcesSourceTokensTokenLinkV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>>, TError = ErrorType<string>>(
 source: string,
    token: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRedirectSourcesSourceTokensTokenLinkV2InfiniteQueryOptions(source,token,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetRedirectSourcesSourceTokensTokenLinkV2QueryOptions = <TData = Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>, TError = ErrorType<string>>(source: string,
    token: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRedirectSourcesSourceTokensTokenLinkV2QueryKey(source,token);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>> = ({ signal }) => getRedirectSourcesSourceTokensTokenLinkV2(source,token, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(source && token),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRedirectSourcesSourceTokensTokenLinkV2QueryResult = NonNullable<Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>>
export type GetRedirectSourcesSourceTokensTokenLinkV2QueryError = ErrorType<string>

/**
 * @summary Redirect response to the given source token page
 */
export const useGetRedirectSourcesSourceTokensTokenLinkV2 = <TData = Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>, TError = ErrorType<string>>(
 source: string,
    token: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRedirectSourcesSourceTokensTokenLinkV2QueryOptions(source,token,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API requires an administrator API for execution. Explore and try the `/collections-sets/v1` or `/contracts-sets/v1` endpoints. Please contact technical support with more questions.
 * @summary Set a community for a specific collection
 */
export const putCollectionsCollectionCommunityV1 = (
    collection: string,
    putCollectionsCollectionCommunityV1Payload: BodyType<PutCollectionsCollectionCommunityV1Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PutCollectionsCollectionCommunityV1Response>(
      {url: `/collections/${collection}/community/v1`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: putCollectionsCollectionCommunityV1Payload
    },
      options);
    }
  


export const getPutCollectionsCollectionCommunityV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putCollectionsCollectionCommunityV1>>, TError,{collection: string;data: BodyType<PutCollectionsCollectionCommunityV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof putCollectionsCollectionCommunityV1>>, TError,{collection: string;data: BodyType<PutCollectionsCollectionCommunityV1Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putCollectionsCollectionCommunityV1>>, {collection: string;data: BodyType<PutCollectionsCollectionCommunityV1Payload>}> = (props) => {
          const {collection,data} = props ?? {};

          return  putCollectionsCollectionCommunityV1(collection,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PutCollectionsCollectionCommunityV1MutationResult = NonNullable<Awaited<ReturnType<typeof putCollectionsCollectionCommunityV1>>>
    export type PutCollectionsCollectionCommunityV1MutationBody = BodyType<PutCollectionsCollectionCommunityV1Payload>
    export type PutCollectionsCollectionCommunityV1MutationError = ErrorType<unknown>

    /**
 * @summary Set a community for a specific collection
 */
export const usePutCollectionsCollectionCommunityV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putCollectionsCollectionCommunityV1>>, TError,{collection: string;data: BodyType<PutCollectionsCollectionCommunityV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPutCollectionsCollectionCommunityV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger calculation of the give collection tokens rarity
 */
export const postAdminCalcrarity = (
    postAdminCalcRarityPayload: BodyType<PostAdminCalcRarityPayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/calc-rarity`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminCalcRarityPayload
    },
      options);
    }
  


export const getPostAdminCalcrarityMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminCalcrarity>>, TError,{data: BodyType<PostAdminCalcRarityPayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminCalcrarity>>, TError,{data: BodyType<PostAdminCalcRarityPayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminCalcrarity>>, {data: BodyType<PostAdminCalcRarityPayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminCalcrarity(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminCalcrarityMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminCalcrarity>>>
    export type PostAdminCalcrarityMutationBody = BodyType<PostAdminCalcRarityPayload>
    export type PostAdminCalcrarityMutationError = ErrorType<string>

    /**
 * @summary Trigger calculation of the give collection tokens rarity
 */
export const usePostAdminCalcrarity = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminCalcrarity>>, TError,{data: BodyType<PostAdminCalcRarityPayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminCalcrarityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Clear refresh tokens metadata objects
 */
export const postAdminClearrefreshtokens = (
    
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/clear-refresh-tokens`, method: 'POST'
    },
      options);
    }
  


export const getPostAdminClearrefreshtokensMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminClearrefreshtokens>>, TError,void, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminClearrefreshtokens>>, TError,void, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminClearrefreshtokens>>, void> = () => {
          

          return  postAdminClearrefreshtokens(requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminClearrefreshtokensMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminClearrefreshtokens>>>
    
    export type PostAdminClearrefreshtokensMutationError = ErrorType<string>

    /**
 * @summary Clear refresh tokens metadata objects
 */
export const usePostAdminClearrefreshtokens = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminClearrefreshtokens>>, TError,void, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminClearrefreshtokensMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Clear tag tokens metadata objects
 */
export const postAdminCleartagtokens = (
    
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/clear-tag-tokens`, method: 'POST'
    },
      options);
    }
  


export const getPostAdminCleartagtokensMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminCleartagtokens>>, TError,void, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminCleartagtokens>>, TError,void, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminCleartagtokens>>, void> = () => {
          

          return  postAdminCleartagtokens(requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminCleartagtokensMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminCleartagtokens>>>
    
    export type PostAdminCleartagtokensMutationError = ErrorType<string>

    /**
 * @summary Clear tag tokens metadata objects
 */
export const usePostAdminCleartagtokens = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminCleartagtokens>>, TError,void, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminCleartagtokensMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Count all messages from a RabbitMQ queue
 */
export const postAdminCountrabbitqueue = (
    postAdminCountRabbitQueuePayload: BodyType<PostAdminCountRabbitQueuePayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/count-rabbit-queue`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminCountRabbitQueuePayload
    },
      options);
    }
  


export const getPostAdminCountrabbitqueueMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminCountrabbitqueue>>, TError,{data: BodyType<PostAdminCountRabbitQueuePayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminCountrabbitqueue>>, TError,{data: BodyType<PostAdminCountRabbitQueuePayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminCountrabbitqueue>>, {data: BodyType<PostAdminCountRabbitQueuePayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminCountrabbitqueue(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminCountrabbitqueueMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminCountrabbitqueue>>>
    export type PostAdminCountrabbitqueueMutationBody = BodyType<PostAdminCountRabbitQueuePayload>
    export type PostAdminCountrabbitqueueMutationError = ErrorType<string>

    /**
 * @summary Count all messages from a RabbitMQ queue
 */
export const usePostAdminCountrabbitqueue = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminCountrabbitqueue>>, TError,{data: BodyType<PostAdminCountRabbitQueuePayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminCountrabbitqueueMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger fixing any orphaned block.
 */
export const postAdminFixblocks = (
    postAdminFixBlocksPayload: BodyType<PostAdminFixBlocksPayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/fix-blocks`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminFixBlocksPayload
    },
      options);
    }
  


export const getPostAdminFixblocksMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminFixblocks>>, TError,{data: BodyType<PostAdminFixBlocksPayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminFixblocks>>, TError,{data: BodyType<PostAdminFixBlocksPayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminFixblocks>>, {data: BodyType<PostAdminFixBlocksPayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminFixblocks(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminFixblocksMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminFixblocks>>>
    export type PostAdminFixblocksMutationBody = BodyType<PostAdminFixBlocksPayload>
    export type PostAdminFixblocksMutationError = ErrorType<string>

    /**
 * @summary Trigger fixing any orphaned block.
 */
export const usePostAdminFixblocks = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminFixblocks>>, TError,{data: BodyType<PostAdminFixBlocksPayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminFixblocksMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger fixing any cache inconsistencies for array of contracts.
 */
export const postAdminFixcache = (
    postAdminFixCachePayload: BodyType<PostAdminFixCachePayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/fix-cache`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminFixCachePayload
    },
      options);
    }
  


export const getPostAdminFixcacheMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminFixcache>>, TError,{data: BodyType<PostAdminFixCachePayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminFixcache>>, TError,{data: BodyType<PostAdminFixCachePayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminFixcache>>, {data: BodyType<PostAdminFixCachePayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminFixcache(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminFixcacheMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminFixcache>>>
    export type PostAdminFixcacheMutationBody = BodyType<PostAdminFixCachePayload>
    export type PostAdminFixcacheMutationError = ErrorType<string>

    /**
 * @summary Trigger fixing any cache inconsistencies for array of contracts.
 */
export const usePostAdminFixcache = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminFixcache>>, TError,{data: BodyType<PostAdminFixCachePayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminFixcacheMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger fixing any order inconsistencies.
 */
export const postAdminFixorders = (
    postAdminFixOrdersPayload: BodyType<PostAdminFixOrdersPayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/fix-orders`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminFixOrdersPayload
    },
      options);
    }
  


export const getPostAdminFixordersMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminFixorders>>, TError,{data: BodyType<PostAdminFixOrdersPayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminFixorders>>, TError,{data: BodyType<PostAdminFixOrdersPayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminFixorders>>, {data: BodyType<PostAdminFixOrdersPayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminFixorders(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminFixordersMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminFixorders>>>
    export type PostAdminFixordersMutationBody = BodyType<PostAdminFixOrdersPayload>
    export type PostAdminFixordersMutationError = ErrorType<string>

    /**
 * @summary Trigger fixing any order inconsistencies.
 */
export const usePostAdminFixorders = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminFixorders>>, TError,{data: BodyType<PostAdminFixOrdersPayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminFixordersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger fixing broken Immutable order hashes
 */
export const postAdminFixordersimtbl = (
    
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/fix-orders-imtbl`, method: 'POST'
    },
      options);
    }
  


export const getPostAdminFixordersimtblMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminFixordersimtbl>>, TError,void, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminFixordersimtbl>>, TError,void, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminFixordersimtbl>>, void> = () => {
          

          return  postAdminFixordersimtbl(requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminFixordersimtblMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminFixordersimtbl>>>
    
    export type PostAdminFixordersimtblMutationError = ErrorType<string>

    /**
 * @summary Trigger fixing broken Immutable order hashes
 */
export const usePostAdminFixordersimtbl = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminFixordersimtbl>>, TError,void, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminFixordersimtblMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger fixing any cache inconsistencies for specific token.
 */
export const postAdminFixtokencache = (
    postAdminFixTokenCachePayload: BodyType<PostAdminFixTokenCachePayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/fix-token-cache`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminFixTokenCachePayload
    },
      options);
    }
  


export const getPostAdminFixtokencacheMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminFixtokencache>>, TError,{data: BodyType<PostAdminFixTokenCachePayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminFixtokencache>>, TError,{data: BodyType<PostAdminFixTokenCachePayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminFixtokencache>>, {data: BodyType<PostAdminFixTokenCachePayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminFixtokencache(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminFixtokencacheMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminFixtokencache>>>
    export type PostAdminFixtokencacheMutationBody = BodyType<PostAdminFixTokenCachePayload>
    export type PostAdminFixtokencacheMutationError = ErrorType<string>

    /**
 * @summary Trigger fixing any cache inconsistencies for specific token.
 */
export const usePostAdminFixtokencache = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminFixtokencache>>, TError,{data: BodyType<PostAdminFixTokenCachePayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminFixtokencacheMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger metadata indexing for a token's collection
 */
export const postAdminIndexcollectionmetadata = (
    postAdminIndexCollectionMetadataPayload: BodyType<PostAdminIndexCollectionMetadataPayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/index-collection-metadata`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminIndexCollectionMetadataPayload
    },
      options);
    }
  


export const getPostAdminIndexcollectionmetadataMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminIndexcollectionmetadata>>, TError,{data: BodyType<PostAdminIndexCollectionMetadataPayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminIndexcollectionmetadata>>, TError,{data: BodyType<PostAdminIndexCollectionMetadataPayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminIndexcollectionmetadata>>, {data: BodyType<PostAdminIndexCollectionMetadataPayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminIndexcollectionmetadata(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminIndexcollectionmetadataMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminIndexcollectionmetadata>>>
    export type PostAdminIndexcollectionmetadataMutationBody = BodyType<PostAdminIndexCollectionMetadataPayload>
    export type PostAdminIndexcollectionmetadataMutationError = ErrorType<string>

    /**
 * @summary Trigger metadata indexing for a token's collection
 */
export const usePostAdminIndexcollectionmetadata = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminIndexcollectionmetadata>>, TError,{data: BodyType<PostAdminIndexCollectionMetadataPayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminIndexcollectionmetadataMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Pause realtime block catchup
 */
export const postAdminPausecatchup = (
    postAdminPauseCatchupPayload: BodyType<PostAdminPauseCatchupPayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/pause-catchup`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminPauseCatchupPayload
    },
      options);
    }
  


export const getPostAdminPausecatchupMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminPausecatchup>>, TError,{data: BodyType<PostAdminPauseCatchupPayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminPausecatchup>>, TError,{data: BodyType<PostAdminPauseCatchupPayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminPausecatchup>>, {data: BodyType<PostAdminPauseCatchupPayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminPausecatchup(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminPausecatchupMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminPausecatchup>>>
    export type PostAdminPausecatchupMutationBody = BodyType<PostAdminPauseCatchupPayload>
    export type PostAdminPausecatchupMutationError = ErrorType<string>

    /**
 * @summary Pause realtime block catchup
 */
export const usePostAdminPausecatchup = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminPausecatchup>>, TError,{data: BodyType<PostAdminPauseCatchupPayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminPausecatchupMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Pause rabbit queue
 */
export const postAdminPauserabbitqueue = (
    postAdminPauseRabbitQueuePayload: BodyType<PostAdminPauseRabbitQueuePayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/pause-rabbit-queue`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminPauseRabbitQueuePayload
    },
      options);
    }
  


export const getPostAdminPauserabbitqueueMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminPauserabbitqueue>>, TError,{data: BodyType<PostAdminPauseRabbitQueuePayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminPauserabbitqueue>>, TError,{data: BodyType<PostAdminPauseRabbitQueuePayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminPauserabbitqueue>>, {data: BodyType<PostAdminPauseRabbitQueuePayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminPauserabbitqueue(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminPauserabbitqueueMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminPauserabbitqueue>>>
    export type PostAdminPauserabbitqueueMutationBody = BodyType<PostAdminPauseRabbitQueuePayload>
    export type PostAdminPauserabbitqueueMutationError = ErrorType<string>

    /**
 * @summary Pause rabbit queue
 */
export const usePostAdminPauserabbitqueue = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminPauserabbitqueue>>, TError,{data: BodyType<PostAdminPauseRabbitQueuePayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminPauserabbitqueueMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Purge all messages from a RabbitMQ queue
 */
export const postAdminPurgerabbitqueue = (
    postAdminPurgeRabbitQueuePayload: BodyType<PostAdminPurgeRabbitQueuePayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/purge-rabbit-queue`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminPurgeRabbitQueuePayload
    },
      options);
    }
  


export const getPostAdminPurgerabbitqueueMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminPurgerabbitqueue>>, TError,{data: BodyType<PostAdminPurgeRabbitQueuePayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminPurgerabbitqueue>>, TError,{data: BodyType<PostAdminPurgeRabbitQueuePayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminPurgerabbitqueue>>, {data: BodyType<PostAdminPurgeRabbitQueuePayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminPurgerabbitqueue(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminPurgerabbitqueueMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminPurgerabbitqueue>>>
    export type PostAdminPurgerabbitqueueMutationBody = BodyType<PostAdminPurgeRabbitQueuePayload>
    export type PostAdminPurgerabbitqueueMutationError = ErrorType<string>

    /**
 * @summary Purge all messages from a RabbitMQ queue
 */
export const usePostAdminPurgerabbitqueue = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminPurgerabbitqueue>>, TError,{data: BodyType<PostAdminPurgeRabbitQueuePayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminPurgerabbitqueueMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Refresh a collection's orders and metadata. Tokens marked as spam will be ignored.
 */
export const postAdminRefreshcollection = (
    postAdminRefreshCollectionPayload: BodyType<PostAdminRefreshCollectionPayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/refresh-collection`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminRefreshCollectionPayload
    },
      options);
    }
  


export const getPostAdminRefreshcollectionMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminRefreshcollection>>, TError,{data: BodyType<PostAdminRefreshCollectionPayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminRefreshcollection>>, TError,{data: BodyType<PostAdminRefreshCollectionPayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminRefreshcollection>>, {data: BodyType<PostAdminRefreshCollectionPayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminRefreshcollection(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminRefreshcollectionMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminRefreshcollection>>>
    export type PostAdminRefreshcollectionMutationBody = BodyType<PostAdminRefreshCollectionPayload>
    export type PostAdminRefreshcollectionMutationError = ErrorType<string>

    /**
 * @summary Refresh a collection's orders and metadata. Tokens marked as spam will be ignored.
 */
export const usePostAdminRefreshcollection = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminRefreshcollection>>, TError,{data: BodyType<PostAdminRefreshCollectionPayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminRefreshcollectionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Refresh a token's orders and metadata
 */
export const postAdminRefreshtoken = (
    postAdminRefreshTokenPayload: BodyType<PostAdminRefreshTokenPayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/refresh-token`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminRefreshTokenPayload
    },
      options);
    }
  


export const getPostAdminRefreshtokenMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminRefreshtoken>>, TError,{data: BodyType<PostAdminRefreshTokenPayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminRefreshtoken>>, TError,{data: BodyType<PostAdminRefreshTokenPayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminRefreshtoken>>, {data: BodyType<PostAdminRefreshTokenPayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminRefreshtoken(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminRefreshtokenMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminRefreshtoken>>>
    export type PostAdminRefreshtokenMutationBody = BodyType<PostAdminRefreshTokenPayload>
    export type PostAdminRefreshtokenMutationError = ErrorType<string>

    /**
 * @summary Refresh a token's orders and metadata
 */
export const usePostAdminRefreshtoken = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminRefreshtoken>>, TError,{data: BodyType<PostAdminRefreshTokenPayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminRefreshtokenMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Resume realtime block catchup
 */
export const postAdminResumecatchup = (
    
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/resume-catchup`, method: 'POST'
    },
      options);
    }
  


export const getPostAdminResumecatchupMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminResumecatchup>>, TError,void, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminResumecatchup>>, TError,void, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminResumecatchup>>, void> = () => {
          

          return  postAdminResumecatchup(requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminResumecatchupMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminResumecatchup>>>
    
    export type PostAdminResumecatchupMutationError = ErrorType<string>

    /**
 * @summary Resume realtime block catchup
 */
export const usePostAdminResumecatchup = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminResumecatchup>>, TError,void, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminResumecatchupMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Resume rabbit queue
 */
export const postAdminResumerabbitqueue = (
    postAdminResumeRabbitQueuePayload: BodyType<PostAdminResumeRabbitQueuePayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/resume-rabbit-queue`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminResumeRabbitQueuePayload
    },
      options);
    }
  


export const getPostAdminResumerabbitqueueMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminResumerabbitqueue>>, TError,{data: BodyType<PostAdminResumeRabbitQueuePayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminResumerabbitqueue>>, TError,{data: BodyType<PostAdminResumeRabbitQueuePayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminResumerabbitqueue>>, {data: BodyType<PostAdminResumeRabbitQueuePayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminResumerabbitqueue(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminResumerabbitqueueMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminResumerabbitqueue>>>
    export type PostAdminResumerabbitqueueMutationBody = BodyType<PostAdminResumeRabbitQueuePayload>
    export type PostAdminResumerabbitqueueMutationError = ErrorType<string>

    /**
 * @summary Resume rabbit queue
 */
export const usePostAdminResumerabbitqueue = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminResumerabbitqueue>>, TError,{data: BodyType<PostAdminResumeRabbitQueuePayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminResumerabbitqueueMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger fixing any floor events inconsistencies for any particular collection.
 */
export const postAdminResyncfloorevents = (
    postAdminResyncFloorEventsPayload: BodyType<PostAdminResyncFloorEventsPayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/resync-floor-events`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminResyncFloorEventsPayload
    },
      options);
    }
  


export const getPostAdminResyncflooreventsMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncfloorevents>>, TError,{data: BodyType<PostAdminResyncFloorEventsPayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncfloorevents>>, TError,{data: BodyType<PostAdminResyncFloorEventsPayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminResyncfloorevents>>, {data: BodyType<PostAdminResyncFloorEventsPayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminResyncfloorevents(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminResyncflooreventsMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminResyncfloorevents>>>
    export type PostAdminResyncflooreventsMutationBody = BodyType<PostAdminResyncFloorEventsPayload>
    export type PostAdminResyncflooreventsMutationError = ErrorType<string>

    /**
 * @summary Trigger fixing any floor events inconsistencies for any particular collection.
 */
export const usePostAdminResyncfloorevents = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncfloorevents>>, TError,{data: BodyType<PostAdminResyncFloorEventsPayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminResyncflooreventsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger the recalculation of nft balances for tokens transferred in any particular block range
 */
export const postAdminResyncnftbalances = (
    postAdminResyncNftBalancesPayload: BodyType<PostAdminResyncNftBalancesPayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/resync-nft-balances`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminResyncNftBalancesPayload
    },
      options);
    }
  


export const getPostAdminResyncnftbalancesMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncnftbalances>>, TError,{data: BodyType<PostAdminResyncNftBalancesPayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncnftbalances>>, TError,{data: BodyType<PostAdminResyncNftBalancesPayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminResyncnftbalances>>, {data: BodyType<PostAdminResyncNftBalancesPayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminResyncnftbalances(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminResyncnftbalancesMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminResyncnftbalances>>>
    export type PostAdminResyncnftbalancesMutationBody = BodyType<PostAdminResyncNftBalancesPayload>
    export type PostAdminResyncnftbalancesMutationError = ErrorType<string>

    /**
 * @summary Trigger the recalculation of nft balances for tokens transferred in any particular block range
 */
export const usePostAdminResyncnftbalances = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncnftbalances>>, TError,{data: BodyType<PostAdminResyncNftBalancesPayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminResyncnftbalancesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger the recalculation of sale royalties for any particular block range.
 */
export const postAdminResyncsaleroyalties = (
    postAdminResyncSaleRoyaltiesPayloadOptions: BodyType<PostAdminResyncSaleRoyaltiesPayloadOptions>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/resync-sale-royalties`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminResyncSaleRoyaltiesPayloadOptions
    },
      options);
    }
  


export const getPostAdminResyncsaleroyaltiesMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncsaleroyalties>>, TError,{data: BodyType<PostAdminResyncSaleRoyaltiesPayloadOptions>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncsaleroyalties>>, TError,{data: BodyType<PostAdminResyncSaleRoyaltiesPayloadOptions>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminResyncsaleroyalties>>, {data: BodyType<PostAdminResyncSaleRoyaltiesPayloadOptions>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminResyncsaleroyalties(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminResyncsaleroyaltiesMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminResyncsaleroyalties>>>
    export type PostAdminResyncsaleroyaltiesMutationBody = BodyType<PostAdminResyncSaleRoyaltiesPayloadOptions>
    export type PostAdminResyncsaleroyaltiesMutationError = ErrorType<string>

    /**
 * @summary Trigger the recalculation of sale royalties for any particular block range.
 */
export const usePostAdminResyncsaleroyalties = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncsaleroyalties>>, TError,{data: BodyType<PostAdminResyncSaleRoyaltiesPayloadOptions>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminResyncsaleroyaltiesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger re-syncing of specific source domain
 */
export const postAdminResyncsource = (
    postAdminResyncSourcePayload: BodyType<PostAdminResyncSourcePayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/resync-source`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminResyncSourcePayload
    },
      options);
    }
  


export const getPostAdminResyncsourceMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncsource>>, TError,{data: BodyType<PostAdminResyncSourcePayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncsource>>, TError,{data: BodyType<PostAdminResyncSourcePayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminResyncsource>>, {data: BodyType<PostAdminResyncSourcePayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminResyncsource(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminResyncsourceMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminResyncsource>>>
    export type PostAdminResyncsourceMutationBody = BodyType<PostAdminResyncSourcePayload>
    export type PostAdminResyncsourceMutationError = ErrorType<string>

    /**
 * @summary Trigger re-syncing of specific source domain
 */
export const usePostAdminResyncsource = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncsource>>, TError,{data: BodyType<PostAdminResyncSourcePayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminResyncsourceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger the recalculation of user in certain collection
 */
export const postAdminResyncuserbalance = (
    postAdminResyncUserBalancePayload: BodyType<PostAdminResyncUserBalancePayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/resync-user-balance`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminResyncUserBalancePayload
    },
      options);
    }
  


export const getPostAdminResyncuserbalanceMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncuserbalance>>, TError,{data: BodyType<PostAdminResyncUserBalancePayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncuserbalance>>, TError,{data: BodyType<PostAdminResyncUserBalancePayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminResyncuserbalance>>, {data: BodyType<PostAdminResyncUserBalancePayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminResyncuserbalance(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminResyncuserbalanceMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminResyncuserbalance>>>
    export type PostAdminResyncuserbalanceMutationBody = BodyType<PostAdminResyncUserBalancePayload>
    export type PostAdminResyncuserbalanceMutationError = ErrorType<string>

    /**
 * @summary Trigger the recalculation of user in certain collection
 */
export const usePostAdminResyncuserbalance = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncuserbalance>>, TError,{data: BodyType<PostAdminResyncUserBalancePayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminResyncuserbalanceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retry all the messages within the given dead letter rabbit queue
 */
export const postAdminRetryrabbitqueue = (
    postAdminRetryRabbitQueuePayload: BodyType<PostAdminRetryRabbitQueuePayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/retry-rabbit-queue`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminRetryRabbitQueuePayload
    },
      options);
    }
  


export const getPostAdminRetryrabbitqueueMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminRetryrabbitqueue>>, TError,{data: BodyType<PostAdminRetryRabbitQueuePayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminRetryrabbitqueue>>, TError,{data: BodyType<PostAdminRetryRabbitQueuePayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminRetryrabbitqueue>>, {data: BodyType<PostAdminRetryRabbitQueuePayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminRetryrabbitqueue(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminRetryrabbitqueueMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminRetryrabbitqueue>>>
    export type PostAdminRetryrabbitqueueMutationBody = BodyType<PostAdminRetryRabbitQueuePayload>
    export type PostAdminRetryrabbitqueueMutationError = ErrorType<string>

    /**
 * @summary Retry all the messages within the given dead letter rabbit queue
 */
export const usePostAdminRetryrabbitqueue = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminRetryrabbitqueue>>, TError,{data: BodyType<PostAdminRetryRabbitQueuePayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminRetryrabbitqueueMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Revalidate an existing order
 */
export const postAdminRevalidateorder = (
    postAdminRevalidateOrderPayload: BodyType<PostAdminRevalidateOrderPayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/revalidate-order`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminRevalidateOrderPayload
    },
      options);
    }
  


export const getPostAdminRevalidateorderMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminRevalidateorder>>, TError,{data: BodyType<PostAdminRevalidateOrderPayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminRevalidateorder>>, TError,{data: BodyType<PostAdminRevalidateOrderPayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminRevalidateorder>>, {data: BodyType<PostAdminRevalidateOrderPayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminRevalidateorder(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminRevalidateorderMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminRevalidateorder>>>
    export type PostAdminRevalidateorderMutationBody = BodyType<PostAdminRevalidateOrderPayload>
    export type PostAdminRevalidateorderMutationError = ErrorType<string>

    /**
 * @summary Revalidate an existing order
 */
export const usePostAdminRevalidateorder = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminRevalidateorder>>, TError,{data: BodyType<PostAdminRevalidateOrderPayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminRevalidateorderMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Add a new router contract
 */
export const postAdminRouters = (
    postAdminRoutersPayload: BodyType<PostAdminRoutersPayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/routers`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminRoutersPayload
    },
      options);
    }
  


export const getPostAdminRoutersMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminRouters>>, TError,{data: BodyType<PostAdminRoutersPayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminRouters>>, TError,{data: BodyType<PostAdminRoutersPayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminRouters>>, {data: BodyType<PostAdminRoutersPayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminRouters(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminRoutersMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminRouters>>>
    export type PostAdminRoutersMutationBody = BodyType<PostAdminRoutersPayload>
    export type PostAdminRoutersMutationError = ErrorType<string>

    /**
 * @summary Add a new router contract
 */
export const usePostAdminRouters = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminRouters>>, TError,{data: BodyType<PostAdminRoutersPayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminRoutersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Set a community for a specific collection
 */
export const postAdminSetcommunity = (
    postAdminSetCommunityPayload: BodyType<PostAdminSetCommunityPayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/set-community`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminSetCommunityPayload
    },
      options);
    }
  


export const getPostAdminSetcommunityMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminSetcommunity>>, TError,{data: BodyType<PostAdminSetCommunityPayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminSetcommunity>>, TError,{data: BodyType<PostAdminSetCommunityPayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminSetcommunity>>, {data: BodyType<PostAdminSetCommunityPayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminSetcommunity(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminSetcommunityMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminSetcommunity>>>
    export type PostAdminSetcommunityMutationBody = BodyType<PostAdminSetCommunityPayload>
    export type PostAdminSetcommunityMutationError = ErrorType<string>

    /**
 * @summary Set a community for a specific collection
 */
export const usePostAdminSetcommunity = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminSetcommunity>>, TError,{data: BodyType<PostAdminSetCommunityPayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminSetcommunityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger a re-sync of daily volume calculations, volumes should only be calculated when fill_events have been fully synced
 */
export const postAdminSyncdailyvolumes = (
    postAdminSyncDailyVolumesPayload: BodyType<PostAdminSyncDailyVolumesPayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/sync-daily-volumes`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminSyncDailyVolumesPayload
    },
      options);
    }
  


export const getPostAdminSyncdailyvolumesMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminSyncdailyvolumes>>, TError,{data: BodyType<PostAdminSyncDailyVolumesPayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminSyncdailyvolumes>>, TError,{data: BodyType<PostAdminSyncDailyVolumesPayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminSyncdailyvolumes>>, {data: BodyType<PostAdminSyncDailyVolumesPayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminSyncdailyvolumes(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminSyncdailyvolumesMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminSyncdailyvolumes>>>
    export type PostAdminSyncdailyvolumesMutationBody = BodyType<PostAdminSyncDailyVolumesPayload>
    export type PostAdminSyncdailyvolumesMutationError = ErrorType<string>

    /**
 * @summary Trigger a re-sync of daily volume calculations, volumes should only be calculated when fill_events have been fully synced
 */
export const usePostAdminSyncdailyvolumes = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminSyncdailyvolumes>>, TError,{data: BodyType<PostAdminSyncDailyVolumesPayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminSyncdailyvolumesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger syncing of events.
 */
export const postAdminSyncevents = (
    postAdminSyncEventsPayload: BodyType<PostAdminSyncEventsPayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/sync-events`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminSyncEventsPayload
    },
      options);
    }
  


export const getPostAdminSynceventsMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminSyncevents>>, TError,{data: BodyType<PostAdminSyncEventsPayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminSyncevents>>, TError,{data: BodyType<PostAdminSyncEventsPayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminSyncevents>>, {data: BodyType<PostAdminSyncEventsPayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminSyncevents(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminSynceventsMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminSyncevents>>>
    export type PostAdminSynceventsMutationBody = BodyType<PostAdminSyncEventsPayload>
    export type PostAdminSynceventsMutationError = ErrorType<string>

    /**
 * @summary Trigger syncing of events.
 */
export const usePostAdminSyncevents = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminSyncevents>>, TError,{data: BodyType<PostAdminSyncEventsPayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminSynceventsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Tag tokens metadata objects
 */
export const postAdminTagtokens = (
    
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/tag-tokens`, method: 'POST'
    },
      options);
    }
  


export const getPostAdminTagtokensMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminTagtokens>>, TError,void, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminTagtokens>>, TError,void, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminTagtokens>>, void> = () => {
          

          return  postAdminTagtokens(requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminTagtokensMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminTagtokens>>>
    
    export type PostAdminTagtokensMutationError = ErrorType<string>

    /**
 * @summary Tag tokens metadata objects
 */
export const usePostAdminTagtokens = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminTagtokens>>, TError,void, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminTagtokensMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger bullmq job
 */
export const postAdminTriggerjob = (
    postAdminTriggerJobPayload: BodyType<PostAdminTriggerJobPayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/trigger-job`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminTriggerJobPayload
    },
      options);
    }
  


export const getPostAdminTriggerjobMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminTriggerjob>>, TError,{data: BodyType<PostAdminTriggerJobPayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminTriggerjob>>, TError,{data: BodyType<PostAdminTriggerJobPayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminTriggerjob>>, {data: BodyType<PostAdminTriggerJobPayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminTriggerjob(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminTriggerjobMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminTriggerjob>>>
    export type PostAdminTriggerjobMutationBody = BodyType<PostAdminTriggerJobPayload>
    export type PostAdminTriggerjobMutationError = ErrorType<string>

    /**
 * @summary Trigger bullmq job
 */
export const usePostAdminTriggerjob = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminTriggerjob>>, TError,{data: BodyType<PostAdminTriggerJobPayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminTriggerjobMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger rabbit job
 */
export const postAdminTriggerrabbitjob = (
    postAdminTriggerRabbitJobPayload: BodyType<PostAdminTriggerRabbitJobPayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/trigger-rabbit-job`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminTriggerRabbitJobPayload
    },
      options);
    }
  


export const getPostAdminTriggerrabbitjobMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminTriggerrabbitjob>>, TError,{data: BodyType<PostAdminTriggerRabbitJobPayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminTriggerrabbitjob>>, TError,{data: BodyType<PostAdminTriggerRabbitJobPayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminTriggerrabbitjob>>, {data: BodyType<PostAdminTriggerRabbitJobPayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminTriggerrabbitjob(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminTriggerrabbitjobMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminTriggerrabbitjob>>>
    export type PostAdminTriggerrabbitjobMutationBody = BodyType<PostAdminTriggerRabbitJobPayload>
    export type PostAdminTriggerrabbitjobMutationError = ErrorType<string>

    /**
 * @summary Trigger rabbit job
 */
export const usePostAdminTriggerrabbitjob = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminTriggerrabbitjob>>, TError,{data: BodyType<PostAdminTriggerRabbitJobPayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminTriggerrabbitjobMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update the given api key
 */
export const postAdminUpdateapikey = (
    postAdminUpdateApiKeyPayload: BodyType<PostAdminUpdateApiKeyPayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/update-api-key`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminUpdateApiKeyPayload
    },
      options);
    }
  


export const getPostAdminUpdateapikeyMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminUpdateapikey>>, TError,{data: BodyType<PostAdminUpdateApiKeyPayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminUpdateapikey>>, TError,{data: BodyType<PostAdminUpdateApiKeyPayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminUpdateapikey>>, {data: BodyType<PostAdminUpdateApiKeyPayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminUpdateapikey(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminUpdateapikeyMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminUpdateapikey>>>
    export type PostAdminUpdateapikeyMutationBody = BodyType<PostAdminUpdateApiKeyPayload>
    export type PostAdminUpdateapikeyMutationError = ErrorType<string>

    /**
 * @summary Update the given api key
 */
export const usePostAdminUpdateapikey = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminUpdateapikey>>, TError,{data: BodyType<PostAdminUpdateApiKeyPayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminUpdateapikeyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Increment the metadata version for a collection to bust the cache
 */
export const postAdminUpdateimageversion = (
    postAdminUpdateImageVersionPayload: BodyType<PostAdminUpdateImageVersionPayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/update-image-version`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminUpdateImageVersionPayload
    },
      options);
    }
  


export const getPostAdminUpdateimageversionMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminUpdateimageversion>>, TError,{data: BodyType<PostAdminUpdateImageVersionPayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminUpdateimageversion>>, TError,{data: BodyType<PostAdminUpdateImageVersionPayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminUpdateimageversion>>, {data: BodyType<PostAdminUpdateImageVersionPayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminUpdateimageversion(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminUpdateimageversionMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminUpdateimageversion>>>
    export type PostAdminUpdateimageversionMutationBody = BodyType<PostAdminUpdateImageVersionPayload>
    export type PostAdminUpdateimageversionMutationError = ErrorType<string>

    /**
 * @summary Increment the metadata version for a collection to bust the cache
 */
export const usePostAdminUpdateimageversion = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminUpdateimageversion>>, TError,{data: BodyType<PostAdminUpdateImageVersionPayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminUpdateimageversionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger re-syncing of specific source domain
 */
export const postAdminUpdatesource = (
    postAdminUpdateSourcePayload: BodyType<PostAdminUpdateSourcePayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/update-source`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAdminUpdateSourcePayload
    },
      options);
    }
  


export const getPostAdminUpdatesourceMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminUpdatesource>>, TError,{data: BodyType<PostAdminUpdateSourcePayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminUpdatesource>>, TError,{data: BodyType<PostAdminUpdateSourcePayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminUpdatesource>>, {data: BodyType<PostAdminUpdateSourcePayload>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminUpdatesource(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminUpdatesourceMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminUpdatesource>>>
    export type PostAdminUpdatesourceMutationBody = BodyType<PostAdminUpdateSourcePayload>
    export type PostAdminUpdatesourceMutationError = ErrorType<string>

    /**
 * @summary Trigger re-syncing of specific source domain
 */
export const usePostAdminUpdatesource = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminUpdatesource>>, TError,{data: BodyType<PostAdminUpdateSourcePayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminUpdatesourceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Array of collections to gather in a set. Adding or removing a collection will change the response. You may use this set when `collectionSetId` is an available param. An example is below.

`"collections": "0xba30E5F9Bb24caa003E9f2f0497Ad287FDF95623", "0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D"`

`"collectionsSetId": "8daa732ebe5db23f267e58d52f1c9b1879279bcdf4f78b8fb563390e6946ea65"`
 * @summary Create collection set
 */
export const postCollectionssetsV1 = (
    postCollectionsSetsV1Payload: BodyType<PostCollectionsSetsV1Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostCollectionsSetsV1Response>(
      {url: `/collections-sets/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postCollectionsSetsV1Payload
    },
      options);
    }
  


export const getPostCollectionssetsV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCollectionssetsV1>>, TError,{data: BodyType<PostCollectionsSetsV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postCollectionssetsV1>>, TError,{data: BodyType<PostCollectionsSetsV1Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCollectionssetsV1>>, {data: BodyType<PostCollectionsSetsV1Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postCollectionssetsV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostCollectionssetsV1MutationResult = NonNullable<Awaited<ReturnType<typeof postCollectionssetsV1>>>
    export type PostCollectionssetsV1MutationBody = BodyType<PostCollectionsSetsV1Payload>
    export type PostCollectionssetsV1MutationError = ErrorType<unknown>

    /**
 * @summary Create collection set
 */
export const usePostCollectionssetsV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCollectionssetsV1>>, TError,{data: BodyType<PostCollectionsSetsV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostCollectionssetsV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Array of contracts to gather in a set. Adding or removing a contract will change the response. You may use this set when contractSetId is an available param.
 * @summary Create contracts set
 */
export const postContractssetsV1 = (
    postContractsSetsV1Payload: BodyType<PostContractsSetsV1Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostContractsSetsV1Response>(
      {url: `/contracts-sets/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postContractsSetsV1Payload
    },
      options);
    }
  


export const getPostContractssetsV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postContractssetsV1>>, TError,{data: BodyType<PostContractsSetsV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postContractssetsV1>>, TError,{data: BodyType<PostContractsSetsV1Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postContractssetsV1>>, {data: BodyType<PostContractsSetsV1Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postContractssetsV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostContractssetsV1MutationResult = NonNullable<Awaited<ReturnType<typeof postContractssetsV1>>>
    export type PostContractssetsV1MutationBody = BodyType<PostContractsSetsV1Payload>
    export type PostContractssetsV1MutationError = ErrorType<unknown>

    /**
 * @summary Create contracts set
 */
export const usePostContractssetsV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postContractssetsV1>>, TError,{data: BodyType<PostContractsSetsV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostContractssetsV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Submit single order
 */
export const postOrderV2 = (
    postOrderV2Payload: BodyType<PostOrderV2Payload>,
    params?: PostOrderV2Params,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostOrderV2Response>(
      {url: `/order/v2`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postOrderV2Payload,
        params
    },
      options);
    }
  


export const getPostOrderV2MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrderV2>>, TError,{data: BodyType<PostOrderV2Payload>;params?: PostOrderV2Params}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postOrderV2>>, TError,{data: BodyType<PostOrderV2Payload>;params?: PostOrderV2Params}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postOrderV2>>, {data: BodyType<PostOrderV2Payload>;params?: PostOrderV2Params}> = (props) => {
          const {data,params} = props ?? {};

          return  postOrderV2(data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostOrderV2MutationResult = NonNullable<Awaited<ReturnType<typeof postOrderV2>>>
    export type PostOrderV2MutationBody = BodyType<PostOrderV2Payload>
    export type PostOrderV2MutationError = ErrorType<unknown>

    /**
 * @summary Submit single order
 */
export const usePostOrderV2 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrderV2>>, TError,{data: BodyType<PostOrderV2Payload>;params?: PostOrderV2Params}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostOrderV2MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Submit signed order
 */
export const postOrderV3 = (
    postOrderV3Payload: BodyType<PostOrderV3Payload>,
    params?: PostOrderV3Params,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostOrderV3Response>(
      {url: `/order/v3`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postOrderV3Payload,
        params
    },
      options);
    }
  


export const getPostOrderV3MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrderV3>>, TError,{data: BodyType<PostOrderV3Payload>;params?: PostOrderV3Params}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postOrderV3>>, TError,{data: BodyType<PostOrderV3Payload>;params?: PostOrderV3Params}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postOrderV3>>, {data: BodyType<PostOrderV3Payload>;params?: PostOrderV3Params}> = (props) => {
          const {data,params} = props ?? {};

          return  postOrderV3(data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostOrderV3MutationResult = NonNullable<Awaited<ReturnType<typeof postOrderV3>>>
    export type PostOrderV3MutationBody = BodyType<PostOrderV3Payload>
    export type PostOrderV3MutationError = ErrorType<unknown>

    /**
 * @summary Submit signed order
 */
export const usePostOrderV3 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrderV3>>, TError,{data: BodyType<PostOrderV3Payload>;params?: PostOrderV3Params}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostOrderV3MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Submit signed orders
 */
export const postOrderV4 = (
    postOrderV4Payload: BodyType<PostOrderV4Payload>,
    params?: PostOrderV4Params,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostOrderV4Response>(
      {url: `/order/v4`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postOrderV4Payload,
        params
    },
      options);
    }
  


export const getPostOrderV4MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrderV4>>, TError,{data: BodyType<PostOrderV4Payload>;params?: PostOrderV4Params}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postOrderV4>>, TError,{data: BodyType<PostOrderV4Payload>;params?: PostOrderV4Params}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postOrderV4>>, {data: BodyType<PostOrderV4Payload>;params?: PostOrderV4Params}> = (props) => {
          const {data,params} = props ?? {};

          return  postOrderV4(data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostOrderV4MutationResult = NonNullable<Awaited<ReturnType<typeof postOrderV4>>>
    export type PostOrderV4MutationBody = BodyType<PostOrderV4Payload>
    export type PostOrderV4MutationError = ErrorType<unknown>

    /**
 * @summary Submit signed orders
 */
export const usePostOrderV4 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrderV4>>, TError,{data: BodyType<PostOrderV4Payload>;params?: PostOrderV4Params}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostOrderV4MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @deprecated
 * @summary Submit order batch
 */
export const postOrdersV1 = (
    postOrdersV1Payload: BodyType<PostOrdersV1Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/orders/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postOrdersV1Payload
    },
      options);
    }
  


export const getPostOrdersV1MutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrdersV1>>, TError,{data: BodyType<PostOrdersV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postOrdersV1>>, TError,{data: BodyType<PostOrdersV1Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postOrdersV1>>, {data: BodyType<PostOrdersV1Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postOrdersV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostOrdersV1MutationResult = NonNullable<Awaited<ReturnType<typeof postOrdersV1>>>
    export type PostOrdersV1MutationBody = BodyType<PostOrdersV1Payload>
    export type PostOrdersV1MutationError = ErrorType<string>

    /**
 * @deprecated
 * @summary Submit order batch
 */
export const usePostOrdersV1 = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrdersV1>>, TError,{data: BodyType<PostOrdersV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostOrdersV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @deprecated
 * @summary Submit multiple Seaport offers (compatible with OpenSea's API response)
 */
export const postSeaportOffers = (
    postSeaportOffersPayload: BodyType<PostSeaportOffersPayload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/seaport/offers`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postSeaportOffersPayload
    },
      options);
    }
  


export const getPostSeaportOffersMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postSeaportOffers>>, TError,{data: BodyType<PostSeaportOffersPayload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postSeaportOffers>>, TError,{data: BodyType<PostSeaportOffersPayload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postSeaportOffers>>, {data: BodyType<PostSeaportOffersPayload>}> = (props) => {
          const {data} = props ?? {};

          return  postSeaportOffers(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostSeaportOffersMutationResult = NonNullable<Awaited<ReturnType<typeof postSeaportOffers>>>
    export type PostSeaportOffersMutationBody = BodyType<PostSeaportOffersPayload>
    export type PostSeaportOffersMutationError = ErrorType<string>

    /**
 * @deprecated
 * @summary Submit multiple Seaport offers (compatible with OpenSea's API response)
 */
export const usePostSeaportOffers = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postSeaportOffers>>, TError,{data: BodyType<PostSeaportOffersPayload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostSeaportOffersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Use this API to create a `tokenSetId` to call specific tokens from a collection. Adding or removing a tokenId will change the response. See an example below.

 Input of `0xd774557b647330c91bf44cfeab205095f7e6c367:1` and `0xd774557b647330c91bf44cfeab205095f7e6c367:2`

 Output of `list:0xd774557b647330c91bf44cfeab205095f7e6c367:0xb6fd98eeb7e08fc521f11511289afe4d8e873fd7a3fb76ab757fa47c23f596e9`

 Notes:

- Include `list:` when using this `tokenSetId` for it to work successfully.

- You cannot adjust tokens within a `tokenSetId`. Please create a new set.

- Use the `/tokens/ids` endpoint to get a list of tokens within a set.
 * @summary Create token set
 */
export const postTokensetsV2 = (
    postTokenSetsV2Payload: BodyType<PostTokenSetsV2Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostTokenSetsV2Response>(
      {url: `/token-sets/v2`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postTokenSetsV2Payload
    },
      options);
    }
  


export const getPostTokensetsV2MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensetsV2>>, TError,{data: BodyType<PostTokenSetsV2Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postTokensetsV2>>, TError,{data: BodyType<PostTokenSetsV2Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTokensetsV2>>, {data: BodyType<PostTokenSetsV2Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postTokensetsV2(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostTokensetsV2MutationResult = NonNullable<Awaited<ReturnType<typeof postTokensetsV2>>>
    export type PostTokensetsV2MutationBody = BodyType<PostTokenSetsV2Payload>
    export type PostTokensetsV2MutationError = ErrorType<unknown>

    /**
 * @summary Create token set
 */
export const usePostTokensetsV2 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensetsV2>>, TError,{data: BodyType<PostTokenSetsV2Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostTokensetsV2MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get API usage metrics for the given API key
 * @summary Get API usage metrics for the given API key
 */
export const postAdminApikeysMetrics = (
    postAdminApikeysMetricsBody: BodyType<PostAdminApikeysMetricsBody>,
 options?: SecondParameter<typeof client>,) => {
      
      const formUrlEncoded = new URLSearchParams();
postAdminApikeysMetricsBody.keys.forEach(value => formUrlEncoded.append('keys', value));
if(postAdminApikeysMetricsBody.granularity !== undefined) {
 formUrlEncoded.append('granularity', postAdminApikeysMetricsBody.granularity)
 }
if(postAdminApikeysMetricsBody.groupBy !== undefined) {
 formUrlEncoded.append('groupBy', postAdminApikeysMetricsBody.groupBy.toString())
 }
if(postAdminApikeysMetricsBody.startTime !== undefined) {
 formUrlEncoded.append('startTime', postAdminApikeysMetricsBody.startTime)
 }
if(postAdminApikeysMetricsBody.endTime !== undefined) {
 formUrlEncoded.append('endTime', postAdminApikeysMetricsBody.endTime)
 }

      return client<PostAdminApiKeysMetricsResponse>(
      {url: `/admin/api-keys/metrics`, method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded', },
       data: formUrlEncoded
    },
      options);
    }
  


export const getPostAdminApikeysMetricsMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminApikeysMetrics>>, TError,{data: BodyType<PostAdminApikeysMetricsBody>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminApikeysMetrics>>, TError,{data: BodyType<PostAdminApikeysMetricsBody>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminApikeysMetrics>>, {data: BodyType<PostAdminApikeysMetricsBody>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminApikeysMetrics(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminApikeysMetricsMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminApikeysMetrics>>>
    export type PostAdminApikeysMetricsMutationBody = BodyType<PostAdminApikeysMetricsBody>
    export type PostAdminApikeysMetricsMutationError = ErrorType<unknown>

    /**
 * @summary Get API usage metrics for the given API key
 */
export const usePostAdminApikeysMetrics = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminApikeysMetrics>>, TError,{data: BodyType<PostAdminApikeysMetricsBody>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminApikeysMetricsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * This API requires an allowed API key for execution. Please contact technical support with more questions.
 * @summary Disable or reenable metadata for a collection
 */
export const postCollectionsDisablemetadataV1 = (
    postCollectionsDisableMetadataV1Payload: BodyType<PostCollectionsDisableMetadataV1Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostCollectionsDisableMetadataV1Response>(
      {url: `/collections/disable-metadata/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postCollectionsDisableMetadataV1Payload
    },
      options);
    }
  


export const getPostCollectionsDisablemetadataV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCollectionsDisablemetadataV1>>, TError,{data: BodyType<PostCollectionsDisableMetadataV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postCollectionsDisablemetadataV1>>, TError,{data: BodyType<PostCollectionsDisableMetadataV1Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCollectionsDisablemetadataV1>>, {data: BodyType<PostCollectionsDisableMetadataV1Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postCollectionsDisablemetadataV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostCollectionsDisablemetadataV1MutationResult = NonNullable<Awaited<ReturnType<typeof postCollectionsDisablemetadataV1>>>
    export type PostCollectionsDisablemetadataV1MutationBody = BodyType<PostCollectionsDisableMetadataV1Payload>
    export type PostCollectionsDisablemetadataV1MutationError = ErrorType<unknown>

    /**
 * @summary Disable or reenable metadata for a collection
 */
export const usePostCollectionsDisablemetadataV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCollectionsDisablemetadataV1>>, TError,{data: BodyType<PostCollectionsDisableMetadataV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostCollectionsDisablemetadataV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Use this API to refresh a collection metadata. Only use this endpoint when you notice multiple tokens with incorrect metadata. Otherwise, refresh single token metadata. Collections with over 30,000 tokens require admin key override, so please contact technical support for assistance.

 Collection metadata is automatically updated at 23:30 UTC daily for:

- Top 500 Collection by 24hr Volume

- Collections Minted 1 Day Ago

- Collections Minted 7 Days Ago

 Caution: This API should be used in moderation, like only when missing data is discovered. Calling it in bulk or programmatically will result in your API key getting rate limited.
 * @summary Refresh Collection
 */
export const postCollectionsRefreshV2 = (
    postCollectionsRefreshV2Payload: BodyType<PostCollectionsRefreshV2Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostCollectionsRefreshV2Response>(
      {url: `/collections/refresh/v2`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postCollectionsRefreshV2Payload
    },
      options);
    }
  


export const getPostCollectionsRefreshV2MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCollectionsRefreshV2>>, TError,{data: BodyType<PostCollectionsRefreshV2Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postCollectionsRefreshV2>>, TError,{data: BodyType<PostCollectionsRefreshV2Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCollectionsRefreshV2>>, {data: BodyType<PostCollectionsRefreshV2Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postCollectionsRefreshV2(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostCollectionsRefreshV2MutationResult = NonNullable<Awaited<ReturnType<typeof postCollectionsRefreshV2>>>
    export type PostCollectionsRefreshV2MutationBody = BodyType<PostCollectionsRefreshV2Payload>
    export type PostCollectionsRefreshV2MutationError = ErrorType<unknown>

    /**
 * @summary Refresh Collection
 */
export const usePostCollectionsRefreshV2 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCollectionsRefreshV2>>, TError,{data: BodyType<PostCollectionsRefreshV2Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostCollectionsRefreshV2MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * This API can be used by allowed API keys to update the spam status of a collection.
 * @summary Update collections spam status
 */
export const postCollectionsSpamstatusV1 = (
    postCollectionsSpamStatusV1Payload: BodyType<PostCollectionsSpamStatusV1Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostCollectionsSpamStatusV1Response>(
      {url: `/collections/spam-status/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postCollectionsSpamStatusV1Payload
    },
      options);
    }
  


export const getPostCollectionsSpamstatusV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCollectionsSpamstatusV1>>, TError,{data: BodyType<PostCollectionsSpamStatusV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postCollectionsSpamstatusV1>>, TError,{data: BodyType<PostCollectionsSpamStatusV1Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCollectionsSpamstatusV1>>, {data: BodyType<PostCollectionsSpamStatusV1Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postCollectionsSpamstatusV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostCollectionsSpamstatusV1MutationResult = NonNullable<Awaited<ReturnType<typeof postCollectionsSpamstatusV1>>>
    export type PostCollectionsSpamstatusV1MutationBody = BodyType<PostCollectionsSpamStatusV1Payload>
    export type PostCollectionsSpamstatusV1MutationError = ErrorType<unknown>

    /**
 * @summary Update collections spam status
 */
export const usePostCollectionsSpamstatusV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCollectionsSpamstatusV1>>, TError,{data: BodyType<PostCollectionsSpamStatusV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostCollectionsSpamstatusV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Attach a signature to an existing auth challenge
 */
export const postExecuteAuthsignatureV1 = (
    postExecuteAuthSignatureV1Payload: BodyType<PostExecuteAuthSignatureV1Payload>,
    params: PostExecuteAuthsignatureV1Params,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostExecuteAuthSignatureV1Response>(
      {url: `/execute/auth-signature/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postExecuteAuthSignatureV1Payload,
        params
    },
      options);
    }
  


export const getPostExecuteAuthsignatureV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteAuthsignatureV1>>, TError,{data: BodyType<PostExecuteAuthSignatureV1Payload>;params: PostExecuteAuthsignatureV1Params}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteAuthsignatureV1>>, TError,{data: BodyType<PostExecuteAuthSignatureV1Payload>;params: PostExecuteAuthsignatureV1Params}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteAuthsignatureV1>>, {data: BodyType<PostExecuteAuthSignatureV1Payload>;params: PostExecuteAuthsignatureV1Params}> = (props) => {
          const {data,params} = props ?? {};

          return  postExecuteAuthsignatureV1(data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteAuthsignatureV1MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteAuthsignatureV1>>>
    export type PostExecuteAuthsignatureV1MutationBody = BodyType<PostExecuteAuthSignatureV1Payload>
    export type PostExecuteAuthsignatureV1MutationError = ErrorType<unknown>

    /**
 * @summary Attach a signature to an existing auth challenge
 */
export const usePostExecuteAuthsignatureV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteAuthsignatureV1>>, TError,{data: BodyType<PostExecuteAuthSignatureV1Payload>;params: PostExecuteAuthsignatureV1Params}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteAuthsignatureV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Generate a bid and submit it to multiple marketplaces
 * @deprecated
 * @summary Create bid (offer)
 */
export const postExecuteBidV4 = (
    postExecuteBidV4Payload: BodyType<PostExecuteBidV4Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostExecuteBidV4Response>(
      {url: `/execute/bid/v4`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postExecuteBidV4Payload
    },
      options);
    }
  


export const getPostExecuteBidV4MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteBidV4>>, TError,{data: BodyType<PostExecuteBidV4Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteBidV4>>, TError,{data: BodyType<PostExecuteBidV4Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteBidV4>>, {data: BodyType<PostExecuteBidV4Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteBidV4(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteBidV4MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteBidV4>>>
    export type PostExecuteBidV4MutationBody = BodyType<PostExecuteBidV4Payload>
    export type PostExecuteBidV4MutationError = ErrorType<unknown>

    /**
 * @deprecated
 * @summary Create bid (offer)
 */
export const usePostExecuteBidV4 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteBidV4>>, TError,{data: BodyType<PostExecuteBidV4Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteBidV4MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Generate bids and submit them to multiple marketplaces.

 Notes:

- Please use the `/cross-posting-orders/v1` to check the status on cross posted bids.

- We recommend using Reservoir SDK as it abstracts the process of iterating through steps, and returning callbacks that can be used to update your UI.
 * @summary Create bids (offers)
 */
export const postExecuteBidV5 = (
    postExecuteBidV5Payload: BodyType<PostExecuteBidV5Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostExecuteBidV5Response>(
      {url: `/execute/bid/v5`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postExecuteBidV5Payload
    },
      options);
    }
  


export const getPostExecuteBidV5MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteBidV5>>, TError,{data: BodyType<PostExecuteBidV5Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteBidV5>>, TError,{data: BodyType<PostExecuteBidV5Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteBidV5>>, {data: BodyType<PostExecuteBidV5Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteBidV5(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteBidV5MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteBidV5>>>
    export type PostExecuteBidV5MutationBody = BodyType<PostExecuteBidV5Payload>
    export type PostExecuteBidV5MutationError = ErrorType<unknown>

    /**
 * @summary Create bids (offers)
 */
export const usePostExecuteBidV5 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteBidV5>>, TError,{data: BodyType<PostExecuteBidV5Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteBidV5MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @deprecated
 * @summary Buy tokens
 */
export const postExecuteBuyV5 = (
    postExecuteBuyV5Payload: BodyType<PostExecuteBuyV5Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostExecuteBuyV5Response>(
      {url: `/execute/buy/v5`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postExecuteBuyV5Payload
    },
      options);
    }
  


export const getPostExecuteBuyV5MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteBuyV5>>, TError,{data: BodyType<PostExecuteBuyV5Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteBuyV5>>, TError,{data: BodyType<PostExecuteBuyV5Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteBuyV5>>, {data: BodyType<PostExecuteBuyV5Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteBuyV5(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteBuyV5MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteBuyV5>>>
    export type PostExecuteBuyV5MutationBody = BodyType<PostExecuteBuyV5Payload>
    export type PostExecuteBuyV5MutationError = ErrorType<unknown>

    /**
 * @deprecated
 * @summary Buy tokens
 */
export const usePostExecuteBuyV5 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteBuyV5>>, TError,{data: BodyType<PostExecuteBuyV5Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteBuyV5MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @deprecated
 * @summary Buy tokens
 */
export const postExecuteBuyV6 = (
    postExecuteBuyV6Payload: BodyType<PostExecuteBuyV6Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostExecuteBuyV6Response>(
      {url: `/execute/buy/v6`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postExecuteBuyV6Payload
    },
      options);
    }
  


export const getPostExecuteBuyV6MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteBuyV6>>, TError,{data: BodyType<PostExecuteBuyV6Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteBuyV6>>, TError,{data: BodyType<PostExecuteBuyV6Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteBuyV6>>, {data: BodyType<PostExecuteBuyV6Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteBuyV6(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteBuyV6MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteBuyV6>>>
    export type PostExecuteBuyV6MutationBody = BodyType<PostExecuteBuyV6Payload>
    export type PostExecuteBuyV6MutationError = ErrorType<unknown>

    /**
 * @deprecated
 * @summary Buy tokens
 */
export const usePostExecuteBuyV6 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteBuyV6>>, TError,{data: BodyType<PostExecuteBuyV6Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteBuyV6MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Use this API to fill listings. We recommend using the SDK over this API as the SDK will iterate through the steps and return callbacks. Please mark `excludeEOA` as `true` to exclude Blur orders.
 * @summary Buy tokens (fill listings)
 */
export const postExecuteBuyV7 = (
    postExecuteBuyV7Payload: BodyType<PostExecuteBuyV7Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostExecuteBuyV7Response>(
      {url: `/execute/buy/v7`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postExecuteBuyV7Payload
    },
      options);
    }
  


export const getPostExecuteBuyV7MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteBuyV7>>, TError,{data: BodyType<PostExecuteBuyV7Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteBuyV7>>, TError,{data: BodyType<PostExecuteBuyV7Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteBuyV7>>, {data: BodyType<PostExecuteBuyV7Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteBuyV7(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteBuyV7MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteBuyV7>>>
    export type PostExecuteBuyV7MutationBody = BodyType<PostExecuteBuyV7Payload>
    export type PostExecuteBuyV7MutationError = ErrorType<unknown>

    /**
 * @summary Buy tokens (fill listings)
 */
export const usePostExecuteBuyV7 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteBuyV7>>, TError,{data: BodyType<PostExecuteBuyV7Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteBuyV7MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Cancel existing orders on any marketplace
 * @summary Cancel orders
 */
export const postExecuteCancelV3 = (
    postExecuteCancelV3Payload: BodyType<PostExecuteCancelV3Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostExecuteCancelV3Response>(
      {url: `/execute/cancel/v3`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postExecuteCancelV3Payload
    },
      options);
    }
  


export const getPostExecuteCancelV3MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteCancelV3>>, TError,{data: BodyType<PostExecuteCancelV3Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteCancelV3>>, TError,{data: BodyType<PostExecuteCancelV3Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteCancelV3>>, {data: BodyType<PostExecuteCancelV3Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteCancelV3(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteCancelV3MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteCancelV3>>>
    export type PostExecuteCancelV3MutationBody = BodyType<PostExecuteCancelV3Payload>
    export type PostExecuteCancelV3MutationError = ErrorType<unknown>

    /**
 * @summary Cancel orders
 */
export const usePostExecuteCancelV3 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteCancelV3>>, TError,{data: BodyType<PostExecuteCancelV3Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteCancelV3MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * This endpoint cancels Imtbl orders in their API, using the signature of the cancel request.
 * @summary Imtbl cancel orders
 */
export const postExecuteCancelimtblV1 = (
    postExecuteCancelImtblV1Payload: BodyType<PostExecuteCancelImtblV1Payload>,
    params?: PostExecuteCancelimtblV1Params,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostExecuteCancelImtblV1Response>(
      {url: `/execute/cancel-imtbl/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postExecuteCancelImtblV1Payload,
        params
    },
      options);
    }
  


export const getPostExecuteCancelimtblV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteCancelimtblV1>>, TError,{data: BodyType<PostExecuteCancelImtblV1Payload>;params?: PostExecuteCancelimtblV1Params}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteCancelimtblV1>>, TError,{data: BodyType<PostExecuteCancelImtblV1Payload>;params?: PostExecuteCancelimtblV1Params}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteCancelimtblV1>>, {data: BodyType<PostExecuteCancelImtblV1Payload>;params?: PostExecuteCancelimtblV1Params}> = (props) => {
          const {data,params} = props ?? {};

          return  postExecuteCancelimtblV1(data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteCancelimtblV1MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteCancelimtblV1>>>
    export type PostExecuteCancelimtblV1MutationBody = BodyType<PostExecuteCancelImtblV1Payload>
    export type PostExecuteCancelimtblV1MutationError = ErrorType<unknown>

    /**
 * @summary Imtbl cancel orders
 */
export const usePostExecuteCancelimtblV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteCancelimtblV1>>, TError,{data: BodyType<PostExecuteCancelImtblV1Payload>;params?: PostExecuteCancelimtblV1Params}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteCancelimtblV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * If your order was created using the Seaport Oracle to allow off chain & gasless cancellations, you can just use the Kit's cancel modals, SDK's `cancelOrder`, or `/execute/cancel/`. Those tools will automatically access this endpoint for an oracle cancellation without you directly calling this endpoint.
 * @summary Off-chain cancel orders
 */
export const postExecuteCancelsignatureV1 = (
    postExecuteCancelSignatureV1Payload: BodyType<PostExecuteCancelSignatureV1Payload>,
    params?: PostExecuteCancelsignatureV1Params,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostExecuteCancelSignatureV1Response>(
      {url: `/execute/cancel-signature/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postExecuteCancelSignatureV1Payload,
        params
    },
      options);
    }
  


export const getPostExecuteCancelsignatureV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteCancelsignatureV1>>, TError,{data: BodyType<PostExecuteCancelSignatureV1Payload>;params?: PostExecuteCancelsignatureV1Params}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteCancelsignatureV1>>, TError,{data: BodyType<PostExecuteCancelSignatureV1Payload>;params?: PostExecuteCancelsignatureV1Params}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteCancelsignatureV1>>, {data: BodyType<PostExecuteCancelSignatureV1Payload>;params?: PostExecuteCancelsignatureV1Params}> = (props) => {
          const {data,params} = props ?? {};

          return  postExecuteCancelsignatureV1(data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteCancelsignatureV1MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteCancelsignatureV1>>>
    export type PostExecuteCancelsignatureV1MutationBody = BodyType<PostExecuteCancelSignatureV1Payload>
    export type PostExecuteCancelsignatureV1MutationError = ErrorType<unknown>

    /**
 * @summary Off-chain cancel orders
 */
export const usePostExecuteCancelsignatureV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteCancelsignatureV1>>, TError,{data: BodyType<PostExecuteCancelSignatureV1Payload>;params?: PostExecuteCancelsignatureV1Params}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteCancelsignatureV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Generate a listing and submit it to multiple marketplaces
 * @deprecated
 * @summary Create ask (listing)
 */
export const postExecuteListV4 = (
    postExecuteListV4Payload: BodyType<PostExecuteListV4Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostExecuteListV4Response>(
      {url: `/execute/list/v4`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postExecuteListV4Payload
    },
      options);
    }
  


export const getPostExecuteListV4MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteListV4>>, TError,{data: BodyType<PostExecuteListV4Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteListV4>>, TError,{data: BodyType<PostExecuteListV4Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteListV4>>, {data: BodyType<PostExecuteListV4Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteListV4(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteListV4MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteListV4>>>
    export type PostExecuteListV4MutationBody = BodyType<PostExecuteListV4Payload>
    export type PostExecuteListV4MutationError = ErrorType<unknown>

    /**
 * @deprecated
 * @summary Create ask (listing)
 */
export const usePostExecuteListV4 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteListV4>>, TError,{data: BodyType<PostExecuteListV4Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteListV4MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Generate listings and submit them to multiple marketplaces.

 Notes:

- Please use the `/cross-posting-orders/v1` to check the status on cross posted bids.

- We recommend using Reservoir SDK as it abstracts the process of iterating through steps, and returning callbacks that can be used to update your UI.
 * @summary Create asks (listings)
 */
export const postExecuteListV5 = (
    postExecuteListV5Payload: BodyType<PostExecuteListV5Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostExecuteListV5Response>(
      {url: `/execute/list/v5`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postExecuteListV5Payload
    },
      options);
    }
  


export const getPostExecuteListV5MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteListV5>>, TError,{data: BodyType<PostExecuteListV5Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteListV5>>, TError,{data: BodyType<PostExecuteListV5Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteListV5>>, {data: BodyType<PostExecuteListV5Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteListV5(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteListV5MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteListV5>>>
    export type PostExecuteListV5MutationBody = BodyType<PostExecuteListV5Payload>
    export type PostExecuteListV5MutationError = ErrorType<unknown>

    /**
 * @summary Create asks (listings)
 */
export const usePostExecuteListV5 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteListV5>>, TError,{data: BodyType<PostExecuteListV5Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteListV5MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Use this API to mint tokens. We recommend using the SDK over this API as the SDK will iterate through the steps and return callbacks.
 * @summary Mint tokens
 */
export const postExecuteMintV1 = (
    postExecuteMintV1Payload: BodyType<PostExecuteMintV1Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostExecuteMintV1Response>(
      {url: `/execute/mint/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postExecuteMintV1Payload
    },
      options);
    }
  


export const getPostExecuteMintV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteMintV1>>, TError,{data: BodyType<PostExecuteMintV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteMintV1>>, TError,{data: BodyType<PostExecuteMintV1Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteMintV1>>, {data: BodyType<PostExecuteMintV1Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteMintV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteMintV1MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteMintV1>>>
    export type PostExecuteMintV1MutationBody = BodyType<PostExecuteMintV1Payload>
    export type PostExecuteMintV1MutationError = ErrorType<unknown>

    /**
 * @summary Mint tokens
 */
export const usePostExecuteMintV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteMintV1>>, TError,{data: BodyType<PostExecuteMintV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteMintV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Attach a signature to an existing permit
 */
export const postExecutePermitsignatureV1 = (
    postExecutePermitSignatureV1Payload: BodyType<PostExecutePermitSignatureV1Payload>,
    params: PostExecutePermitsignatureV1Params,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostExecutePermitSignatureV1Response>(
      {url: `/execute/permit-signature/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postExecutePermitSignatureV1Payload,
        params
    },
      options);
    }
  


export const getPostExecutePermitsignatureV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecutePermitsignatureV1>>, TError,{data: BodyType<PostExecutePermitSignatureV1Payload>;params: PostExecutePermitsignatureV1Params}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecutePermitsignatureV1>>, TError,{data: BodyType<PostExecutePermitSignatureV1Payload>;params: PostExecutePermitsignatureV1Params}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecutePermitsignatureV1>>, {data: BodyType<PostExecutePermitSignatureV1Payload>;params: PostExecutePermitsignatureV1Params}> = (props) => {
          const {data,params} = props ?? {};

          return  postExecutePermitsignatureV1(data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecutePermitsignatureV1MutationResult = NonNullable<Awaited<ReturnType<typeof postExecutePermitsignatureV1>>>
    export type PostExecutePermitsignatureV1MutationBody = BodyType<PostExecutePermitSignatureV1Payload>
    export type PostExecutePermitsignatureV1MutationError = ErrorType<unknown>

    /**
 * @summary Attach a signature to an existing permit
 */
export const usePostExecutePermitsignatureV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecutePermitsignatureV1>>, TError,{data: BodyType<PostExecutePermitSignatureV1Payload>;params: PostExecutePermitsignatureV1Params}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecutePermitsignatureV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Attach a signature to an existing pre-signature
 */
export const postExecutePresignatureV1 = (
    postExecutePreSignatureV1Payload: BodyType<PostExecutePreSignatureV1Payload>,
    params: PostExecutePresignatureV1Params,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostExecutePreSignatureV1Response>(
      {url: `/execute/pre-signature/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postExecutePreSignatureV1Payload,
        params
    },
      options);
    }
  


export const getPostExecutePresignatureV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecutePresignatureV1>>, TError,{data: BodyType<PostExecutePreSignatureV1Payload>;params: PostExecutePresignatureV1Params}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecutePresignatureV1>>, TError,{data: BodyType<PostExecutePreSignatureV1Payload>;params: PostExecutePresignatureV1Params}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecutePresignatureV1>>, {data: BodyType<PostExecutePreSignatureV1Payload>;params: PostExecutePresignatureV1Params}> = (props) => {
          const {data,params} = props ?? {};

          return  postExecutePresignatureV1(data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecutePresignatureV1MutationResult = NonNullable<Awaited<ReturnType<typeof postExecutePresignatureV1>>>
    export type PostExecutePresignatureV1MutationBody = BodyType<PostExecutePreSignatureV1Payload>
    export type PostExecutePresignatureV1MutationError = ErrorType<unknown>

    /**
 * @summary Attach a signature to an existing pre-signature
 */
export const usePostExecutePresignatureV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecutePresignatureV1>>, TError,{data: BodyType<PostExecutePreSignatureV1Payload>;params: PostExecutePresignatureV1Params}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecutePresignatureV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Send the success status of an execution
 */
export const postExecuteResultsV1 = (
    postExecuteResultsV1Payload: BodyType<PostExecuteResultsV1Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostExecuteResultsV1Response>(
      {url: `/execute/results/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postExecuteResultsV1Payload
    },
      options);
    }
  


export const getPostExecuteResultsV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteResultsV1>>, TError,{data: BodyType<PostExecuteResultsV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteResultsV1>>, TError,{data: BodyType<PostExecuteResultsV1Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteResultsV1>>, {data: BodyType<PostExecuteResultsV1Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteResultsV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteResultsV1MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteResultsV1>>>
    export type PostExecuteResultsV1MutationBody = BodyType<PostExecuteResultsV1Payload>
    export type PostExecuteResultsV1MutationError = ErrorType<unknown>

    /**
 * @summary Send the success status of an execution
 */
export const usePostExecuteResultsV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteResultsV1>>, TError,{data: BodyType<PostExecuteResultsV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteResultsV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @deprecated
 * @summary Sell tokens (accept bids)
 */
export const postExecuteSellV6 = (
    postExecuteSellV6Payload: BodyType<PostExecuteSellV6Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostExecuteSellV6Response>(
      {url: `/execute/sell/v6`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postExecuteSellV6Payload
    },
      options);
    }
  


export const getPostExecuteSellV6MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteSellV6>>, TError,{data: BodyType<PostExecuteSellV6Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteSellV6>>, TError,{data: BodyType<PostExecuteSellV6Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteSellV6>>, {data: BodyType<PostExecuteSellV6Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteSellV6(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteSellV6MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteSellV6>>>
    export type PostExecuteSellV6MutationBody = BodyType<PostExecuteSellV6Payload>
    export type PostExecuteSellV6MutationError = ErrorType<unknown>

    /**
 * @deprecated
 * @summary Sell tokens (accept bids)
 */
export const usePostExecuteSellV6 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteSellV6>>, TError,{data: BodyType<PostExecuteSellV6Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteSellV6MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Use this API to accept bids. We recommend using the SDK over this API as the SDK will iterate through the steps and return callbacks. Please mark `excludeEOA` as `true` to exclude Blur orders.
 * @summary Sell tokens (accept bids)
 */
export const postExecuteSellV7 = (
    postExecuteSellV7Payload: BodyType<PostExecuteSellV7Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostExecuteSellV7Response>(
      {url: `/execute/sell/v7`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postExecuteSellV7Payload
    },
      options);
    }
  


export const getPostExecuteSellV7MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteSellV7>>, TError,{data: BodyType<PostExecuteSellV7Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteSellV7>>, TError,{data: BodyType<PostExecuteSellV7Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteSellV7>>, {data: BodyType<PostExecuteSellV7Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteSellV7(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteSellV7MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteSellV7>>>
    export type PostExecuteSellV7MutationBody = BodyType<PostExecuteSellV7Payload>
    export type PostExecuteSellV7MutationError = ErrorType<unknown>

    /**
 * @summary Sell tokens (accept bids)
 */
export const usePostExecuteSellV7 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteSellV7>>, TError,{data: BodyType<PostExecuteSellV7Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteSellV7MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Indirectly fill an order via a solver
 */
export const postExecuteSolveV1 = (
    postExecuteSolveV1PayloadOptions: BodyType<PostExecuteSolveV1PayloadOptions>,
    params?: PostExecuteSolveV1Params,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostExecuteSolveV1Response>(
      {url: `/execute/solve/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postExecuteSolveV1PayloadOptions,
        params
    },
      options);
    }
  


export const getPostExecuteSolveV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteSolveV1>>, TError,{data: BodyType<PostExecuteSolveV1PayloadOptions>;params?: PostExecuteSolveV1Params}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteSolveV1>>, TError,{data: BodyType<PostExecuteSolveV1PayloadOptions>;params?: PostExecuteSolveV1Params}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteSolveV1>>, {data: BodyType<PostExecuteSolveV1PayloadOptions>;params?: PostExecuteSolveV1Params}> = (props) => {
          const {data,params} = props ?? {};

          return  postExecuteSolveV1(data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteSolveV1MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteSolveV1>>>
    export type PostExecuteSolveV1MutationBody = BodyType<PostExecuteSolveV1PayloadOptions>
    export type PostExecuteSolveV1MutationError = ErrorType<unknown>

    /**
 * @summary Indirectly fill an order via a solver
 */
export const usePostExecuteSolveV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteSolveV1>>, TError,{data: BodyType<PostExecuteSolveV1PayloadOptions>;params?: PostExecuteSolveV1Params}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteSolveV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get the status of an execution
 */
export const postExecuteStatusV1 = (
    postExecuteStatusV1Payload: BodyType<PostExecuteStatusV1Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostExecuteStatusV1Response>(
      {url: `/execute/status/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postExecuteStatusV1Payload
    },
      options);
    }
  


export const getPostExecuteStatusV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteStatusV1>>, TError,{data: BodyType<PostExecuteStatusV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteStatusV1>>, TError,{data: BodyType<PostExecuteStatusV1Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteStatusV1>>, {data: BodyType<PostExecuteStatusV1Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteStatusV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteStatusV1MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteStatusV1>>>
    export type PostExecuteStatusV1MutationBody = BodyType<PostExecuteStatusV1Payload>
    export type PostExecuteStatusV1MutationError = ErrorType<unknown>

    /**
 * @summary Get the status of an execution
 */
export const usePostExecuteStatusV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteStatusV1>>, TError,{data: BodyType<PostExecuteStatusV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteStatusV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Batch transfer NFTs
 */
export const postExecuteTransferV1 = (
    postExecuteTransferV1Payload: BodyType<PostExecuteTransferV1Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostExecuteTransferV1Response>(
      {url: `/execute/transfer/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postExecuteTransferV1Payload
    },
      options);
    }
  


export const getPostExecuteTransferV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteTransferV1>>, TError,{data: BodyType<PostExecuteTransferV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteTransferV1>>, TError,{data: BodyType<PostExecuteTransferV1Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteTransferV1>>, {data: BodyType<PostExecuteTransferV1Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteTransferV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteTransferV1MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteTransferV1>>>
    export type PostExecuteTransferV1MutationBody = BodyType<PostExecuteTransferV1Payload>
    export type PostExecuteTransferV1MutationError = ErrorType<unknown>

    /**
 * @summary Batch transfer NFTs
 */
export const usePostExecuteTransferV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteTransferV1>>, TError,{data: BodyType<PostExecuteTransferV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteTransferV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cancel Imtbl Orders
 */
export const postOrdersCancelimtblV1 = (
    postOrdersCancelImtblV1Payload: BodyType<PostOrdersCancelImtblV1Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/orders/cancel-imtbl/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postOrdersCancelImtblV1Payload
    },
      options);
    }
  


export const getPostOrdersCancelimtblV1MutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrdersCancelimtblV1>>, TError,{data: BodyType<PostOrdersCancelImtblV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postOrdersCancelimtblV1>>, TError,{data: BodyType<PostOrdersCancelImtblV1Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postOrdersCancelimtblV1>>, {data: BodyType<PostOrdersCancelImtblV1Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postOrdersCancelimtblV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostOrdersCancelimtblV1MutationResult = NonNullable<Awaited<ReturnType<typeof postOrdersCancelimtblV1>>>
    export type PostOrdersCancelimtblV1MutationBody = BodyType<PostOrdersCancelImtblV1Payload>
    export type PostOrdersCancelimtblV1MutationError = ErrorType<string>

    /**
 * @summary Cancel Imtbl Orders
 */
export const usePostOrdersCancelimtblV1 = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrdersCancelimtblV1>>, TError,{data: BodyType<PostOrdersCancelImtblV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostOrdersCancelimtblV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * This API requires an allowed API key for execution. Please contact technical support with more questions.
 * @summary Disable or reenable metadata for a token
 */
export const postTokensDisablemetadataV1 = (
    postTokensDisableMetadataV1Payload: BodyType<PostTokensDisableMetadataV1Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostTokensDisableMetadataV1Response>(
      {url: `/tokens/disable-metadata/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postTokensDisableMetadataV1Payload
    },
      options);
    }
  


export const getPostTokensDisablemetadataV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensDisablemetadataV1>>, TError,{data: BodyType<PostTokensDisableMetadataV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postTokensDisablemetadataV1>>, TError,{data: BodyType<PostTokensDisableMetadataV1Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTokensDisablemetadataV1>>, {data: BodyType<PostTokensDisableMetadataV1Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postTokensDisablemetadataV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostTokensDisablemetadataV1MutationResult = NonNullable<Awaited<ReturnType<typeof postTokensDisablemetadataV1>>>
    export type PostTokensDisablemetadataV1MutationBody = BodyType<PostTokensDisableMetadataV1Payload>
    export type PostTokensDisablemetadataV1MutationError = ErrorType<unknown>

    /**
 * @summary Disable or reenable metadata for a token
 */
export const usePostTokensDisablemetadataV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensDisablemetadataV1>>, TError,{data: BodyType<PostTokensDisableMetadataV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostTokensDisablemetadataV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update token flag status
 */
export const postTokensFlagV1 = (
    postTokensFlagV1Payload: BodyType<PostTokensFlagV1Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostTokensFlagV1Response>(
      {url: `/tokens/flag/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postTokensFlagV1Payload
    },
      options);
    }
  


export const getPostTokensFlagV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensFlagV1>>, TError,{data: BodyType<PostTokensFlagV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postTokensFlagV1>>, TError,{data: BodyType<PostTokensFlagV1Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTokensFlagV1>>, {data: BodyType<PostTokensFlagV1Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postTokensFlagV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostTokensFlagV1MutationResult = NonNullable<Awaited<ReturnType<typeof postTokensFlagV1>>>
    export type PostTokensFlagV1MutationBody = BodyType<PostTokensFlagV1Payload>
    export type PostTokensFlagV1MutationError = ErrorType<unknown>

    /**
 * @summary Update token flag status
 */
export const usePostTokensFlagV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensFlagV1>>, TError,{data: BodyType<PostTokensFlagV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostTokensFlagV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Token metadata is never automatically refreshed, but may be manually refreshed with this API.

Caution: This API should be used in moderation, like only when missing data is discovered. Calling it in bulk or programmatically will result in your API key getting rate limited.
 * @summary Refresh Token
 */
export const postTokensRefreshV1 = (
    postTokensRefreshV1Payload: BodyType<PostTokensRefreshV1Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostTokensRefreshV1Response>(
      {url: `/tokens/refresh/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postTokensRefreshV1Payload
    },
      options);
    }
  


export const getPostTokensRefreshV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensRefreshV1>>, TError,{data: BodyType<PostTokensRefreshV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postTokensRefreshV1>>, TError,{data: BodyType<PostTokensRefreshV1Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTokensRefreshV1>>, {data: BodyType<PostTokensRefreshV1Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postTokensRefreshV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostTokensRefreshV1MutationResult = NonNullable<Awaited<ReturnType<typeof postTokensRefreshV1>>>
    export type PostTokensRefreshV1MutationBody = BodyType<PostTokensRefreshV1Payload>
    export type PostTokensRefreshV1MutationError = ErrorType<unknown>

    /**
 * @summary Refresh Token
 */
export const usePostTokensRefreshV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensRefreshV1>>, TError,{data: BodyType<PostTokensRefreshV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostTokensRefreshV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @deprecated
 * @summary Simulate the floor ask of any token
 */
export const postTokensSimulatefloorV1 = (
    postTokensSimulateFloorV1Payload: BodyType<PostTokensSimulateFloorV1Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostTokensSimulateFloorV1Response>(
      {url: `/tokens/simulate-floor/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postTokensSimulateFloorV1Payload
    },
      options);
    }
  


export const getPostTokensSimulatefloorV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensSimulatefloorV1>>, TError,{data: BodyType<PostTokensSimulateFloorV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postTokensSimulatefloorV1>>, TError,{data: BodyType<PostTokensSimulateFloorV1Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTokensSimulatefloorV1>>, {data: BodyType<PostTokensSimulateFloorV1Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postTokensSimulatefloorV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostTokensSimulatefloorV1MutationResult = NonNullable<Awaited<ReturnType<typeof postTokensSimulatefloorV1>>>
    export type PostTokensSimulatefloorV1MutationBody = BodyType<PostTokensSimulateFloorV1Payload>
    export type PostTokensSimulatefloorV1MutationError = ErrorType<unknown>

    /**
 * @deprecated
 * @summary Simulate the floor ask of any token
 */
export const usePostTokensSimulatefloorV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensSimulatefloorV1>>, TError,{data: BodyType<PostTokensSimulateFloorV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostTokensSimulatefloorV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @deprecated
 * @summary Simulate the top bid of any token
 */
export const postTokensSimulatetopbidV1 = (
    postTokensSimulateTopBidV1Payload: BodyType<PostTokensSimulateTopBidV1Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostTokensSimulateTopBidV1Response>(
      {url: `/tokens/simulate-top-bid/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postTokensSimulateTopBidV1Payload
    },
      options);
    }
  


export const getPostTokensSimulatetopbidV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensSimulatetopbidV1>>, TError,{data: BodyType<PostTokensSimulateTopBidV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postTokensSimulatetopbidV1>>, TError,{data: BodyType<PostTokensSimulateTopBidV1Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTokensSimulatetopbidV1>>, {data: BodyType<PostTokensSimulateTopBidV1Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postTokensSimulatetopbidV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostTokensSimulatetopbidV1MutationResult = NonNullable<Awaited<ReturnType<typeof postTokensSimulatetopbidV1>>>
    export type PostTokensSimulatetopbidV1MutationBody = BodyType<PostTokensSimulateTopBidV1Payload>
    export type PostTokensSimulatetopbidV1MutationError = ErrorType<unknown>

    /**
 * @deprecated
 * @summary Simulate the top bid of any token
 */
export const usePostTokensSimulatetopbidV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensSimulatetopbidV1>>, TError,{data: BodyType<PostTokensSimulateTopBidV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostTokensSimulatetopbidV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * This API can be used by allowed API keys to update the spam status of a token.
 * @summary Update the tokens spam status
 */
export const postTokensSpamstatusV1 = (
    postTokensSpamStatusV1Payload: BodyType<PostTokensSpamStatusV1Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostTokensSpamStatusV1Response>(
      {url: `/tokens/spam-status/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postTokensSpamStatusV1Payload
    },
      options);
    }
  


export const getPostTokensSpamstatusV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensSpamstatusV1>>, TError,{data: BodyType<PostTokensSpamStatusV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postTokensSpamstatusV1>>, TError,{data: BodyType<PostTokensSpamStatusV1Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTokensSpamstatusV1>>, {data: BodyType<PostTokensSpamStatusV1Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postTokensSpamstatusV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostTokensSpamstatusV1MutationResult = NonNullable<Awaited<ReturnType<typeof postTokensSpamstatusV1>>>
    export type PostTokensSpamstatusV1MutationBody = BodyType<PostTokensSpamStatusV1Payload>
    export type PostTokensSpamstatusV1MutationError = ErrorType<unknown>

    /**
 * @summary Update the tokens spam status
 */
export const usePostTokensSpamstatusV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensSpamstatusV1>>, TError,{data: BodyType<PostTokensSpamStatusV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostTokensSpamstatusV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Re-activates listings when a token is transferred from one account to another, and then back
 * @summary Immutable Webhook endpoint for the `imtbl_zkevm_activity_transfer` event
 */
export const postWebhooksImtblzkevmactivitytransfereventV1 = (
    hiddenModelBody: BodyType<HiddenModelBody>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostWebhooksImtblZkevmActivityTransferEventV1Response>(
      {url: `/webhooks/imtbl-zkevm-activity-transfer-event/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: hiddenModelBody
    },
      options);
    }
  


export const getPostWebhooksImtblzkevmactivitytransfereventV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmactivitytransfereventV1>>, TError,{data: BodyType<HiddenModelBody>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmactivitytransfereventV1>>, TError,{data: BodyType<HiddenModelBody>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postWebhooksImtblzkevmactivitytransfereventV1>>, {data: BodyType<HiddenModelBody>}> = (props) => {
          const {data} = props ?? {};

          return  postWebhooksImtblzkevmactivitytransfereventV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostWebhooksImtblzkevmactivitytransfereventV1MutationResult = NonNullable<Awaited<ReturnType<typeof postWebhooksImtblzkevmactivitytransfereventV1>>>
    export type PostWebhooksImtblzkevmactivitytransfereventV1MutationBody = BodyType<HiddenModelBody>
    export type PostWebhooksImtblzkevmactivitytransfereventV1MutationError = ErrorType<unknown>

    /**
 * @summary Immutable Webhook endpoint for the `imtbl_zkevm_activity_transfer` event
 */
export const usePostWebhooksImtblzkevmactivitytransfereventV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmactivitytransfereventV1>>, TError,{data: BodyType<HiddenModelBody>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostWebhooksImtblzkevmactivitytransfereventV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Consumer for the Immutable Webhook `imtbl_zkevm_collection_updated` event. This event is triggered when a collection is updated in Immutable, and updates the collection spam and verification status.
 * @summary Immutable Webhook endpoint for the `imtbl_zkevm_collection_updated` event
 */
export const postWebhooksImtblzkevmcollectionupdatedeventV1 = (
    hiddenModelBody: BodyType<HiddenModelBody>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostWebhooksImtblZkevmCollectionUpdatedEventV1Response>(
      {url: `/webhooks/imtbl-zkevm-collection-updated-event/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: hiddenModelBody
    },
      options);
    }
  


export const getPostWebhooksImtblzkevmcollectionupdatedeventV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmcollectionupdatedeventV1>>, TError,{data: BodyType<HiddenModelBody>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmcollectionupdatedeventV1>>, TError,{data: BodyType<HiddenModelBody>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postWebhooksImtblzkevmcollectionupdatedeventV1>>, {data: BodyType<HiddenModelBody>}> = (props) => {
          const {data} = props ?? {};

          return  postWebhooksImtblzkevmcollectionupdatedeventV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostWebhooksImtblzkevmcollectionupdatedeventV1MutationResult = NonNullable<Awaited<ReturnType<typeof postWebhooksImtblzkevmcollectionupdatedeventV1>>>
    export type PostWebhooksImtblzkevmcollectionupdatedeventV1MutationBody = BodyType<HiddenModelBody>
    export type PostWebhooksImtblzkevmcollectionupdatedeventV1MutationError = ErrorType<unknown>

    /**
 * @summary Immutable Webhook endpoint for the `imtbl_zkevm_collection_updated` event
 */
export const usePostWebhooksImtblzkevmcollectionupdatedeventV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmcollectionupdatedeventV1>>, TError,{data: BodyType<HiddenModelBody>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostWebhooksImtblzkevmcollectionupdatedeventV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Triggers token reindexing when a metadata update event is received from Immutable
 * @summary Immutable Webhook endpoint for the `imtbl_zkevm_metadata_updated` event
 */
export const postWebhooksImtblzkevmmetadataupdatedeventV1 = (
    hiddenModelBody: BodyType<HiddenModelBody>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostWebhooksImtblZkevmMetadataUpdatedEventV1Response>(
      {url: `/webhooks/imtbl-zkevm-metadata-updated-event/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: hiddenModelBody
    },
      options);
    }
  


export const getPostWebhooksImtblzkevmmetadataupdatedeventV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmmetadataupdatedeventV1>>, TError,{data: BodyType<HiddenModelBody>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmmetadataupdatedeventV1>>, TError,{data: BodyType<HiddenModelBody>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postWebhooksImtblzkevmmetadataupdatedeventV1>>, {data: BodyType<HiddenModelBody>}> = (props) => {
          const {data} = props ?? {};

          return  postWebhooksImtblzkevmmetadataupdatedeventV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostWebhooksImtblzkevmmetadataupdatedeventV1MutationResult = NonNullable<Awaited<ReturnType<typeof postWebhooksImtblzkevmmetadataupdatedeventV1>>>
    export type PostWebhooksImtblzkevmmetadataupdatedeventV1MutationBody = BodyType<HiddenModelBody>
    export type PostWebhooksImtblzkevmmetadataupdatedeventV1MutationError = ErrorType<unknown>

    /**
 * @summary Immutable Webhook endpoint for the `imtbl_zkevm_metadata_updated` event
 */
export const usePostWebhooksImtblzkevmmetadataupdatedeventV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmmetadataupdatedeventV1>>, TError,{data: BodyType<HiddenModelBody>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostWebhooksImtblzkevmmetadataupdatedeventV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Token metadata updates from Immutable can automatically trigger re-indexing in reservoir via Webhooks
 * @summary Immutable Webhook endpoint for the `imtbl_zkevm_nft_updated` event
 */
export const postWebhooksImtblzkevmnftupdatedeventV1 = (
    hiddenModelBody: BodyType<HiddenModelBody>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostWebhooksImtblZkevmNftUpdatedEventV1Response>(
      {url: `/webhooks/imtbl-zkevm-nft-updated-event/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: hiddenModelBody
    },
      options);
    }
  


export const getPostWebhooksImtblzkevmnftupdatedeventV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmnftupdatedeventV1>>, TError,{data: BodyType<HiddenModelBody>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmnftupdatedeventV1>>, TError,{data: BodyType<HiddenModelBody>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postWebhooksImtblzkevmnftupdatedeventV1>>, {data: BodyType<HiddenModelBody>}> = (props) => {
          const {data} = props ?? {};

          return  postWebhooksImtblzkevmnftupdatedeventV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostWebhooksImtblzkevmnftupdatedeventV1MutationResult = NonNullable<Awaited<ReturnType<typeof postWebhooksImtblzkevmnftupdatedeventV1>>>
    export type PostWebhooksImtblzkevmnftupdatedeventV1MutationBody = BodyType<HiddenModelBody>
    export type PostWebhooksImtblzkevmnftupdatedeventV1MutationError = ErrorType<unknown>

    /**
 * @summary Immutable Webhook endpoint for the `imtbl_zkevm_nft_updated` event
 */
export const usePostWebhooksImtblzkevmnftupdatedeventV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmnftupdatedeventV1>>, TError,{data: BodyType<HiddenModelBody>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostWebhooksImtblzkevmnftupdatedeventV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Re-activates listings when a cancelled order is activated again, usually after a transfer
 * @summary Immutable Webhook endpoint for the `imtbl_zkevm_order_updated` event
 */
export const postWebhooksImtblzkevmorderupdatedeventV1 = (
    hiddenModelBody: BodyType<HiddenModelBody>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostWebhooksImtblZkevmOrderUpdatedEventV1Response>(
      {url: `/webhooks/imtbl-zkevm-order-updated-event/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: hiddenModelBody
    },
      options);
    }
  


export const getPostWebhooksImtblzkevmorderupdatedeventV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmorderupdatedeventV1>>, TError,{data: BodyType<HiddenModelBody>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmorderupdatedeventV1>>, TError,{data: BodyType<HiddenModelBody>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postWebhooksImtblzkevmorderupdatedeventV1>>, {data: BodyType<HiddenModelBody>}> = (props) => {
          const {data} = props ?? {};

          return  postWebhooksImtblzkevmorderupdatedeventV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostWebhooksImtblzkevmorderupdatedeventV1MutationResult = NonNullable<Awaited<ReturnType<typeof postWebhooksImtblzkevmorderupdatedeventV1>>>
    export type PostWebhooksImtblzkevmorderupdatedeventV1MutationBody = BodyType<HiddenModelBody>
    export type PostWebhooksImtblzkevmorderupdatedeventV1MutationError = ErrorType<unknown>

    /**
 * @summary Immutable Webhook endpoint for the `imtbl_zkevm_order_updated` event
 */
export const usePostWebhooksImtblzkevmorderupdatedeventV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmorderupdatedeventV1>>, TError,{data: BodyType<HiddenModelBody>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostWebhooksImtblzkevmorderupdatedeventV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Override collections metadata and royalties
 * @summary Override collections
 */
export const postCollectionsCollectionOverrideV1 = (
    collection: string,
    postCollectionsCollectionOverrideV1Payload: BodyType<PostCollectionsCollectionOverrideV1Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostCollectionsCollectionOverrideV1Response>(
      {url: `/collections/${collection}/override/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postCollectionsCollectionOverrideV1Payload
    },
      options);
    }
  


export const getPostCollectionsCollectionOverrideV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCollectionsCollectionOverrideV1>>, TError,{collection: string;data: BodyType<PostCollectionsCollectionOverrideV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postCollectionsCollectionOverrideV1>>, TError,{collection: string;data: BodyType<PostCollectionsCollectionOverrideV1Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCollectionsCollectionOverrideV1>>, {collection: string;data: BodyType<PostCollectionsCollectionOverrideV1Payload>}> = (props) => {
          const {collection,data} = props ?? {};

          return  postCollectionsCollectionOverrideV1(collection,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostCollectionsCollectionOverrideV1MutationResult = NonNullable<Awaited<ReturnType<typeof postCollectionsCollectionOverrideV1>>>
    export type PostCollectionsCollectionOverrideV1MutationBody = BodyType<PostCollectionsCollectionOverrideV1Payload>
    export type PostCollectionsCollectionOverrideV1MutationError = ErrorType<unknown>

    /**
 * @summary Override collections
 */
export const usePostCollectionsCollectionOverrideV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCollectionsCollectionOverrideV1>>, TError,{collection: string;data: BodyType<PostCollectionsCollectionOverrideV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostCollectionsCollectionOverrideV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get the capacity for indirect filling via a solver
 */
export const postExecuteSolveCapacityV1 = (
    postExecuteSolveCapacityV1PayloadOptions: BodyType<PostExecuteSolveCapacityV1PayloadOptions>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostExecuteSolveCapacityV1Response>(
      {url: `/execute/solve/capacity/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postExecuteSolveCapacityV1PayloadOptions
    },
      options);
    }
  


export const getPostExecuteSolveCapacityV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteSolveCapacityV1>>, TError,{data: BodyType<PostExecuteSolveCapacityV1PayloadOptions>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteSolveCapacityV1>>, TError,{data: BodyType<PostExecuteSolveCapacityV1PayloadOptions>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteSolveCapacityV1>>, {data: BodyType<PostExecuteSolveCapacityV1PayloadOptions>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteSolveCapacityV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteSolveCapacityV1MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteSolveCapacityV1>>>
    export type PostExecuteSolveCapacityV1MutationBody = BodyType<PostExecuteSolveCapacityV1PayloadOptions>
    export type PostExecuteSolveCapacityV1MutationError = ErrorType<unknown>

    /**
 * @summary Get the capacity for indirect filling via a solver
 */
export const usePostExecuteSolveCapacityV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteSolveCapacityV1>>, TError,{data: BodyType<PostExecuteSolveCapacityV1PayloadOptions>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteSolveCapacityV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Simulate any given order
 */
export const postManagementOrdersSimulateV1 = (
    postManagementOrdersSimulateV1Payload: BodyType<PostManagementOrdersSimulateV1Payload>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostManagementOrdersSimulateV1Response>(
      {url: `/management/orders/simulate/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postManagementOrdersSimulateV1Payload
    },
      options);
    }
  


export const getPostManagementOrdersSimulateV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postManagementOrdersSimulateV1>>, TError,{data: BodyType<PostManagementOrdersSimulateV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postManagementOrdersSimulateV1>>, TError,{data: BodyType<PostManagementOrdersSimulateV1Payload>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postManagementOrdersSimulateV1>>, {data: BodyType<PostManagementOrdersSimulateV1Payload>}> = (props) => {
          const {data} = props ?? {};

          return  postManagementOrdersSimulateV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostManagementOrdersSimulateV1MutationResult = NonNullable<Awaited<ReturnType<typeof postManagementOrdersSimulateV1>>>
    export type PostManagementOrdersSimulateV1MutationBody = BodyType<PostManagementOrdersSimulateV1Payload>
    export type PostManagementOrdersSimulateV1MutationError = ErrorType<unknown>

    /**
 * @summary Simulate any given order
 */
export const usePostManagementOrdersSimulateV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postManagementOrdersSimulateV1>>, TError,{data: BodyType<PostManagementOrdersSimulateV1Payload>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostManagementOrdersSimulateV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
